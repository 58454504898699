import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';

import * as L from 'leaflet';
import 'leaflet.markercluster';
import 'leaflet-draw';
import 'leaflet-editable';
import { NavbarAordexComponent } from '../../../../COMPONENTS_UI/navbar/navbar-aordex/navbar-aordex.component';
import { PerfilComponent } from '../../../../COMPONENTS_UI/perfil/perfil.component';
import { ModulosComponent } from '../../../../COMPONENTS_UI/modulos/modulos.component';
import { AlertLoadingComponent } from '../../../../COMPONENTS_UI/alert-loading/alert-loading.component';
import { GpxFerramentasComponent } from '../../../GPX/COMPONENTS/gpx-ferramentas/gpx-ferramentas.component';
import { AlertSucessoComponent } from '../../../../COMPONENTS_UI/alert-sucesso/alert-sucesso.component';
import { InfoImportacaoComponent } from '../info-importacao/info-importacao.component';
import { AlertErroComponent } from '../../../../COMPONENTS_UI/alert-erro/alert-erro.component';
import { ImportacoesMainComponent } from '../importacoes-main/importacoes-main.component';
import { NewEquipeComponent } from '../new-equipe/new-equipe.component';
import { MudarCoordenadaComponent } from '../../../../COMPONENTS_UI/mudar-coordenada/mudar-coordenada.component';
import { ViewEquipeComponent } from '../view-equipe/view-equipe.component';
import { ViewBaseComponent } from '../view-base/view-base.component';
import { NewBaseComponent } from '../new-base/new-base.component';
import { DetalhesRoteiroComponent } from '../detalhes-roteiro/detalhes-roteiro.component';
import { AtribuidasMainComponent } from '../atribuidas-main/atribuidas-main.component';
import { ConcluidasMainComponent } from '../concluidas-main/concluidas-main.component';
import { RoteirosComponent } from '../roteiros/roteiros.component';
import { RejeitadasMainComponent } from '../rejeitadas-main/rejeitadas-main.component';
import { PendentesMainComponent } from '../pendentes-main/pendentes-main.component';
import { DADOS_ALERT } from '../../../../MODELS/DADOS_ALERT';
import { AordexService } from '../../SERVICES/aordex.service';
import { MainService } from '../../../../SERVICES/main.service';
import { environment } from '../../../../../environments/environment.development';
import { COORDENADA } from '../../../../MODELS/COORDENADA';
import { RESPOSTA_PADRAO_SERVIDOR } from '../../../../MODELS/RESPOSTA_PADRAO_SERVIDOR';
import { ALTERA_COORDENADA, BASE, BASE_MAPA, NOTA_DE_SERVICO, NOTA_DE_SERVICO_MAPA, NOTAS_ROTEIRO, ROTEIRO_MAPA } from '../../../../MODELS/MODELS_AORDEX';
import { DETALHES_POLIGONO } from '../../../../MODELS/DETALHES_POLIGONO';



@Component({
  selector: 'app-mapa-main',
  standalone: true,
  imports: [
    NavbarAordexComponent,
    PerfilComponent,
    ModulosComponent,
    AlertLoadingComponent,
    GpxFerramentasComponent,
    AlertSucessoComponent,
    AlertErroComponent,
    InfoImportacaoComponent,
    ImportacoesMainComponent,
    MudarCoordenadaComponent,
    NewEquipeComponent,
    ViewEquipeComponent,
    NewBaseComponent,
    ViewBaseComponent,
    DetalhesRoteiroComponent,



    PendentesMainComponent,
    AtribuidasMainComponent,
    ConcluidasMainComponent,
    RejeitadasMainComponent,
    RoteirosComponent,
  ],
  templateUrl: './mapa-main.component.html',
  styleUrl: './mapa-main.component.css'
})
export class MapaMainComponent implements OnInit,OnDestroy {
  private leaflet = (window as any).L; // Referência ao Leaflet com MarkerCluster

  map!: L.Map;

  Nat_dados_alert?: DADOS_ALERT

  Nat_state_loading: boolean = false

  Nat_status_alert_sucesso: boolean = false
  Out_close_alert_sucesso(status: boolean) {//fecha o alerta de sucesso
    this.Nat_status_alert_sucesso = status
    this.Nat_dados_alert = undefined
  }
  Nat_status_alert_erro: boolean = false
  Out_close_alert_erro(status: boolean) {//fecha o alerta de erro
    this.Nat_status_alert_erro = status
    this.Nat_dados_alert = undefined
  }


  constructor(private router: Router, private service: AordexService, private service_main: MainService) { }

  ngOnInit() {


   

    if (environment.user_atual) {
      this.initMap();
    } else {
      this.router.navigateByUrl('')
    }



  }


  Nat_normalMap: L.TileLayer
  Nat_satelliteMap: L.TileLayer
  Nat_simplifiedMap: L.TileLayer
  Nat_activeLayer: L.TileLayer


  Nat_draw_control: L.Control.Draw //recebe o controle de criacao de poligonos temporarios para seleção e transferencia de itens


  Nat_polygons_transferencia: L.Polygon[] = []

  initMap() {
    // Inicializando o mapa com coordenadas e nível de zoom
    this.map = this.leaflet.map('map', {
      center: [-14.2350, -51.9253],  // Coordenadas do centro inicial do mapa
      zoom: 5,  // Nível de zoom inicial
      maxZoom: 18,  // Definindo o zoom máximo
      minZoom: 3,  // Definindo o zoom mínimo
      editable: true, // Habilita a edição no mapa

    });

    navigator.geolocation.getCurrentPosition(
      (position) => {
        const userLat = position.coords.latitude;
        const userLng = position.coords.longitude;


        this.map.setView([userLat, userLng])

      })

    //clicar no mapa
    this.map.on('click', (event) => {
      if (this.Nat_state_coleta_coordenada) {
        /**
         * se a funcao de copia de coordenada manual estiver ativa chama a funcao que copia para a 
         * area de transferencia a coordenada
         */

        const latlng = event.latlng;
        const coordenada_coletada = latlng


        this.Nat_copy_value(`${coordenada_coletada.lat},${coordenada_coletada.lng}`)
      }
    })


    this.iniciarAtualizacaoPosicoes();



    // Remover o controle de zoom
    //this.map.zoomControl.setPosition('bottomright');


    // Inicializando o controle de desenho com edição habilitada
    this.Nat_draw_control = new L.Control.Draw({
      position: 'bottomleft',

      draw: {
        polyline: false, // Desativando linha poligonal
        polygon: {
          shapeOptions: {
            color: 'blue', // Cor do polígono
            weight: 5, // Espessura da borda
            opacity: 0.5, // Opacidade da borda
            fillOpacity: 0.2, // Opacidade de preenchimento
            fillColor: 'blue', // Cor de preenchimento
          },
        },
        rectangle: false, // Desativando a criação de retângulos
        circle: false, // Desativando a criação de círculos
        marker: false, // Desativando a criação de marcadores
        circlemarker: false
      },
    });


    this.map.addControl(this.Nat_draw_control);


    this.map.on(L.Draw.Event.CREATED, (e: any) => {
      //limpando os dados de poligono selecionado
      this.Nat_detalhes_poligono = undefined

      const type = e.layerType,
        layer = e.layer;

      if (type === 'polygon') {
        // Verifica se já existe algum polígono no array e o remove
        if (this.Nat_polygons_transferencia.length > 0) {
          // Remover o polígono antigo do mapa
          const oldLayer = this.Nat_polygons_transferencia.pop(); // Remove o último polígono
          this.map.removeLayer(oldLayer); // Remove do mapa
        }

        // Habilitando a edição do polígono
        this.map.addLayer(layer);
        layer.enableEdit();
        this.Nat_polygons_transferencia.push(layer);



        // Evento de clique no polígono
        layer.on('click', async () => {


          // Encontrar o índice do polígono no array
          const index = this.Nat_polygons_transferencia.indexOf(layer);

          let ids_Notas_pendentes_mapa: number[] = this.Notas_pendentes_mapa
            .map(nota => nota.ID)



          // Obtendo as coordenadas do polígono
          const latLngs = layer.getLatLngs(); // Isso retorna as coordenadas dos vértices do polígono

          // Transformando as coordenadas em um formato desejado (ex: [lat, lng])
          const coordenadas_poligono: COORDENADA[] = latLngs[0].map((latLng: L.LatLng) => ({
            lat: latLng.lat,
            lng: latLng.lng
          }));

          const requisicao_server: any = await this.service.POST_NOTA_IN_POLIGONO(coordenadas_poligono, ids_Notas_pendentes_mapa, 'AORDEX');
          const resposta: RESPOSTA_PADRAO_SERVIDOR = requisicao_server;

          if (resposta.MSG == 'SUCESSO') {


            this.Nat_id_roteiro_selecionado = undefined

            this.Nat_ids_notas_new_roteiro = []

            this.Nat_ids_notas_new_roteiro = resposta.RESPOSTA

            this.Out_change_state_detalhes_roteiro({
              state: true,
              delete: false
            }) //abre os detalhes do novo possivel roteiro


          } else {
            this.Nat_state_loading = false
            let new_alert: DADOS_ALERT = {
              TITULO: 'ERRO',
              CORPO: resposta.ADICIONAL
            };
            this.Nat_dados_alert = new_alert;
            this.Nat_status_alert_erro = true;
          }
        });

        //Evento de edição do polígono (quando o vértice for movido)
        layer.on('editable:vertex:dragend', async () => {

          // Encontrar o índice do polígono no array
          const index = this.Nat_polygons_transferencia.indexOf(layer);

          let ids_Notas_pendentes_mapa: number[] = this.Notas_pendentes_mapa
            .map(nota => nota.ID)




          // Obtendo as coordenadas do polígono
          const latLngs = layer.getLatLngs(); // Isso retorna as coordenadas dos vértices do polígono

          // Transformando as coordenadas em um formato desejado (ex: [lat, lng])
          const coordenadas_poligono: COORDENADA[] = latLngs[0].map((latLng: L.LatLng) => ({
            lat: latLng.lat,
            lng: latLng.lng
          }));

          const requisicao_server: any = await this.service.POST_NOTA_IN_POLIGONO(coordenadas_poligono, ids_Notas_pendentes_mapa, 'AORDEX');
          const resposta: RESPOSTA_PADRAO_SERVIDOR = requisicao_server;

          if (resposta.MSG == 'SUCESSO') {


            console.log(resposta.RESPOSTA)


          } else {
            this.Nat_state_loading = false
            let new_alert: DADOS_ALERT = {
              TITULO: 'ERRO',
              CORPO: resposta.ADICIONAL
            };
            this.Nat_dados_alert = new_alert;
            this.Nat_status_alert_erro = true;
          }
        });
      }
    });
    this.map.on(L.Draw.Event.DRAWSTART, (e: any) => {

      //limpando os dados de poligono selecionado
      this.Nat_detalhes_poligono = undefined

      if (this.Nat_polygons_transferencia.length > 0) {
        // Remover o polígono antigo do mapa
        const oldLayer = this.Nat_polygons_transferencia.pop(); // Remove o último polígono
        this.map.removeLayer(oldLayer); // Remove do mapa
      }

    })


    // Definindo as camadas de mapa
    this.Nat_normalMap = this.leaflet.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    });

    this.Nat_satelliteMap = this.leaflet.tileLayer('https://services.arcgisonline.com/arcgis/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', {
      attribution: 'Tiles © Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
    });

    this.Nat_simplifiedMap = this.leaflet.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}', {
      attribution: 'Tiles &copy; Esri &mdash; Esri, DeLorme, NAVTEQ',
    });

    // Adicionando o mapa normal inicialmente
    this.Nat_normalMap.addTo(this.map);

    // Inicializando a camada ativa
    this.Nat_activeLayer = this.Nat_normalMap;



  }
  ngOnDestroy() {
    // Limpar o intervalo ao sair do componente
    this.pararAtualizacaoPosicoes();
  }
  iniciarAtualizacaoPosicoes() {
    this.intervaloAtualizacao = setInterval(() => {
      this.Nat_criar_localizacao_usuarios();
    }, 180000); // 3 minutos (180000 ms)
  
    // Para garantir que o intervalo pare quando a aba for fechada
    window.addEventListener('beforeunload', this.pararAtualizacaoPosicoes.bind(this));
  }
  

  pararAtualizacaoPosicoes() {
    if (this.intervaloAtualizacao) {
      clearInterval(this.intervaloAtualizacao);
      this.intervaloAtualizacao = null;
    }
  }

  private intervaloAtualizacao: any;
  Nat_users_mapa:L.Marker[] = []
  async Nat_criar_localizacao_usuarios() {


    //se for CENEGED CEARA atualiza as posicoes do mdm
    if(environment.user_atual.EMPRESA_ID == 11){

      const requisicao_server: any = await this.service.POST_ATUALIZAR_POSICOES_USERS_CNG_CEARA();

    }



    const requisicao_server: any = await this.service.POST_ATUALIZAR_POSICOES_USERS(environment.user_atual.EMPRESA_ID);
    const resposta: RESPOSTA_PADRAO_SERVIDOR = requisicao_server;
  
    if (resposta.MSG === 'SUCESSO') {
      // Remover marcadores existentes
      this.Nat_users_mapa.forEach(marker => marker.remove());
      this.Nat_users_mapa = [];
  
      resposta.RESPOSTA.forEach((user: any) => {
        // Criar o ícone personalizado com HTML e estilo
        const customIcon = L.divIcon({
          className: '', // Remova classes padrão do Leaflet
          html: `
            <div class="rounded-full border-4 border-green-500 w-12 h-12 overflow-hidden">
              <img src="${user.FOTO}" alt="User Icon" class="w-full h-full object-cover">
            </div>
          `,
          iconSize: [48, 48], // Tamanho do ícone
          iconAnchor: [24, 24], // Ponto de ancoragem
          popupAnchor: [0, -24], // Ponto de ancoragem do popup
        });
  
        // Criar o marcador
        const marker = L.marker([user.LAT, user.LNG], { icon: customIcon });
  
        // Adicionar eventos de mouseover para tooltip
        marker.bindTooltip(`Usuário: ${user.NOME}`, {
          permanent: false,
          direction: 'top',
        });
  
        // Adicionar popup ao clicar
        marker.bindPopup(`
          <div class="p-2">
            <p class="text-lg font-bold text-blue-700">${user.NOME}</p>
            <p>Equipe: ${user.EQUIPE}</p>
            <p>Atualizado em: ${user.DATA_ATUALIZACAO}</p>
          </div>
        `, {
          className: 'custom-popup',
          maxWidth: 300,
        });
  
        // Adicionar o marcador ao mapa e ao array
        marker.addTo(this.map);
        this.Nat_users_mapa.push(marker);
      });
  
    
  
    
    } else {
   
  
      let new_alert: DADOS_ALERT = {
        TITULO: resposta.MSG,
        CORPO: resposta.ADICIONAL,
      };
      this.Out_open_alert_erro(new_alert)
    }
  }
  


  Nat_coordenada_local_buscado: L.Marker
  Out_move_local_buscado(coordenada: COORDENADA) {

    if (this.Nat_coordenada_local_buscado) {
      this.Nat_coordenada_local_buscado.remove()
      this.Nat_coordenada_local_buscado = undefined
    }

    const customIcon = L.icon({
      iconUrl: 'https://res.cloudinary.com/de74yvifw/image/upload/v1728406615/EXFERA/btn_coletar_coordenada_fnkvnv.svg', // Caminho da imagem
      iconSize: [32, 32], // Tamanho do ícone [largura, altura]
      iconAnchor: [16, 32], // Ponto de ancoragem do ícone [largura, altura]
      popupAnchor: [0, -32] // Ponto de ancoragem do popup [x, y]
    });

    this.Nat_coordenada_local_buscado = L.marker([coordenada.lat, coordenada.lng], { icon: customIcon }).addTo(this.map);
    this.map.setView([coordenada.lat, coordenada.lng], 15);
    this.Nat_coordenada_local_buscado.on('click', (event) => {
      if (this.Nat_coordenada_local_buscado) {
        this.Nat_coordenada_local_buscado.remove()
        this.Nat_coordenada_local_buscado = undefined
      }
    })

  }

  Notas_pendentes_mapa: NOTA_DE_SERVICO_MAPA[] = []
  Out_view_notas_pendentes(notas: NOTA_DE_SERVICO[]) {
    if(this.Notas_atribuidas_mapa.length>0 || this.Notas_concluidas_mapa.length>0|| this.Notas_rejeitadas_mapa.length>0){
      this.Nat_state_loading = false
      let new_alert: DADOS_ALERT = {
        TITULO: 'ERRO',
        CORPO: 'Remova Notas de Outros Status antes de Visualizar novas!'
      };
      this.Nat_dados_alert = new_alert;
      this.Nat_status_alert_erro = true;
    }else{
    notas.forEach((nota, n) => {

      if (!this.Notas_pendentes_mapa.find((nota_buscada) => nota_buscada.ID == nota.ID)) {

        let url_icon: string = ''

        if (nota.TIPO == 'AF') {

          url_icon = 'https://res.cloudinary.com/de74yvifw/image/upload/v1732403531/ICON_AF_tde57q.svg'

        } else if (nota.TIPO == 'CT') {

          url_icon = 'https://res.cloudinary.com/de74yvifw/image/upload/v1732403531/ICON_CT_e6u9sf.svg'

        } else if (nota.TIPO == 'LN') {

          url_icon = 'https://res.cloudinary.com/de74yvifw/image/upload/v1732403532/ICON_LN_ltrs8w.svg'

        } else if (nota.TIPO == 'MD') {

          url_icon = 'https://res.cloudinary.com/de74yvifw/image/upload/v1732403532/ICON_MD_g39dmw.svg'

        } else if (nota.TIPO == 'RE') {

          url_icon = 'https://res.cloudinary.com/de74yvifw/image/upload/v1732403532/ICON_RE_xisoj0.svg'

        } else if (nota.TIPO == 'VR') {

          url_icon = 'https://res.cloudinary.com/de74yvifw/image/upload/v1732403531/ICON_VR_pzaevk.svg'

        } else if (nota.TIPO == 'LEITURA_PE') {

          url_icon = 'https://res.cloudinary.com/de74yvifw/image/upload/v1732404543/ICON_LEIT_PE_lrqnhd.svg'

        } else if (nota.TIPO == 'LEITURA_VEICULO') {

          url_icon = 'https://res.cloudinary.com/de74yvifw/image/upload/v1732404543/ICON_LEIT_CAR_klm6fo.svg'

        }

        const customIcon = L.icon({
          iconUrl: url_icon, // Caminho da imagem
          iconSize: [32, 32], // Tamanho do ícone [largura, altura]
          iconAnchor: [16, 32], // Ponto de ancoragem do ícone [largura, altura]
          popupAnchor: [0, -32] // Ponto de ancoragem do popup [x, y]
        });

        const marker = L.marker([nota.LAT, nota.LNG], { icon: customIcon }).addTo(this.map);
        let new_nota_mapa: NOTA_DE_SERVICO_MAPA = {
          ID: nota.ID,
          MARKER: marker,
          TEXTO_POP_UP: `
  <div class=" bg-white rounded-lg">
    <p class="text-lg font-semibold text-blue-700">${nota.COD_NOTA}</p>
    <p class="mt-2 text-sm text-gray-600">${nota.TIPO}</p>
    <p class="mt-2 text-sm text-gray-600">${nota.DESC_SERVICO}</p>
     <p class="mt-2 text-sm text-gray-600">${nota.DATA_PREV_CONC_SIS}</p>
     <p class="mt-2 text-sm text-gray-600">${nota.ENDERECO}</p>
 `
        }

        // HTML estilizado com Tailwind para o popup
        const popupContent = `
  <div class=" bg-white rounded-lg">
    <p class="text-lg font-semibold text-blue-700">${nota.COD_NOTA}</p>
    <p class="mt-2 text-sm text-gray-600">${nota.TIPO}</p>
    <p class="mt-2 text-sm text-gray-600">${nota.DESC_SERVICO}</p>
     <p class="mt-2 text-sm text-gray-600">${nota.DATA_PREV_CONC_SIS}</p>
      <p class="mt-2 text-sm text-gray-600">${nota.ENDERECO}</p>
  </div>
`;

        // Associar o popup ao marker
        marker.bindPopup(popupContent, {
          className: 'custom-popup', // Nome para customizações adicionais
          closeButton: true
        });

        this.Notas_pendentes_mapa.push(new_nota_mapa)
      }

    });

  }
  
  
  }
  Out_remove_notas_pendentes(notas: NOTA_DE_SERVICO_MAPA[]) {

    notas.forEach((nota, n) => {
      const index = this.Notas_pendentes_mapa.findIndex((sub_nota) => sub_nota.ID == nota.ID)

      if (index >= 0) {
        this.Notas_pendentes_mapa[index].MARKER.remove()
      }
    });

    let ids_notas_recebidas: number[] = notas.map((sub_nota) => sub_nota.ID)
    this.Notas_pendentes_mapa = this.Notas_pendentes_mapa.filter((sub_nota) => !ids_notas_recebidas.includes(sub_nota.ID))

  }

  Notas_atribuidas_mapa: NOTA_DE_SERVICO_MAPA[] = []
  Out_view_notas_atribuidas(notas: NOTA_DE_SERVICO[]) {


    if(this.Notas_pendentes_mapa.length>0 || this.Notas_concluidas_mapa.length>0|| this.Notas_rejeitadas_mapa.length>0){
      this.Nat_state_loading = false
      let new_alert: DADOS_ALERT = {
        TITULO: 'ERRO',
        CORPO: 'Remova Notas de Outros Status antes de Visualizar novas!'
      };
      this.Nat_dados_alert = new_alert;
      this.Nat_status_alert_erro = true;
    }else{
      notas.forEach((nota, n) => {

        if (!this.Notas_atribuidas_mapa.find((nota_buscada) => nota_buscada.ID == nota.ID)) {
  
          let url_icon: string = ''
  
          if (nota.TIPO == 'AF') {
  
            url_icon = 'https://res.cloudinary.com/de74yvifw/image/upload/v1732403531/ICON_AF_tde57q.svg'
  
          } else if (nota.TIPO == 'CT') {
  
            url_icon = 'https://res.cloudinary.com/de74yvifw/image/upload/v1732403531/ICON_CT_e6u9sf.svg'
  
          } else if (nota.TIPO == 'LN') {
  
            url_icon = 'https://res.cloudinary.com/de74yvifw/image/upload/v1732403532/ICON_LN_ltrs8w.svg'
  
          } else if (nota.TIPO == 'MD') {
  
            url_icon = 'https://res.cloudinary.com/de74yvifw/image/upload/v1732403532/ICON_MD_g39dmw.svg'
  
          } else if (nota.TIPO == 'RE') {
  
            url_icon = 'https://res.cloudinary.com/de74yvifw/image/upload/v1732403532/ICON_RE_xisoj0.svg'
  
          } else if (nota.TIPO == 'VR') {
  
            url_icon = 'https://res.cloudinary.com/de74yvifw/image/upload/v1732403531/ICON_VR_pzaevk.svg'
  
          } else if (nota.TIPO == 'LEITURA_PE') {
  
            url_icon = 'https://res.cloudinary.com/de74yvifw/image/upload/v1732404543/ICON_LEIT_PE_lrqnhd.svg'
  
          } else if (nota.TIPO == 'LEITURA_VEICULO') {
  
            url_icon = 'https://res.cloudinary.com/de74yvifw/image/upload/v1732404543/ICON_LEIT_CAR_klm6fo.svg'
  
          }
  
          const customIcon = L.icon({
            iconUrl: url_icon, // Caminho da imagem
            iconSize: [32, 32], // Tamanho do ícone [largura, altura]
            iconAnchor: [16, 32], // Ponto de ancoragem do ícone [largura, altura]
            popupAnchor: [0, -32] // Ponto de ancoragem do popup [x, y]
          });
  
          const marker = L.marker([nota.LAT, nota.LNG], { icon: customIcon }).addTo(this.map);
          let new_nota_mapa: NOTA_DE_SERVICO_MAPA = {
            ID: nota.ID,
            MARKER: marker,
            TEXTO_POP_UP: `
    <div class=" bg-white rounded-lg">
      <p class="text-lg font-semibold text-blue-700">${nota.COD_NOTA}</p>
      <p class="mt-2 text-sm text-gray-600">${nota.TIPO}</p>
      <p class="mt-2 text-sm text-gray-600">${nota.DESC_SERVICO}</p>
       <p class="mt-2 text-sm text-gray-600">${nota.DATA_PREV_CONC_SIS}</p>
       <p class="mt-2 text-sm text-gray-600">${nota.ENDERECO}</p>
   `
          }
  
          // HTML estilizado com Tailwind para o popup
          const popupContent = `
    <div class=" bg-white rounded-lg">
      <p class="text-lg font-semibold text-blue-700">${nota.COD_NOTA}</p>
      <p class="mt-2 text-sm text-gray-600">${nota.TIPO}</p>
      <p class="mt-2 text-sm text-gray-600">${nota.DESC_SERVICO}</p>
       <p class="mt-2 text-sm text-gray-600">${nota.DATA_PREV_CONC_SIS}</p>
        <p class="mt-2 text-sm text-gray-600">${nota.ENDERECO}</p>
    </div>
  `;
  
          // Associar o popup ao marker
          marker.bindPopup(popupContent, {
            className: 'custom-popup', // Nome para customizações adicionais
            closeButton: true
          });
  
          this.Notas_atribuidas_mapa.push(new_nota_mapa)
        }
  
      });
    }




  }
  Out_remove_notas_atribuidas(notas: NOTA_DE_SERVICO_MAPA[]) {

    notas.forEach((nota, n) => {
      const index = this.Notas_atribuidas_mapa.findIndex((sub_nota) => sub_nota.ID == nota.ID)

      if (index >= 0) {
        this.Notas_atribuidas_mapa[index].MARKER.remove()
      }
    });

    let ids_notas_recebidas: number[] = notas.map((sub_nota) => sub_nota.ID)
    this.Notas_atribuidas_mapa = this.Notas_atribuidas_mapa.filter((sub_nota) => !ids_notas_recebidas.includes(sub_nota.ID))

  }


  Notas_concluidas_mapa: NOTA_DE_SERVICO_MAPA[] = []
  Out_view_notas_concluidas(notas: NOTA_DE_SERVICO[]) {


    if(this.Notas_pendentes_mapa.length>0 || this.Notas_atribuidas_mapa.length>0 || this.Notas_rejeitadas_mapa.length>0){
      this.Nat_state_loading = false
      let new_alert: DADOS_ALERT = {
        TITULO: 'ERRO',
        CORPO: 'Remova Notas de Outros Status antes de Visualizar novas!'
      };
      this.Nat_dados_alert = new_alert;
      this.Nat_status_alert_erro = true;
    }else{
      notas.forEach((nota, n) => {

        if (!this.Notas_concluidas_mapa.find((nota_buscada) => nota_buscada.ID == nota.ID)) {
  
          let url_icon: string = ''
  
          if (nota.TIPO == 'AF') {
  
            url_icon = 'https://res.cloudinary.com/de74yvifw/image/upload/v1732403531/ICON_AF_tde57q.svg'
  
          } else if (nota.TIPO == 'CT') {
  
            url_icon = 'https://res.cloudinary.com/de74yvifw/image/upload/v1732403531/ICON_CT_e6u9sf.svg'
  
          } else if (nota.TIPO == 'LN') {
  
            url_icon = 'https://res.cloudinary.com/de74yvifw/image/upload/v1732403532/ICON_LN_ltrs8w.svg'
  
          } else if (nota.TIPO == 'MD') {
  
            url_icon = 'https://res.cloudinary.com/de74yvifw/image/upload/v1732403532/ICON_MD_g39dmw.svg'
  
          } else if (nota.TIPO == 'RE') {
  
            url_icon = 'https://res.cloudinary.com/de74yvifw/image/upload/v1732403532/ICON_RE_xisoj0.svg'
  
          } else if (nota.TIPO == 'VR') {
  
            url_icon = 'https://res.cloudinary.com/de74yvifw/image/upload/v1732403531/ICON_VR_pzaevk.svg'
  
          } else if (nota.TIPO == 'LEITURA_PE') {
  
            url_icon = 'https://res.cloudinary.com/de74yvifw/image/upload/v1732404543/ICON_LEIT_PE_lrqnhd.svg'
  
          } else if (nota.TIPO == 'LEITURA_VEICULO') {
  
            url_icon = 'https://res.cloudinary.com/de74yvifw/image/upload/v1732404543/ICON_LEIT_CAR_klm6fo.svg'
  
          }
  
          const customIcon = L.icon({
            iconUrl: url_icon, // Caminho da imagem
            iconSize: [32, 32], // Tamanho do ícone [largura, altura]
            iconAnchor: [16, 32], // Ponto de ancoragem do ícone [largura, altura]
            popupAnchor: [0, -32] // Ponto de ancoragem do popup [x, y]
          });
  
          const marker = L.marker([nota.LAT, nota.LNG], { icon: customIcon }).addTo(this.map);
          let new_nota_mapa: NOTA_DE_SERVICO_MAPA = {
            ID: nota.ID,
            MARKER: marker,
            TEXTO_POP_UP: `
   <div class=" bg-white rounded-lg">
      <p class="text-lg font-semibold text-blue-700">${nota.COD_NOTA}</p>
      <p class="mt-2 text-sm text-gray-600">${nota.TIPO}</p>
      <p class="mt-2 text-sm text-gray-600">${nota.DESC_SERVICO}</p>
       <p class="mt-2 text-sm text-gray-600">PREVISTA PARA: ${nota.DATA_MANUAL_CONC_STR}</p>
       <p class="mt-2 text-sm text-gray-600">CONCLUÍDA EM: ${nota.DATA_CONCLUSAO_STR}</p>
       <p class="mt-2 text-sm text-gray-600"> ${nota.STATUS_CONC}</p>
        <p class="mt-2 text-sm text-gray-600"> ${nota.STATUS}</p>
        <p class="mt-2 text-sm text-gray-600"> ${nota.OBSERVACAO}</p>
       <p class="mt-2 text-sm text-gray-600">${nota.ENDERECO}</p>
   `
          }
  
          // HTML estilizado com Tailwind para o popup
          const popupContent = `
   <div class=" bg-white rounded-lg">
      <p class="text-lg font-semibold text-blue-700">${nota.COD_NOTA}</p>
      <p class="mt-2 text-sm text-gray-600">${nota.TIPO}</p>
      <p class="mt-2 text-sm text-gray-600">${nota.DESC_SERVICO}</p>
       <p class="mt-2 text-sm text-gray-600">PREVISTA PARA: ${nota.DATA_MANUAL_CONC_STR}</p>
       <p class="mt-2 text-sm text-gray-600">CONCLUÍDA EM: ${nota.DATA_CONCLUSAO_STR}</p>
       <p class="mt-2 text-sm text-gray-600"> ${nota.STATUS_CONC}</p>
        <p class="mt-2 text-sm text-gray-600"> ${nota.STATUS}</p>
        <p class="mt-2 text-sm text-gray-600"> ${nota.OBSERVACAO}</p>
       <p class="mt-2 text-sm text-gray-600">${nota.ENDERECO}</p>
  `;
  
          // Associar o popup ao marker
          marker.bindPopup(popupContent, {
            className: 'custom-popup', // Nome para customizações adicionais
            closeButton: true
          });
  
          this.Notas_concluidas_mapa.push(new_nota_mapa)
        }
  
      });
    }




  }
  Out_remove_notas_concluidas(notas: NOTA_DE_SERVICO_MAPA[]) {

    notas.forEach((nota, n) => {
      const index = this.Notas_concluidas_mapa.findIndex((sub_nota) => sub_nota.ID == nota.ID)

      if (index >= 0) {
        this.Notas_concluidas_mapa[index].MARKER.remove()
      }
    });

    let ids_notas_recebidas: number[] = notas.map((sub_nota) => sub_nota.ID)
    this.Notas_concluidas_mapa = this.Notas_concluidas_mapa.filter((sub_nota) => !ids_notas_recebidas.includes(sub_nota.ID))

  }


  Notas_rejeitadas_mapa: NOTA_DE_SERVICO_MAPA[] = []
  Out_view_notas_rejeitadas(notas: NOTA_DE_SERVICO[]) {


    if(this.Notas_pendentes_mapa.length>0 || this.Notas_atribuidas_mapa.length>0 || this.Notas_concluidas_mapa.length>0){
      this.Nat_state_loading = false
      let new_alert: DADOS_ALERT = {
        TITULO: 'ERRO',
        CORPO: 'Remova Notas de Outros Status antes de Visualizar novas!'
      };
      this.Nat_dados_alert = new_alert;
      this.Nat_status_alert_erro = true;
    }else{
      notas.forEach((nota, n) => {

        if (!this.Notas_rejeitadas_mapa.find((nota_buscada) => nota_buscada.ID == nota.ID)) {
  
          let url_icon: string = ''
  
          if (nota.TIPO == 'AF') {
  
            url_icon = 'https://res.cloudinary.com/de74yvifw/image/upload/v1732403531/ICON_AF_tde57q.svg'
  
          } else if (nota.TIPO == 'CT') {
  
            url_icon = 'https://res.cloudinary.com/de74yvifw/image/upload/v1732403531/ICON_CT_e6u9sf.svg'
  
          } else if (nota.TIPO == 'LN') {
  
            url_icon = 'https://res.cloudinary.com/de74yvifw/image/upload/v1732403532/ICON_LN_ltrs8w.svg'
  
          } else if (nota.TIPO == 'MD') {
  
            url_icon = 'https://res.cloudinary.com/de74yvifw/image/upload/v1732403532/ICON_MD_g39dmw.svg'
  
          } else if (nota.TIPO == 'RE') {
  
            url_icon = 'https://res.cloudinary.com/de74yvifw/image/upload/v1732403532/ICON_RE_xisoj0.svg'
  
          } else if (nota.TIPO == 'VR') {
  
            url_icon = 'https://res.cloudinary.com/de74yvifw/image/upload/v1732403531/ICON_VR_pzaevk.svg'
  
          } else if (nota.TIPO == 'LEITURA_PE') {
  
            url_icon = 'https://res.cloudinary.com/de74yvifw/image/upload/v1732404543/ICON_LEIT_PE_lrqnhd.svg'
  
          } else if (nota.TIPO == 'LEITURA_VEICULO') {
  
            url_icon = 'https://res.cloudinary.com/de74yvifw/image/upload/v1732404543/ICON_LEIT_CAR_klm6fo.svg'
  
          }
  
          const customIcon = L.icon({
            iconUrl: url_icon, // Caminho da imagem
            iconSize: [32, 32], // Tamanho do ícone [largura, altura]
            iconAnchor: [16, 32], // Ponto de ancoragem do ícone [largura, altura]
            popupAnchor: [0, -32] // Ponto de ancoragem do popup [x, y]
          });
  
          const marker = L.marker([nota.LAT, nota.LNG], { icon: customIcon }).addTo(this.map);
          let new_nota_mapa: NOTA_DE_SERVICO_MAPA = {
            ID: nota.ID,
            MARKER: marker,
            TEXTO_POP_UP: `
    <div class=" bg-white rounded-lg">
      <p class="text-lg font-semibold text-blue-700">${nota.COD_NOTA}</p>
      <p class="mt-2 text-sm text-gray-600">${nota.TIPO}</p>
      <p class="mt-2 text-sm text-gray-600">${nota.DESC_SERVICO}</p>
       <p class="mt-2 text-sm text-gray-600">PREVISTA PARA: ${nota.DATA_MANUAL_CONC_STR}</p>
       <p class="mt-2 text-sm text-gray-600">CONCLUÍDA EM: ${nota.DATA_CONCLUSAO_STR}</p>
       <p class="mt-2 text-sm text-gray-600"> ${nota.STATUS_CONC}</p>
        <p class="mt-2 text-sm text-gray-600"> ${nota.STATUS}</p>
        <p class="mt-2 text-sm text-gray-600"> ${nota.OBSERVACAO}</p>
       <p class="mt-2 text-sm text-gray-600">${nota.ENDERECO}</p>
   `
          }
  
          // HTML estilizado com Tailwind para o popup
          const popupContent = `
   <div class=" bg-white rounded-lg">
      <p class="text-lg font-semibold text-blue-700">${nota.COD_NOTA}</p>
      <p class="mt-2 text-sm text-gray-600">${nota.TIPO}</p>
      <p class="mt-2 text-sm text-gray-600">${nota.DESC_SERVICO}</p>
       <p class="mt-2 text-sm text-gray-600">PREVISTA PARA: ${nota.DATA_MANUAL_CONC_STR}</p>
       <p class="mt-2 text-sm text-gray-600">CONCLUÍDA EM: ${nota.DATA_CONCLUSAO_STR}</p>
       <p class="mt-2 text-sm text-gray-600"> ${nota.STATUS_CONC}</p>
        <p class="mt-2 text-sm text-gray-600"> ${nota.STATUS}</p>
        <p class="mt-2 text-sm text-gray-600"> ${nota.OBSERVACAO}</p>
       <p class="mt-2 text-sm text-gray-600">${nota.ENDERECO}</p>
  `;
  
          // Associar o popup ao marker
          marker.bindPopup(popupContent, {
            className: 'custom-popup', // Nome para customizações adicionais
            closeButton: true
          });
  
          this.Notas_rejeitadas_mapa.push(new_nota_mapa)
        }
  
      });
    }




  }
  Out_remove_notas_rejeitadas(notas: NOTA_DE_SERVICO_MAPA[]) {

    notas.forEach((nota, n) => {
      const index = this.Notas_rejeitadas_mapa.findIndex((sub_nota) => sub_nota.ID == nota.ID)

      if (index >= 0) {
        this.Notas_rejeitadas_mapa[index].MARKER.remove()
      }
    });

    let ids_notas_recebidas: number[] = notas.map((sub_nota) => sub_nota.ID)
    this.Notas_rejeitadas_mapa = this.Notas_rejeitadas_mapa.filter((sub_nota) => !ids_notas_recebidas.includes(sub_nota.ID))

  }

  //roteiros atualmente em visualizacao
  Nat_roteiros_em_mapa:ROTEIRO_MAPA[] = []
  Out_view_roteiro(roteiro_recebido:NOTAS_ROTEIRO){
 // Inicializa o objeto do roteiro
 let roteiro: ROTEIRO_MAPA = {
  ID: roteiro_recebido.ID,
  PONTOS: [],
  LINHA_1: undefined,
  LINHA_2: undefined,
  TIMER: undefined,
  POLYGONO: undefined,
};

const linhaCoordenadas: [number, number][] = roteiro_recebido.COORDENADAS_LINHAS.map((coord: COORDENADA) => [coord.lat, coord.lng]); // Inverte para [lat, lng]

// Linha completa (opcional)
roteiro.LINHA_1 = L.polyline(linhaCoordenadas, {
  color: 'gray', // Cor de fundo
  weight: 6,
  opacity: 0.5,
}).addTo(this.map);

// Linha animada (verde)
roteiro.LINHA_2 = L.polyline([], { // Inicialmente vazia
  color: 'green',
  weight: 6,
  opacity: 1,
}).addTo(this.map);

// Adiciona os marcadores dos pontos ao mapa
roteiro_recebido.NOTAS.forEach((ponto) => {

  let url_icon: string = ''
  
  if (ponto.TIPO == 'AF') {

    url_icon = 'https://res.cloudinary.com/de74yvifw/image/upload/v1732403531/ICON_AF_tde57q.svg'

  } else if (ponto.TIPO == 'CT') {

    url_icon = 'https://res.cloudinary.com/de74yvifw/image/upload/v1732403531/ICON_CT_e6u9sf.svg'

  } else if (ponto.TIPO == 'LN') {

    url_icon = 'https://res.cloudinary.com/de74yvifw/image/upload/v1732403532/ICON_LN_ltrs8w.svg'

  } else if (ponto.TIPO == 'MD') {

    url_icon = 'https://res.cloudinary.com/de74yvifw/image/upload/v1732403532/ICON_MD_g39dmw.svg'

  } else if (ponto.TIPO == 'RE') {

    url_icon = 'https://res.cloudinary.com/de74yvifw/image/upload/v1732403532/ICON_RE_xisoj0.svg'

  } else if (ponto.TIPO == 'VR') {

    url_icon = 'https://res.cloudinary.com/de74yvifw/image/upload/v1732403531/ICON_VR_pzaevk.svg'

  } else if (ponto.TIPO == 'LEITURA_PE') {

    url_icon = 'https://res.cloudinary.com/de74yvifw/image/upload/v1732404543/ICON_LEIT_PE_lrqnhd.svg'

  } else if (ponto.TIPO == 'LEITURA_VEICULO') {

    url_icon = 'https://res.cloudinary.com/de74yvifw/image/upload/v1732404543/ICON_LEIT_CAR_klm6fo.svg'

  }

  const customIcon = L.icon({
    iconUrl: url_icon, // Caminho da imagem
    iconSize: [32, 32], // Tamanho do ícone [largura, altura]
    iconAnchor: [16, 32], // Ponto de ancoragem do ícone [largura, altura]
    popupAnchor: [0, -32] // Ponto de ancoragem do popup [x, y]
  });

  const marker = L.marker([ponto.LAT, ponto.LNG], { icon: customIcon }).addTo(this.map);
  const popupContent = `
    <b>Ponto ${ponto.SEQUENCIA}, ID ${ponto.ID}</b><br>
    Data Prevista: ${ponto.DATA_MANUAL_CONC}<br>
    Data Conclusão: ${ponto.DATA_CONCLUSAO}<br>
    Status: ${ponto.STATUS}<br>
    Serviço: ${ponto.DESC_SERVICO}<br>
    
  `;

 // Associar o popup ao marker
 marker.bindPopup(popupContent, {
  className: 'custom-popup', // Nome para customizações adicionais
  closeButton: true
});


roteiro.PONTOS.push(marker)



});

// Variáveis de controle da animação
let currentIndex = 1; // Começa no segundo ponto
let restartAnimation = () => {
  currentIndex = 1; // Reinicia o índice
  roteiro.LINHA_2.setLatLngs([]); // Limpa a linha animada
};

// Timer para animar a linha
roteiro.TIMER = setInterval(() => {
  if (currentIndex < linhaCoordenadas.length) {
    // Adiciona o próximo ponto ao trajeto animado
    roteiro.LINHA_2.addLatLng(linhaCoordenadas[currentIndex]);
    currentIndex++;
  } else {
    // Reinicia a animação
    restartAnimation();
  }
}, 100); // Ajuste o intervalo para controlar a velocidade da animação

   let index_roteiro = this.Nat_roteiros_em_mapa.findIndex((roteiro_)=>roteiro.ID ==roteiro_.ID)

    if(index_roteiro != -1){
      this.Nat_roteiros_em_mapa[index_roteiro] = roteiro
    }else{
      this.Nat_roteiros_em_mapa.push(roteiro)
    }

  }

  Out_remove_roteiro(roteiro:ROTEIRO_MAPA){

    console.log(roteiro)
    
      const index = this.Nat_roteiros_em_mapa.findIndex((sub_roteiro) => sub_roteiro.ID == roteiro.ID)

      if (index != -1) {
        this.Nat_roteiros_em_mapa[index].LINHA_1.remove()
        this.Nat_roteiros_em_mapa[index].LINHA_2.remove()
        this.Nat_roteiros_em_mapa[index].PONTOS.forEach((ponto:L.Marker,p)=>{
          ponto.remove()
        })

        clearInterval(this.Nat_roteiros_em_mapa[index].TIMER);
      }
  


    this.Nat_roteiros_em_mapa = this.Nat_roteiros_em_mapa.filter((sub_roteiro) => sub_roteiro.ID != roteiro.ID)
  }


  Nat_state_nova_coordenada: boolean = false
  Nat_id_muda_coordenada: number
  Out_change_state_nova_coordenada(OBJ_CHANGE: ALTERA_COORDENADA) {
    this.Nat_state_nova_coordenada = OBJ_CHANGE.ATIVO

    if (OBJ_CHANGE.ATIVO == true) {
      this.Nat_id_muda_coordenada = OBJ_CHANGE.ID_NOTA
    }
  }

  Out_change_transferencia(state: boolean) {
    this.Nat_state_transferencia_itens = state
    if (state == true) {
      this.map.addControl(this.Nat_draw_control);
    } else {
      this.Nat_detalhes_poligono = undefined


      // Verifica se já existe algum polígono no array e o remove
      if (this.Nat_polygons_transferencia.length > 0) {
        // Remover o polígono antigo do mapa
        const oldLayer = this.Nat_polygons_transferencia.pop(); // Remove o último polígono
        this.map.removeLayer(oldLayer); // Remove do mapa
      }
      this.map.removeControl(this.Nat_draw_control)
    }


  }

  Nat_state_new_equipe: boolean = false
  Nat_change_state_new_equipe(state: boolean) {
    this.Nat_state_new_equipe = state
  }
  Nat_state_view_equipe: boolean = false
  Nat_change_state_view_equipe(state: boolean) {
    this.Nat_state_view_equipe = state
  }


  Nat_state_new_base: boolean = false
  Nat_change_state_new_base(state: boolean) {
    this.Nat_state_new_base = state
  }
  Nat_state_view_base: boolean = false
  Nat_change_state_view_base(state: boolean) {

    this.Nat_state_view_base = state
  }

  Nat_bases_on_map: BASE_MAPA[] = []
  async Nat_change_state_open_bases(state: boolean) {
    if (state == true) {

      const requisicao_server: any = await this.service.POST_BUSCAR_BASES(environment.user_atual.EMPRESA_ID);
      const resposta: RESPOSTA_PADRAO_SERVIDOR = requisicao_server;

      if (resposta.MSG == 'SUCESSO') {


        let new_bases: BASE_MAPA[] = []


        let url_icon = 'https://res.cloudinary.com/de74yvifw/image/upload/v1732889077/base_icone_hk1apf.svg'
        const customIcon = L.icon({
          iconUrl: url_icon, // Caminho da imagem
          iconSize: [64, 64], // Tamanho do ícone [largura, altura]
          iconAnchor: [32, 64], // Ponto de ancoragem do ícone [largura, altura]
          popupAnchor: [0, -64] // Ponto de ancoragem do popup [x, y]
        });


        resposta.RESPOSTA.forEach((base: BASE, b) => {

          const marker = L.marker([base.LAT, base.LNG], { icon: customIcon }).addTo(this.map);
          let new_base: BASE_MAPA = {
            ID: base.ID,
            NOME: base.NOME,
            OBSERVACAO: base.OBSERVACAO,
            MARKER: marker
          }

          // HTML estilizado com Tailwind para o popup
          const popupContent = `
  <div class=" bg-white rounded-lg">
    <p class="text-lg font-semibold text-blue-700">${base.NOME}</p>
    <p class="mt-2 text-sm text-gray-600">${base.OBSERVACAO}</p>
    
  </div>
`;

          // Associar o popup ao marker
          marker.bindPopup(popupContent, {
            className: 'custom-popup', // Nome para customizações adicionais
            closeButton: true
          });
          new_bases.push(new_base)
        });
        this.Nat_bases_on_map = new_bases

      } else {
        this.Nat_state_loading = false
        let new_alert: DADOS_ALERT = {
          TITULO: 'ERRO',
          CORPO: resposta.ADICIONAL
        };
        this.Nat_dados_alert = new_alert;
        this.Nat_status_alert_erro = true;
      }

    } else {
      this.Nat_bases_on_map.forEach((base, b) => {
        this.Nat_bases_on_map[b].MARKER.remove()
      });
      this.Nat_bases_on_map = []
    }
  }

  Nat_state_change_importacoes: boolean = false
  Out_change_importacoes() {
    this.Nat_state_change_importacoes = !this.Nat_state_change_importacoes
  }
  Nat_state_change_pendentes: boolean = false
  Out_change_pendentes() {
    this.Nat_state_change_pendentes = !this.Nat_state_change_pendentes
  }
  Nat_state_change_atribuidas: boolean = false
  Out_change_atribuidas() {
    this.Nat_state_change_atribuidas = !this.Nat_state_change_atribuidas
  }
  Nat_state_change_concluidas: boolean = false
  Out_change_concluidas() {
    this.Nat_state_change_concluidas = !this.Nat_state_change_concluidas
  }
  Nat_state_change_rejeitadas: boolean = false
  Out_change_rejeitadas() {
    this.Nat_state_change_rejeitadas = !this.Nat_state_change_rejeitadas
  }
  Nat_state_change_roteiros: boolean = false
  Out_change_roteiros() {
    this.Nat_state_change_roteiros = !this.Nat_state_change_roteiros
  }

  Nat_state_informacao_importacao: boolean = false
  Out_change_informacao_importacao(state: boolean) {
    this.Nat_state_informacao_importacao = !this.Nat_state_informacao_importacao
  }

  Nat_state_FERRAMENTAS: boolean = false
  Out_change_aba_FERRAMENTAS(state: boolean) {//altera o estado da aba edicoes
    this.Nat_state_FERRAMENTAS = state

    if (state = true) {//se estiver abrindo, tem que fechar as demais abas

      this.Nat_state_MODULOS = false
    }
  }
  Nat_state_MODULOS: boolean = false
  Out_change_aba_MODULOS(state: boolean) {//altera o estado da aba de modulos
    this.Nat_state_MODULOS = state

    if (state = true) {//se estiver abrindo, tem que fechar as demais abas

      this.Nat_state_FERRAMENTAS = false
    }
  }
  Nat_style_map_index: number = 0
  Out_change_map_type(variavel_inutil: any) { //altera a visualizacao do mapa entre normal, satelite e simplificado

    // Remover a camada atual
    this.map.removeLayer(this.Nat_activeLayer);



    if (this.Nat_style_map_index == 0) {
      this.Nat_style_map_index = 1
      this.Nat_activeLayer = this.Nat_satelliteMap;
      this.Nat_activeLayer.addTo(this.map);
    } else if (this.Nat_style_map_index == 1) {
      this.Nat_style_map_index = 2
      this.Nat_activeLayer = this.Nat_simplifiedMap;
      this.Nat_activeLayer.addTo(this.map);
    } else if (this.Nat_style_map_index == 2) {
      this.Nat_style_map_index = 0
      this.Nat_activeLayer = this.Nat_normalMap;
      this.Nat_activeLayer.addTo(this.map);
    }



  }

  Nat_state_perfil: boolean = false

  Out_change_state_perfil(state: boolean) {
    this.Nat_state_perfil = state
  }

  Nat_state_detalhes_roteiro: boolean = false
  Nat_roteiros_mapa: ROTEIRO_MAPA[] = []
  Nat_id_roteiro_selecionado: number
  Nat_ids_notas_new_roteiro: number[] = []


  /**sempre que o user calcular um novo roteiro que pode ou nao ser salvo e atribuido os dados
   * necessarios para que ele visualize previamente ao save, como: percurso e linhas, popups com informacoes de percurso etc
   * ficam armazenados aqui, sendo deletados sempre que a aba é fechada
   */
  Nat_calculo_new_roteiro: ROTEIRO_MAPA
  Out_recebe_calculo_new_roteiro(objeto_retorno: any) {

    console.log(objeto_retorno);

    // Inicializa o objeto do roteiro
    let roteiro: ROTEIRO_MAPA = {
      ID: 0,
      PONTOS: [],
      LINHA_1: undefined,
      LINHA_2: undefined,
      TIMER: undefined,
      POLYGONO: undefined,
    };

    // Coordenadas da geometria retornada pelo OSRM
    const osrmCoordinates = objeto_retorno.osrmData.trips[0].geometry.coordinates; // Array de [lng, lat]
    const linhaCoordenadas: [number, number][] = osrmCoordinates.map((coord: [number, number]) => [coord[1], coord[0]]); // Inverte para [lat, lng]

    // Linha completa (opcional)
    roteiro.LINHA_1 = L.polyline(linhaCoordenadas, {
      color: 'gray', // Cor de fundo
      weight: 6,
      opacity: 0.5,
    }).addTo(this.map);

    // Linha animada (verde)
    roteiro.LINHA_2 = L.polyline([], { // Inicialmente vazia
      color: 'green',
      weight: 6,
      opacity: 1,
    }).addTo(this.map);

    // Adiciona os marcadores dos pontos ao mapa
    objeto_retorno.pontos.forEach((ponto) => {
      const popupContent = `
        <b>Ponto ${ponto.SEQUENCIA}, ID ${ponto.ID}</b><br>
        Horário visita: ${ponto.HORARIO_VISITA}<br>
        Distância acumulada: ${(ponto.PERCURSO_ACUMULADO / 1000).toFixed(2)} km
      `;





      const index = this.Notas_pendentes_mapa.findIndex((nota) => nota.ID == ponto.ID)

      if (index != -1) {
        this.Notas_pendentes_mapa[index].MARKER.bindPopup(popupContent);


      }



    });

    // Variáveis de controle da animação
    let currentIndex = 1; // Começa no segundo ponto
    let restartAnimation = () => {
      currentIndex = 1; // Reinicia o índice
      roteiro.LINHA_2.setLatLngs([]); // Limpa a linha animada
    };

    // Timer para animar a linha
    roteiro.TIMER = setInterval(() => {
      if (currentIndex < linhaCoordenadas.length) {
        // Adiciona o próximo ponto ao trajeto animado
        roteiro.LINHA_2.addLatLng(linhaCoordenadas[currentIndex]);
        currentIndex++;
      } else {
        // Reinicia a animação
        restartAnimation();
      }
    }, 100); // Ajuste o intervalo para controlar a velocidade da animação

    // Atualiza a referência do roteiro
    this.Nat_calculo_new_roteiro = roteiro;
  }



  Out_change_state_detalhes_roteiro(state_and_delete:{state:boolean,delete:boolean}) {
    this.Nat_state_detalhes_roteiro = state_and_delete.state

    if (state_and_delete.state == false) {
      this.Nat_calculo_new_roteiro.LINHA_1.remove()
      this.Nat_calculo_new_roteiro.LINHA_2.remove()
      // this.Nat_calculo_new_roteiro.POLYGONO.remove()
      this.Notas_pendentes_mapa.forEach((nota, n) => {
        const popupContent = nota.TEXTO_POP_UP



        this.Notas_pendentes_mapa[n].MARKER.bindPopup(popupContent);

      });


      if (this.Nat_calculo_new_roteiro?.TIMER) {
        clearInterval(this.Nat_calculo_new_roteiro.TIMER);
      }


      if(state_and_delete.delete == true){
        this.Notas_pendentes_mapa.forEach((nota,n)=>{
          this.Notas_pendentes_mapa[n].MARKER.remove()
        })
        this.Notas_pendentes_mapa = []
      }

    }
  }

  Nat_state_coleta_coordenada: boolean = false //se ativo esta coletando coordenadas manualmente

  Nat_state_transferencia_itens: boolean = false

  Out_change_state_coleta_coordenada(state: boolean) {
    //recebe o estado do botao de coleta manual de coordenada do component gpx-ferramentas
    this.Nat_state_coleta_coordenada = state
  }

  Nat_state_detalhes_poligono: boolean = false

  Nat_detalhes_poligono: DETALHES_POLIGONO
  Out_change_state_detalhes_poligono(state: boolean) {
    this.Nat_state_detalhes_poligono = state
  }



  // /**evento que abre a aba de loading a partir de outros components */
  Out_open_loading(variavel_inutil: boolean) {
    this.Nat_state_loading = true
  }

  // /**evento que fecha a aba de loading a partir de outros components */
  Out_close_loading(variavel_inutil: boolean) {
    this.Nat_state_loading = false
  }

  /**abre o alerta da mensagem de sucesso */
  Out_open_alert_sucess(obg_alert: DADOS_ALERT) {
    this.Nat_state_loading = false
    this.Nat_dados_alert = obg_alert;
    this.Nat_status_alert_sucesso = true;
  }

  /**abre o alerta da mensagem de erro */
  Out_open_alert_erro(obg_alert: DADOS_ALERT) {
    this.Nat_state_loading = false
    this.Nat_dados_alert = obg_alert;
    this.Nat_status_alert_erro = true;
  }



  Nat_copy_value(value: any) {
    const valueCopy: string = `${value}`;

    // Cria um elemento de input temporário
    const tempInput = document.createElement('input');
    tempInput.value = valueCopy;
    document.body.appendChild(tempInput); // Adiciona o elemento ao DOM

    // Seleciona o texto do input temporário
    tempInput.select();
    tempInput.setSelectionRange(0, 99999); // Para dispositivos móveis

    try {
      // Copia o texto selecionado para a área de transferência
      document.execCommand('copy');



      let new_alert: DADOS_ALERT = {
        TITULO: 'Copiado!',
        CORPO: 'Valor Copiado com Sucesso para a Área de Transferência'
      }
      this.Nat_dados_alert = new_alert
      this.Nat_status_alert_sucesso = true




    } catch (err) {


      let new_alert: DADOS_ALERT = {
        TITULO: 'ERRO',
        CORPO: 'Valor não pôde se Copiado para a Área de Transferência'
      }
      this.Nat_dados_alert = new_alert
      this.Nat_status_alert_erro = true




    } finally {
      // Remove o elemento temporário do DOM
      document.body.removeChild(tempInput);
    }
  }


  /**
   * geracao e administração de cores para markers do mapa
   */
  Nat_coresGeradas: string[] = [];
  Nat_gerarCorHex(): string {
    const randomColor = Math.floor(Math.random() * 0xffffff);
    return `#${randomColor.toString(16).padStart(6, '0')}`;
  }

  Nat_gerarCorUnica(): string {
    let cor = this.Nat_gerarCorHex();

    // Tenta gerar uma nova cor se a cor atual já existe no array
    while (this.Nat_coresGeradas.includes(cor)) {
      cor = this.Nat_gerarCorHex();
    }

    // Armazena a cor única
    this.Nat_coresGeradas.push(cor);
    return cor;
  }



}
