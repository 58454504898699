import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Output, EventEmitter } from '@angular/core';

import { EMPRESA } from '../../../../MODELS/EMPRESA';
import { RESPOSTA_PADRAO_SERVIDOR } from '../../../../MODELS/RESPOSTA_PADRAO_SERVIDOR';

import { CadastroService } from '../../SERVICES/cadastro.service';


@Component({
  selector: 'app-cadastro-select-empresas',
  standalone: true,
  imports: [], // Adicionando módulos aqui
  templateUrl: './cadastro-select-empresas.component.html',
  styleUrls: ['./cadastro-select-empresas.component.css'], // Corrigido para styleUrls
})
export class CadastroSelectEmpresasComponent implements OnInit {


  constructor(

    private router: Router,
    private service:CadastroService
  ) {}

  @Output() Evento_empresa_id = new EventEmitter<number>();

  ngOnInit(): void {
    this.Nat_get_empresas()
  }

  Nat_select_empresa(event: Event) {
    const selectedValue = (event.target as HTMLSelectElement).value;


    this.Evento_empresa_id.emit(parseInt(selectedValue));

  }

  List_empresas: EMPRESA[] = [];
  async Nat_get_empresas(){//busca a lista de empresas atuais
    
    const requisicao_server:any = await this.service.GET_EMPRESAS()
    const resposta:RESPOSTA_PADRAO_SERVIDOR = requisicao_server

   
    if(resposta.MSG == 'SUCESSO'){
      this.List_empresas = resposta.RESPOSTA
    }else{
      alert(resposta.ADICIONAL)
    }
  }
}
