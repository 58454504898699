import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { DADOS_ALERT } from '../../../../MODELS/DADOS_ALERT';
import { NOVA_BASE, NOVA_EQUIPE } from '../../../../MODELS/MODELS_AORDEX';
import { environment } from '../../../../../environments/environment.development';
import { RESPOSTA_PADRAO_SERVIDOR } from '../../../../MODELS/RESPOSTA_PADRAO_SERVIDOR';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AordexService } from '../../SERVICES/aordex.service';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { end } from '@popperjs/core';

@Component({
  selector: 'app-new-base',
  standalone: true,
  imports: [MatFormFieldModule, MatSelectModule, FormsModule, ReactiveFormsModule],
  templateUrl: './new-base.component.html',
  styleUrl: './new-base.component.css'
})
export class NewBaseComponent {

  constructor(private aordexService: AordexService) { }

    /**evento que abre a aba de loading no component main */
    @Output() Evento_open_loading = new EventEmitter<boolean>();

    /**evento que fecha a aba de loading no component main */
    @Output() Evento_close_loading = new EventEmitter<boolean>();
  
    /**evento que abre um alerta de sucesso no component main */
    @Output() Evento_open_alert_sucess = new EventEmitter<DADOS_ALERT>();
  
    /**evento que abre um alerta de erro no component main */
    @Output() Evento_open_alert_erro = new EventEmitter<DADOS_ALERT>();





  
  @ViewChild('inp_nome_base') inp_nome_base:ElementRef
  @ViewChild('inp_coordenada') inp_coordenada:ElementRef
  @ViewChild('inp_endereco') inp_endereco:ElementRef
  async Nat_create_base() {
    const nome_base = this.inp_nome_base.nativeElement.value.trim();
    const coordenada = this.inp_coordenada.nativeElement.value.trim();
    const endereco = this.inp_endereco.nativeElement.value.trim();
  
    // Função para validar coordenadas
    const isValidCoordinate = (coord: string): boolean => {
      const parts = coord.split(',');
      if (parts.length !== 2) return false;
  
      const lat = parseFloat(parts[0].trim());
      const lng = parseFloat(parts[1].trim());
  
      // Verifica se os valores estão dentro dos limites válidos de latitude e longitude
      return (
        !isNaN(lat) &&
        !isNaN(lng) &&
        lat >= -90 &&
        lat <= 90 &&
        lng >= -180 &&
        lng <= 180
      );
    };
  
    if (
      nome_base !== '' &&
      endereco !== '' &&
      isValidCoordinate(coordenada)
    ) {
      const [lat, lng] = coordenada.split(',').map((val) => parseFloat(val.trim()));
  
      let new_base: NOVA_BASE = {
        EMPRESA_ID: environment.user_atual.EMPRESA_ID,
        CRIADOR_ID: environment.user_atual.ID,
        CRIADOR_NAME: environment.user_atual.NOME,
        DATA_CRIACAO: '',
        LAT: lat,
        LNG: lng,
        OBSERVACAO: endereco,
        NOME: nome_base
      };
  
      this.Evento_open_loading.emit(true);
  
      try {
        const requisicao_server: any = await this.aordexService.POST_CRIAR_BASE(new_base);
        const resposta: RESPOSTA_PADRAO_SERVIDOR = requisicao_server;
  
        if (resposta.MSG === 'SUCESSO') {
          this.Evento_close_loading.emit(true);
          let new_alert: DADOS_ALERT = {
            TITULO: resposta.MSG,
            CORPO: resposta.ADICIONAL
          };
          this.Evento_open_alert_sucess.emit(new_alert);
          this.Nat_close();
        } else {
          this.Evento_close_loading.emit(true);
          let new_alert: DADOS_ALERT = {
            TITULO: resposta.MSG,
            CORPO: resposta.ADICIONAL
          };
          this.Evento_open_alert_erro.emit(new_alert);
        }
      } catch (error) {
        this.Evento_close_loading.emit(true);
        let new_alert: DADOS_ALERT = {
          TITULO: 'ERRO',
          CORPO: 'Erro ao comunicar com o servidor!'
        };
        this.Evento_open_alert_erro.emit(new_alert);
      }
    } else {
      this.Evento_close_loading.emit(true);
      let new_alert: DADOS_ALERT = {
        TITULO: 'ERRO',
        CORPO: 'Dados de Cadastro Incorretos! Certifique-se de que as coordenadas estejam no formato "latitude, longitude".'
      };
      this.Evento_open_alert_erro.emit(new_alert);
    }
  }
  

  @Output() Evento_change_state_new_base = new EventEmitter<boolean>()
  Nat_close(){
    this.Evento_change_state_new_base.emit(false)
  }
}
