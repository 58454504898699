import { Component, ElementRef, EventEmitter, Input, Output, ViewChild, viewChild } from '@angular/core';
import { ALTERA_COORDENADA } from '../../MODELS/MODELS_AORDEX';
import { DADOS_ALERT } from '../../MODELS/DADOS_ALERT';
import { RESPOSTA_PADRAO_SERVIDOR } from '../../MODELS/RESPOSTA_PADRAO_SERVIDOR';
import { AordexService } from '../../MODULES/AORDEX/SERVICES/aordex.service';

@Component({
  selector: 'app-mudar-coordenada',
  standalone: true,
  imports: [],
  templateUrl: './mudar-coordenada.component.html',
  styleUrl: './mudar-coordenada.component.css'
})
export class MudarCoordenadaComponent {

  constructor(private aordexService:AordexService){}


  @Input() input_id_nota:number

  @ViewChild('inp_nova_coordenada') inp_nova_coordenada: ElementRef

    /**evento que abre a aba de loading no component main */
    @Output() Evento_open_loading = new EventEmitter<boolean>();

    /**evento que fecha a aba de loading no component main */
    @Output() Evento_close_loading = new EventEmitter<boolean>();
  
    /**evento que abre um alerta de sucesso no component main */
    @Output() Evento_open_alert_sucess = new EventEmitter<DADOS_ALERT>();
  
    /**evento que abre um alerta de erro no component main */
    @Output() Evento_open_alert_erro = new EventEmitter<DADOS_ALERT>();

    async Nat_change_coordenada() {

      this.Evento_open_loading.emit(true)

      const coordenada_nova: string = this.inp_nova_coordenada.nativeElement.value;
    
      // Regex para validar o formato -25.362955, -49.192667
      const coordRegex = /^-?\d+\.\d+, -?\d+\.\d+$/;
    
      if (coordRegex.test(coordenada_nova)) {
       


          const requisicao_server: any = await this.aordexService.POST_ALTERA_COORDENADA(
            this.input_id_nota,parseFloat(coordenada_nova.split(',')[0]),
            parseFloat(coordenada_nova.split(',')[1])
          );
          const resposta: RESPOSTA_PADRAO_SERVIDOR = requisicao_server;

          if (resposta.MSG == 'SUCESSO') {
    
            this.Evento_close_loading.emit(true);
            let new_alert: DADOS_ALERT = {
              TITULO: resposta.MSG,
              CORPO: resposta.ADICIONAL
            };
            this.Evento_open_alert_sucess.emit(new_alert);
        }else{
        this.Evento_close_loading.emit(true);
        let new_alert: DADOS_ALERT = {
          TITULO: resposta.MSG,
          CORPO: resposta.ADICIONAL
        };
        this.Evento_open_alert_erro.emit(new_alert);
        }


    
        this.Evento_close_loading.emit(true);
        let new_alert: DADOS_ALERT = {
          TITULO: "SUCESSO",
          CORPO: "Coordenada Alterada com Sucesso!"
        };
        this.Evento_open_alert_sucess.emit(new_alert);
      } else {
        this.Evento_close_loading.emit(true);
        let new_alert: DADOS_ALERT = {
          TITULO: "ERRO",
          CORPO: "Coordenadas em Formato Incorreto. O formato correto é: -25.362955, -49.192667"
        };
        this.Evento_open_alert_erro.emit(new_alert);
      }
    }
    
  @Output() Evento_change_coordenada = new EventEmitter<ALTERA_COORDENADA>()
  Nat_change_state_mudar_coordenada(){
    this.Evento_change_coordenada.emit({
      ATIVO: false,
      ID_NOTA: undefined
    })
  }
}
