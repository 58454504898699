import { Component } from '@angular/core';

@Component({
  selector: 'app-alert-loading',
  standalone: true,
  imports: [],
  templateUrl: './alert-loading.component.html',
  styleUrl: './alert-loading.component.css'
})
export class AlertLoadingComponent {

}
