<div class="relative isolate z-50 shadow w-screen">
    <div class="absolute w-full inset-x-0 top-16 -z-10 bg-white pt-2 shadow-lg ring-1 ring-gray-900/5">
      <div class="mx-auto grid max-w-full w-full grid-cols-1 gap-x-8 gap-y-4 px-2 py-2 lg:grid-cols-1 lg:px-2 ">
  
        <form class="w-full mt-2 ml-1">
          <div class="flex items-center space-x-2">
            <!-- Input de busca -->
            <input matInput (keyup)="applyFilter($event)" #input type="text" id="inp_filtro"
              class="block p-2.5 w-80 text-sm text-gray-900 bg-gray-50 border border-gray-300 rounded-l-md focus:ring-blue-500 focus:border-blue-500"
              placeholder="Buscar Livro" required />
  
            <!-- Botões ao lado do input -->
            <span class="isolate inline-flex rounded-md shadow-sm w-full">
              <button type="button" (click)="Nat_clear_filter()"
                class="relative inline-flex items-center bg-red-700 px-3 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-red-300 hover:bg-red-500 focus:z-10">
                Limpar
              </button>
  
              @if(Nat_all_itens_mapa){
              <button type="button" (click)="Nat_close_todos()"
                class="relative inline-flex items-center bg-orange-700 px-3 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-orange-300 hover:bg-orange-500 focus:z-10">
                Fechar Todos
              </button>
              }@else {
              <button type="button" (click)="Nat_open_todos()"
                class="relative inline-flex items-center bg-green-700 px-3 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-green-300 hover:bg-green-500 focus:z-10">
                Abrir Todos
              </button>
              }
  
              <button type="button" (click)="Nat_calcular_duracoes_gerais('pe')"
                class="relative ms-4 inline-flex items-center bg-blue-700 px-3 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-blue-300 hover:bg-blue-500 focus:z-10">
                Trajeto a Pé
              </button>
              <button type="button" (click)="Nat_calcular_duracoes_gerais('moto')"
                class="relative inline-flex items-center bg-orange-700 px-3 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-orange-300 hover:bg-orange-500 focus:z-10">
                Trajeto Moto
              </button>
              <button type="button" (click)="Nat_calcular_duracoes_gerais('carro')"
                class="relative inline-flex items-center bg-indigo-700 px-3 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-indigo-300 hover:bg-indigo-500 focus:z-10">
                Trajeto Carro
              </button>
  
  
           
  
  
              <button type="button" (click)="Nat_close_aba()"
                class="absolute right-2 inline-flex items-center bg-gray-300 px-3 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-gray-300 hover:bg-gray-400 focus:z-10">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                  stroke="currentColor" class="size-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
                </svg>
  
              </button>
            </span>
  
           
  
  
          </div>
        </form>
  
  
  
  
  
        <div class="mat-elevation-z8">
          <table mat-table [dataSource]="dataSource" matSort>
  
            <!-- ID Column -->
            <ng-container matColumnDef="acoes">
              <th mat-header-cell *matHeaderCellDef> #</th>
              <td mat-cell *matCellDef="let row">
  
  
  
                @if(!row.VISIVEL){
                <div class="p-2 space-x-2">
     
                    <span (click)="Nat_open_operacao(row)" class="inline-flex items-center
                           rounded-md bg-green-50 px-2 py-1
                            text-xs font-medium text-green-700
                             ring-1 ring-inset ring-green-600/20 cursor-pointer hover:underline">Visualizar</span>
      
                                    <!-- Botão Trajeto a Pé -->
                                    <span (click)="Nat_calcular_duracao_livro(row,'pe')" class="inline-flex items-center rounded-md bg-blue-50 px-2 py-1
                                    text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-600/20 
                                    cursor-pointer hover:underline">
                             Trajeto a Pé
                           </span>
           
                           <!-- Botão Calcular Trajeto -->
                           <span (click)="Nat_calcular_duracao_livro(row,'moto')" class="inline-flex items-center rounded-md bg-orange-50 px-2 py-1
                                        text-xs font-medium text-orange-700 ring-1 ring-inset ring-orange-600/20 
                                        cursor-pointer hover:underline">
                             Trajeto Moto
                           </span>
           
                           <!-- Botão Trajeto a Pé -->
                           <span (click)="Nat_calcular_duracao_livro(row,'carro')" class="inline-flex items-center rounded-md bg-indigo-50 px-2 py-1
                                        text-xs font-medium text-indigo-700 ring-1 ring-inset ring-indigo-600/20 
                                        cursor-pointer hover:underline">
                             Trajeto Carro
                           </span>
                </div>
                }@else {
                <div class="p-2 space-x-2">
          
                    <span (click)="Nat_close_operacao(row)" class="inline-flex items-center
                           rounded-md bg-red-50 px-2 py-1
                            text-xs font-medium text-red-700
                             ring-1 ring-inset ring-red-600/20 cursor-pointer hover:underline">Tirar do Mapa</span>
               
                                    <!-- Botão Trajeto a Pé -->
                                    <span (click)="Nat_calcular_duracao_livro(row,'pe')" class="inline-flex items-center rounded-md bg-blue-50 px-2 py-1
                                    text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-600/20 
                                    cursor-pointer hover:underline">
                             Trajeto a Pé
                           </span>
           
                           <!-- Botão Calcular Trajeto -->
                           <span (click)="Nat_calcular_duracao_livro(row,'moto')" class="inline-flex items-center rounded-md bg-orange-50 px-2 py-1
                                        text-xs font-medium text-orange-700 ring-1 ring-inset ring-orange-600/20 
                                        cursor-pointer hover:underline">
                             Trajeto Moto
                           </span>
           
                           <!-- Botão Trajeto a Pé -->
                           <span (click)="Nat_calcular_duracao_livro(row,'carro')" class="inline-flex items-center rounded-md bg-indigo-50 px-2 py-1
                                        text-xs font-medium text-indigo-700 ring-1 ring-inset ring-indigo-600/20 
                                        cursor-pointer hover:underline">
                             Trajeto Carro
                           </span>
                </div>
                }
             
  
  
  
  
              </td>
            </ng-container>
  
            <!-- ID Column -->
            <ng-container matColumnDef="ID_STR">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
              <td mat-cell *matCellDef="let row"> {{row.ID_STR}} </td>
            </ng-container>
  
            <!-- Progress Column -->
            <ng-container matColumnDef="CENTRO_LEITURA_STR">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> CL </th>
              <td mat-cell *matCellDef="let row"> {{row.CENTRO_LEITURA_STR}}</td>
            </ng-container>
  
            <!-- Name Column -->
            <ng-container matColumnDef="GRUPO_STR">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> GRUPO </th>
              <td mat-cell *matCellDef="let row"> {{row.GRUPO_STR}} </td>
            </ng-container>
  
            <!-- Fruit Column -->
            <ng-container matColumnDef="QUANT_ITENS">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> ITENS </th>
              <td mat-cell *matCellDef="let row"> {{row.QUANT_ITENS}} </td>
            </ng-container>
  
            <!-- Fruit Column -->
            <ng-container matColumnDef="DURACAO_STR">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> DURAÇÃO </th>
              <td mat-cell *matCellDef="let row"> {{row.DURACAO_STR}} </td>
            </ng-container>
  
  
           <!-- Fruit Column -->
           <ng-container matColumnDef="DISTANCIA_STR">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> DISTANCIA </th>
            <td mat-cell *matCellDef="let row"> {{row.DISTANCIA_STR}} </td>
          </ng-container>
          
                    <!-- Fruit Column -->
                    <ng-container matColumnDef="POSSUI_EDICAO">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> ROTEIRIZADO? </th>
                      <td mat-cell *matCellDef="let row"> 
                      @if(row.POSSUI_EDICAO){
                        <div class="text-green-500 font-bold">
                          SIM
                        </div>
                        
                      }@else {
                        <div class="text-red-500 font-bold">
                          NÃO
                        </div>
                        
                        
                      }  
                      </td>
                    </ng-container>
          
  
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  
            <!-- Row shown when there is no matching data. -->
            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="4">Nenhum Resultado para a Busca "{{input.value}}"</td>
            </tr>
          </table>
  
          <mat-paginator [pageSizeOptions]="[4]" aria-label="Select page of users"></mat-paginator>
        </div>
  
  
  
  
  
  
  
  
  
  
  
  
  
      </div>
  
    </div>
  </div>