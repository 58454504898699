import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { USER } from '../../MODELS/USER';
import { environment } from '../../../environments/environment.development';
import { Router } from '@angular/router';
import { RESPOSTA_PADRAO_SERVIDOR } from '../../MODELS/RESPOSTA_PADRAO_SERVIDOR';
import { MainService } from '../../SERVICES/main.service';
import { DADOS_ALERT } from '../../MODELS/DADOS_ALERT';

@Component({
  selector: 'app-perfil',
  standalone: true,
  imports: [],
  templateUrl: './perfil.component.html',
  styleUrl: './perfil.component.css'
})
export class PerfilComponent implements OnInit{
  constructor(private router:Router,private mainservice:MainService){}

  user:USER
  fotoBase64: string | null = null;


  ngOnInit() {
    this.user = environment.user_atual;

    if(environment.foto){
      this.fotoBase64 = environment.foto
    }else{
    // Verifica se há uma foto e faz a conversão para Base64
    if (this.user?.FOTO?.data) {
      this.fotoBase64 = this.convertBufferToBase64(this.user.FOTO.data);
    }
    }

  }
// Função para converter o array de bytes em uma string Base64
convertBufferToBase64(buffer: number[]): string {
  const base64String = btoa(
    String.fromCharCode(...buffer)
  );
  return `data:image/png;base64,${base64String}`;
}

  @Output() Evento_close_perfil = new EventEmitter<boolean>()
  /**evento que abre a aba de loading no component main */
  @Output() Evento_open_loading = new EventEmitter<boolean>();

  /**evento que fecha a aba de loading no component main */
  @Output() Evento_close_loading = new EventEmitter<boolean>();

  /**evento que abre um alerta de sucesso no component main */
  @Output() Evento_open_alert_sucess = new EventEmitter<DADOS_ALERT>();

  /**evento que abre um alerta de erro no component main */
  @Output() Evento_open_alert_erro = new EventEmitter<DADOS_ALERT>();

  Nat_close_perfil(){
    this.Evento_close_perfil.emit(false)
  }
  Nat_deslogar(){
    this.router.navigateByUrl('')
  }


  selectedImage: File | null = null; // Variável para armazenar a imagem selecionada

  // Método chamado quando o usuário seleciona um arquivo
  onFileSelected(event: any) {
    this.selectedImage = undefined
    const file: File = event.target.files[0]; // Captura o primeiro arquivo
    if (file) {
      this.selectedImage = file; // Armazena a imagem selecionada
   
      this.concluir_nota()

      this.Evento_close_loading.emit(true)
      let new_alert: DADOS_ALERT = {
        TITULO: 'SUCESSO!',
        CORPO: 'Imagem Selecionada com Sucesso!'
      }
      this.Evento_open_alert_sucess.emit(new_alert)
     
    }
  }

  async concluir_nota() {

    this.Evento_open_loading.emit(true)

    if (!this.selectedImage) {
      this.Evento_close_loading.emit(true)
      let new_alert: DADOS_ALERT = {
        TITULO: 'ERRO!',
        CORPO: 'Nenhuma Imagem Selecionada!'
      }
      this.Evento_open_alert_erro.emit(new_alert)
      
      return; // Se não houver imagem, não faz nada
    
    } else {

      const formData = new FormData();
      formData.append('imagem', this.selectedImage); // Adiciona a imagem ao FormData
      formData.append('USER_ID', `${environment.user_atual.ID}`);


      const request_alterar_img_perfil: any = await this.mainservice.POST_SAVE_IMG_PERFIL(formData)
      const retorno_padrao: RESPOSTA_PADRAO_SERVIDOR = request_alterar_img_perfil


      if (retorno_padrao.MSG == 'SUCESSO') {

        this.fotoBase64 = this.convertBufferToBase64_auto(retorno_padrao.RESPOSTA);
        environment.foto = this.fotoBase64 

        this.Evento_close_loading.emit(true)
        let new_alert: DADOS_ALERT = {
          TITULO: retorno_padrao.MSG,
          CORPO: retorno_padrao.ADICIONAL
        }
        this.Evento_open_alert_sucess.emit(new_alert)
      } else {
     
        this.Evento_close_loading.emit(true)
        let new_alert: DADOS_ALERT = {
          TITULO: retorno_padrao.MSG,
          CORPO: retorno_padrao.ADICIONAL
        }
        this.Evento_open_alert_erro.emit(new_alert)
      }





    }




  }

  convertBufferToBase64_auto(bufferObj: { type: string; data: number[] }): string {
    // Certifique-se de que o bufferObj contém um campo `data`
    const bufferData = bufferObj?.data;
    if (!bufferData) {
      return '';
    }
  
    // Converte o array de números em uma string Base64
    const base64String = btoa(
      String.fromCharCode(...bufferData)
    );
    return `data:image/png;base64,${base64String}`;
  }
  
}
