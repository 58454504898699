import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment.development';
import { HttpClient } from '@angular/common/http';
import { RESPOSTA_PADRAO_SERVIDOR } from '../../../MODELS/RESPOSTA_PADRAO_SERVIDOR';
import { COORDENADA } from '../../../MODELS/COORDENADA';
import { NEW_INICIO_EXTERNO_EXEC } from '../../../MODELS/NEW_INICIO_EXTERNO_EXEC';

@Injectable({
  providedIn: 'root'
})
export class RoutexService {

  constructor(private httpClient: HttpClient) { }


  //SOLICITA A LISTA DE OPERACOES EM EDICAO PELO USER
  async POST_OPERACOES_EXEC_EDICOES(user_id: number) {
    try {
      const response = await this.httpClient
        .post(`${environment.url_api_local}/POST_OPERACOES_EXEC_EDICOES`, { user_id: user_id })
        .toPromise();


      return response;
    } catch (error) {
      console.log(error, 'erro');
      const retorno_padrao: RESPOSTA_PADRAO_SERVIDOR = {
        MSG: "ERRO",
        ADICIONAL: "",
        RESPOSTA: undefined
      }
      return retorno_padrao

    }
  }

  //SOLICITA A LISTA DE LIVROS DE UMA OPERACAO
  async POST_RETORNA_LIVROS_OPERACAO_EXEC(operacao_id: number) {
    try {
      const response = await this.httpClient
        .post(`${environment.url_api_local}/POST_RETORNA_LIVROS_OPERACAO_EXEC`, { operacao_id: operacao_id })
        .toPromise();


      return response;
    } catch (error) {
      console.log(error, 'erro');
      const retorno_padrao: RESPOSTA_PADRAO_SERVIDOR = {
        MSG: "ERRO",
        ADICIONAL: "",
        RESPOSTA: undefined
      }
      return retorno_padrao

    }
  }



  //SOLICITA INSTALACOES DE UM LIVRO
  async POST_ITENS_LIVRO_EXEC(operacao_id: number, livro: string) {
    try {
      const response = await this.httpClient
        .post(`${environment.url_api_local}/POST_ITENS_LIVRO_EXEC`, { operacao_id: operacao_id, livro: livro })
        .toPromise();


      return response;
    } catch (error) {
      console.log(error, 'erro');
      const retorno_padrao: RESPOSTA_PADRAO_SERVIDOR = {
        MSG: "ERRO",
        ADICIONAL: "",
        RESPOSTA: undefined
      }
      return retorno_padrao

    }
  }


  //DEVOLVE UMA OPERAÇÃO AO DIVIX
  async POST_DEVOLVER_AO_DIVIX(operacao_id: number) {
    try {
      const response = await this.httpClient
        .post(`${environment.url_api_local}/POST_DEVOLVER_AO_DIVIX`, { operacao_id: operacao_id })
        .toPromise();


      return response;
    } catch (error) {
      console.log(error, 'erro');
      const retorno_padrao: RESPOSTA_PADRAO_SERVIDOR = {
        MSG: "ERRO",
        ADICIONAL: "",
        RESPOSTA: undefined
      }
      return retorno_padrao

    }
  }


    //CALCULA A DURACAO DE UM TRAJETO, NSIDERANDO O MODAL, COM OU SEM INICIO DEFINIDO
    async POST_OSRM_TRIP(operacao_id: number, livro: string, origem_id: number, tipo: "pe" | "carro" | "moto") {
      try {
        const response = await this.httpClient
          .post(`${environment.url_api_local}/POST_OSRM_TRIP_ROUTEX`,
            {
              operacao_id: operacao_id,
              livro: livro,
              origem_id: origem_id,
              tipo: tipo
            })
          .toPromise();
  
  
        return response;
      } catch (error) {
        console.log(error, 'erro');
        const retorno_padrao: RESPOSTA_PADRAO_SERVIDOR = {
          MSG: "ERRO",
          ADICIONAL: "",
          RESPOSTA: undefined
        }
        return retorno_padrao
  
      }
    }

       //RETORNA AS COORDENADAS NO FORMATO "COORDENADA" DA LINHA DE TRAJETO
       async POST_OSRM_TABLE(operacao_id: number, livro: string,origem_coord:COORDENADA) {
        try {
          const response = await this.httpClient
            .post(`${environment.url_api_local}/POST_OSRM_TABLE`,
              {
                operacao_id: operacao_id,
                livro: livro,
                origem_coord: origem_coord
                
              })
            .toPromise();
    
    
          return response;
        } catch (error) {
          console.log(error, 'erro');
          const retorno_padrao: RESPOSTA_PADRAO_SERVIDOR = {
            MSG: "ERRO",
            ADICIONAL: "",
            RESPOSTA: undefined
          }
          return retorno_padrao
    
        }
      }



           //RETORNA AS COORDENADAS NO FORMATO "COORDENADA" DA LINHA DE TRAJETO
           async POST_NOVO_INICIO_EXTERNO(novo_inicio:NEW_INICIO_EXTERNO_EXEC) {
            try {
              const response = await this.httpClient
                .post(`${environment.url_api_local}/POST_NOVO_INICIO_EXTERNO`,
                  novo_inicio)
                .toPromise();
        
        
              return response;
            } catch (error) {
              console.log(error, 'erro');
              const retorno_padrao: RESPOSTA_PADRAO_SERVIDOR = {
                MSG: "ERRO",
                ADICIONAL: "",
                RESPOSTA: undefined
              }
              return retorno_padrao
        
            }
          }

           //DELETA INICIO EXTERNO DE UM LIVRO
           async POST_DELETAR_INICIO_EXTERNO(novo_inicio:NEW_INICIO_EXTERNO_EXEC) {
            try {
              const response = await this.httpClient
                .post(`${environment.url_api_local}/POST_DELETAR_INICIO_EXTERNO`,
                  novo_inicio)
                .toPromise();
        
        
              return response;
            } catch (error) {
              console.log(error, 'erro');
              const retorno_padrao: RESPOSTA_PADRAO_SERVIDOR = {
                MSG: "ERRO",
                ADICIONAL: "",
                RESPOSTA: undefined
              }
              return retorno_padrao
        
            }
          }
          
}
