import { AfterViewInit, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { RESPOSTA_PADRAO_SERVIDOR } from '../../../../MODELS/RESPOSTA_PADRAO_SERVIDOR';
import { AordexService } from '../../SERVICES/aordex.service';
import { environment } from '../../../../../environments/environment.development';
import { IMPORTACAO } from '../../../../MODELS/MODELS_AORDEX';
import { DADOS_ALERT } from '../../../../MODELS/DADOS_ALERT';





@Component({
  selector: 'app-importacoes-main',
  standalone: true,
  imports: [MatFormFieldModule, MatInputModule, MatTableModule, MatSortModule, MatPaginatorModule],
  templateUrl: './importacoes-main.component.html',
  styleUrl: './importacoes-main.component.css'
})
export class ImportacoesMainComponent implements AfterViewInit, OnInit {

//   export class IMPORTACAO {
//     ID:number
//         ID_STR:string
//     EMPRESA_ID:number
//     DATA_IMPORTACAO:string
//     CRIADOR_ID:number
//     CRIADOR_NAME:string
//     TOTAL_ITENS:number
//     ITENS_IMPORTADOS:number
//     PORCENTAGEM_IMP:number
// }

  /**evento que abre a aba de loading no component main */
  @Output() Evento_open_loading = new EventEmitter<boolean>();

  /**evento que fecha a aba de loading no component main */
  @Output() Evento_close_loading = new EventEmitter<boolean>();

  /**evento que abre um alerta de sucesso no component main */
  @Output() Evento_open_alert_sucess = new EventEmitter<DADOS_ALERT>();

  /**evento que abre um alerta de erro no component main */
  @Output() Evento_open_alert_erro = new EventEmitter<DADOS_ALERT>();

  displayedColumns: string[] = ['ID_STR', 'DATA_IMPORTACAO', 'CRIADOR_NAME', 'TOTAL_ITENS', 'ITENS_IMPORTADOS','PORCENTAGEM_IMP','ACOES'];
  dataSource: MatTableDataSource<IMPORTACAO>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private aordexService:AordexService){}

  async ngOnInit() {

    this.Evento_open_loading.emit(true)

    const requisicao_server: any = await this.aordexService.POST_BUSCAR_IMPORTACOES(environment.user_atual);
    const resposta: RESPOSTA_PADRAO_SERVIDOR = requisicao_server;

    if (resposta.MSG == 'SUCESSO') {

     

      this.dataSource = new MatTableDataSource(resposta.RESPOSTA);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;

      this.Evento_close_loading.emit(true)
      
    } else {
      this.Evento_close_loading.emit(true)
      let new_alert:DADOS_ALERT = {
        TITULO: resposta.MSG,
        CORPO: resposta.ADICIONAL
      }
      this.Evento_open_alert_erro.emit(new_alert)
    }
  }

  ngAfterViewInit() {

  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }


  async Nat_delete_importacao(id_importacao:number){

    this.Evento_open_loading.emit(true)

    const requisicao_server: any = await this.aordexService.POST_DELETE_IMPORTACAO(id_importacao);
    const resposta: RESPOSTA_PADRAO_SERVIDOR = requisicao_server;

    if (resposta.MSG == 'SUCESSO') {
      const atualiza = await this.ngOnInit()
      this.Evento_close_loading.emit(true);
      let new_alert: DADOS_ALERT = {
        TITULO: resposta.MSG,
        CORPO: resposta.ADICIONAL
      };
      this.Evento_open_alert_sucess.emit(new_alert);
  }else{
  this.Evento_close_loading.emit(true);
  let new_alert: DADOS_ALERT = {
    TITULO: resposta.MSG,
    CORPO: resposta.ADICIONAL
  };
  this.Evento_open_alert_erro.emit(new_alert);
  }
  }
}

