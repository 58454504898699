import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DADOS_ALERT } from '../../../../MODELS/DADOS_ALERT';
import { ALTERA_COORDENADA, CONCLUSAO_NOTA, NOTA_DE_SERVICO, NOTA_DE_SERVICO_MAPA } from '../../../../MODELS/MODELS_AORDEX';
import { RESPOSTA_PADRAO_SERVIDOR } from '../../../../MODELS/RESPOSTA_PADRAO_SERVIDOR';
import { AordexService } from '../../SERVICES/aordex.service';
import { environment } from '../../../../../environments/environment.development';



@Component({
  selector: 'app-atribuidas-main',
  standalone: true,
  imports: [MatFormFieldModule, MatInputModule, MatTableModule, MatSortModule, MatPaginatorModule],
  templateUrl: './atribuidas-main.component.html',
  styleUrl: './atribuidas-main.component.css'
})
export class AtribuidasMainComponent implements OnInit {


  constructor(private aordexService: AordexService) { }

  /**evento que abre a aba de loading no component main */
  @Output() Evento_open_loading = new EventEmitter<boolean>();

  /**evento que fecha a aba de loading no component main */
  @Output() Evento_close_loading = new EventEmitter<boolean>();

  /**evento que abre um alerta de sucesso no component main */
  @Output() Evento_open_alert_sucess = new EventEmitter<DADOS_ALERT>();

  /**evento que abre um alerta de erro no component main */
  @Output() Evento_open_alert_erro = new EventEmitter<DADOS_ALERT>();




  displayedColumns: string[] = [
    'VISIVEL',
    'ID_STR', 'INSTALACAO', 'ENDERECO', 'NUM_MEDIDA', 'COD_NOTA', 'LIVRO', 'DATA_GERACAO_STR',
    'DATA_PREV_CONC_SIS_STR', 'DATA_MANUAL_CONC_STR', 'SEQUENCIA', 'EQUIPE_ID_STR', 'IMP_ID_STR', 'TIPO', 'DESC_SERVICO', 'CIDADE', 'COORDENADA_MINERADA', 'ACOES'];
  dataSource: MatTableDataSource<NOTA_DE_SERVICO>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;



  async ngOnInit() {
    this.Evento_open_loading.emit(true)

    const requisicao_server: any = await this.aordexService.POST_BUSCAR_ATRIBUIDAS(environment.user_atual);
    const resposta: RESPOSTA_PADRAO_SERVIDOR = requisicao_server;

    if (resposta.MSG == 'SUCESSO') {




      resposta.RESPOSTA.forEach((nota, n) => {
        if (this.input_atribuidas_mapa.find((nota_mapa) => nota_mapa.ID == nota.ID)) {
          resposta.RESPOSTA[n].VISIVEL = true
        }
      });


      this.dataSource = new MatTableDataSource(resposta.RESPOSTA);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;

      this.Evento_close_loading.emit(true)
    } else {
      this.Evento_close_loading.emit(true)
      let new_alert: DADOS_ALERT = {
        TITULO: resposta.MSG,
        CORPO: resposta.ADICIONAL
      }
      this.Evento_open_alert_erro.emit(new_alert)
    }
  }



  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }


  @Output() Evento_change_coordenada = new EventEmitter<ALTERA_COORDENADA>()
  Nat_change_state_mudar_coordenada(id_nota: number) {
    this.Evento_change_coordenada.emit({
      ATIVO: true,
      ID_NOTA: id_nota
    })
  }


  @Output() Evento_view_notas = new EventEmitter<NOTA_DE_SERVICO[]>()
  @Output() Evento_remove_notas = new EventEmitter<NOTA_DE_SERVICO_MAPA[]>()

  @Input() input_atribuidas_mapa: NOTA_DE_SERVICO_MAPA[] = []

  Nat_change_view() {
    if (this.input_atribuidas_mapa.length > 0) {
      this.Nat_remove_all_notas()
    } else {
      this.Nat_view_all_notas_filtered()
    }
  }

  async Nat_view_nota(id_nota: number) {
    this.Evento_open_loading.emit(true)
    const aguarda = await this.Evento_view_notas.emit(this.dataSource.data.filter((nota) => nota.ID == id_nota))



    const index = this.dataSource.data.findIndex((sub_nota) => sub_nota.ID == id_nota)

    if (index >= 0) {
      this.dataSource.data[index].VISIVEL = true
    }
    this.Evento_close_loading.emit(true);
  }

  @ViewChild('checkbox_all') checkbox_all: ElementRef
  async Nat_remove_nota(id_nota: number) {
    this.Evento_open_loading.emit(true)
    const aguarda = await this.Evento_remove_notas.emit(this.input_atribuidas_mapa.filter((nota) => nota.ID == id_nota))


    const index = this.dataSource.data.findIndex((sub_nota) => sub_nota.ID == id_nota)

    if (index >= 0) {
      this.dataSource.data[index].VISIVEL = false
    }


    if (this.dataSource.data.filter((nota) => nota.VISIVEL == true).length == 0) {
      this.checkbox_all.nativeElement.checked = false
    }

    this.Evento_close_loading.emit(true);

  }
  async Nat_view_all_notas_filtered() {

    this.Evento_open_loading.emit(true)

    const aguarda = await this.Evento_view_notas.emit(this.dataSource.filteredData)


    this.dataSource.filteredData.map((nota) => nota.VISIVEL = true)

    this.Evento_close_loading.emit(true);
  }

  async Nat_remove_all_notas() {
    this.Evento_open_loading.emit(true)
    const aguarda = await this.Evento_remove_notas.emit(this.input_atribuidas_mapa)

    this.input_atribuidas_mapa.forEach((nota, n) => {
      const index = this.dataSource.data.findIndex((sub_nota) => sub_nota.ID == nota.ID)

      if (index >= 0) {
        this.dataSource.data[index].VISIVEL = false
      }
    });

    this.checkbox_all.nativeElement.checked = false

    this.Evento_close_loading.emit(true);
  }


  async Nat_delete_nota(id_nota: number) {

    this.Evento_open_loading.emit(true)

    const requisicao_server: any = await this.aordexService.POST_DELETE_NOTA(id_nota);
    const resposta: RESPOSTA_PADRAO_SERVIDOR = requisicao_server;

    if (resposta.MSG == 'SUCESSO') {

      const atualiza = await this.ngOnInit()

      this.Evento_close_loading.emit(true);
      let new_alert: DADOS_ALERT = {
        TITULO: resposta.MSG,
        CORPO: resposta.ADICIONAL
      };
      this.Evento_open_alert_sucess.emit(new_alert);
    } else {
      this.Evento_close_loading.emit(true);
      let new_alert: DADOS_ALERT = {
        TITULO: resposta.MSG,
        CORPO: resposta.ADICIONAL
      };
      this.Evento_open_alert_erro.emit(new_alert);
    }
  }

  Nat_view_concluir_nota: boolean = false

  Nat_nota_selecionada: NOTA_DE_SERVICO
  Nat_change_view_concluir_nota(nota: NOTA_DE_SERVICO) {
    this.Nat_nota_selecionada = nota
    this.Nat_view_concluir_nota = !this.Nat_view_concluir_nota
  }




  Nat_img_medidor_esquerda: File | null = null;
  Nat_img_medidor_direita: File | null = null;
  Nat_img_fachada: File | null = null;

  triggerFileInput(inputElement: HTMLInputElement): void {
    inputElement.value = ''; // Limpa o valor do input antes de abrir o seletor de arquivos
    inputElement.click(); // Abre o seletor de arquivos
  }

  handleFileUpload(event: Event, buttonLabel: string): void {
    const fileInput = event.target as HTMLInputElement;
    if (fileInput.files && fileInput.files.length > 0) {
      const file = fileInput.files[0]; // Obtém o primeiro arquivo selecionado
      console.log(`Arquivo selecionado por ${buttonLabel}:`, file);

      // Armazena a imagem no estado apropriado
      switch (buttonLabel) {
        case 'MEDIDOR_E':
          this.Nat_img_medidor_esquerda = file;
          break;
        case 'MEDIDOR_D':
          this.Nat_img_medidor_direita = file;
          break;
        case 'FACHADA':
          this.Nat_img_fachada = file;
          break;
      }

      this.processFile(file, buttonLabel);
    }
  }

  processFile(file: File, buttonLabel: string): void {
    console.log(`Processando arquivo de ${buttonLabel}:`, file.name);
    // Implementar lógica adicional se necessário
  }

  clearImages(): void {
    // Limpa as imagens selecionadas
    this.Nat_img_medidor_esquerda = null;
    this.Nat_img_medidor_direita = null;
    this.Nat_img_fachada = null;
    console.log('Todas as imagens foram limpas.');
  }


  Nat_tipo_conclusao: string = ''
  Nat_change_tipo_conclusao(tipo_conclusao: string) {
    this.Nat_tipo_conclusao = tipo_conclusao
  }

  @ViewChild('inp_obs') inp_obs: ElementRef
  @ViewChild('inp_token') inp_token: ElementRef
  async concluir_nota() {
    this.Evento_open_loading.emit(true)

    let new_body_conclusao: CONCLUSAO_NOTA = {
      IMG_FACHADA: this.Nat_img_fachada,
      IMG_MD_DIREITA: this.Nat_img_medidor_direita,
      IMG_MD_ESQUERDA: this.Nat_img_medidor_esquerda,
      OBSERVACOES: this.inp_obs.nativeElement.value,
      NOTA_ID: this.Nat_nota_selecionada.ID,
      DATA_CONCLUSAO: '',
      TIPO_CONC: '',
      STATUS_CONC: '',
      STATUS: this.Nat_tipo_conclusao,
      LAT_CONC: 0,
      LNG_CONC: 0,
      DIST_LOCAL_CONC: 0,
      DIF_CONC: '',
      TOKEN: this.inp_token.nativeElement.value
    }


    if (
      !new_body_conclusao.IMG_FACHADA
      || !new_body_conclusao.IMG_MD_DIREITA
      || !new_body_conclusao.IMG_MD_ESQUERDA
      || new_body_conclusao.OBSERVACOES == ""
      || new_body_conclusao.TOKEN == ""
      || new_body_conclusao.STATUS == ""
      || new_body_conclusao.TOKEN != this.Nat_nota_selecionada.TOKEN
    ) {
      this.Evento_close_loading.emit(true);
      let new_alert: DADOS_ALERT = {
        TITULO: "ERRO",
        CORPO: "Informações Incompletas ou Incorretas, verifique observações, tipo de Conclusão e se o Token Confere!"
      };
      this.Evento_open_alert_erro.emit(new_alert);
    } else {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          // Localização obtida com sucesso
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;

          new_body_conclusao.LAT_CONC = latitude
          new_body_conclusao.LNG_CONC = longitude

          console.log('Objeto criado com localização do usuário:', new_body_conclusao);


          const requisicao_server: any = await this.aordexService.POST_CONCLUIR_NOTA(new_body_conclusao);
          const resposta: RESPOSTA_PADRAO_SERVIDOR = requisicao_server;

          if (resposta.MSG == 'SUCESSO') {

            const atualiza = await this.ngOnInit()

            this.Evento_close_loading.emit(true);
            let new_alert: DADOS_ALERT = {
              TITULO: resposta.MSG,
              CORPO: resposta.ADICIONAL
            };
            this.Evento_open_alert_sucess.emit(new_alert);




            setTimeout(() => {
              this.clearImages();
              this.Nat_change_view_concluir_nota(undefined);
            }, 1000); // 1000ms = 1 segundo


          } else {
            this.Evento_close_loading.emit(true);
            let new_alert: DADOS_ALERT = {
              TITULO: resposta.MSG,
              CORPO: resposta.ADICIONAL
            };
            this.Evento_open_alert_erro.emit(new_alert);
          }
        },
        (error) => {
          // Tratamento de erro ao obter localização
          console.error('Erro ao obter localização do usuário:', error);
          alert('Não foi possível obter a localização atual. Por favor, verifique as permissões de localização.');
        }
      );

    }



  }
}




