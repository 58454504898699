import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DADOS_ALERT } from '../../MODELS/DADOS_ALERT';

@Component({
  selector: 'app-alert-sucesso',
  standalone: true,
  imports: [],
  templateUrl: './alert-sucesso.component.html',
  styleUrl: './alert-sucesso.component.css'
})
export class AlertSucessoComponent {


  @Input() Input_dados_alert?:DADOS_ALERT

  @Output() Evento_close_alert_sucesso = new EventEmitter<boolean>();

  Nat_close_alert(){
    this.Evento_close_alert_sucesso.emit(false)
  }
}
