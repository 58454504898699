import { AfterViewInit, Component, ViewChild, OnInit, ElementRef } from '@angular/core';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';


import { Router } from '@angular/router';
import { StoxService } from '../../SERVICES/stox.service';
import { environment } from '../../../../../environments/environment.development';
import { codigo_barras, ITEM, REQ_NOVA_RETIRADA, retirada_pendente, RETORNO_SERVIDOR } from '../../../../MODELS/MODELS_STOX';







export interface User {
  name: string;
}

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [MatFormFieldModule,
    MatInputModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,


  ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.css'
})
export class HomeComponentStox implements AfterViewInit {
  displayedColumns_codigos_barra: string[] = ['codigo'];
  dataSource_codigos_barra: MatTableDataSource<codigo_barras>;

  @ViewChild('sort_cod_barras') sort_cod_barras = new MatSort();
  @ViewChild('paginator_cod_barras') paginator_cod_barras: MatPaginator;



  displayedColumns_itens_atuais: string[] = ['INFORMCOES', 'QUANTIDADE'];
  dataSource_itens_atuais: MatTableDataSource<retirada_pendente>;

  @ViewChild('sort_itens_atuais') sort_itens_atuais = new MatSort();
  @ViewChild('paginator_itens_atuais') paginator_itens_atuais: MatPaginator;




  displayedColumns_itens_estoque: string[] = ['COD_INTERNO', 'TIPO', 'NOME', 'QUANTIDADE'];
  dataSource_itens_estoque: MatTableDataSource<ITEM>;

  @ViewChild('sort_itens_estoque') sort_itens_estoque = new MatSort();
  @ViewChild('paginator_itens_estoque') paginator_itens_estoque: MatPaginator;


  constructor(private allservices: StoxService, private router: Router) {
  }

  is_superuser:boolean = false

  goto(page: string) {
    this.router.navigateByUrl(page)
  }

  @ViewChild('span_username') span_username!: ElementRef
  ngAfterViewInit() {

    if (!environment.user_atual) {
      this.router.navigateByUrl('')
    } else {

      this.span_username.nativeElement.innerText  = environment.user_atual.NOME

      if(environment.user_atual.XTOQ_MASTER == true){
        this.is_superuser = true
      }

      this.atualiza_lista()

    }



  }









  @ViewChild('input_doc') input_doc!: ElementRef
  @ViewChild('input_cce') input_cce!: ElementRef
  @ViewChild('input_osme') input_osme!: ElementRef

  @ViewChild('input_id') input_id!: ElementRef
  @ViewChild('input_nome') input_nome!: ElementRef
  @ViewChild('input_codigo') input_codigo!: ElementRef
  @ViewChild('input_tipo') input_tipo!: ElementRef
  @ViewChild('input_quantidade') input_quantidade!: ElementRef

  @ViewChild('input_codigos') input_codigos!: ElementRef

  codigos_barra_atuais: codigo_barras[] = []

  itens_adicionados: retirada_pendente[] = []

  onInputCodigos(value: string) {
    let quantidade: number = 0

    if (!isNaN(parseInt(this.input_quantidade.nativeElement.value))) {
      quantidade = parseInt(this.input_quantidade.nativeElement.value)
    }


    const foundItem = this.codigos_barra_atuais.find(codigo => codigo.codigo === value);
    if (!foundItem && quantidade > this.codigos_barra_atuais.length) {
      const new_codigo: codigo_barras = {
        codigo: value
      }

      this.codigos_barra_atuais.push(new_codigo)

      this.atualiza_lista()

      this.input_codigos.nativeElement.value = ''
    } else if (quantidade <= this.codigos_barra_atuais.length) {
      alert('Não é possível adicionar mais códigos de barra que a quantidade solicitada!')
      this.input_codigos.nativeElement.value = ''
    } else {
      alert('Código de barras ja inserido!')
      this.input_codigos.nativeElement.value = ''
    }


  }

  adicionar_itens() {
    const id: number = parseInt(this.input_id.nativeElement.value)
    const tipo: string = this.input_tipo.nativeElement.value
    const input_codigo: string = this.input_codigo.nativeElement.value
    let quantidade: number = 0

    if (!isNaN(parseInt(this.input_quantidade.nativeElement.value))) {
      quantidade = parseInt(this.input_quantidade.nativeElement.value)
    }
    const nome: string = this.input_nome.nativeElement.value
    const doc: string = this.input_doc.nativeElement.value
    const cce: string = this.input_cce.nativeElement.value
    const osme: string = this.input_osme.nativeElement.value

    let new_item_retirada: retirada_pendente = {
      ITEM_ID: id,
      TIPO: tipo,
      NOME: nome,
      CODIGO: input_codigo,
      QUANTIDADE: quantidade,
      COD_BARRAS: this.codigos_barra_atuais.map((codigo_barras) => codigo_barras.codigo)
    }

    if (isNaN(id)) {
      alert('Nenhum item selecionado!')
    } else {
      this.dataSource_itens_estoque.data.forEach((item) => {
        if (item.ID == id) {

          if (quantidade <= item.QUANTIDADE && quantidade > 0) {

            if (item.COD_BARRAS == true) {

              if (quantidade == this.codigos_barra_atuais.length) {
                this.itens_adicionados.push(new_item_retirada)
                this.atualiza_lista()
                this.limpar()
              } else {
                alert('A quantidade de seriais deve ser igual a quantidade de itens solicitados!')
              }

            } else {
              this.itens_adicionados.push(new_item_retirada)
              this.atualiza_lista()
              this.limpar()
            }

          } else {
            alert('Não é possível retirar mais itens que o disponível no estoque ou nenhum item!')
          }

        }
      });
    }





  }
  limpar() {
    this.input_id.nativeElement.value = ''
    this.input_tipo.nativeElement.value = ''
    this.input_codigo.nativeElement.value = ''
    this.input_nome.nativeElement.value = ''
    this.input_quantidade.nativeElement.value = ''

    this.codigos_barra_atuais = []
    this.dataSource_codigos_barra = new MatTableDataSource(this.codigos_barra_atuais);
    this.dataSource_codigos_barra.paginator = this.paginator_cod_barras;
    this.dataSource_codigos_barra.sort = this.sort_cod_barras;


  }

  limpar_tudo() {
    this.input_doc.nativeElement.value = ''
    this.input_cce.nativeElement.value = ''
    this.input_osme.nativeElement.value = ''
    this.input_quantidade.nativeElement.value = ''


    this.input_id.nativeElement.value = ''
    this.input_tipo.nativeElement.value = ''
    this.input_codigo.nativeElement.value = ''
    this.input_nome.nativeElement.value = ''

    this.codigos_barra_atuais = []
    this.dataSource_codigos_barra = new MatTableDataSource(this.codigos_barra_atuais);
    this.dataSource_codigos_barra.paginator = this.paginator_cod_barras;
    this.dataSource_codigos_barra.sort = this.sort_cod_barras;

    this.itens_adicionados = []
    this.dataSource_itens_atuais = new MatTableDataSource(this.itens_adicionados);
    this.dataSource_itens_atuais.paginator = this.paginator_itens_atuais;
    this.dataSource_itens_atuais.sort = this.sort_itens_atuais;

  }

  selecionar(ITEM: ITEM) {

    this.limpar()

    const id: number = parseInt(this.input_id.nativeElement.value)
    const tipo: string = this.input_tipo.nativeElement.value
    const input_codigo: string = this.input_codigo.nativeElement.value
    let quantidade: number = 0

    if (!isNaN(parseInt(this.input_quantidade.nativeElement.value))) {
      quantidade = parseInt(this.input_quantidade.nativeElement.value)
    }
    const nome: string = this.input_nome.nativeElement.value
    const doc: string = this.input_doc.nativeElement.value
    const cce: string = this.input_cce.nativeElement.value
    const osme: string = this.input_osme.nativeElement.value


    this.input_id.nativeElement.value = ITEM.ID
    this.input_tipo.nativeElement.value = ITEM.TIPO
    this.input_codigo.nativeElement.value = ITEM.COD_INTERNO
    this.input_nome.nativeElement.value = ITEM.NOME
    this.input_codigos.nativeElement.value = ''

  }
  applyFilter_cod_barras(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource_codigos_barra.filter = filterValue.trim().toLowerCase();

    if (this.dataSource_codigos_barra.paginator) {
      this.dataSource_codigos_barra.paginator.firstPage();
    }
  }

  applyFilter_itens_atuais(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource_itens_atuais.filter = filterValue.trim().toLowerCase();

    if (this.dataSource_itens_atuais.paginator) {
      this.dataSource_itens_atuais.paginator.firstPage();
    }
  }
  applyFilter_itens_estoque(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource_itens_estoque.filter = filterValue.trim().toLowerCase();

    if (this.dataSource_itens_estoque.paginator) {
      this.dataSource_itens_estoque.paginator.firstPage();
    }
  }

  async nova_retirada() {
    const doc: string = this.input_doc.nativeElement.value
    const cce: string = this.input_cce.nativeElement.value
    const osme: string = this.input_osme.nativeElement.value

    if (this.itens_adicionados.length > 0) {
      if (doc != '' && cce != '' && osme != '') {
        let body: REQ_NOVA_RETIRADA = {
          RETIRADAS: [],
          OSME: osme,
          CCE: cce,
          DOC: doc,
          USER_ID: environment.user_atual.ID
        }
        this.itens_adicionados.forEach((item) => {
          body.RETIRADAS.push(item)
        })


        const req_retirar_item: any = await this.allservices.POST_criar_retirada(body)
        let retorno_2: RETORNO_SERVIDOR = req_retirar_item
    
    
    
    
        if (retorno_2.MSG == 'SUCESSO') {
          this.atualiza_lista()
          this.limpar_tudo()

          alert('Retirada realizada com sucesso!')
        }else{
          alert(retorno_2.ADICIONAL)
        }

      } else {
        alert('As informações de identificação estão vazias(osme,doc,cce)!')
      }
    } else {
      alert('Nenhum item solicitado!')
    }


  }

  async atualiza_lista() {

    const req_buscar_item: any = await this.allservices.POST_listar_item(environment.user_atual.EMPRESA_ID)
    let retorno_2: RETORNO_SERVIDOR = req_buscar_item




    if (retorno_2.MSG == 'SUCESSO') {
      this.dataSource_itens_estoque = new MatTableDataSource(retorno_2.RESPOSTA);

      this.dataSource_itens_estoque.paginator = this.paginator_itens_estoque;
      this.dataSource_itens_estoque.sort = this.sort_itens_estoque;


      this.dataSource_codigos_barra = new MatTableDataSource(this.codigos_barra_atuais);
      this.dataSource_codigos_barra.paginator = this.paginator_cod_barras;
      this.dataSource_codigos_barra.sort = this.sort_cod_barras;



      this.dataSource_itens_atuais = new MatTableDataSource(this.itens_adicionados);

      this.dataSource_itens_atuais.paginator = this.paginator_itens_atuais;
      this.dataSource_itens_atuais.sort = this.sort_itens_atuais;


    } else {
      alert(retorno_2.ADICIONAL)
    }




  }
}

