import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { CommonModule } from '@angular/common';
import { COORDENADA } from '../../../MODELS/COORDENADA';
import { environment } from '../../../../environments/environment.development';
import { USER } from '../../../MODELS/USER';
import { RESPOSTA_PADRAO_SERVIDOR } from '../../../MODELS/RESPOSTA_PADRAO_SERVIDOR';
import { MainService } from '../../../SERVICES/main.service';
import { INSTALACAO_BD } from '../../../MODELS/INSTALACAO_BD';
import { INSTALACAO_BUSCADA } from '../../../MODELS/INSTALACAO_BUSCADA';

@Component({
  selector: 'app-navbar-main',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './navbar-main.component.html',
  styleUrls: ['./navbar-main.component.css'],
})
export class NavbarMainComponent implements OnInit{
  suggestions: any[] = [];
  Nat_type_busca: string = 'ENDEREÇO';
  Nat_coordenada_localizada: COORDENADA;



  @Output() Evento_change_state_DISPONIVEIS = new EventEmitter<boolean>();
  @Output() Evento_change_state_EDICOES = new EventEmitter<boolean>();
  @Output() Evento_change_state_FERRAMENTAS = new EventEmitter<boolean>();
  @Output() Evento_change_state_MODULOS = new EventEmitter<boolean>();
  @Output() Evento_close_perfil = new EventEmitter<boolean>()//abre ou fecha a aba de perfil de acordo com o boolean
  @Output() Evento_local_buscado = new EventEmitter<COORDENADA>() //ENVIA A COORDENADA DO LOCAL BUSCADO NA BARRA DE PESQUISA DE LOCAIS
  @Output() Evento_markers_temporarios = new EventEmitter<COORDENADA[]>() 
  
  @Input() Nat_state_DISPONIVEIS: boolean = false
  @Input() Nat_state_EDICOES: boolean = false
  @Input() Nat_state_FERRAMENTAS: boolean = false
  @Input() Nat_state_MODULOS: boolean = false

  user:USER
  fotoBase64: string | null = null;


  ngOnInit() {
    this.user = environment.user_atual;

    if(environment.foto){
      this.fotoBase64 = environment.foto
    }else{
    // Verifica se há uma foto e faz a conversão para Base64
    if (this.user?.FOTO?.data) {
      this.fotoBase64 = this.convertBufferToBase64(this.user.FOTO.data);
    }
    }

  }
// Função para converter o array de bytes em uma string Base64
convertBufferToBase64(buffer: number[]): string {
  const base64String = btoa(
    String.fromCharCode(...buffer)
  );
  return `data:image/png;base64,${base64String}`;
}
  Nat_open_perfil(){
    this.Evento_close_perfil.emit(true)
  }
  Nat_change_aba(aba: string) {//altera o estado das abas da nav

    if (aba == 'DISPONIVEIS') {
      this.Nat_state_DISPONIVEIS = !this.Nat_state_DISPONIVEIS

      if (this.Nat_state_DISPONIVEIS == true) {
        this.Evento_change_state_DISPONIVEIS.emit(true)
      } else {
        this.Evento_change_state_DISPONIVEIS.emit(false)
      }
    } else if (aba == 'EDICOES') {
      this.Nat_state_EDICOES = !this.Nat_state_EDICOES

      if (this.Nat_state_EDICOES == true) {
        this.Evento_change_state_EDICOES.emit(true)
      } else {
        this.Evento_change_state_EDICOES.emit(false)
      }
    } else if (aba == 'FERRAMENTAS') {
      this.Nat_state_FERRAMENTAS = !this.Nat_state_FERRAMENTAS

      if (this.Nat_state_FERRAMENTAS == true) {
        this.Evento_change_state_FERRAMENTAS.emit(true)
      } else {
        this.Evento_change_state_FERRAMENTAS.emit(false)
      }
    } else if (aba == 'MODULOS') {
      this.Nat_state_MODULOS = !this.Nat_state_MODULOS

      if (this.Nat_state_MODULOS == true) {
        this.Evento_change_state_MODULOS.emit(true)
      } else {
        this.Evento_change_state_MODULOS.emit(false)
      }
    }

  }

  constructor(private http: HttpClient,private mainservice:MainService) { }


  operacoes_encontradas_logradouro = []
  pontos_temporarios = []

  async onSearch(valor:string) { //vai buscando conforme é digitado no campo de busca
    let query = valor.trim();

    if(query ==''){
      this.Evento_markers_temporarios.emit([])
    }

    if(this.Nat_type_busca == 'INSTALAÇÃO'){


      const request_alterar_img_perfil: any = await this.mainservice.POST_ENCONTRAR_INSTALACAO({
        empresa_id: environment.user_atual.EMPRESA_ID,
        instalacao_buscada: query,
        modulo: 'GPX'
      })
      const retorno_padrao: RESPOSTA_PADRAO_SERVIDOR = request_alterar_img_perfil


      if (retorno_padrao.MSG == 'SUCESSO') {

        console.log(retorno_padrao.RESPOSTA)

        retorno_padrao.RESPOSTA.forEach((instalacao_encontrada:INSTALACAO_BUSCADA,i) => {
        this.suggestions.push(
          {display_name:`OPERAÇÃO ${instalacao_encontrada.OPERACAO_ID} ${instalacao_encontrada.USER_OPERACAO}`,lat:instalacao_encontrada.LAT,lon:instalacao_encontrada.LNG}
          )
       });
      } else {
     
        alert(retorno_padrao.ADICIONAL)
      }

    }else if(this.Nat_type_busca == 'LOGRADOURO CAD.'){

      const request_buscar_logradouro: any = await this.mainservice.POST_ENCONTRAR_LOGRADOURO({
        empresa_id: environment.user_atual.EMPRESA_ID,
        logradouro: valor,
        modulo: 'GPX'
      })
      const retorno_padrao: RESPOSTA_PADRAO_SERVIDOR = request_buscar_logradouro

      
      if (retorno_padrao.MSG == 'SUCESSO') {

     this.operacoes_encontradas_logradouro = retorno_padrao.RESPOSTA

        retorno_padrao.RESPOSTA.forEach((operacao:{OPERACAO_ID:number,USER_OPERACAO:string,ITENS:{INSTALACAO:string,LAT:number,LNG:number,LOGRADOURO:string,OPERACAO_ID:number}[]},i) => {
        this.suggestions.push(
          {display_name:`OPERAÇÃO ${operacao.OPERACAO_ID} ${operacao.USER_OPERACAO} - ${operacao.ITENS.length} ITENS`}
          )
       });
      } else {
     
        alert(retorno_padrao.ADICIONAL)
      }


    }else{
      if (query.length < 3) {
        this.suggestions = [];
        return;
      }
  
      of(query)
        .pipe(
          debounceTime(300),
          distinctUntilChanged(),
          switchMap((q) => {
            let url = '';
  
            if (this.Nat_type_busca === 'ENDEREÇO') {
              url = `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(
                q
              )}&addressdetails=1&limit=5`;
            } else if (this.Nat_type_busca === 'COORDENADA') {
              const [lat, lon] = q.split(',');
              if (!lat || !lon) {
                return of([]); // Retorna vazio se coordenadas são inválidas
              }
              url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat.trim()}&lon=${lon.trim()}&addressdetails=1`;
            }
  
            return this.http.get<any[]>(url);
          }),
          catchError((error) => {
            console.error('Erro ao buscar locais:', error);
            return of([]);
          })
        )
        .subscribe((results) => {
          if (this.Nat_type_busca === 'COORDENADA') {
            this.suggestions = results ? [results] : [];
          } else {
            this.suggestions = results;
          }
        });
    }
  

  }

  @ViewChild('inp_buscar') inp_buscar!: ElementRef;

  Nat_change_parametro() {//altera o metodo de busca entre endereco ou coordenada
    if (this.Nat_type_busca == 'COORDENADA') {
      this.Nat_type_busca = 'ENDEREÇO'
    } else if(this.Nat_type_busca == 'ENDEREÇO') {
      this.Nat_type_busca = 'INSTALAÇÃO'
    }else if(this.Nat_type_busca == 'INSTALAÇÃO'){
  this.Nat_type_busca = 'LOGRADOURO CAD.'
    }else if(this.Nat_type_busca == 'LOGRADOURO CAD.'){
this.Nat_type_busca = 'COORDENADA'
    }

  }

  selectSuggestion(suggestion: any): void {//seleciona um item na lista de sugestoes



    if (this.Nat_type_busca === 'ENDEREÇO') {
      this.inp_buscar.nativeElement.value = `${suggestion.address.road}, ${suggestion.address.postcode}, ${suggestion.address.municipality}, ${suggestion.address.state}`;
    
      this.Nat_coordenada_localizada = {
        lat: parseFloat(suggestion.lat),
        lng: parseFloat(suggestion.lon),
      };
  
      this.Evento_local_buscado.emit(this.Nat_coordenada_localizada)
    
    } else if (this.Nat_type_busca === 'COORDENADA') {
      this.inp_buscar.nativeElement.value = `${suggestion.address.road}, ${suggestion.address.postcode}, ${suggestion.address.municipality}, ${suggestion.address.state}`;
    
      this.Nat_coordenada_localizada = {
        lat: parseFloat(suggestion.lat),
        lng: parseFloat(suggestion.lon),
      };
  
      this.Evento_local_buscado.emit(this.Nat_coordenada_localizada)


    }else if (this.Nat_type_busca === 'INSTALAÇÃO') {

    
      this.Nat_coordenada_localizada = {
        lat: parseFloat(suggestion.lat),
        lng: parseFloat(suggestion.lon),
      };
  
      this.Evento_local_buscado.emit(this.Nat_coordenada_localizada)


    }else if(this.Nat_type_busca === 'LOGRADOURO CAD.'){
  
      let index = this.operacoes_encontradas_logradouro.findIndex((operacao => operacao.OPERACAO_ID == parseInt(suggestion.display_name.split(' ')[1])))
     
      if(index != -1){
       
        let coordenadas = []

        this.operacoes_encontradas_logradouro[index].ITENS.forEach((item) => {

          coordenadas.push({
            lat: parseFloat(item.LAT),
            lng: parseFloat(item.LNG),
          })

        });

        this.Evento_markers_temporarios.emit(coordenadas)
      }


    }




    this.suggestions = [];
  }

  Nat_clear_input() {//limpa o input de busca
    this.suggestions = [];
    this.Nat_coordenada_localizada = undefined
    this.inp_buscar.nativeElement.value = ''
  }



}
