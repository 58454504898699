<div class="relative z-50" aria-labelledby="modal-title" role="dialog" aria-modal="true">

    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
  
    <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
      <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">

        <div class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
          <div>
            <div class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
              <svg class="h-6 w-6 text-green-600" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" data-slot="icon">
                <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 12.75 6 6 9-13.5" />
              </svg>
            </div>
            <div class="mt-3 text-center sm:mt-5">
              <p class="text-base font-semibold text-gray-900" id="modal-title">{{Input_dados_alert?.TITULO}}</p>
              <div class="mt-2">
                <p class="text-sm text-gray-500">{{Input_dados_alert?.CORPO}}</p>
              </div>
            </div>
          </div>
          <div class="mt-5 sm:mt-6">
            <button 
            (click)="Nat_close_alert()"
            type="button"
             class="inline-flex w-full justify-center 
             rounded-md bg-blue-600 px-3 py-2
              text-sm font-semibold text-white shadow-sm
               hover:bg-blue-500 focus-visible:outline
                focus-visible:outline-2 focus-visible:outline-offset-2
                 focus-visible:outline-blue-600">Entendido!</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  