import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { AsyncPipe } from '@angular/common';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DADOS_ALERT } from '../../MODELS/DADOS_ALERT';
import { OPERACAO_ou_LIVRO } from '../../MODELS/OPERACAO_ou_LIVRO';
import { GpxService } from '../../MODULES/GPX/SERVICES/gpx.service';
import { MainService } from '../../SERVICES/main.service';
import { RESPOSTA_PADRAO_SERVIDOR } from '../../MODELS/RESPOSTA_PADRAO_SERVIDOR';
import { environment } from '../../../environments/environment.development';
import { DETALHES_POLIGONO } from '../../MODELS/DETALHES_POLIGONO';
import { DivixService } from '../../MODULES/DIVIX/SERVICES/divix.service';

@Component({
  selector: 'app-divix-detalhes-poligono',
  standalone: true,
  imports: [
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    AsyncPipe,
  ],
  templateUrl: './divix-detalhes-poligono.component.html',
  styleUrl: './divix-detalhes-poligono.component.css'
})
export class DivixDetalhesPoligonoComponent implements OnInit {

  /**lista de operacoes disponiveis para envio dos itens */
  myControl = new FormControl('');
  options: string[] = [];
  filteredOptions: Observable<string[]>;

  /**lista de livros disponiveis para envio dos itens */
  myControl_livros = new FormControl('');
  options_livros: string[] = [];
  filteredOptions_livros: Observable<string[]>;

  @Input() Input_poligono_recebido: DETALHES_POLIGONO
  @Input() input_ids_operacoes_mapa: any[]

  Nat_tipo_alteracao: string = "livro"
  Nat_altera_tipo(event: Event): void {
    const selectElement = event.target as HTMLSelectElement;
    this.Nat_tipo_alteracao = selectElement.value;

  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.options.filter(option => option.toLowerCase().includes(filterValue));
  }

  private _filter_livros(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.options_livros.filter(option => option.toLowerCase().includes(filterValue));
  }

  constructor(private service: DivixService, private main_service: MainService) {


  }
  Nat_operacoes: OPERACAO_ou_LIVRO[] = []
  Nat_livros: OPERACAO_ou_LIVRO[] = []

  /**evento que abre a aba de loading no component main */
  @Output() Evento_open_loading = new EventEmitter<boolean>();

  /**evento que fecha a aba de loading no component main */
  @Output() Evento_close_loading = new EventEmitter<boolean>();

  /**evento que abre um alerta de sucesso no component main */
  @Output() Evento_open_alert_sucess = new EventEmitter<DADOS_ALERT>();

  /**evento que abre um alerta de erro no component main */
  @Output() Evento_open_alert_erro = new EventEmitter<DADOS_ALERT>();


  @Output() Evento_close_detalhes_poligono = new EventEmitter<boolean>();


  async ngOnInit() {

    this.Evento_open_loading.emit(true)
    this.Nat_busca_operacoes()
    this.Nat_busca_livros()

  }

  async Nat_busca_operacoes() {
    const requisicao_server: any = await this.service.POST_OPERACOES_DIVIX_EDICOES(environment.user_atual.ID)
    const resposta: RESPOSTA_PADRAO_SERVIDOR = requisicao_server


    if (resposta.MSG == 'SUCESSO') {

      this.Nat_operacoes = resposta.RESPOSTA



      this.Nat_operacoes.forEach((operacao, o) => {
        this.options.push(`${operacao.ID_STR} - ${operacao.CENTRO_LEITURA_STR} - ${operacao.GRUPO_STR}`)
      });

      this.filteredOptions = this.myControl.valueChanges.pipe(
        startWith(''),
        map(value => this._filter(value || '')),
      );

      this.Evento_close_loading.emit(true)
    } else {
      this.Evento_close_loading.emit(true)
      let new_alert: DADOS_ALERT = {
        TITULO: resposta.MSG,
        CORPO: resposta.ADICIONAL
      }
      this.Evento_open_alert_erro.emit(new_alert)
    }
  }

    @ViewChild('inp_nova_coordenada') inp_nova_coordenada!: ElementRef
    async Nat_enviar_alteracao_coordenada() {
      this.Evento_open_loading.emit(true)
      const nova_coordenada_input: string = this.inp_nova_coordenada.nativeElement.value.trim();
  
      // Expressão regular para validar o formato "latitude, longitude"
      const coordenadaRegex = /^(-?\d{1,2}(?:\.\d+)?),\s*(-?\d{1,3}(?:\.\d+)?)$/;
  
      if (coordenadaRegex.test(nova_coordenada_input)) {
  
  
  
  
        const [latitude, longitude] = nova_coordenada_input.split(',').map(coord => parseFloat(coord.trim()));
  
        const requisicao_server: any = await this.service.POST_MUDAR_COORDENADA(latitude, longitude, this.Input_poligono_recebido.ITENS_ID)
        const resposta: RESPOSTA_PADRAO_SERVIDOR = requisicao_server
  
  
        if (resposta.MSG == 'SUCESSO') {
  
          this.inp_nova_coordenada.nativeElement.value = ''
          this.Nat_tipo_alteracao = ''
  

  
  
          this.Evento_close_loading.emit(true)
          
          let new_alert: DADOS_ALERT = {
            TITULO: resposta.MSG,
            CORPO: resposta.ADICIONAL
          }
          this.Evento_open_alert_sucess.emit(new_alert)
          this.Nat_close_detalhes_poligono()
  
        } else {
          this.Evento_close_loading.emit(true)
          let new_alert: DADOS_ALERT = {
            TITULO: resposta.MSG,
            CORPO: resposta.ADICIONAL
          }
          this.Evento_open_alert_erro.emit(new_alert)
        }
  
  
  
      } else {
        // Coordenada inválida
        this.Evento_close_loading.emit(true)
        let new_alert: DADOS_ALERT = {
          TITULO: "ERRO",
          CORPO: "Formato inválido. Use o formato: -25.436251, -49.337996"
        }
        this.Evento_open_alert_erro.emit(new_alert)
  
      }
    }
  async Nat_busca_livros() {

    this.Evento_open_loading.emit(true)

    if (this.input_ids_operacoes_mapa.length > 0) {
      const requisicao_server: any = await this.service.POST_RETORNA_LIVROS_OPERACAO_DIVIX(parseInt(this.input_ids_operacoes_mapa[0].split("-")[0]))
      const resposta: RESPOSTA_PADRAO_SERVIDOR = requisicao_server


      if (resposta.MSG == 'SUCESSO') {

        this.Nat_livros = resposta.RESPOSTA



        this.Nat_livros.forEach((operacao, o) => {
          this.options_livros.push(`${operacao.LIVRO_STR}`)
        });

        this.filteredOptions_livros = this.myControl_livros.valueChanges.pipe(
          startWith(''),
          map(value => this._filter_livros(value || '')),
        );

        this.Evento_close_loading.emit(true)
      } else {
        this.Evento_close_loading.emit(true)
        let new_alert: DADOS_ALERT = {
          TITULO: resposta.MSG,
          CORPO: resposta.ADICIONAL
        }
        this.Evento_open_alert_erro.emit(new_alert)
      }
    }


  }

  @Output() Evento_repoe_mapa = new EventEmitter<boolean>()
  async Nat_alterar_livro() {
    this.Evento_open_loading.emit(true)
    if (this.Input_poligono_recebido.TIPO == "SELECAO") {





      const requisicao_server: any = await this.service.POST_ALTERA_LIVRO_DIVIX(parseInt(this.input_ids_operacoes_mapa[0].split("-")[0]),
      "",
      this.myControl_livros.value.replace('.',''),
      this.Input_poligono_recebido.ITENS_ID
      )
      const resposta: RESPOSTA_PADRAO_SERVIDOR = requisicao_server


      if (resposta.MSG == 'SUCESSO') {

        this.Evento_repoe_mapa.emit(true)
        this.Evento_close_loading.emit(true)
      } else {
        this.Evento_close_loading.emit(true)
        let new_alert: DADOS_ALERT = {
          TITULO: resposta.MSG,
          CORPO: resposta.ADICIONAL
        }
        this.Evento_open_alert_erro.emit(new_alert)
      }
    } else {



      const requisicao_server: any = await this.service.POST_ALTERA_LIVRO_DIVIX(
        parseInt(this.input_ids_operacoes_mapa[0].split("-")[0]),
      this.Input_poligono_recebido.LIVRO.replace('.',''),
      this.myControl_livros.value.replace('.',''),
      []
      )
      const resposta: RESPOSTA_PADRAO_SERVIDOR = requisicao_server


      if (resposta.MSG == 'SUCESSO') {

        this.Evento_repoe_mapa.emit(true)
        this.Evento_close_loading.emit(true)
      } else {
        this.Evento_close_loading.emit(true)
        let new_alert: DADOS_ALERT = {
          TITULO: resposta.MSG,
          CORPO: resposta.ADICIONAL
        }
        this.Evento_open_alert_erro.emit(new_alert)
      }
    }

  }

  async Nat_alterar_operacao() {

    this.Evento_open_loading.emit(true)
    const requisicao_server: any = await this.service.POST_ALTERA_OPERACAO_DIVIX( 
      parseInt(this.input_ids_operacoes_mapa[0].split("-")[0]),
    this.Input_poligono_recebido.LIVRO.replace('.',''),
    parseInt(this.myControl.value.split('-')[0].replace('ID ', '').replace('.', ''))

    )
    const resposta: RESPOSTA_PADRAO_SERVIDOR = requisicao_server


    if (resposta.MSG == 'SUCESSO') {

      this.Evento_repoe_mapa.emit(true)
      this.Evento_close_loading.emit(true)
    } else {
      this.Evento_close_loading.emit(true)
      let new_alert: DADOS_ALERT = {
        TITULO: resposta.MSG,
        CORPO: resposta.ADICIONAL
      }
      this.Evento_open_alert_erro.emit(new_alert)
    }
  }


  numeroSubPartes: number = 2;

  // Função chamada ao alterar o valor do input
  atualizarNumero(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    this.numeroSubPartes = Math.max(2, Number(inputElement.value)); // Garante que o valor seja no mínimo 2
  }
  @Input() input_livros_mapa: OPERACAO_ou_LIVRO[] = []
    /**evento que envia um id de operacao para ser aberto no mapa */
    @Output() Evento_open_operacao = new EventEmitter<OPERACAO_ou_LIVRO>();
    /**evento que envia um id de operacao para ser fechado no mapa */
    @Output() Evento_close_operacao = new EventEmitter<OPERACAO_ou_LIVRO>();
  // Função chamada ao clicar no botão
  async Nat_subdividir_livro() {
    this.Evento_open_loading.emit(true)

    const requisicao_server: any = await this.service.POST_DIVIDIR_LIVROS( 
      parseInt(this.input_ids_operacoes_mapa[0].split("-")[0]),
    this.Input_poligono_recebido.LIVRO.replace('.',''),
    this.numeroSubPartes

    )
    const resposta: RESPOSTA_PADRAO_SERVIDOR = requisicao_server


    if (resposta.MSG == 'SUCESSO') {
      
      for(let l =0;l<this.input_livros_mapa.length;l++){
        this.Evento_close_operacao.emit(this.input_livros_mapa[l])
      }

      this.Evento_close_detalhes_poligono.emit(false)

      this.Evento_close_loading.emit(true)
    } else {
      this.Evento_close_detalhes_poligono.emit(false)

      this.Evento_close_loading.emit(true)
      let new_alert: DADOS_ALERT = {
        TITULO: resposta.MSG,
        CORPO: resposta.ADICIONAL
      }
      this.Evento_open_alert_erro.emit(new_alert)
    }
  }

  Nat_close_detalhes_poligono() {
    this.Evento_close_detalhes_poligono.emit(false)
  }



}
