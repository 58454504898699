import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment.development';
import { RESPOSTA_PADRAO_SERVIDOR } from '../../../MODELS/RESPOSTA_PADRAO_SERVIDOR';

@Injectable({
  providedIn: 'root'
})
export class DivixService {


  constructor(private httpClient: HttpClient) { }


  //SOLICITA A LISTA DE OPERACOES EM EDICAO PELO USER
  async POST_OPERACOES_DIVIX_EDICOES(user_id: number) {
    try {
      const response = await this.httpClient
        .post(`${environment.url_api_local}/POST_OPERACOES_DIVIX_EDICOES`, { user_id: user_id })
        .toPromise();


      return response;
    } catch (error) {
      console.log(error, 'erro');
      const retorno_padrao: RESPOSTA_PADRAO_SERVIDOR = {
        MSG: "ERRO",
        ADICIONAL: "",
        RESPOSTA: undefined
      }
      return retorno_padrao

    }
  }

    //CRIA UMA NOVA RUA MANUAL E VINCULA AS INSTALACOES À ELA CRIANDO UMA SEQUENCIA
    async POST_SALVAR_ROTEIRO_MANUAL(instalacoes: any[],newWay:any) {
      try {
        const response = await this.httpClient
          .post(`${environment.url_api_local}/POST_SALVAR_ROTEIRO_MANUAL`, { instalacoes: instalacoes,newWay:newWay })
          .toPromise();
  
  
        return response;
      } catch (error) {
        console.log(error, 'erro');
        const retorno_padrao: RESPOSTA_PADRAO_SERVIDOR = {
          MSG: "ERRO",
          ADICIONAL: "",
          RESPOSTA: undefined
        }
        return retorno_padrao
  
      }
    }
        //BUSCA AS RUAS CRADAS MANUALMENTE
        async POST_LISTAR_ROTEIROS_MANUAIS(userId) {
          try {
            const response = await this.httpClient
              .post(`${environment.url_api_local}/POST_LISTAR_ROTEIROS_MANUAIS`, { userId: userId })
              .toPromise();
      
      
            return response;
          } catch (error) {
            console.log(error, 'erro');
            const retorno_padrao: RESPOSTA_PADRAO_SERVIDOR = {
              MSG: "ERRO",
              ADICIONAL: "",
              RESPOSTA: undefined
            }
            return retorno_padrao
      
          }
        }

        //BUSCA AS INSTALACOES DE UM ROTEIRO MANUAL 
        async POST_ITENS_ROTEIRO_MANUAL(wayId) {
          try {
            const response = await this.httpClient
              .post(`${environment.url_api_local}/POST_ITENS_ROTEIRO_MANUAL`, { wayId: wayId })
              .toPromise();
      
      
            return response;
          } catch (error) {
            console.log(error, 'erro');
            const retorno_padrao: RESPOSTA_PADRAO_SERVIDOR = {
              MSG: "ERRO",
              ADICIONAL: "",
              RESPOSTA: undefined
            }
            return retorno_padrao
      
          }
        }

        //MUDA A COORDENADA DE UMA OU MAIS INSTALACOES
    async POST_MUDAR_COORDENADA(latitude: number,longitude:number,ids:number[]) {
      try {
        const response = await this.httpClient
          .post(`${environment.url_api_local}/POST_MUDAR_COORDENADA_DIVIX`, { latitude: latitude,longitude:longitude,ids:ids })
          .toPromise();
  
  
        return response;
      } catch (error) {
        console.log(error, 'erro');
        const retorno_padrao: RESPOSTA_PADRAO_SERVIDOR = {
          MSG: "ERRO",
          ADICIONAL: "",
          RESPOSTA: undefined
        }
        return retorno_padrao
  
      }
    }

  //SOLICITA A LISTA DE LIVROS DE UMA OPERACAO
  async POST_RETORNA_LIVROS_OPERACAO_DIVIX(operacao_id: number) {
    try {
      const response = await this.httpClient
        .post(`${environment.url_api_local}/POST_RETORNA_LIVROS_OPERACAO_DIVIX`, { operacao_id: operacao_id })
        .toPromise();


      return response;
    } catch (error) {
      console.log(error, 'erro');
      const retorno_padrao: RESPOSTA_PADRAO_SERVIDOR = {
        MSG: "ERRO",
        ADICIONAL: "",
        RESPOSTA: undefined
      }
      return retorno_padrao

    }
  }



  //SOLICITA INSTALACOES DE UM LIVRO
  async POST_ITENS_LIVRO_DIVIX(operacao_id: number, livro: string) {
    try {
      const response = await this.httpClient
        .post(`${environment.url_api_local}/POST_ITENS_LIVRO_DIVIX`, { operacao_id: operacao_id, livro: livro })
        .toPromise();


      return response;
    } catch (error) {
      console.log(error, 'erro');
      const retorno_padrao: RESPOSTA_PADRAO_SERVIDOR = {
        MSG: "ERRO",
        ADICIONAL: "",
        RESPOSTA: undefined
      }
      return retorno_padrao

    }
  }

    //ENVIA UM GRUPO DE ITENS PARA O AORDEX PARA SEREM SEQUENCIADOS DA MELHOR FORMA POSSIVEL
    async POST_ENVIAR_AORDEX(operacao_id: number) {
      try {
        const response = await this.httpClient
          .post(`${environment.url_api_local}/POST_ENVIAR_AORDEX`, { operacao_id: operacao_id ,user:environment.user_atual})
          .toPromise();
  
  
        return response;
      } catch (error) {
        console.log(error, 'erro');
        const retorno_padrao: RESPOSTA_PADRAO_SERVIDOR = {
          MSG: "ERRO",
          ADICIONAL: "",
          RESPOSTA: undefined
        }
        return retorno_padrao
  
      }
    }
  

  //DEVOLVE UMA OPERAÇÃO AO GPX
  async POST_DEVOLVER_AO_GPX(operacao_id: number) {
    try {
      const response = await this.httpClient
        .post(`${environment.url_api_local}/POST_DEVOLVER_AO_GPX`, { operacao_id: operacao_id })
        .toPromise();


      return response;
    } catch (error) {
      console.log(error, 'erro');
      const retorno_padrao: RESPOSTA_PADRAO_SERVIDOR = {
        MSG: "ERRO",
        ADICIONAL: "",
        RESPOSTA: undefined
      }
      return retorno_padrao

    }
  }


  //RECEBE OS PARAMETROS NECESSARIOS E CRIA NOVOS LIVROS A PARTIR DELES
  async POST_CRIAR_LIVRO_UNICO_DIVIX(operacao_id: number) {
    try {
      const response = await this.httpClient
        .post(`${environment.url_api_local}/POST_CRIAR_LIVRO_UNICO_DIVIX`, { operacao_id: operacao_id })
        .toPromise();


      return response;
    } catch (error) {
      console.log(error, 'erro');
      const retorno_padrao: RESPOSTA_PADRAO_SERVIDOR = {
        MSG: "ERRO",
        ADICIONAL: "",
        RESPOSTA: undefined
      }
      return retorno_padrao

    }
  }



  //RECEBE OS PARAMETROS NECESSARIOS E CRIA NOVOS LIVROS A PARTIR DELES
  async POST_CRIAR_LIVROS(operacao_id: number, variacao: number, parametro: string, quantidade: number, quadras: boolean) {
    try {
      const response = await this.httpClient
        .post(`${environment.url_api_local}/POST_CRIAR_LIVROS`, {
          operacao_id: operacao_id,
          variacao: variacao,
          parametro: parametro,
          quantidade: quantidade,
          quadras: quadras
        })
        .toPromise();


      return response;
    } catch (error) {
      console.log(error, 'erro');
      const retorno_padrao: RESPOSTA_PADRAO_SERVIDOR = {
        MSG: "ERRO",
        ADICIONAL: "",
        RESPOSTA: undefined
      }
      return retorno_padrao

    }
  }

  //ALTERA O LIVRO DE UM GRUPO DE INSTALAÇÕES
  async POST_ALTERA_LIVRO_DIVIX(operacao_id: number, livro_origem: string, livro_destino: string, ids_itens: number[]) {
    try {
      const response = await this.httpClient
        .post(`${environment.url_api_local}/POST_ALTERA_LIVRO_DIVIX`,
          {
            operacao_id: operacao_id,
            livro_origem: livro_origem,
            livro_destino: livro_destino,
            ids_itens: ids_itens
          })
        .toPromise();


      return response;
    } catch (error) {
      console.log(error, 'erro');
      const retorno_padrao: RESPOSTA_PADRAO_SERVIDOR = {
        MSG: "ERRO",
        ADICIONAL: "",
        RESPOSTA: undefined
      }
      return retorno_padrao

    }
  }


  //ALTERA A OPERACAO DE UM LIVRO INTEIRO
  async POST_ALTERA_OPERACAO_DIVIX(operacao_id: number, livro_origem: string, operacao_destino_id: number) {
    try {
      const response = await this.httpClient
        .post(`${environment.url_api_local}/POST_ALTERA_OPERACAO_DIVIX`,
          {
            operacao_id: operacao_id,
            livro_origem: livro_origem,
            operacao_destino_id: operacao_destino_id
          })
        .toPromise();


      return response;
    } catch (error) {
      console.log(error, 'erro');
      const retorno_padrao: RESPOSTA_PADRAO_SERVIDOR = {
        MSG: "ERRO",
        ADICIONAL: "",
        RESPOSTA: undefined
      }
      return retorno_padrao

    }
  }


  //CALCULA A DURACAO DE UM TRAJETO, NSIDERANDO O MODAL, COM OU SEM INICIO DEFINIDO
  async POST_OSRM_TRIP(operacao_id: number, livro: string, origem_id: number, tipo: "pe" | "carro" | "moto") {
    try {
      const response = await this.httpClient
        .post(`${environment.url_api_local}/POST_OSRM_TRIP`,
          {
            operacao_id: operacao_id,
            livro: livro,
            origem_id: origem_id,
            tipo: tipo
          })
        .toPromise();


      return response;
    } catch (error) {
      console.log(error, 'erro');
      const retorno_padrao: RESPOSTA_PADRAO_SERVIDOR = {
        MSG: "ERRO",
        ADICIONAL: "",
        RESPOSTA: undefined
      }
      return retorno_padrao

    }
  }




  //DIVIDE UM LIVRO EM SUB PARTES
  async POST_DIVIDIR_LIVROS(operacao_id: number, livro_original: string, quantidade: number) {
    try {
      const response = await this.httpClient
        .post(`${environment.url_api_local}/POST_DIVIDIR_LIVROS`,
          {
            operacao_id: operacao_id,
            livro_original: livro_original,
            quantidade: quantidade
          })
        .toPromise();


      return response;
    } catch (error) {
      console.log(error, 'erro');
      const retorno_padrao: RESPOSTA_PADRAO_SERVIDOR = {
        MSG: "ERRO",
        ADICIONAL: "",
        RESPOSTA: undefined
      }
      return retorno_padrao

    }
  }



    //ENVIA UMA OPERACAO DO DIVIX PARA O ROUTEX
    async POST_ENVIAR_ROUTEX(operacao_id: number) {
      try {
        const response = await this.httpClient
          .post(`${environment.url_api_local}/POST_ENVIAR_ROUTEX`, { operacao_id: operacao_id })
          .toPromise();
  
  
        return response;
      } catch (error) {
        console.log(error, 'erro');
        const retorno_padrao: RESPOSTA_PADRAO_SERVIDOR = {
          MSG: "ERRO",
          ADICIONAL: "",
          RESPOSTA: undefined
        }
        return retorno_padrao
  
      }
    }


}
