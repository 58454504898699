import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { DADOS_ALERT } from '../../../../MODELS/DADOS_ALERT';

@Component({
  selector: 'app-login-modal-token',
  standalone: true,
  imports: [],
  templateUrl: './login-modal-token.component.html',
  styleUrl: './login-modal-token.component.css'
})
export class LoginModalTokenComponent {

  @Output() Evento_close_modal_token = new EventEmitter<boolean>();
  @Output() Evento_send_token = new EventEmitter<string>();

  Nat_close_modal(){
    this.Evento_close_modal_token.emit(false)
  }

  @ViewChild('inp_token') inp_token!: ElementRef;
  Nat_send_token(){
    this.Evento_send_token.emit(this.inp_token.nativeElement.value)
  }
}
