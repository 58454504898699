import { Component } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-cadastro-select-modulos',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule
  ],
  templateUrl: './cadastro-select-modulos.component.html',
  styleUrl: './cadastro-select-modulos.component.css'
})
export class CadastroSelectModulosComponent {

  @Output() Evento_modulos = new EventEmitter<string>();

  Fix_modulos = new FormControl('');

  List_modulos: string[] = [
    'Gpx',
    'Divix',
    'Routex',
    'Aordex User',
    'Aordex Admin',
    'Ceara Apps',
    'Stox User',
    'Stox Admin',
    'XSocial'
  ];

  Nat_add_modulo(event: MatSelectChange) {//quando um modulo for selecionado e adicionado a lista de modulos desejados pelo user

    this.Evento_modulos.emit(event.value)
   
  }

}
