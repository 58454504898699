<div class="h-screen w-screen">

    <!--navbar menu principal-->
    <app-navbar-divix 
    
        [Nat_state_EDICOES]="Nat_state_EDICOES"
        [Nat_state_FERRAMENTAS]="Nat_state_FERRAMENTAS"
        (Evento_close_perfil)="Out_change_state_perfil($event)"
        (Evento_change_state_EDICOES)="Out_change_aba_EDICOES($event)"
        (Evento_change_state_FERRAMENTAS)="Out_change_aba_FERRAMENTAS($event)"
        (Evento_change_state_MODULOS)="Out_change_aba_MODULOS($event)"
        (Evento_local_buscado)="Out_move_local_buscado($event)"
        ></app-navbar-divix>



<!-- Select com overflow-y -->
<div class="absolute top-24 right-5 z-40">
    <select #select_manual_way (change)="select_rota_manual(select_manual_way.value)" class="overflow-y-auto max-h-48 p-2 border rounded w-[20rem]">
        <option value="">Selecione uma Rota Manual</option>
        @for(roteiro of rotas_manuais;track roteiro){
            <option [value]="roteiro.id">{{roteiro.str_way_name}}</option>
        }
        
   
    </select>
</div>
    <!--tabela de edicoes-->
    @if(Nat_state_EDICOES){
    <app-divix-edicoes 
        [input_ids_operacoes_mapa]="Nat_ids_operacoes_mapa_edicoes"
        [input_parametros]="Nat_parametros"

        [input_estado_lista_livros]="Nat_state_LIVROS"
        (Evento_mostrar_lista_livros)="Out_change_aba_LIVROS($event)"

        
        (Evento_open_loading)="Out_open_loading($event)"
        (Evento_close_loading)="Out_close_loading($event)"
        (Evento_open_alert_sucess)="Out_open_alert_sucess($event)"
        (Evento_open_alert_erro)="Out_open_alert_erro($event)"
        ></app-divix-edicoes>
    }

        <!--tabela de livros de uma operacao-->
        @if(Nat_state_LIVROS){
            <app-divix-livros 
                [input_ids_operacoes_mapa]="Nat_ids_operacoes_mapa_edicoes"
                [input_livros_mapa]="Nat_operacoes_mapa_edicoes"

                [input_operacao_selecionada]="Nat_operacao_livros_atual"

                (Evento_desenha_trajeto_osrm)="Out_desenha_trajeto_osrm($event)"

                (Evento_close_livros)="Out_close_livros($event)"

                (Evento_open_operacao)="Out_open_operacao_edicoes($event)"
                (Evento_close_operacao)="Out_close_operacao_edicoes($event)"
                
                (Evento_open_loading)="Out_open_loading($event)"
                (Evento_close_loading)="Out_close_loading($event)"
                (Evento_open_alert_sucess)="Out_open_alert_sucess($event)"
                (Evento_open_alert_erro)="Out_open_alert_erro($event)"

                (Evento_tipo_visao_livros)="Out_muda_visao_livros($event)"
                ></app-divix-livros>
            }

    <!--tabela de ferramentas-->
    @if(Nat_state_FERRAMENTAS){
    <app-gpx-ferramentas 
        [Input_estado_coleta_coordenada]="Nat_state_coleta_coordenada"
        [Input_estado_transferencia_itens]="Nat_state_transferencia_itens"
        (Evento_change_type_MAPA)="Out_change_map_type($event)"
        (Evento_change_state_COLETA_COORDENADA)="Out_change_state_coleta_coordenada($event)"
        (Evento_change_state_TRANSFERENCIA)="Out_change_transferencia($event)"
        
        ></app-gpx-ferramentas>
    }


    @if(Nat_state_perfil){
        <app-perfil
        (Evento_close_perfil)="Out_change_state_perfil($event)"
        >
        </app-perfil>
    }
    @if(Nat_state_MODULOS){
        <app-modulos>
            
        </app-modulos>
    }

<!--detalhes do poligono selecionado, seja de selacao para transferencia seja de tarefa-->
@if(Nat_state_detalhes_poligono){
    <app-divix-detalhes-poligono
    [Input_poligono_recebido]="Nat_detalhes_poligono"
    [input_ids_operacoes_mapa]="Nat_ids_operacoes_mapa_edicoes"
    [input_livros_mapa]="Nat_operacoes_mapa_edicoes"

    (Evento_close_detalhes_poligono)="Out_change_state_detalhes_poligono($event)"

    (Evento_open_loading)="Out_open_loading($event)"
    (Evento_close_loading)="Out_close_loading($event)"
    (Evento_open_alert_sucess)="Out_open_alert_sucess($event)"
    (Evento_open_alert_erro)="Out_open_alert_erro($event)"

    (Evento_repoe_mapa)="Out_repoe_mapa_pos_edicao($event)"

    (Evento_open_operacao)="Out_open_operacao_edicoes($event)"
    (Evento_close_operacao)="Out_close_operacao_edicoes($event)"
    
    ></app-divix-detalhes-poligono>
}



    <div id="map_divix" class="h-full w-full z-10">
    </div>


    <!--loading-->
    @if(Nat_state_loading){
    <app-alert-loading></app-alert-loading>
    }



    <!--alert de sucesso-->
    @if(Nat_status_alert_sucesso){

    <app-alert-sucesso [Input_dados_alert]="Nat_dados_alert"
        (Evento_close_alert_sucesso)="Out_close_alert_sucesso($event)">
    </app-alert-sucesso>
    }
    <!--alert de erro-->
    @if(Nat_status_alert_erro){

    <app-alert-erro [Input_dados_alert]="Nat_dados_alert" (Evento_close_alert_erro)="Out_close_alert_erro($event)">
    </app-alert-erro>
    }



    <app-divix-parametros-analise
    
    (Evento_parametros)="Out_change_parametros($event)"
    >
    </app-divix-parametros-analise>
</div>