import { HttpClient } from '@angular/common/http';
import { Component, ViewChild, AfterViewInit, inject, OnInit, viewChild, ElementRef, SimpleChanges, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule, SortDirection } from '@angular/material/sort';
import { merge, Observable, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { MatTableModule, MatTableDataSource } from '@angular/material/table';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DatePipe } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { environment } from '../../../../../environments/environment.development';

import { RESPOSTA_PADRAO_SERVIDOR } from '../../../../MODELS/RESPOSTA_PADRAO_SERVIDOR';
import { OPERACAO_ou_LIVRO } from '../../../../MODELS/OPERACAO_ou_LIVRO';
import { DADOS_ALERT } from '../../../../MODELS/DADOS_ALERT';
import { MainService } from '../../../../SERVICES/main.service';
import { GpxService } from '../../../GPX/SERVICES/gpx.service';
import { DivixService } from '../../SERVICES/divix.service';
import { DADOS_PARAMETRO } from '../../../../MODELS/DADOS_PARAMETRO';

@Component({
  selector: 'app-divix-edicoes',
  standalone: true,
  imports: [
    MatProgressSpinnerModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    DatePipe,
    MatFormFieldModule,
    MatInputModule
  ],
  templateUrl: './divix-edicoes.component.html',
  styleUrl: './divix-edicoes.component.css'
})
export class DivixEdicoesComponent implements AfterViewInit, OnInit, OnChanges {
  displayedColumns: string[] = ['acoes', 'ID_STR', 'CENTRO_LEITURA_STR', 'GRUPO_STR', 'QUANT_ITENS','QUANT_LIVROS'];

  dataSource: MatTableDataSource<OPERACAO_ou_LIVRO>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private service: DivixService,private main_service:MainService) {


  }
  Nat_operacoes: OPERACAO_ou_LIVRO[] = []

  /**evento que abre a aba de loading no component main */
  @Output() Evento_open_loading = new EventEmitter<boolean>();

  /**evento que fecha a aba de loading no component main */
  @Output() Evento_close_loading = new EventEmitter<boolean>();

  /**evento que abre um alerta de sucesso no component main */
  @Output() Evento_open_alert_sucess = new EventEmitter<DADOS_ALERT>();

  /**evento que abre um alerta de erro no component main */
  @Output() Evento_open_alert_erro = new EventEmitter<DADOS_ALERT>();


  async ngOnInit() {

    this.Evento_open_loading.emit(true)

    const requisicao_server: any = await this.service.POST_OPERACOES_DIVIX_EDICOES(environment.user_atual.ID)
    const resposta: RESPOSTA_PADRAO_SERVIDOR = requisicao_server


    if (resposta.MSG == 'SUCESSO') {

      this.Nat_operacoes = resposta.RESPOSTA

      this.dataSource = new MatTableDataSource(this.Nat_operacoes);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;


      this.dataSource.data.forEach((operacao, o) => {
        if (this.input_ids_operacoes_mapa.includes(operacao.ID)) {
          this.dataSource.data[o].VISIVEL = true
        } else {
          this.dataSource.data[o].VISIVEL = false
        }
      })

   

      this.Evento_close_loading.emit(true)
    } else {
      this.Evento_close_loading.emit(true)
      let new_alert: DADOS_ALERT = {
        TITULO: resposta.MSG,
        CORPO: resposta.ADICIONAL
      }
      this.Evento_open_alert_erro.emit(new_alert)
    }
  }

  ngAfterViewInit() {

  }

  /**
 * ids das operacoes que estao em mapa para manter a visualizacao do mapa atualizada com as indicacoes dos itens 
 * que estao ou nao no mapa e estao ou nao disponiveis
 */
  @Input() input_ids_operacoes_mapa: any[] = []

  @Input() input_ids_operacoes_mapa_disponiveis: number[] = []


  @Input() input_estado_lista_livros:boolean



  @Output() Evento_mostrar_lista_livros = new EventEmitter<number>()//mostra ou oculta a lista de livros de uma operacao especifica


  async Nat_delete_operacao(operacao_id:number){
    if(this.input_ids_operacoes_mapa.length>0){
      this.Evento_close_loading.emit(true)
      let new_alert: DADOS_ALERT = {
        TITULO: "ERRO",
        CORPO: 'Retire os Itens do Mapa para Realizar a Movimentação Desejada!'
      }
      this.Evento_open_alert_erro.emit(new_alert)
    }else{

    this.Evento_open_loading.emit(true)

    const requisicao_server: any = await this.main_service.POST_DELETE_OPERACAO(operacao_id)
    const resposta: RESPOSTA_PADRAO_SERVIDOR = requisicao_server


    if (resposta.MSG == 'SUCESSO') {




      this.dataSource.data = this.dataSource.data.filter((operacao => operacao.ID != operacao_id))
      this.Evento_close_loading.emit(true)
      let new_alert: DADOS_ALERT = {
        TITULO: resposta.MSG,
        CORPO: resposta.ADICIONAL
      }
      this.Evento_open_alert_sucess.emit(new_alert)
      
    } else {
      this.Evento_close_loading.emit(true)
      let new_alert: DADOS_ALERT = {
        TITULO: resposta.MSG,
        CORPO: resposta.ADICIONAL
      }
      this.Evento_open_alert_erro.emit(new_alert)
    }
  }
  }

  async Nat_devolve_operacao(operacao_id:number){
    if(this.input_ids_operacoes_mapa.length>0){
      this.Evento_close_loading.emit(true)
      let new_alert: DADOS_ALERT = {
        TITULO: "ERRO",
        CORPO: 'Retire os Itens do Mapa para Realizar a Movimentação Desejada!'
      }
      this.Evento_open_alert_erro.emit(new_alert)
    }else{
    this.Evento_open_loading.emit(true)

    const requisicao_server: any = await this.service.POST_DEVOLVER_AO_GPX(operacao_id)
    const resposta: RESPOSTA_PADRAO_SERVIDOR = requisicao_server


    if (resposta.MSG == 'SUCESSO') {




      this.dataSource.data = this.dataSource.data.filter((operacao => operacao.ID != operacao_id))
      this.Evento_close_loading.emit(true)
      let new_alert: DADOS_ALERT = {
        TITULO: resposta.MSG,
        CORPO: resposta.ADICIONAL
      }
      this.Evento_open_alert_sucess.emit(new_alert)
      
    } else {
      this.Evento_close_loading.emit(true)
      let new_alert: DADOS_ALERT = {
        TITULO: resposta.MSG,
        CORPO: resposta.ADICIONAL
      }
      this.Evento_open_alert_erro.emit(new_alert)
    }
  }
  }
  async Nat_gerar_aordex(operacao_id:number){
    if(this.input_ids_operacoes_mapa.length>0){
      this.Evento_close_loading.emit(true)
      let new_alert: DADOS_ALERT = {
        TITULO: "ERRO",
        CORPO: 'Retire os Itens do Mapa para Realizar a Movimentação Desejada!'
      }
      this.Evento_open_alert_erro.emit(new_alert)
    }else{
    this.Evento_open_loading.emit(true)

    const requisicao_server: any = await this.service.POST_ENVIAR_AORDEX(operacao_id)
    const resposta: RESPOSTA_PADRAO_SERVIDOR = requisicao_server


    if (resposta.MSG == 'SUCESSO') {

      this.Evento_close_loading.emit(true)
      let new_alert: DADOS_ALERT = {
        TITULO: resposta.MSG,
        CORPO: resposta.ADICIONAL
      }
      this.Evento_open_alert_sucess.emit(new_alert)
      
    } else {
      this.Evento_close_loading.emit(true)
      let new_alert: DADOS_ALERT = {
        TITULO: resposta.MSG,
        CORPO: resposta.ADICIONAL
      }
      this.Evento_open_alert_erro.emit(new_alert)
    }
  }
  }
  
  async Nat_criar_livro_unico(operacao_id:any){

    if(this.input_ids_operacoes_mapa.length>0){
      this.Evento_close_loading.emit(true)
      let new_alert: DADOS_ALERT = {
        TITULO: "ERRO",
        CORPO: 'Retire os Itens do Mapa para Realizar a Movimentação Desejada!'
      }
      this.Evento_open_alert_erro.emit(new_alert)
    }else{
      this.Evento_open_loading.emit(true)

      const requisicao_server: any = await this.service.POST_CRIAR_LIVRO_UNICO_DIVIX(operacao_id)
      const resposta: RESPOSTA_PADRAO_SERVIDOR = requisicao_server
  
  
      if (resposta.MSG == 'SUCESSO') {
  
  
  
       const index = this.dataSource.data.findIndex((operacao)=>operacao.ID == operacao_id)

        this.dataSource.data[index].QUANT_LIVROS = 1
  
   
        this.Evento_close_loading.emit(true)
        let new_alert: DADOS_ALERT = {
          TITULO: resposta.MSG,
          CORPO: resposta.ADICIONAL
        }
        this.Evento_open_alert_sucess.emit(new_alert)
        
      } else {
        this.Evento_close_loading.emit(true)
        let new_alert: DADOS_ALERT = {
          TITULO: resposta.MSG,
          CORPO: resposta.ADICIONAL
        }
        this.Evento_open_alert_erro.emit(new_alert)
      }
    }


  }

  @Input() input_parametros:DADOS_PARAMETRO
  async Nat_cria_livros(operacao_id:number){
  
    if(this.input_ids_operacoes_mapa.length>0){
      this.Evento_close_loading.emit(true)
      let new_alert: DADOS_ALERT = {
        TITULO: "ERRO",
        CORPO: "Não é Possível Realizar Alterações desse Tipo com Itens em Mapa!"
      }
      this.Evento_open_alert_erro.emit(new_alert)
    }else if(this.input_parametros.TIPO == "DURACAO"){
      this.Evento_close_loading.emit(true)
      let new_alert: DADOS_ALERT = {
        TITULO: "ERRO",
        CORPO: "Não é Possível Realizar Divisão por Duração!"
      }
      this.Evento_open_alert_erro.emit(new_alert)
    }else{
      this.Evento_open_loading.emit(true)

      const requisicao_server: any = await this.service.POST_CRIAR_LIVROS(
        operacao_id,
        this.input_parametros.VARIACAO,
        this.input_parametros.TIPO,
        this.input_parametros.QUANTIDADE,
        this.input_parametros.QUADRAS
      )
      const resposta: RESPOSTA_PADRAO_SERVIDOR = requisicao_server
  
  
      if (resposta.MSG == 'SUCESSO') {
  
        const atualiza = await this.ngOnInit()
  
        this.Evento_close_loading.emit(true)
        let new_alert: DADOS_ALERT = {
          TITULO: resposta.MSG,
          CORPO: resposta.ADICIONAL
        }
        this.Evento_open_alert_sucess.emit(new_alert)
      } else {
        this.Evento_close_loading.emit(true)
        let new_alert: DADOS_ALERT = {
          TITULO: resposta.MSG,
          CORPO: resposta.ADICIONAL
        }
        this.Evento_open_alert_erro.emit(new_alert)
      }
  
    }


  }

  applyFilter(event: Event) {//filtragem de itens da tabela
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  @ViewChild('input') input!: ElementRef;

  Nat_clear_filter() {//limpa o campo de busca 
    this.input.nativeElement.value = ''

    this.dataSource.filter = ''
  }


  Nat_view_livros(operacao_id:number){
    this.Evento_mostrar_lista_livros.emit(operacao_id)
  }

  async Nat_enviar_routex(operacao_id:number){
    this.Evento_open_loading.emit(true)
    if(this.input_ids_operacoes_mapa.length>0){
      this.Evento_close_loading.emit(true)
      let new_alert: DADOS_ALERT = {
        TITULO: "ERRO",
        CORPO: 'Retire os Itens do Mapa para Realizar a Movimentação Desejada!'
      }
      this.Evento_open_alert_erro.emit(new_alert)
    }else{
      const requisicao_server: any = await this.service.POST_ENVIAR_ROUTEX(
        operacao_id,
      
      )
      const resposta: RESPOSTA_PADRAO_SERVIDOR = requisicao_server
  
  
      if (resposta.MSG == 'SUCESSO') {
  
        const atualiza = await this.ngOnInit()
  
        this.Evento_close_loading.emit(true)
        let new_alert: DADOS_ALERT = {
          TITULO: resposta.MSG,
          CORPO: resposta.ADICIONAL
        }
        this.Evento_open_alert_sucess.emit(new_alert)
      } else {
        this.Evento_close_loading.emit(true)
        let new_alert: DADOS_ALERT = {
          TITULO: resposta.MSG,
          CORPO: resposta.ADICIONAL
        }
        this.Evento_open_alert_erro.emit(new_alert)
      }
    }

  }


  // async Nat_envia_divix(operacao_id: number) {
  //   this.Evento_open_loading.emit(true)

  //   const requisicao_server: any = await this.service.POST_ENVIAR_DIVIX(operacao_id)
  //   const resposta: RESPOSTA_PADRAO_SERVIDOR = requisicao_server


  //   if (resposta.MSG == 'SUCESSO') {

      
  //     if(this.input_ids_operacoes_mapa.includes(operacao_id)){

  //       const index = this.dataSource.data.findIndex((operacao => operacao.ID == operacao_id))

  //       this.Nat_close_operacao(this.dataSource.data[index])
  //     }

  //     this.dataSource.data = this.dataSource.data.filter((operacao => operacao.ID != operacao_id))


  //     this.Evento_close_loading.emit(true)
  //     let new_alert: DADOS_ALERT = {
  //       TITULO: resposta.MSG,
  //       CORPO: resposta.ADICIONAL
  //     }
  //     this.Evento_open_alert_sucess.emit(new_alert)
  //   } else {
  //     this.Evento_close_loading.emit(true)
  //     let new_alert: DADOS_ALERT = {
  //       TITULO: resposta.MSG,
  //       CORPO: resposta.ADICIONAL
  //     }
  //     this.Evento_open_alert_erro.emit(new_alert)
  //   }
  // }
  ngOnChanges(changes: SimpleChanges) {
    // Verifica se houve mudanças em 'input_ids_operacoes_mapa'
    if (changes['input_ids_operacoes_mapa']) {
      const change = changes['input_ids_operacoes_mapa'];

      // Verifica se houve realmente uma mudança no valor da variavel input_ids_operacoes_mapa
      if (change.previousValue !== change.currentValue) {

        if (change.currentValue.length > 0 && this.dataSource) {

          this.dataSource.data.forEach((operacao, o) => {
            if (change.currentValue.includes(operacao.ID)) {
              this.dataSource.data[o].VISIVEL = true
            } else {
              this.dataSource.data[o].VISIVEL = false
            }
          })


        }

      }
    }
  }
}
