import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DADOS_ALERT } from '../../../../MODELS/DADOS_ALERT';
import { ALTERA_COORDENADA, CONCLUSAO_NOTA, NOTA_DE_SERVICO, NOTA_DE_SERVICO_MAPA, NOTAS_ROTEIRO, ROTEIRO, ROTEIRO_MAPA } from '../../../../MODELS/MODELS_AORDEX';
import { RESPOSTA_PADRAO_SERVIDOR } from '../../../../MODELS/RESPOSTA_PADRAO_SERVIDOR';
import { AordexService } from '../../SERVICES/aordex.service';
import { environment } from '../../../../../environments/environment.development';

import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-roteiros',
  standalone: true,
  imports: [MatFormFieldModule, MatInputModule, MatTableModule, MatSortModule, MatPaginatorModule],
  templateUrl: './roteiros.component.html',
  styleUrl: './roteiros.component.css'
})
export class RoteirosComponent implements OnInit {


  constructor(private aordexService: AordexService) { }

  /**evento que abre a aba de loading no component main */
  @Output() Evento_open_loading = new EventEmitter<boolean>();

  /**evento que fecha a aba de loading no component main */
  @Output() Evento_close_loading = new EventEmitter<boolean>();

  /**evento que abre um alerta de sucesso no component main */
  @Output() Evento_open_alert_sucess = new EventEmitter<DADOS_ALERT>();

  /**evento que abre um alerta de erro no component main */
  @Output() Evento_open_alert_erro = new EventEmitter<DADOS_ALERT>();


  displayedColumns: string[] = [
    'VISIVEL',
    'ID_STR','DATA_CRIACAO_STR','DATA_CONCLUSAO_STR','TOTAL_ITENS','TOTAL_ATRIBUIDA','TOTAL_REJEITADA_PRAZO','TOTAL_REJEITADA_ATRASO','TOTAL_CONCLUIDA_ATRASO',
     'TOTAL_CONCLUIDA_PRAZO','PORCENTAGEM_CONC','BASE_NOME','ACOES'];
  dataSource: MatTableDataSource<ROTEIRO>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;


  @Input() input_roteiros_mapa: ROTEIRO_MAPA[] = []
  async ngOnInit() {
    this.Evento_open_loading.emit(true)

    const requisicao_server: any = await this.aordexService.POST_BUSCAR_ROTEIROS(environment.user_atual);
    const resposta: RESPOSTA_PADRAO_SERVIDOR = requisicao_server;

    if (resposta.MSG == 'SUCESSO') {




      resposta.RESPOSTA.forEach((roteiro, n) => {
        if (this.input_roteiros_mapa.find((roteiro_mapa) => roteiro_mapa.ID == roteiro.ID)) {
          resposta.RESPOSTA[n].VISIVEL = true
        }
      });


      this.dataSource = new MatTableDataSource(resposta.RESPOSTA);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;

      this.Evento_close_loading.emit(true)
    } else {
      this.Evento_close_loading.emit(true)
      let new_alert: DADOS_ALERT = {
        TITULO: resposta.MSG,
        CORPO: resposta.ADICIONAL
      }
      this.Evento_open_alert_erro.emit(new_alert)
    }
  }



  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }


  @Output() Evento_change_coordenada = new EventEmitter<ALTERA_COORDENADA>()



  @Output() Evento_view_roteiro = new EventEmitter<NOTAS_ROTEIRO>()
  @Output() Evento_remove_roteiro = new EventEmitter<ROTEIRO_MAPA>()








  async Nat_remove_roteiro(id_roteiro: number) {
    this.Evento_open_loading.emit(true)
    const aguarda = await this.Evento_remove_roteiro.emit(this.input_roteiros_mapa.filter((roteiro) => roteiro.ID == id_roteiro)[0])


    const index = this.dataSource.data.findIndex((sub_nota) => sub_nota.ID == id_roteiro)

    if (index >= 0) {
      this.dataSource.data[index].VISIVEL = false
    }




    this.Evento_close_loading.emit(true);

  }




  async Nat_change_view_roteiro(roteiro: ROTEIRO) {

    this.Evento_open_loading.emit(true)

    const requisicao_server: any = await this.aordexService.POST_BUSCAR_NOTAS_ROTEIRO(roteiro.ID);
    const resposta: RESPOSTA_PADRAO_SERVIDOR = requisicao_server;

    if (resposta.MSG == 'SUCESSO') {

      this.Evento_view_roteiro.emit(resposta.RESPOSTA)
      const atualiza = await this.ngOnInit()

    } else {
      this.Evento_close_loading.emit(true);
      let new_alert: DADOS_ALERT = {
        TITULO: resposta.MSG,
        CORPO: resposta.ADICIONAL
      };
      this.Evento_open_alert_erro.emit(new_alert);
    }
  }



  async Nat_baixar_roteiro(roteiro_id:number){
    this.Evento_open_loading.emit(true)

    const requisicao_server: any = await this.aordexService.POST_BUSCAR_NOTAS_ROTEIRO(roteiro_id);
    const resposta: RESPOSTA_PADRAO_SERVIDOR = requisicao_server;

    if (resposta.MSG == 'SUCESSO') {

      let resposta_edited:NOTAS_ROTEIRO = resposta.RESPOSTA

         // Dados do JSON
    const dados = resposta_edited.NOTAS;

    // Convertendo JSON para planilha
    const worksheet = XLSX.utils.json_to_sheet(dados);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Notas');

    // Gerando o arquivo Excel
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

    // Salvando o arquivo
    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    saveAs(blob, `Notas_Roteiro_${roteiro_id}.xlsx`);

      this.Evento_close_loading.emit(true);
      let new_alert: DADOS_ALERT = {
        TITULO: 'SUCESSO!',
        CORPO: 'Download Iniciado com Sucesso!'
      };
      this.Evento_open_alert_sucess.emit(new_alert);


    } else {
      this.Evento_close_loading.emit(true);
      let new_alert: DADOS_ALERT = {
        TITULO: resposta.MSG,
        CORPO: resposta.ADICIONAL
      };
      this.Evento_open_alert_erro.emit(new_alert);
    }
  }


  async Nat_delete_roteiro(roteiro_id:number){
    this.Evento_open_loading.emit(true)

    const requisicao_server: any = await this.aordexService.POST_DELETE_ROTEIRO(roteiro_id);
    const resposta: RESPOSTA_PADRAO_SERVIDOR = requisicao_server;

    if (resposta.MSG == 'SUCESSO') {


      const atualiza = await this.ngOnInit()

      this.Evento_close_loading.emit(true);
      let new_alert: DADOS_ALERT = {
        TITULO: resposta.MSG,
        CORPO: resposta.ADICIONAL
      };
      this.Evento_open_alert_sucess.emit(new_alert);

    } else {

      const atualiza = await this.ngOnInit()

      this.Evento_close_loading.emit(true);
      let new_alert: DADOS_ALERT = {
        TITULO: resposta.MSG,
        CORPO: resposta.ADICIONAL
      };
      this.Evento_open_alert_erro.emit(new_alert);
    }
  }
}




