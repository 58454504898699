<div class="relative z-30" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <div class="fixed inset-0 bg-gray-500/75 transition-opacity" aria-hidden="true"></div>
  
    <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
      <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
  
        <!-- Main modal -->
        <div class="relative p-4 w-full max-w-md max-h-full">
            <!-- Modal content -->
            <div class="relative bg-white rounded-lg shadow">
                <!-- Modal header -->
                <div class="flex items-center justify-between p-6 border-b rounded-t green">
                    <p class="text-xl font-semibold text-gray-600 ">
                        Definir Base Operacional
                    </p>
                    <button (click)="Nat_close()" type="button" class="end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center" data-modal-hide="authentication-modal">
                        <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                        </svg>
                        <span class="sr-only">Close modal</span>
                    </button>
                </div>
                <!-- Modal body -->
                <div class="p-4 md:p-5">
                    <form class="space-y-4">
                        <div>
                            <input #inp_nova_coordenada type="text" name="text" id="text" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="Coordenadas no formato -25.442763, -49.335013" required />
                        </div>
                        <div>
                            <input #inp_nome type="text" name="text" id="text" placeholder="Titulo do Local" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                        </div>
                        <div>
                            <!-- Select dropdown -->
                            <select #inp_tipo_modal (change)="Nat_select_modal(inp_tipo_modal.value)" 
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg
                             focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                                <option value="" selected>Escolha um Modal</option>
                                <option value="pe">A Pé</option>
                                <option value="carro">Carro</option>
                                <option value="moto">Moto</option>
                            </select>
                        </div>
                        <button (click)="Nat_delete_origem_externa()" type="button" class="w-full text-white
                         bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none
                          focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Deletar Inicio Externo</button>
                  
                        
                        <button (click)="Nat_criar_ponto_partida()" type="button" class="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Inserir</button>
                    </form>
                </div>
            </div>
        </div>
      </div>
    </div>
</div>
