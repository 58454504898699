import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import {FormControl, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatSelectModule} from '@angular/material/select';
import {MatFormFieldModule} from '@angular/material/form-field';
import { RESPOSTA_PADRAO_SERVIDOR } from '../../../../MODELS/RESPOSTA_PADRAO_SERVIDOR';
import { AordexService } from '../../SERVICES/aordex.service';
import { environment } from '../../../../../environments/environment.development';
import { DADOS_ALERT } from '../../../../MODELS/DADOS_ALERT';
import { BASE, EQUIPE } from '../../../../MODELS/MODELS_AORDEX';

@Component({
  selector: 'app-view-base',
  standalone: true,
  imports: [MatFormFieldModule, MatSelectModule, FormsModule, ReactiveFormsModule],
  templateUrl: './view-base.component.html',
  styleUrl: './view-base.component.css'
})
export class ViewBaseComponent implements OnInit{

  constructor(private aordexService:AordexService){}

      /**evento que abre a aba de loading no component main */
      @Output() Evento_open_loading = new EventEmitter<boolean>();

      /**evento que fecha a aba de loading no component main */
      @Output() Evento_close_loading = new EventEmitter<boolean>();
    
      /**evento que abre um alerta de sucesso no component main */
      @Output() Evento_open_alert_sucess = new EventEmitter<DADOS_ALERT>();
    
      /**evento que abre um alerta de erro no component main */
      @Output() Evento_open_alert_erro = new EventEmitter<DADOS_ALERT>();

  form = new FormControl('');
  
  selectedValue: string;


  Nat_bases: BASE[] = [

  ];

  @ViewChild('inp_nome_equipe') inp_nome_equipe:ElementRef

  async ngOnInit() {
    const requisicao_server: any = await this.aordexService.POST_BUSCAR_BASES(environment.user_atual.EMPRESA_ID);
    const resposta: RESPOSTA_PADRAO_SERVIDOR = requisicao_server;

    if (resposta.MSG == 'SUCESSO') {

      this.Nat_bases = resposta.RESPOSTA

    } else {
      this.Evento_close_loading.emit(true)
      let new_alert: DADOS_ALERT = {
        TITULO: resposta.MSG,
        CORPO: resposta.ADICIONAL
      }
      this.Evento_open_alert_erro.emit(new_alert)
    }
  }


  @ViewChild('inp_nome_base') inp_nome_base:ElementRef
  @ViewChild('inp_coordenada') inp_coordenada:ElementRef
  @ViewChild('inp_endereco') inp_endereco:ElementRef
  async save_alteracao() {
    const nome_base = this.inp_nome_base.nativeElement.value.trim();
    const coordenada = this.inp_coordenada.nativeElement.value.trim();
    const endereco = this.inp_endereco.nativeElement.value.trim();
  
    // Função para validar coordenadas
    const isValidCoordinate = (coord: string): boolean => {
      const parts = coord.split(',');
      if (parts.length !== 2) return false;
  
      const lat = parseFloat(parts[0].trim());
      const lng = parseFloat(parts[1].trim());
  
      // Verifica se os valores estão dentro dos limites válidos de latitude e longitude
      return (
        !isNaN(lat) &&
        !isNaN(lng) &&
        lat >= -90 &&
        lat <= 90 &&
        lng >= -180 &&
        lng <= 180
      );
    };
  
    if (
      nome_base !== '' &&
      endereco !== '' &&
      isValidCoordinate(coordenada)
    ) {
      const [lat, lng] = coordenada.split(',').map((val) => parseFloat(val.trim()));
  
      let new_base: BASE = {
        EMPRESA_ID: environment.user_atual.EMPRESA_ID,
        CRIADOR_ID: environment.user_atual.ID,
        CRIADOR_NAME: environment.user_atual.NOME,
        DATA_CRIACAO: '',
        LAT: lat,
        LNG: lng,
        OBSERVACAO: endereco,
        NOME: nome_base,
        ID: parseInt(this.selectedValue),
        ID_STR: '',
        VISIVEL: false
      };
  
      this.Evento_open_loading.emit(true);
  

        const requisicao_server: any = await this.aordexService.POST_ATUALIZAR_BASE(new_base);
        const resposta: RESPOSTA_PADRAO_SERVIDOR = requisicao_server;
  
        if (resposta.MSG === 'SUCESSO') {
          this.Evento_close_loading.emit(true);
          let new_alert: DADOS_ALERT = {
            TITULO: resposta.MSG,
            CORPO: resposta.ADICIONAL
          };
          this.Evento_open_alert_sucess.emit(new_alert);
          this.Nat_close();
        } else {
          this.Evento_close_loading.emit(true);
          let new_alert: DADOS_ALERT = {
            TITULO: resposta.MSG,
            CORPO: resposta.ADICIONAL
          };
          this.Evento_open_alert_erro.emit(new_alert);
        }
  
    } else {
      this.Evento_close_loading.emit(true);
      let new_alert: DADOS_ALERT = {
        TITULO: 'ERRO',
        CORPO: 'Dados Incorretos! Certifique-se de que as coordenadas estejam no formato "latitude, longitude".'
      };
      this.Evento_open_alert_erro.emit(new_alert);
    }
  }
  

  Nat_select_base(){
    let base_escolhida = this.Nat_bases.filter((base)=>base.ID == parseInt(this.selectedValue))

    if(base_escolhida.length>0){
      this.inp_nome_base.nativeElement.value = base_escolhida[0].NOME
      this.inp_coordenada.nativeElement.value=`${base_escolhida[0].LAT},${base_escolhida[0].LNG}`
      this.inp_endereco.nativeElement.value=base_escolhida[0].OBSERVACAO

    }
  }

  async delete_base(){

      const requisicao_server: any = await this.aordexService.POST_DELETAR_BASE(parseInt(this.selectedValue));
      const resposta: RESPOSTA_PADRAO_SERVIDOR = requisicao_server;
  
      if (resposta.MSG == 'SUCESSO') {
  
        this.Evento_close_loading.emit(true)
        let new_alert: DADOS_ALERT = {
          TITULO: resposta.MSG,
          CORPO: resposta.ADICIONAL
        }
        this.Evento_open_alert_erro.emit(new_alert)

        this.Nat_close()
  
      } else {
        this.Evento_close_loading.emit(true)
        let new_alert: DADOS_ALERT = {
          TITULO: resposta.MSG,
          CORPO: resposta.ADICIONAL
        }
        this.Evento_open_alert_erro.emit(new_alert)
      }

  }

  @Output() Evento_change_state_view_base = new EventEmitter<boolean>()
  Nat_close(){
    this.Evento_change_state_view_base.emit(false)
  }
}
