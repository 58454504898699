import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-info-importacao',
  standalone: true,
  imports: [],
  templateUrl: './info-importacao.component.html',
  styleUrl: './info-importacao.component.css'
})
export class InfoImportacaoComponent {

  @Output() Evento_close_informacao = new EventEmitter<boolean>()
  Nat_close_informacoes(){
    this.Evento_close_informacao.emit(false)
  }

}
