import { Component, EventEmitter, Input, input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { USER } from '../../MODELS/USER';
import { environment } from '../../../environments/environment.development';

@Component({
  selector: 'app-modulos',
  standalone: true,
  imports: [],
  templateUrl: './modulos.component.html',
  styleUrl: './modulos.component.css'
})
export class ModulosComponent implements OnInit{

  constructor(private router:Router){}

  user:USER

  ngOnInit() {
   this.user = environment.user_atual
   
  }

  //a variavel enviada nao é usada, apenas o disparo do evento, todo o processo é feito no modulo pai
   @Output() Evento_change_type_MAPA = new EventEmitter<any>();
 /**
  * envia o estado atual da selecao manual de coordenada
  */
   @Output() Evento_change_state_COLETA_COORDENADA = new EventEmitter<boolean>();
 
   /**
  * envia o estado atual da transferencia de itens
  */
   @Output() Evento_change_state_TRANSFERENCIA = new EventEmitter<boolean>();
 
   /**
    * recebe via input o ultimo estado salvo da coleta de coordenada
    */
 
   @Input() Input_estado_coleta_coordenada:boolean = false
 
   /**recebe via input o ultimo estado salvo da transferencia de itens */
 
   @Input() Input_estado_transferencia_itens:boolean = false
  
   
  
   Nat_change_modulo(modulo:string){
this.router.navigateByUrl(modulo)
   }
 
 }
 