<div class="px-4 z-30 absolute w-full bg-white top-16 py-4">
    <div class="sm:flex sm:items-center mt-2">


    </div>
    <!-- Search input -->
    <div class="mt-2">
        <input matInput (keyup)="applyFilter($event)" #input type="text" placeholder="Buscar Notas..."
            class="w-96 rounded-md border-gray-300 px-4 py-2 text-sm focus:border-blue-600 focus:ring-blue-600"
            id="search-input">
    </div>
    <div class="mt-2 flow-root">
        <div class=" -my-2 overflow-x-auto ">
            <div class="inline-block min-w-full py-2 align-middle">
                <div class="relative pb-4">

                    
                    <table mat-table [dataSource]="dataSource" matSort
                        class="min-w-full table-fixed divide-y divide-gray-300">


           

<!-- Progress Column -->
<ng-container matColumnDef="VISIVEL">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
    <td mat-cell *matCellDef="let row"> 
        <div class="flex justify-center items-center h-full">
            @if(row.VISIVEL){
            <input type="checkbox" checked (change)="Nat_remove_roteiro(row.ID)"
                class="size-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600">
            }@else{
            <input (change)="Nat_change_view_roteiro(row)" type="checkbox"
                class="size-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600">
            }

        </div>
    </td>
</ng-container>
                        <!-- Progress Column -->
                        <ng-container matColumnDef="ID_STR">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
                            <td mat-cell *matCellDef="let row"> {{row.ID_STR}} </td>
                        </ng-container>
                        <!-- Progress Column -->
                        <ng-container matColumnDef="DATA_CRIACAO_STR">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> CRIADO EM </th>
                            <td mat-cell *matCellDef="let row"> {{row.DATA_CRIACAO_STR}} </td>
                        </ng-container>
                        <!-- Progress Column -->
                        <ng-container matColumnDef="DATA_CONCLUSAO_STR">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> CONCLUIDO EM </th>
                            <td mat-cell *matCellDef="let row"> {{row.DATA_CONCLUSAO_STR}} </td>
                        </ng-container>
                        <!-- Progress Column -->
                        <ng-container matColumnDef="TOTAL_ITENS">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> ITENS </th>
                            <td mat-cell *matCellDef="let row"> {{row.TOTAL_ITENS}} </td>
                        </ng-container>
                        <!-- Progress Column -->
                        <ng-container matColumnDef="TOTAL_ATRIBUIDA">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> ATRIBUÍDAS </th>
                            <td mat-cell *matCellDef="let row"> {{row.TOTAL_ATRIBUIDA}} </td>
                        </ng-container>
                        <!-- Progress Column -->
                        <ng-container matColumnDef="TOTAL_REJEITADA_PRAZO">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> REJEITADAS PRAZO </th>
                            <td mat-cell *matCellDef="let row"> {{row.TOTAL_REJEITADA_PRAZO}} </td>
                        </ng-container>
                        <!-- Progress Column -->
                        <ng-container matColumnDef="TOTAL_REJEITADA_ATRASO">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> REJEITADAS ATRASO </th>
                            <td mat-cell *matCellDef="let row"> {{row.TOTAL_REJEITADA_ATRASO}} </td>
                        </ng-container>
                          <!-- Progress Column -->
                          <ng-container matColumnDef="TOTAL_CONCLUIDA_ATRASO">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> CONCLUÍDAS ATRASO </th>
                            <td mat-cell *matCellDef="let row"> {{row.TOTAL_CONCLUIDA_ATRASO}} </td>
                        </ng-container>
                        <!-- Progress Column -->
                        <ng-container matColumnDef="TOTAL_CONCLUIDA_PRAZO">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> CONCLUÍDAS PRAZO </th>
                            <td mat-cell *matCellDef="let row"> {{row.TOTAL_CONCLUIDA_PRAZO}} </td>
                        </ng-container>
                      
                        <!-- Progress Column -->
                        <ng-container matColumnDef="PORCENTAGEM_CONC">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> % CONCLUSÃO </th>
                            <td mat-cell *matCellDef="let row"> {{row.PORCENTAGEM_CONC}} </td>
                        </ng-container>
 <!-- Progress Column -->
 <ng-container matColumnDef="BASE_NOME">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> BASE </th>
    <td mat-cell *matCellDef="let row"> {{row.BASE_NOME}} </td>
</ng-container>

           <!-- Fruit Column -->
           <ng-container matColumnDef="ACOES">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>

            </th>
            <td mat-cell *matCellDef="let row">
              <div class="w-full space-x-2">
                <a (click)="Nat_baixar_roteiro(row.ID)" class="rounded-md
                      bg-blue-50 px-2 py-1 
                      text-xs font-medium text-blue-700 ring-1 
                      ring-inset ring-blue-600/20 cursor-pointer
                      hover:bg-blue-100
                      ">Baixar</a>

                <a (click)="Nat_delete_roteiro(row.ID)" class="rounded-md
                      bg-red-50 px-2 py-1 
                      text-xs font-medium text-red-700 ring-1 
                      ring-inset ring-red-600/20 cursor-pointer
                      hover:bg-red-100
                      ">Deletar</a>
              </div>

            </td>
          </ng-container>



                        <tr mat-header-row *matHeaderRowDef="displayedColumns"
                            class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                            class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 hover:bg-slate-100"></tr>

                        <!-- Row shown when there is no matching data. -->
                        <tr class="mat-row" *matNoDataRow>
                            <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
                        </tr>
                    </table>

                    <mat-paginator [pageSizeOptions]="[5]" aria-label="Select page of users"></mat-paginator>


                </div>
            </div>
        </div>
    </div>
</div>