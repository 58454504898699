import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import {FormControl, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatSelectModule} from '@angular/material/select';
import {MatFormFieldModule} from '@angular/material/form-field';
import { AordexService } from '../../SERVICES/aordex.service';
import { DADOS_ALERT } from '../../../../MODELS/DADOS_ALERT';
import { RESPOSTA_PADRAO_SERVIDOR } from '../../../../MODELS/RESPOSTA_PADRAO_SERVIDOR';
import { environment } from '../../../../../environments/environment.development';
import { NOVA_EQUIPE } from '../../../../MODELS/MODELS_AORDEX';

@Component({
  selector: 'app-new-equipe',
  standalone: true,
  imports: [MatFormFieldModule, MatSelectModule, FormsModule, ReactiveFormsModule],
  templateUrl: './new-equipe.component.html',
  styleUrl: './new-equipe.component.css'
})
export class NewEquipeComponent implements OnInit{

  constructor(private aordexService: AordexService) { }

    /**evento que abre a aba de loading no component main */
    @Output() Evento_open_loading = new EventEmitter<boolean>();

    /**evento que fecha a aba de loading no component main */
    @Output() Evento_close_loading = new EventEmitter<boolean>();
  
    /**evento que abre um alerta de sucesso no component main */
    @Output() Evento_open_alert_sucess = new EventEmitter<DADOS_ALERT>();
  
    /**evento que abre um alerta de erro no component main */
    @Output() Evento_open_alert_erro = new EventEmitter<DADOS_ALERT>();

  form = new FormControl('');

  users_disponiveis: string[] = [];

  tipo_equipe:string = ''

  async ngOnInit() {


    this.Evento_open_loading.emit(true)

    const requisicao_server: any = await this.aordexService.POST_BUSCAR_USERS_EMPRESA_LIVRES(environment.user_atual);
    const resposta: RESPOSTA_PADRAO_SERVIDOR = requisicao_server;

    if (resposta.MSG == 'SUCESSO') {

      this.users_disponiveis = resposta.RESPOSTA.map((user)=> `${user.name}-${user.id}`)
     

      this.Evento_close_loading.emit(true)
    } else {
      this.Evento_close_loading.emit(true)
      let new_alert: DADOS_ALERT = {
        TITULO: resposta.MSG,
        CORPO: resposta.ADICIONAL
      }
      this.Evento_open_alert_erro.emit(new_alert)
    }
  }

  Nat_select_tipo_equipe(tipo:string){
    this.tipo_equipe = tipo
  }

  @ViewChild('inp_nome_equipe') inp_nome_equipe:ElementRef
 async Nat_create_equipe(){
    const membros:any= this.form.value


    //verifica o tipo de equipe
    if(this.tipo_equipe != ""){

     if(membros.length>0){ //verifica se selecionou algum membro

      if(this.inp_nome_equipe.nativeElement.value != ""){//verifica se deu nome a equipe
        this.Evento_open_loading.emit(true)

       

        let new_equipe:NOVA_EQUIPE = {
          MEMBROS_IDS: membros.map((membro)=>membro.split('-')[1]).join(','),
          MEMBROS_NAME: membros.map((membro)=>membro.split('-')[0]).join(','),
          DATA_CRIACAO: '',
          CRIADOR_ID: environment.user_atual.ID,
          CRIADOR_NAME: environment.user_atual.NOME,
          CCE: this.inp_nome_equipe.nativeElement.value ,
          EMPRESA_ID: environment.user_atual.EMPRESA_ID
        }

    
        const requisicao_server: any = await this.aordexService.POST_CRIAR_EQUIPE(new_equipe);
        const resposta: RESPOSTA_PADRAO_SERVIDOR = requisicao_server;
    
        if (resposta.MSG == 'SUCESSO') {
    
  
          this.Evento_close_loading.emit(true)

          this.Evento_close_loading.emit(true)
          let new_alert: DADOS_ALERT = {
            TITULO: resposta.MSG,
            CORPO: resposta.ADICIONAL
          }
          this.Evento_open_alert_sucess.emit(new_alert)
        } else {
          this.Evento_close_loading.emit(true)
          let new_alert: DADOS_ALERT = {
            TITULO: resposta.MSG,
            CORPO: resposta.ADICIONAL
          }
          this.Evento_open_alert_erro.emit(new_alert)
        }
      }else{
        this.Evento_close_loading.emit(true)
        let new_alert: DADOS_ALERT = {
          TITULO: "ERRO",
          CORPO: "O nome da Equipe é Obrigatório!"
        }
        this.Evento_open_alert_erro.emit(new_alert)
      }
     }else{
      this.Evento_close_loading.emit(true)
      let new_alert: DADOS_ALERT = {
        TITULO: "ERRO",
        CORPO: "Pelo menos um Membro deve ser Informado!"
      }
      this.Evento_open_alert_erro.emit(new_alert)
     }

    }else{
      this.Evento_close_loading.emit(true)
      let new_alert: DADOS_ALERT = {
        TITULO: "ERRO",
        CORPO: "O tipo de Equipe é Obrigatório!"
      }
      this.Evento_open_alert_erro.emit(new_alert)
    }
   

    



    console.log(membros)
  }

  @Output() Evento_change_state_new_equipe = new EventEmitter<boolean>()
  Nat_close(){
    this.Evento_change_state_new_equipe.emit(false)
  }
}
