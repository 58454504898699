<div class="relative z-50" aria-labelledby="modal-title" role="dialog" aria-modal="true">

    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

    <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">

            <div
                class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div>
                    <div class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-orange-100">


                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                            stroke="currentColor" class="h-6 w-6 text-orange-600">
                            <path stroke-linecap="round" stroke-linejoin="round"
                                d="M15.75 5.25a3 3 0 0 1 3 3m3 0a6 6 0 0 1-7.029 5.912c-.563-.097-1.159.026-1.563.43L10.5 17.25H8.25v2.25H6v2.25H2.25v-2.818c0-.597.237-1.17.659-1.591l6.499-6.499c.404-.404.527-1 .43-1.563A6 6 0 1 1 21.75 8.25Z" />
                        </svg>

                    </div>
                    <div class="mt-3 text-center sm:mt-5">
                        <p class="text-base font-semibold text-gray-900" id="modal-title">Token de Reset Gerado</p>
                        <div class="mt-2">
                            <p class="text-sm text-gray-500">Verifique a caixa de Entrada do Email Cadastrado e Insira o
                                Token Gerado, A duração do Token é de 2 minutos.</p>
                        </div>
                    </div>

                    <div class="py-4">
                        <label for="token" class="sr-only">Email Cadastrado</label>
                        <input type="text" #inp_email name="token" id="token" class="block w-full rounded-md border-0 py-1.5
                 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300
                  placeholder:text-gray-400 focus:ring-2 focus:ring-inset
                   focus:ring-blue-600 sm:text-sm/6" placeholder="Insira o Seu Email Cadastrado">
                    </div>
                    <div class="py-4">
                        <label for="senha" class="sr-only">Nova Senha</label>
                        <input type="text" #inp_password name="senha" id="senha" class="block w-full rounded-md border-0 py-1.5
                 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300
                  placeholder:text-gray-400 focus:ring-2 focus:ring-inset
                   focus:ring-blue-600 sm:text-sm/6" placeholder="Insira a Nova Senha Escolhida">
                    </div>
                    <div class="py-4">
                        <label for="token" class="sr-only">Token Recebido</label>
                        <input type="text" #inp_token name="token" id="token" class="block w-full rounded-md border-0 py-1.5
     text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300
      placeholder:text-gray-400 focus:ring-2 focus:ring-inset
       focus:ring-blue-600 sm:text-sm/6" placeholder="Insira o Token Enviado para Seu Email">
                    </div>


                </div>
                <div class="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                    <button type="button" (click)="Nat_reset_senha()" class="inline-flex w-full justify-center
             rounded-md bg-blue-600 px-3 py-2 text-sm 
             font-semibold text-white shadow-sm hover:bg-blue-500 
             focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2
              focus-visible:outline-blue-600 sm:col-start-2">
                        Resetar Senha
                    </button>

                    <button type="button" (click)="Nat_close_modal()" class="mt-3 inline-flex w-full
              justify-center rounded-md bg-white px-3
               py-2 text-sm font-semibold text-gray-900 
               shadow-sm ring-1 ring-inset ring-gray-300
                hover:bg-gray-50 sm:col-start-1 sm:mt-0">
                        Cancelar
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>