<select
(change)="Nat_select_empresa($event)"
 id="location" name="location" class="mt-2 block w-full rounded-md border-0 
 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset
  ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm/6">
    <option selected>Escolha uma Empresa</option>

      @for(empresa of List_empresas;track empresa){
        <option [value]="empresa.ID">{{empresa.NOME}}</option>
      }
    

    
    
  </select>