import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { CommonModule } from '@angular/common';
import { COORDENADA } from '../../../MODELS/COORDENADA';
import { USER } from '../../../MODELS/USER';
import { environment } from '../../../../environments/environment.development';
import * as XLSX from 'xlsx';
import { RESPOSTA_PADRAO_SERVIDOR } from '../../../MODELS/RESPOSTA_PADRAO_SERVIDOR';
import { AordexService } from '../../../MODULES/AORDEX/SERVICES/aordex.service';
import { DADOS_ALERT } from '../../../MODELS/DADOS_ALERT';
import { BASE_MAPA } from '../../../MODELS/MODELS_AORDEX';

@Component({
  selector: 'app-navbar-aordex',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './navbar-aordex.component.html',
  styleUrl: './navbar-aordex.component.css'
})
export class NavbarAordexComponent  implements OnInit{
  suggestions: any[] = [];
  Nat_type_busca: string = 'ENDEREÇO';
  Nat_coordenada_localizada: COORDENADA;

  constructor(private http: HttpClient,private aordexservice:AordexService) { }


  @Output() Evento_change_state_EDICOES = new EventEmitter<boolean>();
  @Output() Evento_change_state_FERRAMENTAS = new EventEmitter<boolean>();
  @Output() Evento_change_state_MODULOS = new EventEmitter<boolean>();
  @Output() Evento_close_perfil = new EventEmitter<boolean>()//abre ou fecha a aba de perfil de acordo com o boolean
  @Output() Evento_local_buscado = new EventEmitter<COORDENADA>() //ENVIA A COORDENADA DO LOCAL BUSCADO NA BARRA DE PESQUISA DE LOCAIS
  /**evento que abre a aba de loading no component main */
  @Output() Evento_open_loading = new EventEmitter<boolean>();

  /**evento que fecha a aba de loading no component main */
  @Output() Evento_close_loading = new EventEmitter<boolean>();

  /**evento que abre um alerta de sucesso no component main */
  @Output() Evento_open_alert_sucess = new EventEmitter<DADOS_ALERT>();

  @Output() Evento_change_state_pendentes= new EventEmitter();
  @Output() Evento_change_state_atribuidas= new EventEmitter();
  @Output() Evento_change_state_concluidas= new EventEmitter();
  @Output() Evento_change_state_rejeitadas= new EventEmitter();
  @Output() Evento_change_state_roteiros= new EventEmitter();
  @Output() Evento_change_state_importacoes= new EventEmitter();

  /**evento que abre um alerta de erro no component main */
  @Output() Evento_open_alert_erro = new EventEmitter<DADOS_ALERT>();
  @Input() Nat_state_FERRAMENTAS: boolean = false
  @Input() Nat_state_MODULOS: boolean = false

  user:USER
  fotoBase64: string | null = null;


  ngOnInit() {
    this.user = environment.user_atual;

    if(environment.foto){
      this.fotoBase64 = environment.foto
    }else{
    // Verifica se há uma foto e faz a conversão para Base64
    if (this.user?.FOTO?.data) {
      this.fotoBase64 = this.convertBufferToBase64(this.user.FOTO.data);
    }
    }

  }
// Função para converter o array de bytes em uma string Base64
convertBufferToBase64(buffer: number[]): string {
  const base64String = btoa(
    String.fromCharCode(...buffer)
  );
  return `data:image/png;base64,${base64String}`;
}
  Nat_open_perfil(){
    this.Evento_close_perfil.emit(true)
  }
  Nat_change_aba(aba: string) {//altera o estado das abas da nav

 if (aba == 'FERRAMENTAS') {
      this.Nat_state_FERRAMENTAS = !this.Nat_state_FERRAMENTAS

      if (this.Nat_state_FERRAMENTAS == true) {
        this.Evento_change_state_FERRAMENTAS.emit(true)
      } else {
        this.Evento_change_state_FERRAMENTAS.emit(false)
      }
    } else if (aba == 'MODULOS') {
      this.Nat_state_MODULOS = !this.Nat_state_MODULOS

      if (this.Nat_state_MODULOS == true) {
        this.Evento_change_state_MODULOS.emit(true)
      } else {
        this.Evento_change_state_MODULOS.emit(false)
      }
    }

  }



  onSearch(event: Event): void { //vai buscando conforme é digitado no campo de busca
    const query = (event.target as HTMLInputElement).value.trim();

    if (query.length < 3) {
      this.suggestions = [];
      return;
    }

    of(query)
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((q) => {
          let url = '';

          if (this.Nat_type_busca === 'ENDEREÇO') {
            url = `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(
              q
            )}&addressdetails=1&limit=5`;
          } else if (this.Nat_type_busca === 'COORDENADA') {
            const [lat, lon] = q.split(',');
            if (!lat || !lon) {
              return of([]); // Retorna vazio se coordenadas são inválidas
            }
            url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat.trim()}&lon=${lon.trim()}&addressdetails=1`;
          }

          return this.http.get<any[]>(url);
        }),
        catchError((error) => {
          console.error('Erro ao buscar locais:', error);
          return of([]);
        })
      )
      .subscribe((results) => {
        if (this.Nat_type_busca === 'COORDENADA') {
          this.suggestions = results ? [results] : [];
        } else {
          this.suggestions = results;
        }
      });
  }

  @ViewChild('inp_buscar') inp_buscar!: ElementRef;

  Nat_change_parametro() {//altera o metodo de busca entre endereco ou coordenada
    if (this.Nat_type_busca == 'COORDENADA') {
      this.Nat_type_busca = 'ENDEREÇO'
    } else {
      this.Nat_type_busca = 'COORDENADA'
    }

  }

  selectSuggestion(suggestion: any): void {//seleciona um item na lista de sugestoes
    if (this.Nat_type_busca === 'ENDEREÇO') {
      this.inp_buscar.nativeElement.value = `${suggestion.address.road}, ${suggestion.address.postcode}, ${suggestion.address.municipality}, ${suggestion.address.state}`;
    } else if (this.Nat_type_busca === 'COORDENADA') {
      this.inp_buscar.nativeElement.value = `${suggestion.address.road}, ${suggestion.address.postcode}, ${suggestion.address.municipality}, ${suggestion.address.state}`;
    }

    this.Nat_coordenada_localizada = {
      lat: parseFloat(suggestion.lat),
      lng: parseFloat(suggestion.lon),
    };

    this.Evento_local_buscado.emit(this.Nat_coordenada_localizada)


    this.suggestions = [];
  }

  Nat_clear_input() {//limpa o input de busca
    this.suggestions = [];
    this.Nat_coordenada_localizada = undefined
    this.inp_buscar.nativeElement.value = ''
  }

  Nat_change_state_importacoes(){
    this.Evento_change_state_importacoes.emit()
  }
  Nat_state_importacoes:boolean = false
  Nat_change_dropdown_importacoes(){
    this.Nat_state_importacoes = !this.Nat_state_importacoes
  }


  Nat_state_notas:boolean = false
  Nat_change_dropdown_notas(){
    this.Nat_state_notas = !this.Nat_state_notas
  }

  Nat_change_state_pendentes(){
    this.Evento_change_state_pendentes.emit()
  }



  Nat_change_state_atribuidas(){
    this.Evento_change_state_atribuidas.emit()
  }


  
  Nat_change_state_concluidas(){
    this.Evento_change_state_concluidas.emit()
  }


  
  Nat_change_state_rejeitadas(){
    this.Evento_change_state_rejeitadas.emit()
  }



  
  Nat_change_state_roteiros(){
    this.Evento_change_state_roteiros.emit()
  }



  
  Nat_state_equipes:boolean = false
  Nat_change_dropdown_equipes(){
    this.Nat_state_equipes = !this.Nat_state_equipes
  }

  @Output() Evento_change_state_new_equipe = new EventEmitter<boolean>()
  @Input() input_state_new_equipe:boolean
  Nat_change_state_new_equipe(){
    this.Evento_change_state_new_equipe.emit(!this.input_state_new_equipe)
  }

  @Output() Evento_change_state_view_equipe = new EventEmitter<boolean>()
  @Input() input_state_view_equipe:boolean
  Nat_change_state_view_equipe(){
    this.Evento_change_state_view_equipe.emit(!this.input_state_view_equipe)
  }


  Nat_state_bases:boolean = false
  Nat_change_dropdown_bases(){
    this.Nat_state_bases = !this.Nat_state_bases
  }
  @Output() Evento_change_state_new_base = new EventEmitter<boolean>()
  @Input() input_state_new_base:boolean
  Nat_change_state_new_base(){
    this.Evento_change_state_new_base.emit(!this.input_state_new_base)
  }
  @Output() Evento_change_state_view_base = new EventEmitter<boolean>()
  @Input() input_state_view_base:boolean
  Nat_change_state_view_base(){
    this.Evento_change_state_view_base.emit(!this.input_state_view_base)
  }

  @Input() input_bases_mapa:BASE_MAPA[]
  @Output() Evento_change_state_open_bases = new EventEmitter<boolean>()
  Nat_change_state_open_bases(){
    if(this.input_bases_mapa.length>0){
      this.Evento_change_state_open_bases.emit(false)
    }else{
      this.Evento_change_state_open_bases.emit(true)
    }
  }


  @Output() Evento_abrir_informacoes_importacao= new EventEmitter<boolean>()
  Nat_baixar_modelo(){
    const link = document.createElement('a');
    // Caminho relativo ao arquivo na pasta public
    link.href = 'ARQUIVOS/ESFERA_AORDEX_NOTAS.xlsx'; 
    link.download = 'ESFERA_AORDEX_NOTAS.xlsx'; // Nome do arquivo baixado
    link.click();
    this.Evento_abrir_informacoes_importacao.emit(true)
  }
  Nat_informacoes_importacao(){
    this.Evento_abrir_informacoes_importacao.emit(true)
  }

  async Nat_importar_modelo(event: Event) {
    const input = event.target as HTMLInputElement;
  
    if (input.files && input.files[0]) {
      const file = input.files[0];
      const formData = new FormData();
      formData.append('planilha', file);
      formData.append('user_id', `${environment.user_atual.ID}`);
      formData.append('user_name', `${environment.user_atual.NOME}`);
      formData.append('empresa_id', `${environment.user_atual.EMPRESA_ID}`);
  
      // Exibir loading
      this.Evento_close_loading.emit(true);
  
      try {
        const requisicao_server: any = await this.aordexservice.POST_RECEBE_PLANILHA(formData);
        const resposta: RESPOSTA_PADRAO_SERVIDOR = requisicao_server;
  
        // Processar resposta do servidor
        if (resposta.MSG === 'SUCESSO') {
          this.Evento_close_loading.emit(false);
          const new_alert: DADOS_ALERT = {
            TITULO: resposta.MSG,
            CORPO: resposta.ADICIONAL,
          };
          this.Evento_open_alert_sucess.emit(new_alert);


          console.log(resposta.RESPOSTA)
        } else {
          this.Evento_close_loading.emit(false);
          const new_alert: DADOS_ALERT = {
            TITULO: resposta.MSG,
            CORPO: resposta.ADICIONAL,
          };
          this.Evento_open_alert_erro.emit(new_alert);
        }
      } catch (error) {
        console.error('Erro ao enviar a planilha:', error);
        this.Evento_close_loading.emit(false);
        const new_alert: DADOS_ALERT = {
          TITULO: 'ERRO',
          CORPO: 'Falha ao enviar a planilha!',
        };
        this.Evento_open_alert_erro.emit(new_alert);
      } finally {
        // Redefinir o campo de entrada de arquivo para permitir novo envio
        input.value = '';
      }
    } else {
      this.Evento_close_loading.emit(false);
      const new_alert: DADOS_ALERT = {
        TITULO: 'ERRO',
        CORPO: 'Nenhum Arquivo Inserido!',
      };
      this.Evento_open_alert_erro.emit(new_alert);
    }
  }
  

    
  

}
