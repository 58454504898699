<div class="relative z-30" aria-labelledby="slide-over-title" role="dialog" aria-modal="true">


  

    <div class="fixed  overflow-hidden">
        <div class="absolute overflow-hidden">
            <div class="pointer-events-none fixed inset-y-0 right-0 flex w-[32rem] pl-10">

                <div class="pointer-events-auto w-full max-w-full">
                    <div class="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                        <div class="flex min-h-0 flex-1 flex-col overflow-y-scroll py-6">
                            <div class="px-4 sm:px-6">
                                <div class="flex items-start justify-between">
                                    <p class="text-base font-semibold text-gray-900" id="slide-over-title">Polígono
                                        Selecionado
                                    </p>
                                    <div class="ml-3 flex h-7 items-center">
                                        <button type="button"
                                        (click)="Nat_close_detalhes_poligono()"
                                            class="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500">
                                            <span class="absolute -inset-2.5"></span>
                                            <span class="sr-only">Close panel</span>
                                            <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                                stroke="currentColor" aria-hidden="true" data-slot="icon">
                                                <path stroke-linecap="round" stroke-linejoin="round"
                                                    d="M6 18 18 6M6 6l12 12" />
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="relative mt-6 flex-1 px-4 sm:px-6">
                                <!-- Your content -->





                                <div class="space-y-6 pb-5 pt-6">
                                    @if(Input_poligono_recebido){
                                        @if(Input_poligono_recebido.TIPO != "SELECAO" && Input_poligono_recebido.MODULO != "GPX"){
                                            <!--centro de leitura atual-->
                                            <div>
                                             <label for="project-name"
                                                 class="block text-sm/6 font-medium text-gray-900">Centro de Leitura</label>
                                             <div class="mt-2">
                                                 <input disabled type="text" [value]="Input_poligono_recebido.CENTRO_LEITURA" name="project-name" id="project-name" class="block w-full rounded-md border-1 py-1.5
                                     text-gray-900 shadow-sm ring-1 ring-inset ring-gray-500
                                     placeholder:text-gray-400 focus:ring-2 focus:ring-inset
                                     focus:ring-blue-600 sm:text-sm/6">
                                             </div>
                                         </div>
                                     
                                         <!--grupo atual-->
                                         <div>
                                             <label for="project-name"
                                                 class="block text-sm/6 font-medium text-gray-900">Grupo</label>
                                             <div class="mt-2">
                                                 <input [value]="Input_poligono_recebido.GRUPO" disabled type="text" name="project-name" id="project-name" class="block w-full rounded-md border-1 py-1.5
                                     text-gray-900 shadow-sm ring-1 ring-inset ring-gray-500
                                     placeholder:text-gray-400 focus:ring-2 focus:ring-inset
                                     focus:ring-blue-600 sm:text-sm/6">
                                             </div>
                                         </div>
                                     
                                         <!--livro atual-->
                                         <div>
                                             <label for="project-name"
                                                 class="block text-sm/6 font-medium text-gray-900">Livro</label>
                                             <div class="mt-2">
                                                 <input [value]="Input_poligono_recebido.LIVRO" disabled type="text" name="project-name" id="project-name" class="block w-full rounded-md border-1 py-1.5
                                     text-gray-900 shadow-sm ring-1 ring-inset ring-gray-500
                                     placeholder:text-gray-400 focus:ring-2 focus:ring-inset
                                     focus:ring-blue-600 sm:text-sm/6">
                                             </div>
                                         </div>
                                     
                                                   <!--duração atual do poligono(se se aplicar)-->
                                     
                                                   <div>
                                                     <label for="project-name"
                                                         class="block text-sm/6 font-medium text-gray-900">Duração Total</label>
                                                     <div class="mt-2">
                                                         <input [value]="Input_poligono_recebido.DURACAO" disabled type="text" name="project-name" id="project-name" class="block w-full rounded-md border-1 py-1.5
                                     text-gray-900 shadow-sm ring-1 ring-inset ring-gray-500
                                     placeholder:text-gray-400 focus:ring-2 focus:ring-inset
                                     focus:ring-blue-600 sm:text-sm/6">
                                                     </div>
                                                 </div>
                                     
                                     
                                     
                                                 <!--total de subdivisoes desejadas-->
                                                 <div>
                                                     <label for="project-name" class="block text-sm font-medium text-gray-900">Dividir em?</label>
                                                     <div class="relative mt-2">
                                                       <!-- Input para número -->
                                                       <input type="number"
                                                         min="2"
                                                         [(ngModel)]="numeroSubPartes"
                                                         (input)="atualizarNumero($event)"
                                                         id="project-name"
                                                         class="block w-full rounded-md border border-gray-300 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-500 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm pr-14"
                                                         placeholder="Número de Sub-Partes Desejadas" />
                                                   
                                                       <!-- Botão ao lado do input -->
                                                       <button (click)="Nat_subdividir_livro()"
                                                         type="button"
                                                         class="absolute end-1 top-1/2 -translate-y-1/2 bg-blue-600 hover:bg-blue-700 text-white font-medium rounded-md px-3 py-1 focus:outline-none focus:ring-2 focus:ring-blue-500">
                                                         OK
                                                       </button>
                                                     </div>
                                                   </div>
                                                   
                                     
                                     
                                     
                                                 <div>
                                                     <label for="location" class="block text-sm/6 font-medium text-gray-900">Tipo de Alteração</label>
                                                     <select 
                                                     (change)="Nat_altera_tipo($event)"
                                                     id="location" name="location" class="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm/6">
                                                       <option value="livro">Livro</option>
                                                       <option value="operacao">Operação (Centro de Leitura + Grupo)</option>
                                                     </select>
                                                   </div>
                                                                         }
                                                                  
                                    }
                                  

                                   

                                      @if(Nat_tipo_alteracao=="livro"){
                                        <form>
                                            <label for="search"
                                                class="mb-2 text-sm font-medium text-gray-900 sr-only ">Search</label>
                                            <div class="relative">
                                                <div
                                                    class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                                                    <svg class="w-4 h-4 text-gray-500 " aria-hidden="true"
                                                        xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                                        <path stroke="currentColor" stroke-linecap="round"
                                                            stroke-linejoin="round" stroke-width="2"
                                                            d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                                    </svg>
                                                </div>
                                                <input type="text" matInput [formControl]="myControl_livros" [matAutocomplete]="auto_livros"
                                                    id="search" class="block w-full py-3 ps-10 text-sm text-gray-900 border
                                                  border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500
                                                   focus:border-blue-500 " placeholder="Selecionar Livro Destino" required />
                                                <button type="button" (click)="Nat_alterar_livro()" class="text-white absolute end-2.5
                                                 bottom-2.5 bg-blue-700 hover:bg-blue-800
                                                  focus:ring-4 focus:outline-none focus:ring-blue-300
                                                   font-medium rounded-lg text-sm px-4 py-2 ">Enviar</button>
                                            </div>
                                            <mat-autocomplete #auto_livros="matAutocomplete">
                                                @for (option of filteredOptions_livros | async; track option) {
                                                <mat-option [value]="option">{{option}}</mat-option>
                                                }
                                            </mat-autocomplete>
                                        </form>


                                                   <!--nova coordenada-->
                                  <div>
                                    <label for="project-name"
                                        class="block text-sm font-medium text-gray-900">Nova Coordenada</label>
                                    <div class="relative mt-2">
                                        <!-- Input para número -->
                                        <input type="text" #inp_nova_coordenada  name="project-name" id="project-name"
                                            class="block w-full rounded-md border border-gray-300 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-500 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm pr-14"
                                            placeholder="Ex:-25.435165, -49.338682" />
                    
                                        <!-- Botão ao lado do input -->
                                        <button (click)="Nat_enviar_alteracao_coordenada()" type="button"
                                            class="absolute end-1 top-1/2 -translate-y-1/2 bg-blue-600 hover:bg-blue-700 text-white font-medium rounded-md px-3 py-1 focus:outline-none focus:ring-2 focus:ring-blue-500">
                                            OK
                                        </button>
                                    </div>
                                </div>
                                      }@else if(Nat_tipo_alteracao=="operacao") {
                                        <form>
                                            <label for="search"
                                                class="mb-2 text-sm font-medium text-gray-900 sr-only ">Search</label>
                                            <div class="relative">
                                                <div
                                                    class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                                                    <svg class="w-4 h-4 text-gray-500 " aria-hidden="true"
                                                        xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                                        <path stroke="currentColor" stroke-linecap="round"
                                                            stroke-linejoin="round" stroke-width="2"
                                                            d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                                    </svg>
                                                </div>
                                                <input type="text" matInput [formControl]="myControl" [matAutocomplete]="auto"
                                                    id="search" class="block w-full py-3 ps-10 text-sm text-gray-900 border
                                                  border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500
                                                   focus:border-blue-500 " placeholder="Selecionar Destino" required />
                                                <button (click)="Nat_alterar_operacao()" type="button" class="text-white absolute end-2.5
                                                 bottom-2.5 bg-blue-700 hover:bg-blue-800
                                                  focus:ring-4 focus:outline-none focus:ring-blue-300
                                                   font-medium rounded-lg text-sm px-4 py-2 ">Enviar</button>
                                            </div>
                                            <mat-autocomplete #auto="matAutocomplete">
                                                @for (option of filteredOptions | async; track option) {
                                                <mat-option [value]="option">{{option}}</mat-option>
                                                }
                                            </mat-autocomplete>
                                        </form>
                                      }
                                      
                               

              

                                    <!--quantidade de itens selecionados ou participantes do poligono-->
                                    <div>
                                        <label for="project-name"
                                            class="block text-sm/6 font-medium text-gray-900">Total de Itens</label>
                                        <div class="mt-2">
                                            <input disabled [value]="Input_poligono_recebido.TOTAL_ITENS" type="text" name="project-name" id="project-name" class="block w-full rounded-md border-1 py-1.5
           text-gray-900 shadow-sm ring-1 ring-inset ring-gray-500
            placeholder:text-gray-400 focus:ring-2 focus:ring-inset
             focus:ring-blue-600 sm:text-sm/6">
                                        </div>
                                    </div>



                          

                                </div>

                                <div>

                                </div>

                             
                              
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>