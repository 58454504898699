<div class="relative">

    <div class="absolute left-1/4 z-30 mt-20 flex w-screen max-w-[20rem] -translate-x-1/2 px-2">
        <div
            class="w-screen max-w-full flex-auto overflow-hidden rounded-3xl bg-white text-sm/6 shadow-lg ring-1 ring-gray-900/5">
            <div class="p-2">

                <!--tipo de mapa-->
                @if(user.GPS){
                <div class="group relative flex gap-x-2 rounded-lg p-2 hover:bg-gray-50">
                    <div
                        class="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">

                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                            <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z" />
                          </svg>
                          


                    </div>
                 
                    <div>
                        <a (click)="Nat_change_modulo('gpx')" class="font-semibold text-gray-900">
                            Gpx
                            <span class="absolute inset-0"></span>
                        </a>
                        <p class="mt-1 text-gray-600">Corrigir e Aprimorar Coordenadas</p>
                    </div>
                 
             

                </div>
            }
            @if(user.ZONE){
                <div class="group relative flex gap-x-2 rounded-lg p-2 hover:bg-gray-50">
                    <div
                        class="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">

                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6A2.25 2.25 0 0 1 6 3.75h2.25A2.25 2.25 0 0 1 10.5 6v2.25a2.25 2.25 0 0 1-2.25 2.25H6a2.25 2.25 0 0 1-2.25-2.25V6ZM3.75 15.75A2.25 2.25 0 0 1 6 13.5h2.25a2.25 2.25 0 0 1 2.25 2.25V18a2.25 2.25 0 0 1-2.25 2.25H6A2.25 2.25 0 0 1 3.75 18v-2.25ZM13.5 6a2.25 2.25 0 0 1 2.25-2.25H18A2.25 2.25 0 0 1 20.25 6v2.25A2.25 2.25 0 0 1 18 10.5h-2.25a2.25 2.25 0 0 1-2.25-2.25V6ZM13.5 15.75a2.25 2.25 0 0 1 2.25-2.25H18a2.25 2.25 0 0 1 2.25 2.25V18A2.25 2.25 0 0 1 18 20.25h-2.25A2.25 2.25 0 0 1 13.5 18v-2.25Z" />
                          </svg>
                          


                    </div>
                 
                    <div>
                        <a (click)="Nat_change_modulo('divix')" class="font-semibold text-gray-900">
                            Divix
                            <span class="absolute inset-0"></span>
                        </a>
                        <p class="mt-1 text-gray-600">Dividir e Organizar Livros</p>
                    </div>
                 
             

                </div>
            }
            @if(user.EXEC){
                <div class="group relative flex gap-x-2 rounded-lg p-2 hover:bg-gray-50">
                    <div
                        class="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">

                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                          </svg>
                          

                    </div>
                 
                    <div>
                        <a (click)="Nat_change_modulo('routex')" class="font-semibold text-gray-900">
                            Routex
                            <span class="absolute inset-0"></span>
                        </a>
                        <p class="mt-1 text-gray-600">Sequenciar Livros</p>
                    </div>
                 
             

                </div>
            }

            @if(user.RAXTRO_MASTER){
                <div class="group relative flex gap-x-2 rounded-lg p-2 hover:bg-gray-50">
                    <div
                        class="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">

                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z" />
                          </svg>
                          
                          

                    </div>
                 
                    <div>
                        <a (click)="Nat_change_modulo('aordex')" class="font-semibold text-gray-900">
                            Aordex
                            <span class="absolute inset-0"></span>
                        </a>
                        <p class="mt-1 text-gray-600">Criar e Acompanhar Roteiros</p>
                    </div>
                 
             

                </div>
            }

            </div>

        </div>
    </div>
</div>