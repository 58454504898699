import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment.development';
import { RESPOSTA_PADRAO_SERVIDOR } from '../../../MODELS/RESPOSTA_PADRAO_SERVIDOR';
import { User } from '../../STOX/COMPONENTS/home/home.component';
import { USER } from '../../../MODELS/USER';

@Injectable({
  providedIn: 'root'
})
export class GpxService {

  constructor(private httpClient: HttpClient) { }

  //SOLICITA A LISTA DE OPERACOES DISPONIVEIS
  async POST_OPERACOES_GPX_DISPONIVEIS(empresa_id: number) {
    try {
      const response = await this.httpClient
        .post(`${environment.url_api_local}/POST_OPERACOES_GPX_DISPONIVEIS`, { empresa_id: empresa_id })
        .toPromise();


      return response;
    } catch (error) {
      console.log(error, 'erro');
      const retorno_padrao: RESPOSTA_PADRAO_SERVIDOR = {
        MSG: "ERRO",
        ADICIONAL: "",
        RESPOSTA: undefined
      }
      return retorno_padrao

    }
  }

  //SOLICITA A LISTA DE OPERACOES EM EDICAO PELO USER
  async POST_OPERACOES_GPX_EDICOES(user_id: number) {
    try {
      const response = await this.httpClient
        .post(`${environment.url_api_local}/POST_OPERACOES_GPX_EDICOES`, { user_id: user_id })
        .toPromise();


      return response;
    } catch (error) {
      console.log(error, 'erro');
      const retorno_padrao: RESPOSTA_PADRAO_SERVIDOR = {
        MSG: "ERRO",
        ADICIONAL: "",
        RESPOSTA: undefined
      }
      return retorno_padrao

    }
  }

  //SOLICITA INSTALACOES DE UMA OPERACAO
  async POST_ITENS_OPERACAO_GPX(operacao_id: number) {
    try {
      const response = await this.httpClient
        .post(`${environment.url_api_local}/POST_ITENS_OPERACAO_GPX`, { operacao_id: operacao_id })
        .toPromise();


      return response;
    } catch (error) {
      console.log(error, 'erro');
      const retorno_padrao: RESPOSTA_PADRAO_SERVIDOR = {
        MSG: "ERRO",
        ADICIONAL: "",
        RESPOSTA: undefined
      }
      return retorno_padrao

    }
  }

  

    //CRIA UMA EDICAO GPX PARA O USER
    async POST_CRIA_EDICAO_GPX(operacao_id: number,user_id:number) {
      try {
        const response = await this.httpClient
          .post(`${environment.url_api_local}/POST_CRIA_EDICAO_GPX`, { operacao_id: operacao_id,user_id:user_id })
          .toPromise();
  
  
        return response;
      } catch (error) {
        console.log(error, 'erro');
        const retorno_padrao: RESPOSTA_PADRAO_SERVIDOR = {
          MSG: "ERRO",
          ADICIONAL: "",
          RESPOSTA: undefined
        }
        return retorno_padrao
  
      }
    }

      //MUDA A COORDENADA DE UMA OU MAIS INSTALACOES
  async POST_MUDAR_COORDENADA(latitude: number,longitude:number,ids:number[]) {
    try {
      const response = await this.httpClient
        .post(`${environment.url_api_local}/POST_MUDAR_COORDENADA`, { latitude: latitude,longitude:longitude,ids:ids })
        .toPromise();


      return response;
    } catch (error) {
      console.log(error, 'erro');
      const retorno_padrao: RESPOSTA_PADRAO_SERVIDOR = {
        MSG: "ERRO",
        ADICIONAL: "",
        RESPOSTA: undefined
      }
      return retorno_padrao

    }
  }

        //VALIDA UM LOGRADOURO COMO CORRETO
        async POST_VALIDAR_LOGRADOURO(id:number,user:USER) {
          try {
            const response = await this.httpClient
              .post(`${environment.url_api_local}/POST_VALIDAR_LOGRADOURO`, { id: id,user:user})
              .toPromise();
      
      
            return response;
          } catch (error) {
            console.log(error, 'erro');
            const retorno_padrao: RESPOSTA_PADRAO_SERVIDOR = {
              MSG: "ERRO",
              ADICIONAL: "",
              RESPOSTA: undefined
            }
            return retorno_padrao
      
          }
        }

        //BUSCA OSLOGRADOUROS DE UMA OPERACAO ESPECIFICA PARA CORREÇÃO
        async POST_RETORNA_LISTA_LOGRADOUROS_OP(OPERACAO_ID:number) {
          try {
            const response = await this.httpClient
              .post(`${environment.url_api_local}/POST_RETORNA_LISTA_LOGRADOUROS_OP`, { OPERACAO_ID:OPERACAO_ID })
              .toPromise();
      
      
            return response;
          } catch (error) {
            console.log(error, 'erro');
            const retorno_padrao: RESPOSTA_PADRAO_SERVIDOR = {
              MSG: "ERRO",
              ADICIONAL: "",
              RESPOSTA: undefined
            }
            return retorno_padrao
      
          }
        }

       //MUDA A OPERACAO DE UMA OU MAIS INSTALACOES
       async POST_MUDAR_OPERACAO_GPX(operacao_destino:number,ids:number[]) {
        try {
          const response = await this.httpClient
            .post(`${environment.url_api_local}/POST_MUDAR_OPERACAO_GPX`, { operacao_destino:operacao_destino,ids:ids })
            .toPromise();
    
    
          return response;
        } catch (error) {
          console.log(error, 'erro');
          const retorno_padrao: RESPOSTA_PADRAO_SERVIDOR = {
            MSG: "ERRO",
            ADICIONAL: "",
            RESPOSTA: undefined
          }
          return retorno_padrao
    
        }
      }
  

  //ENVIA UMA OPERACAO DISPONIVEL PARA A LISTA DE EDICOES DO USUARIO
  async POST_ENVIAR_DIVIX(operacao_id: number) {
    try {
      const response = await this.httpClient
        .post(`${environment.url_api_local}/POST_ENVIAR_DIVIX`, { operacao_id: operacao_id })
        .toPromise();


      return response;
    } catch (error) {
      console.log(error, 'erro');
      const retorno_padrao: RESPOSTA_PADRAO_SERVIDOR = {
        MSG: "ERRO",
        ADICIONAL: "",
        RESPOSTA: undefined
      }
      return retorno_padrao

    }
  }


}
