<div class="absolute z-30 w-1/2 right-10 top-20 overflow-hidden bg-white shadow sm:rounded-lg">
    <div class="px-4 py-6 ">
      <p class="text-base/7 font-semibold text-gray-900">Instruções para Importações Corretas</p>
      <p class="mt-1 max-w-2xl text-sm/6 text-gray-500">Após download do Modelo de Tabela usado, conheça quais dados devem ser Inseridos em Cada Coluna.</p>
   
   
   
      <button (click)="Nat_close_informacoes()"
        class="absolute top-2 right-2 rounded-full bg-gray-200 p-1 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-500"
      >
        <span class="sr-only">Fechar</span>
        <svg class="h-5 w-5 text-gray-700" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
          <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
        </svg>
      </button>
    </div>

    									

    <div class="border-t border-gray-100  max-h-[30rem] overflow-y-auto">
      <dl class="divide-y divide-gray-100">
        <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-900">DATA_GERACAO (obrigatória)</dt>
          <dd class="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">Data de Geração Nota em Seu Sistema Interno no formato DD/MM/AAAA, exemplo: 10/10/2010</dd>
        </div>
        <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-900">DATA_PREV_CONC_SIS (obrigatória)</dt>
          <dd class="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">Data limite de Conclusão da Nota em Seu Sistema Interno no formato DD/MM/AAAA, exemplo: 10/10/2010</dd>
        </div>
        <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-900">TIPO (obrigatória)</dt>
          <dd class="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">Um dos Valores Disponíveis em Sistema, Sendo as Opções atuais: 
            AF, CT, LN, MD, RE, VR, LEITURA_PE, LEITURA_VEICULO.
          </dd>
        </div>
        <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-900">DESC_SERVICO (obrigatória)</dt>
          <dd class="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">A descrição Específica da Atividade, como: Cortado e Retirados Medidor e TC's</dd>
        </div>
        <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-900">INSTALACAO (obrigatória)</dt>
          <dd class="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">Código Único da Unidade Consumidora.</dd>
        </div>
        <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt class="text-sm font-medium text-gray-900">RESPONSAVEL_UC (opcional)</dt>

            <dd class="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">Nome da Pessoa Responsável pela Unidade Consumidora.</dd>  </div>
          <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt class="text-sm font-medium text-gray-900">ENDERECO (obrigatória)</dt>
            <dd class="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">Endereço por Escrito mediante Cadastrado.</dd> </div>
          <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt class="text-sm font-medium text-gray-900">CIDADE (obrigatória)</dt>
            <dd class="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">Nome da Cidade da Atividade. </dd></div>
          <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt class="text-sm font-medium text-gray-900">ESTADO (obrigatória)</dt>
            <dd class="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0"> Sigla do Estado da Atividade, como: SP.</dd></div>	
          <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt class="text-sm font-medium text-gray-900">LAT (opcional)</dt>
            <dd class="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0"> Se possuir, o código da latitude da Unidade Consumidora, semelhante a: -5.062316, caso não possua, será gerado por Mineração com o Endereço Fornecido.</dd></div>
          <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt class="text-sm font-medium text-gray-900">LNG (opcional)</dt>
            <dd class="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">Se possuir, o código da longitude da Unidade Consumidora, semelhante a: -42.801043, caso não possua, será gerado por Mineração com o Endereço Fornecido.</dd> </div>
            <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt class="text-sm font-medium text-gray-900">NUM_MEDIDA (obrigatória)</dt>
              <dd class="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">Número da Execução de Serviço atual, por exemplo, se for a Segunda atividade igual para a mesma Unidade, será 2, para serviços de Leitura, equivale a Leitura(1) ou Releitura(2).</dd> </div>
              <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt class="text-sm font-medium text-gray-900">COD_NOTA (obrigatória)</dt>
                <dd class="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">Código Único da Nota Gerada em Sistema.</dd> </div>
                <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt class="text-sm font-medium text-gray-900">LIVRO (obrigatória)</dt>
                  <dd class="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">Livro da Unidade Consumidora objeto do Serviço.</dd> </div>
            
          </dl>
    </div>
  </div>
  