import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';



import { TooltipPosition, MatTooltipModule } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { CadastroSelectEmpresasComponent } from "../cadastro-select-empresas/cadastro-select-empresas.component";
import { CadastroSelectModulosComponent } from "../cadastro-select-modulos/cadastro-select-modulos.component";
import { RESPOSTA_PADRAO_SERVIDOR } from '../../../../MODELS/RESPOSTA_PADRAO_SERVIDOR';
import { CadastroService } from '../../SERVICES/cadastro.service';
import { NEW_USER } from '../../../../MODELS/NEW_USER';
import { FormsModule } from '@angular/forms';
import { DADOS_ALERT } from '../../../../MODELS/DADOS_ALERT';
import { AlertSucessoComponent } from "../../../../COMPONENTS_UI/alert-sucesso/alert-sucesso.component";
import { AlertErroComponent } from "../../../../COMPONENTS_UI/alert-erro/alert-erro.component";
@Component({
  selector: 'app-cadastro-main',
  standalone: true,
  imports: [
    MatTooltipModule,
    CadastroSelectEmpresasComponent,
    CadastroSelectModulosComponent,
    FormsModule,
    AlertSucessoComponent,
    AlertErroComponent
],
  templateUrl: './cadastro-main.component.html',
  styleUrl: './cadastro-main.component.css'
})
export class CadastroMainComponent implements OnInit {
  constructor(
   
    private router: Router,
    private service: CadastroService
  ) { }

  ngOnInit(): void {


  }


  Nat_goto(page: string) {//navega ate uma pagina especifica

    this.router.navigateByUrl(page)

  }

  Out_empresa_id?: number
  Out_recebe_empresa(empresa_id: number) {//output do componente cadastro-select-empresas quando o user seleciona uma empresa da lista
    this.Out_empresa_id = empresa_id
    console.log('Output recebido com empresa id; ', empresa_id)
  }
  Out_modulos?: string[]
  Out_recebe_modulos(modulos: string | any) {//output do componente cadastro-select-modulos quando o user seleciona os modulos que deseja usar
    //mostra como string mas recebe de fato um array de strings

    this.Out_modulos = modulos
    console.log('Output recebido com modulos; ', modulos)
  }

  Nat_isAdmin: boolean = false;

  Nat_onCheckboxAdmChange(event: Event) {
    this.Nat_isAdmin = (event.target as HTMLInputElement).checked;

    console.log(this.Nat_isAdmin, ' admin')
  }

  async Nat_gerar_token_cadastro() {
    const requisicao_server: any = await this.service.GET_GERAR_TOKEN_CAD_USER()
    const resposta: RESPOSTA_PADRAO_SERVIDOR = requisicao_server


    if (resposta.MSG == 'SUCESSO') {
      alert(resposta.ADICIONAL)
    } else {
      alert(resposta.ADICIONAL)
    }
  }

  @ViewChild('inp_nome') inp_nome!: ElementRef;
  @ViewChild('inp_password') inp_password!: ElementRef;
  @ViewChild('inp_email') inp_email!: ElementRef;
  @ViewChild('inp_token') inp_token!: ElementRef;

  async Nat_cadastrar() {

    const nome: string = this.inp_nome.nativeElement.value
    const email: string = this.inp_email.nativeElement.value
    const senha: string = this.inp_password.nativeElement.value
    let token: string = ''
    if(this.inp_token){
       token = this.inp_token.nativeElement.value
    }
    

    if (!this.Out_empresa_id || !this.Out_modulos || nome == '' || email == '' || senha == '') {

      let new_alert:DADOS_ALERT={
        TITULO: 'ERRO',
        CORPO: 'Todos os Campos devem ser Preenchidos!'
      }
      this.Nat_dados_alert = new_alert
      this.Nat_status_alert_erro = true
    } else {

      let new_user = {
        EMAIL: email,
        NOME: nome,
        SENHA: senha,
        EMPRESA_ID: this.Out_empresa_id,
        GPS: false,
        ZONE: false,
        EXEC: false,
        REVIEW: false,
        NEW: false,
        SOCIAL: false,
        ATIVO: false,
        XTOQ_USER: false,
        XTOQ_MASTER: false,
        RAXTRO_USER: false,
        RAXTRO_MASTER: false,
        CEARA_APS:false,
        LAT: 0,
        LNG: 0
      }



      if (this.Out_modulos.includes('Gpx')) {
        new_user.GPS = true
      }

      if (this.Out_modulos.includes('Divix')) {
        new_user.ZONE = true
      }

      if (this.Out_modulos.includes('Routex')) {
        new_user.EXEC = true
      }

      if (this.Out_modulos.includes('Aordex user')) {
        new_user.RAXTRO_USER = true
      }
      if (this.Out_modulos.includes('Aordex Admin')) {
        new_user.RAXTRO_MASTER = true
        new_user.RAXTRO_USER = true
      }
      if (this.Out_modulos.includes('Ceara Apps')) {
        new_user.CEARA_APS = true
       
      }

      

      if (this.Out_modulos.includes('Stox User')) {
        new_user.XTOQ_USER = true
      }
      if (this.Out_modulos.includes('Stox Admin')) {
        new_user.XTOQ_MASTER = true
        new_user.XTOQ_USER = true
      }

      if (this.Out_modulos.includes('XSocial')) {
        new_user.SOCIAL = true
      }
      const requisicao_server: any = await this.service.POST_CADASTRAR_USER({
        novo_user: new_user,
        token: token
      })
      const resposta: RESPOSTA_PADRAO_SERVIDOR = requisicao_server


      if (resposta.MSG == 'SUCESSO') {



        this.inp_nome.nativeElement.value=''
        this.inp_email.nativeElement.value=''
        this.inp_password.nativeElement.value=''

        this.inp_token.nativeElement.value=''

        this.Out_empresa_id=undefined
        this.Out_modulos=undefined

        this.Nat_isAdmin = false


        let new_alert:DADOS_ALERT={
          TITULO: resposta.MSG,
          CORPO: resposta.ADICIONAL
        }
        this.Nat_dados_alert = new_alert
        this.Nat_status_alert_sucesso = true

      
      } else {
        
        let new_alert:DADOS_ALERT={
          TITULO: resposta.MSG,
          CORPO: resposta.ADICIONAL
        }
        this.Nat_dados_alert = new_alert
        this.Nat_status_alert_erro = true
      }

    }
    console.log(this.Out_empresa_id, this.Out_modulos)
  }


  Nat_dados_alert?:DADOS_ALERT


  Nat_status_alert_sucesso:boolean = false
  Out_close_alert_sucesso(status:boolean){//fecha o alerta de sucesso
    this.Nat_status_alert_sucesso = status
    this.Nat_dados_alert = undefined
  }
  Nat_status_alert_erro:boolean = false
  Out_close_alert_erro(status:boolean){//fecha o alerta de erro
    this.Nat_status_alert_erro = status
    this.Nat_dados_alert = undefined
  }
}