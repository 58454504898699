
<div>
 
  
    <!-- Static sidebar for desktop -->
    <div class="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
      <!-- Sidebar component, swap this element with another sidebar if you like -->
      <div class="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6">
        <div class="flex h-16 shrink-0 items-center">
          <img src="https://res.cloudinary.com/de74yvifw/image/upload/v1728393037/EXFERA/logo_simples_rs5par.png" class="w-10">Stox
        </div>
        <nav class="flex flex-1 flex-col">
          <ul role="list" class="flex flex-1 flex-col gap-y-7">
            <li>
              <ul role="list" class="-mx-2 space-y-1">
                <li>
                  <!-- Current: "bg-gray-50 text-indigo-600", Default: "text-gray-700 hover:text-indigo-600 hover:bg-gray-50" -->
                  <a  class="group flex gap-x-3 rounded-md bg-gray-50 p-2 text-sm font-semibold leading-6 text-indigo-600">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                      </svg>
                      
                    Nova Retirada
                  </a>
                </li>

                @if(is_superuser== true){
                  <li>
                    <a (click)="goto('retiradas')"  class="group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-indigo-600">
                      <svg class="h-6 w-6 shrink-0 text-gray-400 group-hover:text-indigo-600" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" data-slot="icon">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M15 19.128a9.38 9.38 0 0 0 2.625.372 9.337 9.337 0 0 0 4.121-.952 4.125 4.125 0 0 0-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 0 1 8.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0 1 11.964-3.07M12 6.375a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0Zm8.25 2.25a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0Z" />
                      </svg>
                      Retiradas
                    </a>
                  </li>
                  <li>
                    <a (click)="goto('atualizacoes')"  class="group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-indigo-600">
               
  
                      <svg class="h-6 w-6 shrink-0 text-gray-400 group-hover:text-indigo-600" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" data-slot="icon">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5" />
                        </svg>
                      Atualizações
                    </a>
                  </li>
                  <li>
                    <a (click)="goto('estoque')" class="group cursor-pointer flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-indigo-600">
                      <svg class="h-6 w-6 shrink-0 text-gray-400 group-hover:text-indigo-600" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" data-slot="icon">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 12.75V12A2.25 2.25 0 0 1 4.5 9.75h15A2.25 2.25 0 0 1 21.75 12v.75m-8.69-6.44-2.12-2.12a1.5 1.5 0 0 0-1.061-.44H4.5A2.25 2.25 0 0 0 2.25 6v12a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9a2.25 2.25 0 0 0-2.25-2.25h-5.379a1.5 1.5 0 0 1-1.06-.44Z" />
                        </svg>
                      Meu Estoque
                    </a>
                  </li>
                  <li>
                    <a  class="group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-indigo-600">
                      <svg class="h-6 w-6 shrink-0 text-gray-400 group-hover:text-indigo-600" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" data-slot="icon">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 0 1-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 0 1 1.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 0 0-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 0 1-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H9.75" />
                      </svg>
                      Dashboards
                    </a>
                  </li>
                }
                <li>
                  <a (click)="goto('home')"
                      class="group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-indigo-600">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                        </svg>
                        
                      Menu de Módulos
                  </a>
              </li>
              <li>
                <a (click)="goto('')"
                    class="group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-indigo-600">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 9V5.25A2.25 2.25 0 0 1 10.5 3h6a2.25 2.25 0 0 1 2.25 2.25v13.5A2.25 2.25 0 0 1 16.5 21h-6a2.25 2.25 0 0 1-2.25-2.25V15m-3 0-3-3m0 0 3-3m-3 3H15" />
                      </svg>
                      
                   Sair
                </a>
            </li>
              
              </ul>
            </li>
           
            <li class="-mx-6 mt-auto">
              <a
                  class="flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-50">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                    </svg>
                    
                
                  <span #span_username aria-hidden="true">User Name</span>
              </a>
          </li>
          </ul>
        </nav>
      </div>
    </div>
  
    <div class="sticky top-0 z-40 flex items-center gap-x-6 bg-white px-4 py-4 shadow-sm sm:px-6 lg:hidden">
      <button type="button" class="-m-2.5 p-2.5 text-gray-700 lg:hidden">
        <span class="sr-only">Open sidebar</span>
        <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" data-slot="icon">
          <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
        </svg>
      </button>
      <div class="flex-1 text-sm font-semibold leading-6 text-gray-900">Dashboard</div>
      <a >
        <span class="sr-only">Your profile</span>
        <img class="h-8 w-8 rounded-full bg-gray-50" src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="">
      </a>
    </div>
  
    <main class="lg:pl-72 bg-indigo-50">
      <div class="xl:pl-96">
        <div class="px-4 py-10 sm:px-6 lg:px-8 lg:py-6">
          <!-- Main area -->
          <div class="border-b border-gray-900/10 pb-12">
            <h2 class="text-base font-semibold leading-7 text-gray-900">Dados da Retirada</h2>
            <p class="mt-1 text-sm leading-6 text-gray-600">Forneça as informações corretamente para realizar a retirada dos itens.</p>
      
            <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div class="sm:col-span-2">
                <label for="first-name" class="block text-sm font-medium leading-6 text-gray-900">CCE</label>
                <div class="mt-2">
                  <input #input_cce type="text" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                </div>
              </div>
      
              <div class="sm:col-span-2">
                <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">OSME</label>
                <div class="mt-2">
                  <input #input_osme type="text" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                </div>
              </div>
      
              <div class="sm:col-span-2">
                <label for="email" class="block text-sm font-medium leading-6 text-gray-900">DOC</label>
                <div class="mt-2">
                  <input #input_doc type="text" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                </div>
              </div>
              <div class="sm:col-span-1">
                <label for="email" class="block text-sm font-medium leading-6 text-gray-900">ID</label>
                <div class="mt-2">
                  <input #input_id disabled type="text"  class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                </div>
              </div>
              <div class="sm:col-span-3">
                <label for="email" class="block text-sm font-medium leading-6 text-gray-900">NOME</label>
                <div class="mt-2">
                  <input #input_nome disabled type="text"  class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                </div>
              </div>
              <div class="sm:col-span-2">
                <label for="email" class="block text-sm font-medium leading-6 text-gray-900">CODIGO</label>
                <div class="mt-2">
                  <input #input_codigo disabled type="text"  class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                </div>
              </div>
              <div class="sm:col-span-2">
                <label for="email" class="block text-sm font-medium leading-6 text-gray-900">TIPO</label>
                <div class="mt-2">
                  <input #input_tipo disabled type="text"  class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                </div>
              </div>
              <div class="sm:col-span-2">
                <label for="email" class="block text-sm font-medium leading-6 text-gray-900">QUANTIDADE</label>
                <div class="mt-2">
                  <input #input_quantidade type="number"  class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                </div>
              </div>

              <div class="sm:col-span-6">
                <label for="email" class="block text-sm font-medium leading-6 text-gray-900">CODIGOS DE BARRA({{codigos_barra_atuais.length}})</label>
                <div class="mt-2">
                  <input (change)="onInputCodigos(input_codigos.value)" #input_codigos type="text"  class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                </div>
              </div>
      
          
      
              <div class="col-span-full">
                <label for="street-address" class="block text-sm font-medium leading-6 text-gray-900">Selecione o item</label>
                <div class="mt-2">
                    <div>

                        <div class="relative mt-2 rounded-md shadow-sm">
                    
                          <input matInput (keyup)="applyFilter_itens_estoque($event)" #input type="text" class="block w-full
                           rounded-md border-0 py-1.5 pl-10
                            text-gray-900 ring-1 ring-inset
                             ring-gray-300 placeholder:text-gray-400
                              focus:ring-2 focus:ring-inset focus:ring-indigo-600
                               sm:text-sm sm:leading-6" placeholder="Buscar item do estooue">
                               <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
                                  </svg>
                                  
                                  
                              </div>
                        </div>
                      </div>
                    <!-- Secondary column (hidden on smaller screens) -->
                  <div class="mat-elevation-z8">
                    <table mat-table [dataSource]="dataSource_itens_estoque"  matSort
                    #sort_itens_estoque="matSort" >
                  
            
                      <!-- ID Column -->
                      <ng-container matColumnDef="COD_INTERNO">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> COD. INTERNO </th>
                        <td mat-cell *matCellDef="let row" >
                            <div> {{row.COD_INTERNO}} </div>
              
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="TIPO">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> TIPO </th>
                        <td mat-cell *matCellDef="let row" >
                            <div> {{row.TIPO}} </div>
              
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="NOME">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>NOME </th>
                        <td mat-cell *matCellDef="let row" >
                            <div> {{row.NOME}} </div>
              
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="QUANTIDADE">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> QUANTIDADE </th>
                        <td mat-cell *matCellDef="let row" >
                            <div> {{row.QUANTIDADE}} </div>
                            <div class="text-indigo-500 hover:underline cursor-pointer"><a (click)="selecionar(row)">Selecionar</a>  </div>
              
                        </td>
                      </ng-container>
                   
                  
                  
                      <tr mat-header-row *matHeaderRowDef="displayedColumns_itens_estoque" ></tr>
                      <tr mat-row *matRowDef="let row; columns: displayedColumns_itens_estoque;" ></tr>
                  
                      <!-- Row shown when there is no matching data. -->
                      <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
                      </tr>
                    </table>
                  
                    <mat-paginator #paginator_itens_estoque [pageSizeOptions]="[3]" aria-label="Select page of users"></mat-paginator>
                  </div>
                </div>
                  
              </div>
      
             
            </div>
          </div>

<!--CODIGOS DE BARRA-->
<div class="mt-4">


<div>

    <div class="relative mt-2 rounded-md shadow-sm">

      <input matInput (keyup)="applyFilter_cod_barras($event)" #input type="text" class="block w-full
       rounded-md border-0 py-1.5 pl-10
        text-gray-900 ring-1 ring-inset
         ring-gray-300 placeholder:text-gray-400
          focus:ring-2 focus:ring-inset focus:ring-indigo-600
           sm:text-sm sm:leading-6" placeholder="Buscar código inserido">
           <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
              </svg>
              
              
          </div>
    </div>
  </div>
  
      
      <div class="mat-elevation-z8">
        <table mat-table [dataSource]="dataSource_codigos_barra"  matSort
        #sort_cod_barras="matSort">
      
          <!-- ID Column -->
          <ng-container matColumnDef="codigo">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> codigo </th>
            <td mat-cell *matCellDef="let row"> {{row.codigo}} </td>
          </ng-container>
      
      
      
          <tr mat-header-row *matHeaderRowDef="displayedColumns_codigos_barra"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns_codigos_barra;"></tr>
      
          <!-- Row shown when there is no matching data. -->
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
          </tr>
        </table>
      
        <mat-paginator #paginator_cod_barras [pageSizeOptions]="[100]" aria-label="Select page of users"></mat-paginator>
      </div>
</div>

  
  


 <!-- Div para os botões -->
 <div class="mt-6 flex justify-end space-x-4">
  <button type="button" class="px-4 py-2 bg-gray-500 text-white rounded-md shadow-sm hover:bg-gray-600"
  (click)="limpar()"
  >Limpar atual</button>
  <button type="button" class="px-4 py-2 bg-yellow-500 text-white rounded-md shadow-sm hover:bg-yellow-600"
  (click)="limpar_tudo()"
  >Limpar tudo</button>
  <button type="button" class="px-4 py-2 bg-orange-500 text-white rounded-md shadow-sm hover:bg-orange-600"
  (click)="atualiza_lista()"
  >Atualizar</button>
  <button type="button" class="px-4 py-2 bg-blue-500 text-white rounded-md shadow-sm hover:bg-blue-600"
  (click)="adicionar_itens()"
  >Adicionar à lista</button>
  <button type="button" class="px-4 py-2 bg-green-500 text-white rounded-md shadow-sm hover:bg-green-600"
  (click)="nova_retirada()"
  >Enviar Retirada</button>
</div>




        </div>
      </div>
    </main>
  
    <aside class="bg-gray-50 fixed inset-y-0 left-72 hidden w-96 overflow-y-auto border-r border-gray-200 px-4 py-6 sm:px-6 lg:px-8 xl:block">
        <div>

            <div class="relative mt-2 rounded-md shadow-sm">
        
              <input matInput (keyup)="applyFilter_itens_atuais($event)" #input type="text" class="block w-full
               rounded-md border-0 py-1.5 pl-10
                text-gray-900 ring-1 ring-inset
                 ring-gray-300 placeholder:text-gray-400
                  focus:ring-2 focus:ring-inset focus:ring-indigo-600
                   sm:text-sm sm:leading-6" placeholder="Buscar item inserido">
                   <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
                      </svg>
                      
                      
                  </div>
            </div>
          </div>
        <!-- Secondary column (hidden on smaller screens) -->
      <div class="mat-elevation-z8">
        <table mat-table [dataSource]="dataSource_itens_atuais"  matSort
        #sort_itens_atuais="matSort" >
      

          <!-- ID Column -->
          <ng-container matColumnDef="INFORMCOES">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> INFO. </th>
            <td mat-cell *matCellDef="let row" >
                <div> {{row.TIPO}} </div>
                <div> {{row.CODIGO}} </div>
                <div> {{row.NOME}} </div>
                <div> Seriais {{row.COD_BARRAS.length}} </div>
            </td>
          </ng-container>
          <!-- ID Column -->
          <ng-container matColumnDef="QUANTIDADE">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> QUANT. </th>
            <td mat-cell *matCellDef="let row">
                <div> {{row.QUANTIDADE}} </div> 
                <div class="text-red-700 hover:text-red-500 hover:underline cursor-pointer"> Deletar </div> 
            </td>
          </ng-container>
       
      
      
          <tr mat-header-row *matHeaderRowDef="displayedColumns_itens_atuais" ></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns_itens_atuais;" ></tr>
      
          <!-- Row shown when there is no matching data. -->
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
          </tr>
        </table>
      
        <mat-paginator #paginator_itens_atuais [pageSizeOptions]="[10]" aria-label="Select page of users"></mat-paginator>
      </div>
    </aside>
  </div>
  