<div class="px-4 z-30 absolute w-full bg-white top-16">
    <div class="sm:flex sm:items-center mt-2">
      
      
    </div>
    <!-- Search input -->
    <div class="mt-2">
      <input 
        matInput (keyup)="applyFilter($event)" #input
        type="text" 
        placeholder="Buscar Notas..." 
        class="w-96 rounded-md border-gray-300 px-4 py-2 text-sm focus:border-blue-600 focus:ring-blue-600" 
        id="search-input">
    </div>
    <div class="mt-2 flow-root">
      <div class=" -my-2 overflow-x-auto ">
        <div class="inline-block min-w-full py-2 align-middle">
          <div class="relative pb-4">


            <table mat-table [dataSource]="dataSource" matSort class="min-w-full table-fixed divide-y divide-gray-300">
            
              
                <!-- Progress Column -->
                <ng-container matColumnDef="ID_STR">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
                  <td mat-cell *matCellDef="let row"> {{row.ID_STR}} </td>
                </ng-container>
            
                <!-- Name Column -->
                <ng-container matColumnDef="DATA_IMPORTACAO">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> IMPORTADO EM </th>
                  <td mat-cell *matCellDef="let row"> {{row.DATA_IMPORTACAO}} </td>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="CRIADOR_NAME">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> CRIADOR </th>
                  <td mat-cell *matCellDef="let row"> {{row.CRIADOR_NAME}} </td>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="TOTAL_ITENS">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> TOTAL ITENS </th>
                  <td mat-cell *matCellDef="let row"> {{row.TOTAL_ITENS}} </td>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="ITENS_IMPORTADOS">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> ITENS IMPORTADOS</th>
                  <td mat-cell *matCellDef="let row"> {{row.ITENS_IMPORTADOS}} </td>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="PORCENTAGEM_IMP">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>%</th>
                  <td mat-cell *matCellDef="let row"> {{row.PORCENTAGEM_IMP}}% </td>
                </ng-container>
            
                <!-- Fruit Column -->
                <ng-container matColumnDef="ACOES">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> 
                    AÇÕES 
                </th>
                  <td mat-cell *matCellDef="let row">
                    <div class="w-full space-x-2">
                        <a (click)="Nat_delete_importacao(row.ID)" class="rounded-md
                        bg-red-50 px-2 py-1 
                        text-xs font-medium text-red-700 ring-1 
                        ring-inset ring-red-600/20 cursor-pointer
                        hover:bg-red-100
                        ">Deletar</a>
                    </div>
                  
                    </td>
                </ng-container>
            
                <tr mat-header-row *matHeaderRowDef="displayedColumns" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 hover:bg-slate-100"></tr>
                
                <!-- Row shown when there is no matching data. -->
                <tr class="mat-row" *matNoDataRow>
                  <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
                </tr>
              </table>
            
              <mat-paginator [pageSizeOptions]="[5]" aria-label="Select page of users"></mat-paginator>


          </div>
        </div>
      </div>
    </div>
  </div>
  
  