<!-- Main modal -->
<div id="authentication-modal" tabindex="-1" aria-hidden="true" class="fixed inset-0 z-50 flex justify-center items-center w-full h-full bg-gray-500/75">
    <div class="relative p-4 w-full max-w-md">
      <!-- Modal content -->
      <div class="relative bg-white rounded-lg shadow">
        <!-- Modal header -->
        <div class="flex items-center justify-between p-4 border-b rounded-t">
          <a class="text-xl font-normal text-gray-900" >
           Criar um Nova Equipe
          </a>
          <button (click)="Nat_close()" type="button" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center" data-modal-hide="authentication-modal">
            <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
            </svg>
            <span class="sr-only">Close modal</span>
          </button>
        </div>
        <!-- Modal body -->
        <div class="p-2">
            <form class="p-4 md:p-5">
                <div class="grid gap-4 mb-4 grid-cols-2">
                    <div class="col-span-2 ">
                        <label for="category" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Tipo de Equipe</label>
                        <select #select_tipo_equipe (change)="Nat_select_tipo_equipe(select_tipo_equipe.value)" id="category" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500">
                            <option selected="" value="">Selecione uma</option>
                            <option value="LEITURA">Leitura</option>
                            <option value="ELETRICISTA">Eletricistas</option>
                          
                        </select>
                    </div>
                    <div class="col-span-2">
                        <label for="name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Nome da Equipe</label>
                        <input type="text" #inp_nome_equipe name="name" id="name" 
                        class="bg-gray-50 border border-gray-300
                         text-gray-900 text-sm rounded-lg focus:ring-primary-600
                          focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600
                           dark:border-gray-500 dark:placeholder-gray-400 dark:text-white
                            dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Ex: CCE 001" required="">
                    </div>
                   
                    <div class="col-span-2">
                        <label for="category" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Membros Disponíveis</label>
                      
                         
                            <mat-select [formControl]="form" multiple>
                              <mat-select-trigger>
                                {{form.value?.[0] || ''}}
                                @if ((form.value?.length || 0) > 1) {
                                  <span class="example-additional-selection">
                                    (+{{(form.value?.length || 0) - 1}} {{form.value?.length === 2 ? 'Outro' : 'Outros'}})
                                  </span>
                                }
                              </mat-select-trigger>
                              @for (user of users_disponiveis; track user) {
                            <mat-option [value]="user">{{user}}</mat-option>
                          }
                            </mat-select>
                     
                          
                    </div>
                    <!-- <div class="col-span-2">
                        <label for="description" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Product Description</label>
                        <textarea id="description" rows="4" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Write product description here"></textarea>                    
                    </div> -->
                </div>
                <button (click)="Nat_create_equipe()" type="button" class="text-white inline-flex items-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                    <svg class="me-1 -ms-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clip-rule="evenodd"></path></svg>
                    Criar Equipe
                </button>
            </form>
        </div>
      </div>
    </div>
  </div>
  