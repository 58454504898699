import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import {FormControl, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatSelectModule} from '@angular/material/select';
import {MatFormFieldModule} from '@angular/material/form-field';
import { RESPOSTA_PADRAO_SERVIDOR } from '../../../../MODELS/RESPOSTA_PADRAO_SERVIDOR';
import { AordexService } from '../../SERVICES/aordex.service';
import { environment } from '../../../../../environments/environment.development';
import { DADOS_ALERT } from '../../../../MODELS/DADOS_ALERT';
import { EQUIPE } from '../../../../MODELS/MODELS_AORDEX';

@Component({
  selector: 'app-view-equipe',
  standalone: true,
  imports: [MatFormFieldModule, MatSelectModule, FormsModule, ReactiveFormsModule],
  templateUrl: './view-equipe.component.html',
  styleUrl: './view-equipe.component.css'
})
export class ViewEquipeComponent implements OnInit{

  constructor(private aordexService:AordexService){}

      /**evento que abre a aba de loading no component main */
      @Output() Evento_open_loading = new EventEmitter<boolean>();

      /**evento que fecha a aba de loading no component main */
      @Output() Evento_close_loading = new EventEmitter<boolean>();
    
      /**evento que abre um alerta de sucesso no component main */
      @Output() Evento_open_alert_sucess = new EventEmitter<DADOS_ALERT>();
    
      /**evento que abre um alerta de erro no component main */
      @Output() Evento_open_alert_erro = new EventEmitter<DADOS_ALERT>();

  form = new FormControl('');
  
  selectedValue: string;


  Nat_equipes: EQUIPE[] = [

  ];

  @ViewChild('inp_nome_equipe') inp_nome_equipe:ElementRef

  async ngOnInit() {
    const requisicao_server: any = await this.aordexService.POST_BUSCAR_EQUIPES(environment.user_atual.EMPRESA_ID);
    const resposta: RESPOSTA_PADRAO_SERVIDOR = requisicao_server;

    if (resposta.MSG == 'SUCESSO') {

      this.Nat_equipes = resposta.RESPOSTA

    } else {
      this.Evento_close_loading.emit(true)
      let new_alert: DADOS_ALERT = {
        TITULO: resposta.MSG,
        CORPO: resposta.ADICIONAL
      }
      this.Evento_open_alert_erro.emit(new_alert)
    }
  }


  async save_alteracao(){
    if(this.inp_nome_equipe.nativeElement.value != '' && this.selectedValue){
      const requisicao_server: any = await this.aordexService.POST_ATUALIZAR_EQUIPE(parseInt(this.selectedValue),this.inp_nome_equipe.nativeElement.value);
      const resposta: RESPOSTA_PADRAO_SERVIDOR = requisicao_server;
  
      if (resposta.MSG == 'SUCESSO') {
  
        this.Evento_close_loading.emit(true)
        let new_alert: DADOS_ALERT = {
          TITULO: resposta.MSG,
          CORPO: resposta.ADICIONAL
        }
        this.Evento_open_alert_erro.emit(new_alert)

        this.Nat_close()
  
      } else {
        this.Evento_close_loading.emit(true)
        let new_alert: DADOS_ALERT = {
          TITULO: resposta.MSG,
          CORPO: resposta.ADICIONAL
        }
        this.Evento_open_alert_erro.emit(new_alert)
      }
    }else{
      this.Evento_close_loading.emit(true)
      let new_alert: DADOS_ALERT = {
        TITULO: 'ERRO',
        CORPO: 'Dados Fornecidos não Completos!'
      }
      this.Evento_open_alert_erro.emit(new_alert)
    }
  }


  async delete_equipe(){

      const requisicao_server: any = await this.aordexService.POST_DELETAR_EQUIPE(parseInt(this.selectedValue));
      const resposta: RESPOSTA_PADRAO_SERVIDOR = requisicao_server;
  
      if (resposta.MSG == 'SUCESSO') {
  
        this.Evento_close_loading.emit(true)
        let new_alert: DADOS_ALERT = {
          TITULO: resposta.MSG,
          CORPO: resposta.ADICIONAL
        }
        this.Evento_open_alert_erro.emit(new_alert)

        this.Nat_close()
  
      } else {
        this.Evento_close_loading.emit(true)
        let new_alert: DADOS_ALERT = {
          TITULO: resposta.MSG,
          CORPO: resposta.ADICIONAL
        }
        this.Evento_open_alert_erro.emit(new_alert)
      }

  }

  @Output() Evento_change_state_view_equipe = new EventEmitter<boolean>()
  Nat_close(){
    this.Evento_change_state_view_equipe.emit(false)
  }
}
