<div class="relative z-50" aria-labelledby="slide-over-title" role="dialog" aria-modal="true">


    <div class="fixed  overflow-hidden">
        <div class="absolute  overflow-hidden">
            <div class=" fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">

                <div class="pointer-events-auto w-md max-w-md">
                    <form class="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                        <div class="h-0 flex-1 overflow-y-auto">
                            <div class="bg-blue-700 px-4 py-6 sm:px-6">
                                <div class="flex items-center justify-between">
                                    <p class="text-base font-semibold text-white" id="slide-over-title">Roteiro</p>
                                    <div class="ml-3 flex h-7 items-center">
                                        <button (click)="Nat_close(false)" type="button"
                                            class="relative rounded-md bg-blue-700 text-blue-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white">
                                            <span class="absolute -inset-2.5"></span>
                                            <span class="sr-only">Close panel</span>
                                            <svg class="size-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                                stroke="currentColor" aria-hidden="true" data-slot="icon">
                                                <path stroke-linecap="round" stroke-linejoin="round"
                                                    d="M6 18 18 6M6 6l12 12" />
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                                <div class="mt-1">
                                    <p class="text-sm text-blue-300">Veja e Avalie a Proposta de Roteiro Ótimo para os
                                        Serviços Selecionados.</p>
                                </div>
                            </div>
                            <div class="flex flex-1 flex-col justify-between">
                                <div class="divide-y divide-gray-200 px-4 sm:px-6">
                                    <div class="space-y-6 pb-5 pt-6">
                                        <div>
                                            <label for="project-name"
                                                class="block text-sm/6 font-medium text-gray-900">Total de Notas</label>
                                            <div class="mt-2">
                                                <input disabled [value]="input_ids_notas_new_roteiro.length" type="text" name="project-name" id="project-name"
                                                    class="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline-2 focus:-outline-offset-2 focus:outline-blue-600 sm:text-sm/6">
                                            </div>
                                        </div>
                                        <div>
                                            <div>
                                                <label for="location"
                                                    class="block text-sm/6 font-medium text-gray-900">Equipe
                                                    Responsável</label>
                                                <div class="mt-2 grid grid-cols-1">
                                                    <input type="text"
                                                    placeholder="Selecione uma Equipe"
                                                    
                                                    matInput
                                                    [formControl]="myControl"
                                                    [matAutocomplete]="auto">
                                             <mat-autocomplete 
                                             (optionSelected)="Nat_select_equipe($event)"
                                             autoActiveFirstOption #auto="matAutocomplete" >
                                               @for (option of filteredOptions | async; track option) {
                                                 <mat-option [value]="option">{{option}}</mat-option>
                                               }
                                             </mat-autocomplete>
                                                </div>
                                            </div>

                                         
                                              
                                               
                                              
                                              
                                             
                                              
                                        </div>

                                        <div>
                                            <label for="location"
                                                class="block text-sm/6 font-medium text-gray-900">Base de
                                                Origem</label>
                                            <div class="mt-2 grid grid-cols-1">
                                                <select (change)="Nat_select_base(select_base.value)" #select_base id="location" name="location"
                                                    class="col-start-1 row-start-1 w-full appearance-none rounded-md bg-white py-1.5 pl-3 pr-8 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6">
                                                    <option selected [value]="undefined">Selecione a Base de Partida</option>
                                                    @for(base of Nat_bases; track base){
                                                        <option [value]="base.ID">{{base.NOME}}</option>
                                                    }
                                                   
                                                   
                                        
                                                </select>
                                                <svg class="pointer-events-none col-start-1 row-start-1 mr-2 size-5 self-center justify-self-end text-gray-500 sm:size-4"
                                                    viewBox="0 0 16 16" fill="currentColor" aria-hidden="true"
                                                    data-slot="icon">
                                                    <path fill-rule="evenodd"
                                                        d="M4.22 6.22a.75.75 0 0 1 1.06 0L8 8.94l2.72-2.72a.75.75 0 1 1 1.06 1.06l-3.25 3.25a.75.75 0 0 1-1.06 0L4.22 7.28a.75.75 0 0 1 0-1.06Z"
                                                        clip-rule="evenodd" />
                                                </svg>
                                            </div>
                                        </div>

                                        <div>
                                            <label for="location"
                                                class="block text-sm/6 font-medium text-gray-900">Modal de Execução</label>
                                            <div class="mt-2 grid grid-cols-1">
                                                <select (change)="Nat_select_modal(select_base.value)" #select_modal id="location" name="location"
                                                    class="col-start-1 row-start-1 w-full appearance-none rounded-md bg-white py-1.5 pl-3 pr-8 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6">
                                                    <option selected value="">Selecione a Base de Partida</option>
                                                    <option  value="carro">Carro</option>
                                                    <option  value="moto">Moto</option>
                                                    <option  value="pe">A Pé</option>
                                                   
                                        
                                                </select>
                                                <svg class="pointer-events-none col-start-1 row-start-1 mr-2 size-5 self-center justify-self-end text-gray-500 sm:size-4"
                                                    viewBox="0 0 16 16" fill="currentColor" aria-hidden="true"
                                                    data-slot="icon">
                                                    <path fill-rule="evenodd"
                                                        d="M4.22 6.22a.75.75 0 0 1 1.06 0L8 8.94l2.72-2.72a.75.75 0 1 1 1.06 1.06l-3.25 3.25a.75.75 0 0 1-1.06 0L4.22 7.28a.75.75 0 0 1 0-1.06Z"
                                                        clip-rule="evenodd" />
                                                </svg>
                                            </div>
                                        </div>
                                        <!-- Adicionando o Datepicker -->


                                        <div class="relative max-w-sm">
                                            <!-- Ícone SVG com evento de clique -->
                                            <div class="absolute inset-y-0 start-0 flex items-center ps-3.5">
                                                <svg class="w-4 h-4 text-gray-500 dark:text-gray-400 cursor-pointer"
                                                    aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                                    fill="currentColor" viewBox="0 0 20 20" (click)="openDatepicker()">
                                                    <path
                                                        d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
                                                </svg>
                                            </div>

                                            <!-- Input do Datepicker -->
                                            <input (dateChange)="onDateChange($event)" #inp_data matInput [matDatepicker]="picker" placeholder="Escolha uma data"
                                                id="default-datepicker" type="text"
                                                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg
                                               focus:ring-blue-500 focus:border-blue-500 block w-full ps-10  dark:bg-gray-700
                                                dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                                            <mat-datepicker-toggle matSuffix [for]="picker"
                                                class="hidden"></mat-datepicker-toggle>
                                            <mat-datepicker #picker></mat-datepicker>
                                        </div>



                                        <div>



                                            <form class="max-w-full mx-auto grid grid-cols-2 gap-4 py-4">
                                                <div>
                                                    <label for="start-time"
                                                        class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Hora
                                                        Início:</label>
                                                    <div class="relative">
                                                        <div
                                                            class="absolute inset-y-0 end-0 top-0 flex items-center pe-3.5 pointer-events-none">
                                                            <svg class="w-4 h-4 text-gray-500 dark:text-gray-400"
                                                                aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                                                fill="currentColor" viewBox="0 0 24 24">
                                                                <path fill-rule="evenodd"
                                                                    d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm11-4a1 1 0 1 0-2 0v4a1 1 0 0 0 .293.707l3 3a1 1 0 0 0 1.414-1.414L13 11.586V8Z"
                                                                    clip-rule="evenodd" />
                                                            </svg>
                                                        </div>
                                                        <input (change)="Nat_calcular_roteiro()" #inp_hora_inicio type="time" id="start-time"
                                                            class="bg-gray-50 border leading-none border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                            min="09:00" max="18:00" value="00:00" required />
                                                    </div>
                                                </div>
                                                <div>
                                                    <label for="end-time"
                                                        class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Hora
                                                        Final:</label>
                                                    <div class="relative">
                                                        <div
                                                            class="absolute inset-y-0 end-0 top-0 flex items-center pe-3.5 pointer-events-none">
                                                            <svg class="w-4 h-4 text-gray-500 dark:text-gray-400"
                                                                aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                                                fill="currentColor" viewBox="0 0 24 24">
                                                                <path fill-rule="evenodd"
                                                                    d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm11-4a1 1 0 1 0-2 0v4a1 1 0 0 0 .293.707l3 3a1 1 0 0 0 1.414-1.414L13 11.586V8Z"
                                                                    clip-rule="evenodd" />
                                                            </svg>
                                                        </div>
                                                        <input #inp_hora_fim disabled type="time" id="end-time"
                                                            class="bg-gray-50 border leading-none border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                            min="09:00" max="18:00" value="00:00" required />
                                                    </div>
                                                </div>
                                            </form>

                                           


                                        </div>




                                        <div>
                                            <label for="project-name"
                                                class="block text-sm/6 font-medium text-gray-900">Duração</label>
                                            <div class="mt-2">
                                                <input #inp_duracao disabled type="text" name="project-name" id="project-name"
                                                    class="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline-2 focus:-outline-offset-2 focus:outline-blue-600 sm:text-sm/6">
                                            </div>
                                        </div>
                                        <div>
                                            <label for="project-name"
                                                class="block text-sm/6 font-medium text-gray-900">Percurso</label>
                                            <div class="mt-2">
                                                <input #inp_percurso disabled type="text" name="project-name" id="project-name"
                                                    class="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline-2 focus:-outline-offset-2 focus:outline-blue-600 sm:text-sm/6">
                                            </div>
                                        </div>
                                       



                                    </div>







                                </div>
                            </div>
                        </div>
                        <div class="flex shrink-0 justify-end px-4 py-4">
                            <button (click)="Nat_close(false)" type="button"
                                class="rounded-md bg-white px-3 py-2 text-sm font-semibold
                                 text-gray-900 shadow-sm ring-1 ring-inset
                                  ring-gray-300 hover:bg-gray-50">Fechar</button>

                             
                            <button (click)="Nat_atribuir_roteiro()" type="button"
                                class="ml-4 inline-flex justify-center 
                                rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold
                                 text-white shadow-sm hover:bg-blue-500 focus-visible:outline 
                                 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600">Salvar
                                Roteiro</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>