import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment.development';
import { RESPOSTA_PADRAO_SERVIDOR } from '../../../MODELS/RESPOSTA_PADRAO_SERVIDOR';
import { NEW_USER } from '../../../MODELS/NEW_USER';


@Injectable({
  providedIn: 'root'
})
export class CadastroService {

  constructor(private httpClient: HttpClient) { }

  //SOLICITA A LISTA DE EMPRESAS DISPONIVEIS
  async GET_EMPRESAS() {
    try {
      const response = await this.httpClient
        .get(`${environment.url_api_local}/GET_EMPRESAS`)
        .toPromise();


      return response;
    } catch (error) {
      console.log(error, 'erro');
      const retorno_padrao: RESPOSTA_PADRAO_SERVIDOR = {
        MSG: "ERRO",
        ADICIONAL: "",
        RESPOSTA: undefined
      }
      return retorno_padrao

    }
  }

  //SOLICITA A CRIAÇÃO DE UM NOVO USUARIO
  async POST_CADASTRAR_USER(body:{novo_user:NEW_USER,token:string}) {
    try {
      const response = await this.httpClient
        .post(`${environment.url_api_local}/POST_CADASTRAR_USER`, body)
        .toPromise();


      return response;
    } catch (error) {
      console.log(error, 'erro');
      const retorno_padrao: RESPOSTA_PADRAO_SERVIDOR = {
        MSG: "ERRO",
        ADICIONAL: "",
        RESPOSTA: undefined
      }
      return retorno_padrao

    }
  }

   //GERA UM TOKEN DE 2 MINUTOS PARA O EMAIL ROOT QUE LIBERA A CRIACAO DE UM USER DE FORMA DIRETA
   async GET_GERAR_TOKEN_CAD_USER () {
    try {
      const response = await this.httpClient
        .get(`${environment.url_api_local}/GET_GERAR_TOKEN_CAD_USER`)
        .toPromise();


      return response;
    } catch (error) {
      console.log(error, 'erro');
      const retorno_padrao: RESPOSTA_PADRAO_SERVIDOR = {
        MSG: "ERRO",
        ADICIONAL: "",
        RESPOSTA: undefined
      }
      return retorno_padrao

    }
  }

}
