<div class="relative z-30" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <div class="fixed z-10 w-screen overflow-y-auto">
        <div
            class="flex fixed bottom-0 left-0 h-auto w-full max-w-[20rem] items-end justify-start p-2 text-center sm:items-end sm:p-0">
            <div
                class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-4 text-left shadow-xl transition-all sm:my-4 sm:w-full sm:max-w-lg sm:p-6">
                <div>
                    <div class="text-start">
                        <p class="text-base font-semibold text-gray-900" id="modal-title">Parâmetros Atuais</p>
                        <div class="mt-2">
                            <!-- Input Range -->
                            <label for="steps-range"
                                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                Variação Aceita
                            </label>
                            <input id="steps-range" type="range" min="0" max="100" [value]="Nat_rangeValue" step="5"
                                (input)="Nat_onRangeChange($event)"
                                class="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer" />

                            <p class="text-sm text-gray-600 mt-2">Variação atual: {{ Nat_rangeValue }}%</p>


                            @if(Nat_tipo_parametro=="LIVRO"){
                            <!-- Input com Botão -->
                            <form class="max-w-lg mx-auto mt-4">
                                <div class="flex">
                                    <button (click)="Nat_onTipoChange()"
                                        class="flex-shrink-0 z-10 inline-flex items-center py-2.5 px-4 text-sm font-medium text-center text-gray-900 bg-gray-100 border border-gray-300 rounded-s-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100"
                                        type="button">
                                        Livros
                                    </button>
                                    <div class="relative w-full">
                                        <input type="number" id="search-dropdown" [value]="Nat_inputValue"
                                            (input)="Nat_onInputChange($event)"
                                            class="block p-2.5 w-full z-20 text-sm text-gray-900 bg-gray-50 rounded-e-lg border-s-gray-50 border-s-2 border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                                            placeholder="Quantidade..." required />
                                    </div>
                                </div>
                            </form>

                            <p class="text-sm text-gray-600 mt-2">Total Exato de Livros: {{ Nat_inputValue }}</p>
                            }@else if (Nat_tipo_parametro=="DURACAO") {
                            <!-- Input com Botão -->
                            <form class="max-w-lg mx-auto mt-4">
                                <div class="flex">
                                    <button (click)="Nat_onTipoChange()"
                                        class="flex-shrink-0 z-10 inline-flex items-center py-2.5 px-4 text-sm font-medium text-center text-gray-900 bg-gray-100 border border-gray-300 rounded-s-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100"
                                        type="button">
                                        Duração
                                    </button>
                                    <div class="relative w-full">
                                        <input type="number" id="search-dropdown" [value]="Nat_inputValue"
                                            (input)="Nat_onInputChange($event)"
                                            class="block p-2.5 w-full z-20 text-sm text-gray-900 bg-gray-50 rounded-e-lg border-s-gray-50 border-s-2 border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                                            placeholder="Quantidade..." required />
                                    </div>
                                </div>
                            </form>

                            <p class="text-sm text-gray-600 mt-2">Duração estimada de: {{ Nat_inputValue }} minutos</p>
                            }@else {
                            <!-- Input com Botão -->
                            <form class="max-w-lg mx-auto mt-4">
                                <div class="flex">
                                    <button (click)="Nat_onTipoChange()"
                                        class="flex-shrink-0 z-10 inline-flex items-center py-2.5 px-4 text-sm font-medium text-center text-gray-900 bg-gray-100 border border-gray-300 rounded-s-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100"
                                        type="button">
                                        Itens
                                    </button>
                                    <div class="relative w-full">
                                        <input type="number" id="search-dropdown" [value]="Nat_inputValue"
                                            (input)="Nat_onInputChange($event)"
                                            class="block p-2.5 w-full z-20 text-sm text-gray-900 bg-gray-50 rounded-e-lg border-s-gray-50 border-s-2 border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                                            placeholder="Quantidade..." required />
                                    </div>
                                </div>
                            </form>

                            <p class="text-sm text-gray-600 mt-2">Total estimado de Itens por Livro: {{
                                Nat_inputValue }}</p>
                            }



                            <div class="flex items-center mt-2">
                                <input id="link-checkbox" type="checkbox"  [checked]="Nat_checkboxValue" 
                                (change)="Nat_onCheckboxChange($event)"
                                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded
                                     focus:ring-blue-500 dark:focus:ring-blue-600
                                      dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                                <label for="link-checkbox"
                                    class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Considerar Quadras Estimadas na Divisão.</label>
                            </div>

                            

                        </div>
                        <div class="mt-2 sm:mt-6 sm:grid sm:grid-flow-row-dense ">
                          
                            <button type="button" (click)="Nat_send_dados()"
                             class="mt-3 inline-flex w-full justify-center
                              rounded-md bg-green-100 px-3 py-2 text-sm font-semibold
                               text-gray-600 shadow-sm ring-1 ring-inset ring-green-300
                                hover:bg-green-50 sm:col-start-1 sm:mt-0">Salvar Parâmetros</button>
                          </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>