import { COORDENADA } from "./COORDENADA"

export class CAMINHO_ROTEIRO {
    OPERACAO:string
    LIVRO:string
    LINHA_BG:L.Polyline
    LINHA_MOVEL:L.Polyline
    LINHA:L.Polyline
    COORDENADA_ATUAL:COORDENADA
    CONTADOR:any
    VISIVEL:boolean
}