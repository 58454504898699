import { Component, EventEmitter, Input, input, Output } from '@angular/core';

@Component({
  selector: 'app-gpx-ferramentas',
  standalone: true,
  imports: [],
  templateUrl: './gpx-ferramentas.component.html',
  styleUrl: './gpx-ferramentas.component.css'
})
export class GpxFerramentasComponent {

 //a variavel enviada nao é usada, apenas o disparo do evento, todo o processo é feito no modulo pai
  @Output() Evento_change_type_MAPA = new EventEmitter<any>();
/**
 * envia o estado atual da selecao manual de coordenada
 */
  @Output() Evento_change_state_COLETA_COORDENADA = new EventEmitter<boolean>();

  /**
 * envia o estado atual da transferencia de itens
 */
  @Output() Evento_change_state_TRANSFERENCIA = new EventEmitter<boolean>();

  /**
   * recebe via input o ultimo estado salvo da coleta de coordenada
   */

  @Input() Input_estado_coleta_coordenada:boolean = false

  /**recebe via input o ultimo estado salvo da transferencia de itens */

  @Input() Input_estado_transferencia_itens:boolean = false
 
  Nat_change_type_map(){
    /**altera o tipo de mapa */
    this.Evento_change_type_MAPA.emit(true)
  }
 
  Nat_change_state_coleta_coordenada(){
    /**altera o estado da coleta de coordenada manual */
    this.Input_estado_coleta_coordenada = !this.Input_estado_coleta_coordenada
    this.Evento_change_state_COLETA_COORDENADA.emit(this.Input_estado_coleta_coordenada)
  }

  Nat_change_state_transferencia_itens(){
    /**altera o estado da transferencia de itens */
    this.Input_estado_transferencia_itens = !this.Input_estado_transferencia_itens
    this.Evento_change_state_TRANSFERENCIA.emit(this.Input_estado_transferencia_itens)
  }

}
