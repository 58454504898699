<div class="relative isolate z-50 shadow w-screen">
    <div class="absolute w-full inset-x-0 top-16 -z-10 bg-white pt-2 shadow-lg ring-1 ring-gray-900/5">
      <div class="mx-auto grid max-w-full w-full grid-cols-1 gap-x-8 gap-y-4 px-2 py-2 lg:grid-cols-1 lg:px-2 ">
  
  
        <form class="max-w-lg mt-2 ml-1 ">
  
          <div class="relative w-full">
            <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Mia" #input type="text" id="inp_filtro"
              class="block p-2.5 w-full z-20 text-sm text-gray-900 bg-gray-50 rounded-e-lg border-s-2 border border-gray-300 focus:ring-blue-500 focus:border-blue-500 "
              placeholder="Buscar Operações" required />
            <button type="button" (click)="Nat_clear_filter()"
              class="absolute top-0 end-0 p-2.5 text-sm font-medium h-full text-white bg-red-700 rounded-e-lg border border-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 ">
  
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                stroke="currentColor" class="w-4 h-4">
                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
              </svg>
  
            </button>
  
          </div>
        </form>
  
  
  
  
        <div class="mat-elevation-z8">
          <table mat-table [dataSource]="dataSource" matSort>
  
            <!-- ID Column -->
            <ng-container matColumnDef="acoes">
              <th mat-header-cell *matHeaderCellDef> #</th>
              <td mat-cell *matCellDef="let row">
    

  
                @if(!row.VISIVEL){
                  <div class="p-2 space-x-2">
                   
                      <span
                      (click)="Nat_open_operacao(row)"
                        class="inline-flex items-center
                         rounded-md bg-green-50 px-2 py-1
                          text-xs font-medium text-green-700
                           ring-1 ring-inset ring-green-600/20 cursor-pointer hover:underline">Abrir</span>
                           <span
                           (click)="Nat_delete_operacao(row.ID)"
                             class="inline-flex items-center
                              rounded-md bg-orange-50 px-2 py-1
                               text-xs font-medium text-orange-700
                                ring-1 ring-inset ring-orange-600/20 cursor-pointer hover:underline">Deletar</span>
                     
     
                           <span
                           (click)="Nat_envia_divix(row.ID)"
                             class="inline-flex items-center
                              rounded-md bg-blue-50 px-2 py-1
                               text-xs font-medium text-blue-700
                                ring-1 ring-inset ring-blue-600/20 cursor-pointer hover:underline">Enviar ao Divix</span>
                   
                  </div>
                }@else {
                  <div class="p-2 space-x-2">
                  
                      <span
                      (click)="Nat_close_operacao(row)"
                        class="inline-flex items-center
                         rounded-md bg-red-50 px-2 py-1
                          text-xs font-medium text-red-700
                           ring-1 ring-inset ring-red-600/20 cursor-pointer hover:underline">Fechar</span>
                           <span
                           (click)="Nat_delete_operacao(row.ID)"
                             class="inline-flex items-center
                              rounded-md bg-orange-50 px-2 py-1
                               text-xs font-medium text-orange-700
                                ring-1 ring-inset ring-orange-600/20 cursor-pointer hover:underline">Deletar</span>
                     
     
                           <span
                           (click)="Nat_envia_divix(row.ID)"
                             class="inline-flex items-center
                              rounded-md bg-blue-50 px-2 py-1
                               text-xs font-medium text-blue-700
                                ring-1 ring-inset ring-blue-600/20 cursor-pointer hover:underline">Enviar ao Divix</span>
                    
                  </div>
                }

             
                    
                    
                  
                
               
                
              </td>
            </ng-container>
  
            <!-- ID Column -->
            <ng-container matColumnDef="ID_STR">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
              <td mat-cell *matCellDef="let row"> {{row.ID_STR}} </td>
            </ng-container>
  
            <!-- Progress Column -->
            <ng-container matColumnDef="CENTRO_LEITURA_STR">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> CL </th>
              <td mat-cell *matCellDef="let row"> {{row.CENTRO_LEITURA_STR}}</td>
            </ng-container>
  
            <!-- Name Column -->
            <ng-container matColumnDef="GRUPO_STR">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> GRUPO </th>
              <td mat-cell *matCellDef="let row"> {{row.GRUPO_STR}} </td>
            </ng-container>
  
            <!-- Fruit Column -->
            <ng-container matColumnDef="QUANT_ITENS">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> ITENS </th>
              <td mat-cell *matCellDef="let row"> {{row.QUANT_ITENS}} </td>
            </ng-container>
  
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  
            <!-- Row shown when there is no matching data. -->
            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="4">Nenhum Resultado para a Busca "{{input.value}}"</td>
            </tr>
          </table>
  
          <mat-paginator [pageSizeOptions]="[3]" aria-label="Select page of users"></mat-paginator>
        </div>
  
  
  
  
  
  
  
  
  
  
  
  
  
      </div>
  
    </div>
  </div>