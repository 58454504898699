<div class="relative z-50" role="dialog" aria-modal="true">
  <!-- Background backdrop, show/hide based on slide-over state. -->
  <div class="fixed inset-0"></div>

  <div class="fixed inset-0 overflow-hidden">
    <div class="absolute inset-0 overflow-hidden">
      <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">

        <div class="pointer-events-auto w-screen max-w-[25rem]">
          <div class="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
            <div class="px-4 py-6 sm:px-6">
              <div class="flex items-start justify-between">
                <p id="slide-over-heading" class="text-base font-semibold text-gray-900">Perfil de Usuário</p>
                <div class="ml-3 flex h-7 items-center">
                  <button (click)="Nat_close_perfil()" type="button"
                    class="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-blue-500">
                    <span class="absolute -inset-2.5"></span>
                    <span class="sr-only">Close panel</span>
                    <svg class="size-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                      aria-hidden="true" data-slot="icon">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <!-- Main -->
            <div>
              <div class="pb-1 sm:pb-6">
                <div>
                  <div class="relative h-40 sm:h-56">
                    @if(fotoBase64){
                      <img class="absolute size-full object-cover"
                      [src]="fotoBase64" alt="">
                      
                    }@else {
                      <img class="absolute size-full object-cover"
                      src="https://res.cloudinary.com/de74yvifw/image/upload/v1729701207/icon_user_jrft7l.svg" alt="">
                    }

                   
                  </div>
                  <div class="mt-6 px-4 sm:mt-8 sm:flex sm:items-end sm:px-6">
                    <div class="sm:flex-1">
                      <div>
                        <div class="flex items-center">
                          <p class="text-xl font-bold text-gray-900 sm:text-2xl">{{user.NOME}}</p>
                          <span class="ml-2.5 inline-block size-2 shrink-0 rounded-full bg-green-400">
                            <span class="sr-only">Online</span>
                          </span>
                        </div>
                        <p class="text-sm text-gray-500">{{user.EMAIL}}</p>
                      </div>
                      <div class="mt-5 flex flex-wrap space-y-3 sm:space-x-3 sm:space-y-0">
                        <input type="file" #fileInput (change)="onFileSelected($event)"
                        accept="image/*" style="display: none;" />
                        <button (click)="fileInput.click();" type="button" class="inline-flex w-full shrink-0
                           items-center justify-center rounded-md bg-blue-600 px-3
                            py-2 text-sm font-semibold text-white shadow-sm
                             hover:bg-blue-500 focus-visible:outline focus-visible:outline-2
                              focus-visible:outline-offset-2 focus-visible:outline-blue-600 
                              sm:flex-1">Alterar Foto</button>

                        <button type="button" class="inline-flex w-full flex-1
                           items-center justify-center rounded-md bg-white px-3 py-2 
                           text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset
                            ring-gray-300 hover:bg-gray-50">Suporte</button>
                        <div class="ml-3 inline-flex sm:ml-0">
                          <div class="relative inline-block text-left">
                            <button (click)="Nat_deslogar()" type="button"
                              class="relative inline-flex items-center rounded-md bg-white p-2 text-gray-400 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                              id="options-menu-button" aria-expanded="false" aria-haspopup="true">
                              <span class="absolute -inset-1"></span>
                              <span class="sr-only">Open options menu</span>
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                stroke="red" class="size-6">
                                <path stroke-linecap="round" stroke-linejoin="round"
                                  d="M15.75 9V5.25A2.25 2.25 0 0 0 13.5 3h-6a2.25 2.25 0 0 0-2.25 2.25v13.5A2.25 2.25 0 0 0 7.5 21h6a2.25 2.25 0 0 0 2.25-2.25V15M12 9l-3 3m0 0 3 3m-3-3h12.75" />
                              </svg>

                            </button>


                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>