import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment.development';
import { RESPOSTA_PADRAO_SERVIDOR } from '../MODELS/RESPOSTA_PADRAO_SERVIDOR';
import { COORDENADA } from '../MODELS/COORDENADA';

@Injectable({
  providedIn: 'root'
})
export class MainService {

  constructor(private httpClient: HttpClient) { }


  
  //DELETA UMA OPERACAO E SEUS ITENS
  async POST_DELETE_OPERACAO(operacao_id: number) {
    try {
      const response = await this.httpClient
        .post(`${environment.url_api_local}/POST_DELETE_OPERACAO`, { operacao_id: operacao_id })
        .toPromise();


      return response;
    } catch (error) {
      console.log(error, 'erro');
      const retorno_padrao: RESPOSTA_PADRAO_SERVIDOR = {
        MSG: "ERRO",
        ADICIONAL: "",
        RESPOSTA: undefined
      }
      return retorno_padrao

    }
  }
  async POST_DELETE_OPERACAO_ROUTEX(operacao_id: number) {
    try {
      const response = await this.httpClient
        .post(`${environment.url_api_local}/POST_DELETE_OPERACAO_ROUTEX`, { operacao_id: operacao_id })
        .toPromise();


      return response;
    } catch (error) {
      console.log(error, 'erro');
      const retorno_padrao: RESPOSTA_PADRAO_SERVIDOR = {
        MSG: "ERRO",
        ADICIONAL: "",
        RESPOSTA: undefined
      }
      return retorno_padrao

    }
  }
  


    //VERIFICA QUAIS INSTALACOES ESTÃO DENTRO DO POLIGONO
    async POST_MARKER_IN_POLIGONO(coordenadas_polygon: COORDENADA[],instalacoes_ids:number[],modulo:string) {
      try {
        const response = await this.httpClient
          .post(`${environment.url_api_local}/POST_MARKER_IN_POLIGONO`, { coordenadas_polygon: coordenadas_polygon,instalacoes_ids:instalacoes_ids,modulo:modulo })
          .toPromise();
  
  
        return response;
      } catch (error) {
        console.log(error, 'erro');
        const retorno_padrao: RESPOSTA_PADRAO_SERVIDOR = {
          MSG: "ERRO",
          ADICIONAL: "",
          RESPOSTA: undefined
        }
        return retorno_padrao
  
      }
    }



        /**ALTERAR UMA IMAGEM DE PERFIL DO USUARIO*/
        async POST_SAVE_IMG_PERFIL(BODY:FormData) {
          try {
            const response = await this.httpClient
              .post(`${environment.url_api_local}/POST_SAVE_IMG_PERFIL`, BODY)
              .toPromise();
      
      
            return response;
          } catch (error) {
            console.log(error, 'erro');
            const retorno_padrao: RESPOSTA_PADRAO_SERVIDOR = {
              MSG: "ERRO",
              ADICIONAL: "",
              RESPOSTA: undefined
            }
            return retorno_padrao
            throw error;
          }
        }


  async POST_ENCONTRAR_LOGRADOURO(body:{
    empresa_id:number,
    logradouro:string,
    modulo:"GPX"|"DIVIX"|"ROUTEX"
}) {
    try {
      const response = await this.httpClient
        .post(`${environment.url_api_local}/POST_ENCONTRAR_LOGRADOURO`, body)
        .toPromise();


      return response;
    } catch (error) {
      console.log(error, 'erro');
      const retorno_padrao: RESPOSTA_PADRAO_SERVIDOR = {
        MSG: "ERRO",
        ADICIONAL: "",
        RESPOSTA: undefined
      }
      return retorno_padrao

    }
  }






  async POST_ENCONTRAR_INSTALACAO(body:{
    empresa_id:number,
    instalacao_buscada:string,
    modulo:"GPX"|"DIVIX"|"ROUTEX"
}) {
    try {
      const response = await this.httpClient
        .post(`${environment.url_api_local}/POST_ENCONTRAR_INSTALACAO`, body)
        .toPromise();


      return response;
    } catch (error) {
      console.log(error, 'erro');
      const retorno_padrao: RESPOSTA_PADRAO_SERVIDOR = {
        MSG: "ERRO",
        ADICIONAL: "",
        RESPOSTA: undefined
      }
      return retorno_padrao

    }
  }
}
