<div class="h-screen w-screen">



    <div class="absolute z-50 right-0 w-[4rem] bottom-8">
@if(Nat_state_markers_prioridades == true){

    <button title="Mostrar Indicação de Itens Corrigidos e Suspeitos" (click)="Nat_change_state_prioridades()" type="button" class="text-white bg-gradient-to-r from-red-400 via-red-500
    to-red-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none
     focus:ring-red-300 dark:focus:ring-red-800 font-medium 
     rounded-full text-sm px-2.5 py-2.5 text-center me-2 mb-2">
    
     <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
        <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z" />
      </svg>
      
    </button>
}@else {
    <button title="Ocultar Indicação de Itens Corrigidos e Suspeitos" (click)="Nat_change_state_prioridades()" type="button" class="text-white bg-gradient-to-r from-green-400
          via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4
           focus:outline-none focus:ring-green-300
            dark:focus:ring-green-800 font-medium rounded-full
             text-sm px-2.5 py-2.5 text-center me-2 mb-2">
             <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z" />
              </svg>
            </button>
}

   
     
    </div>

    <div class="absolute z-50 left-2 w-[20rem] top-20 ">
   
<button id="dropdownDefaultButton" data-dropdown-toggle="dropdown_logradouro" 
class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none
 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex 
 items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" type="button">Logradouros<svg class="w-2.5 h-2.5 ms-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4"/>
    </svg>
    </button>
    
    <!-- Dropdown menu -->
    <div id="dropdown_logradouro" class="z-50 left-2 hidden bg-white divide-y divide-gray-100 rounded-lg shadow-sm w-[20rem] dark:bg-gray-700">
        <ul class="py-2 text-sm text-gray-700 dark:text-gray-200 max-h-[20rem] overflow-y-auto" aria-labelledby="dropdownDefaultButton">
            
            @for(logradouro of logradouros_localizados;track logradouro){
                <li>
                    @if(logradouro.VALIDADO==true){
                        <a (click)="view_vizinhos(logradouro)" (dblclick)="validate_logradouro(logradouro)" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white text-green-600">{{logradouro.LOGRADOURO}}</a>
                    }@else {
                        <a (click)="view_vizinhos(logradouro)" (dblclick)="validate_logradouro(logradouro)" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white text-red-600">{{logradouro.LOGRADOURO}}</a>
                    }
                    
                  </li>
            }
           
        
        </ul>
    </div>
    
    </div>

    <!--navbar menu principal-->
    <app-navbar-main 
        [Nat_state_DISPONIVEIS]="Nat_state_DISPONIVEIS" 
        [Nat_state_EDICOES]="Nat_state_EDICOES"
        [Nat_state_FERRAMENTAS]="Nat_state_FERRAMENTAS"
        (Evento_close_perfil)="Out_change_state_perfil($event)"
        (Evento_change_state_DISPONIVEIS)="Out_change_aba_DISPONIVEIS($event)"
        (Evento_change_state_EDICOES)="Out_change_aba_EDICOES($event)"
        (Evento_change_state_FERRAMENTAS)="Out_change_aba_FERRAMENTAS($event)"
        (Evento_change_state_MODULOS)="Out_change_aba_MODULOS($event)"
        (Evento_local_buscado)="Out_move_local_buscado($event)"
        (Evento_markers_temporarios)="Out_cria_markers_temporarios($event)"
        ></app-navbar-main>




    <!--tabela de disponiveis-->
    @if(Nat_state_DISPONIVEIS){
    <app-gpx-disponiveis 
        [input_ids_operacoes_mapa]="Nat_ids_operacoes_mapa_disponiveis"
        [input_ids_operacoes_mapa_edicoes]="Nat_ids_operacoes_mapa_edicoes"

        (Evento_open_operacao)="Out_open_operacao_disponivel($event)"
        (Evento_close_operacao)="Out_close_operacao_disponivel($event)"

        (Evento_open_loading)="Out_open_loading($event)"
        (Evento_close_loading)="Out_close_loading($event)"
        (Evento_open_alert_sucess)="Out_open_alert_sucess($event)"
        (Evento_open_alert_erro)="Out_open_alert_erro($event)"
        ></app-gpx-disponiveis>
    }

    <!--tabela de edicoes-->
    @if(Nat_state_EDICOES){
    <app-gpx-edicoes 
        [input_ids_operacoes_mapa]="Nat_ids_operacoes_mapa_edicoes"
        [input_ids_operacoes_mapa_disponiveis]="Nat_ids_operacoes_mapa_disponiveis"

        (Evento_open_operacao)="Out_open_operacao_edicoes($event)"
        (Evento_close_operacao)="Out_close_operacao_edicoes($event)"
        
        (Evento_open_loading)="Out_open_loading($event)"
        (Evento_close_loading)="Out_close_loading($event)"
        (Evento_open_alert_sucess)="Out_open_alert_sucess($event)"
        (Evento_open_alert_erro)="Out_open_alert_erro($event)"
        ></app-gpx-edicoes>
    }

    <!--tabela de ferramentas-->
    @if(Nat_state_FERRAMENTAS){
    <app-gpx-ferramentas 
        [Input_estado_coleta_coordenada]="Nat_state_coleta_coordenada"
        [Input_estado_transferencia_itens]="Nat_state_transferencia_itens"
        (Evento_change_type_MAPA)="Out_change_map_type($event)"
        (Evento_change_state_COLETA_COORDENADA)="Out_change_state_coleta_coordenada($event)"
        (Evento_change_state_TRANSFERENCIA)="Out_change_transferencia($event)"
        
        ></app-gpx-ferramentas>
    }

    @if(Nat_state_MODULOS){
        <app-modulos>
            
        </app-modulos>
    }


    @if(Nat_state_perfil){
        <app-perfil
        
        (Evento_open_loading)="Out_open_loading($event)"
        (Evento_close_loading)="Out_close_loading($event)"
        (Evento_open_alert_sucess)="Out_open_alert_sucess($event)"
        (Evento_open_alert_erro)="Out_open_alert_erro($event)"
        
        (Evento_close_perfil)="Out_change_state_perfil($event)"
        >
        </app-perfil>
    }
<!--detalhes do poligono selecionado, seja de selacao para transferencia seja de tarefa-->
@if(Nat_state_detalhes_poligono){
    <app-gpx-detalhes-poligono
    [Input_poligono_recebido]="Nat_detalhes_poligono"
    (Evento_close_detalhes_poligono)="Out_change_state_detalhes_poligono($event)"

    (Evento_open_loading)="Out_open_loading($event)"
    (Evento_close_loading)="Out_close_loading($event)"
    (Evento_open_alert_sucess)="Out_open_alert_sucess($event)"
    (Evento_open_alert_erro)="Out_open_alert_erro($event)"

    (Evento_atualiza_mapa)="Out_repoe_mapa_pos_edicao($event)"
 
    
    ></app-gpx-detalhes-poligono>
}



    <div id="map_gpx" class="h-full w-full z-10">
    </div>


    <!--loading-->
    @if(Nat_state_loading){
    <app-alert-loading></app-alert-loading>
    }



    <!--alert de sucesso-->
    @if(Nat_status_alert_sucesso){

    <app-alert-sucesso [Input_dados_alert]="Nat_dados_alert"
        (Evento_close_alert_sucesso)="Out_close_alert_sucesso($event)">
    </app-alert-sucesso>
    }
    <!--alert de erro-->
    @if(Nat_status_alert_erro){

    <app-alert-erro [Input_dados_alert]="Nat_dados_alert" (Evento_close_alert_erro)="Out_close_alert_erro($event)">
    </app-alert-erro>
    }
</div>