import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';

import * as L from 'leaflet';
import 'leaflet.markercluster';
import 'leaflet-draw';
import 'leaflet-editable';
import { AlertLoadingComponent } from '../../../../COMPONENTS_UI/alert-loading/alert-loading.component';


import { DADOS_ALERT } from '../../../../MODELS/DADOS_ALERT';
import { AlertSucessoComponent } from "../../../../COMPONENTS_UI/alert-sucesso/alert-sucesso.component";
import { AlertErroComponent } from "../../../../COMPONENTS_UI/alert-erro/alert-erro.component";
import { OPERACAO_ou_LIVRO } from '../../../../MODELS/OPERACAO_ou_LIVRO';
import { RESPOSTA_PADRAO_SERVIDOR } from '../../../../MODELS/RESPOSTA_PADRAO_SERVIDOR';

import { INSTALACAO_BD } from '../../../../MODELS/INSTALACAO_BD';
import { COORDENADA } from '../../../../MODELS/COORDENADA';

import hull from 'hull.js';
import { MainService } from '../../../../SERVICES/main.service';

import { DETALHES_POLIGONO } from '../../../../MODELS/DETALHES_POLIGONO';
import { environment } from '../../../../../environments/environment.development';


import { GpxFerramentasComponent } from '../../../GPX/COMPONENTS/gpx-ferramentas/gpx-ferramentas.component';

import { DivixDetalhesPoligonoComponent } from '../../../../COMPONENTS_UI/divix-detalhes-poligono/divix-detalhes-poligono.component';

import { DADOS_PARAMETRO } from '../../../../MODELS/DADOS_PARAMETRO';
import { OSRM_TRIP_RETURN } from '../../../../MODELS/OSRM_TRIP_RETORNO';
import { DivixParametrosAnaliseComponent } from '../../../DIVIX/COMPONENTS/divix-parametros-analise/divix-parametros-analise.component';

import { DivixService } from '../../../DIVIX/SERVICES/divix.service';
import { NavbarRoutexComponent } from '../../../../COMPONENTS_UI/navbar/navbar-routex/navbar-routex.component';
import { RoutexLivrosComponent } from '../routex-livros/routex-livros.component';
import { RoutexEdicoesComponent } from '../routex-edicoes/routex-edicoes.component';
import { CAMINHO_ROTEIRO } from '../../../../MODELS/CAMINHO_ROTEIRO';
import { RoutexService } from '../../SERVICES/routex.service';
import { RoutexNovoInicioComponent } from "../routex-novo-inicio/routex-novo-inicio.component";
import { ModulosComponent } from '../../../../COMPONENTS_UI/modulos/modulos.component';
import { PerfilComponent } from '../../../../COMPONENTS_UI/perfil/perfil.component';



@Component({
  selector: 'app-routex-main',
  standalone: true,
  imports: [
    PerfilComponent,
    ModulosComponent,
    AlertLoadingComponent,
    RoutexLivrosComponent,
    RoutexEdicoesComponent,
    GpxFerramentasComponent,
    AlertSucessoComponent,
    AlertErroComponent,
    DivixDetalhesPoligonoComponent,
    NavbarRoutexComponent,
    DivixParametrosAnaliseComponent,
    RoutexNovoInicioComponent
],
  templateUrl: './routex-main.component.html',
  styleUrl: './routex-main.component.css'
})
export class RoutexMainComponent implements OnInit {
  private leaflet = (window as any).L; // Referência ao Leaflet com MarkerCluster

  map!: L.Map;

  Nat_dados_alert?: DADOS_ALERT

  Nat_state_loading: boolean = false

  Nat_status_alert_sucesso: boolean = false
  Out_close_alert_sucesso(status: boolean) {//fecha o alerta de sucesso
    this.Nat_status_alert_sucesso = status
    this.Nat_dados_alert = undefined
  }
  Nat_status_alert_erro: boolean = false
  Out_close_alert_erro(status: boolean) {//fecha o alerta de erro
    this.Nat_status_alert_erro = status
    this.Nat_dados_alert = undefined
  }

  Nat_state_new_inicio:boolean = false


  constructor(private router: Router, private service: RoutexService, private service_main: MainService) { }

  ngOnInit() {



    if (environment.user_atual) {
      this.initMap();
    } else {
      this.router.navigateByUrl('')
    }

  }


  Nat_normalMap: L.TileLayer
  Nat_satelliteMap: L.TileLayer
  Nat_simplifiedMap: L.TileLayer
  Nat_activeLayer: L.TileLayer


  Nat_draw_control: L.Control.Draw //recebe o controle de criacao de poligonos temporarios para seleção e transferencia de itens


  Nat_polygons_transferencia: L.Polygon[] = []

  initMap() {
    if(!this.map){
    // Inicializando o mapa com coordenadas e nível de zoom
    this.map = this.leaflet.map('map_routex', {
      center: [-14.2350, -51.9253],  // Coordenadas do centro inicial do mapa
      zoom: 5,  // Nível de zoom inicial
      maxZoom: 18,  // Definindo o zoom máximo
      minZoom: 3,  // Definindo o zoom mínimo
      editable: true, // Habilita a edição no mapa

    });
    }


    navigator.geolocation.getCurrentPosition(
      (position) => {
        const userLat = position.coords.latitude;
        const userLng = position.coords.longitude;


        this.map.setView([userLat, userLng])

      })

    //clicar no mapa
    this.map.on('click', (event) => {
      if (this.Nat_state_coleta_coordenada) {
        /**
         * se a funcao de copia de coordenada manual estiver ativa chama a funcao que copia para a 
         * area de transferencia a coordenada
         */

        const latlng = event.latlng;
        const coordenada_coletada = latlng


        this.Nat_copy_value(`${coordenada_coletada.lat},${coordenada_coletada.lng}`)
      }
    })



    // Remover o controle de zoom
    this.map.zoomControl.remove()


    // Inicializando o controle de desenho com edição habilitada
    this.Nat_draw_control = new L.Control.Draw({
      position: 'bottomright',

      draw: {
        polyline: false, // Desativando linha poligonal
        polygon: {
          shapeOptions: {
            color: 'blue', // Cor do polígono
            weight: 5, // Espessura da borda
            opacity: 0.5, // Opacidade da borda
            fillOpacity: 0.2, // Opacidade de preenchimento
            fillColor: 'blue', // Cor de preenchimento
          },
        },
        rectangle: false, // Desativando a criação de retângulos
        circle: false, // Desativando a criação de círculos
        marker: false, // Desativando a criação de marcadores
        circlemarker: false
      },
    });





    this.map.on(L.Draw.Event.CREATED, (e: any) => {
      //limpando os dados de poligono selecionado
      this.Nat_detalhes_poligono = undefined

      const type = e.layerType,
        layer = e.layer;

      if (type === 'polygon') {
        // Verifica se já existe algum polígono no array e o remove
        if (this.Nat_polygons_transferencia.length > 0) {
          // Remover o polígono antigo do mapa
          const oldLayer = this.Nat_polygons_transferencia.pop(); // Remove o último polígono
          this.map.removeLayer(oldLayer); // Remove do mapa
        }

        // Habilitando a edição do polígono
        this.map.addLayer(layer);
        layer.enableEdit();
        this.Nat_polygons_transferencia.push(layer);



        // Evento de clique no polígono
        layer.on('click', async () => {


          // Encontrar o índice do polígono no array
          const index = this.Nat_polygons_transferencia.indexOf(layer);

          let ids_instalacoes_mapa: number[] = this.Nat_operacoes_mapa_edicoes
            .map(operacao => operacao.ITENS.map(item => item.ID)) // Mapeia os IDs de cada item
            .flat(); // Achata o array de arrays em um único array de números



          // Obtendo as coordenadas do polígono
          const latLngs = layer.getLatLngs(); // Isso retorna as coordenadas dos vértices do polígono

          // Transformando as coordenadas em um formato desejado (ex: [lat, lng])
          const coordenadas_poligono: COORDENADA[] = latLngs[0].map((latLng: L.LatLng) => ({
            lat: latLng.lat,
            lng: latLng.lng
          }));

          const requisicao_server: any = await this.service_main.POST_MARKER_IN_POLIGONO(coordenadas_poligono, ids_instalacoes_mapa, 'ZONE');
          const resposta: RESPOSTA_PADRAO_SERVIDOR = requisicao_server;

          if (resposta.MSG == 'SUCESSO') {


            let detalhes_poligono: DETALHES_POLIGONO = {
              OPERACAO_ID: undefined,
              CENTRO_LEITURA: undefined,
              GRUPO: undefined,
              LIVRO: undefined,
              DURACAO: undefined,
              TOTAL_ITENS: resposta.RESPOSTA.length,
              OPERACAO_ID_DEST: undefined,
              LIVRO_DEST: undefined,
              TIPO: 'SELECAO',
              MODULO: 'DIVIX',
              ITENS_ID: resposta.RESPOSTA,
              ID_INICIO: 0,
              DISTANCIA: undefined
            }

            this.Nat_detalhes_poligono = detalhes_poligono

            this.Nat_state_detalhes_poligono = true


          } else {
            this.Nat_state_loading = false
            let new_alert: DADOS_ALERT = {
              TITULO: 'ERRO',
              CORPO: resposta.ADICIONAL
            };
            this.Nat_dados_alert = new_alert;
            this.Nat_status_alert_erro = true;
          }
        });

        // Evento de edição do polígono (quando o vértice for movido)
        layer.on('editable:vertex:dragend', async () => {

          // Encontrar o índice do polígono no array
          const index = this.Nat_polygons_transferencia.indexOf(layer);

          let ids_instalacoes_mapa: number[] = this.Nat_operacoes_mapa_edicoes
            .map(operacao => operacao.ITENS.map(item => item.ID)) // Mapeia os IDs de cada item
            .flat(); // Achata o array de arrays em um único array de números



          // Obtendo as coordenadas do polígono
          const latLngs = layer.getLatLngs(); // Isso retorna as coordenadas dos vértices do polígono

          // Transformando as coordenadas em um formato desejado (ex: [lat, lng])
          const coordenadas_poligono: COORDENADA[] = latLngs[0].map((latLng: L.LatLng) => ({
            lat: latLng.lat,
            lng: latLng.lng
          }));

          const requisicao_server: any = await this.service_main.POST_MARKER_IN_POLIGONO(coordenadas_poligono, ids_instalacoes_mapa, 'ZONE');
          const resposta: RESPOSTA_PADRAO_SERVIDOR = requisicao_server;

          if (resposta.MSG == 'SUCESSO') {


            let detalhes_poligono: DETALHES_POLIGONO = {
              OPERACAO_ID: undefined,
              CENTRO_LEITURA: undefined,
              GRUPO: undefined,
              LIVRO: undefined,
              DURACAO: undefined,
              TOTAL_ITENS: resposta.RESPOSTA.length,
              OPERACAO_ID_DEST: undefined,
              LIVRO_DEST: undefined,
              TIPO: 'SELECAO',
              MODULO: 'DIVIX',
              ITENS_ID: resposta.RESPOSTA,
              ID_INICIO: 0,
              DISTANCIA: undefined
            }

            this.Nat_detalhes_poligono = detalhes_poligono

            this.Nat_state_detalhes_poligono = true


          } else {
            this.Nat_state_loading = false
            let new_alert: DADOS_ALERT = {
              TITULO: 'ERRO',
              CORPO: resposta.ADICIONAL
            };
            this.Nat_dados_alert = new_alert;
            this.Nat_status_alert_erro = true;
          }
        });
      }
    });
    this.map.on(L.Draw.Event.DRAWSTART, (e: any) => {

      //limpando os dados de poligono selecionado
      this.Nat_detalhes_poligono = undefined

      if (this.Nat_polygons_transferencia.length > 0) {
        // Remover o polígono antigo do mapa
        const oldLayer = this.Nat_polygons_transferencia.pop(); // Remove o último polígono
        this.map.removeLayer(oldLayer); // Remove do mapa
      }

    })


    // Definindo as camadas de mapa
    this.Nat_normalMap = this.leaflet.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    });

    this.Nat_satelliteMap = this.leaflet.tileLayer('https://services.arcgisonline.com/arcgis/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', {
      attribution: 'Tiles © Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
    });

    this.Nat_simplifiedMap = this.leaflet.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}', {
      attribution: 'Tiles &copy; Esri &mdash; Esri, DeLorme, NAVTEQ',
    });

    // Adicionando o mapa normal inicialmente
    this.Nat_normalMap.addTo(this.map);

    // Inicializando a camada ativa
    this.Nat_activeLayer = this.Nat_normalMap;



  }

  Nat_change_state_novo_inicio(state:boolean){
    this.Nat_state_new_inicio = state
  }

  Nat_parametros: DADOS_PARAMETRO

  Out_change_parametros(parametros: DADOS_PARAMETRO) {
    /**Cconforme o usuario altera os parametros que considera ideais para seus livros altera aqui via output */
    this.Nat_parametros = parametros


    this.Nat_state_loading = false
    let new_alert: DADOS_ALERT = {
      TITULO: 'SUCESSO',
      CORPO: 'Parãmetros Alterados com Sucesso!'
    };
    this.Nat_dados_alert = new_alert;
    this.Nat_status_alert_sucesso = true;
  }

  Out_muda_visao_livros(tipo: string) {
    /** SIMPLES ou ANALITICA */

    if (this.Nat_operacoes_mapa_edicoes.length > 0 && this.Nat_parametros) {

      if (tipo === "ANALITICA") {
        const variacao = this.Nat_parametros.VARIACAO;
        const quantidade = this.Nat_parametros.QUANTIDADE;

        console.log(this.Nat_parametros);

        this.Nat_operacoes_mapa_edicoes.forEach((livro, l) => {
          if (this.Nat_parametros.TIPO === "ITENS") {
            // Critério baseado na quantidade de itens
            if (livro.QUANT_ITENS > ((variacao * quantidade) + quantidade)) {
              livro.POLYGON.setStyle({ color: 'blue' });
            } else if (livro.QUANT_ITENS < (quantidade - (variacao * quantidade))) {
              livro.POLYGON.setStyle({ color: 'red' });
            } else {
              livro.POLYGON.setStyle({ color: 'green' });
            }

          } else if (this.Nat_parametros.TIPO === "DURACAO") {
            // Critério baseado na duração (DURACAO_STR)
            const duracaoMinutos = this.converterDuracaoParaMinutos(livro.DURACAO_STR);

            if (duracaoMinutos > ((variacao * quantidade) + quantidade)) {
              livro.POLYGON.setStyle({ color: 'blue' });
            } else if (duracaoMinutos < (quantidade - (variacao * quantidade))) {
              livro.POLYGON.setStyle({ color: 'red' });
            } else {
              livro.POLYGON.setStyle({ color: 'green' });
            }
          }
        });
      } else {
        // Resetar para a cor original se não for ANALITICA
        this.Nat_operacoes_mapa_edicoes.forEach((livro, l) => {
          livro.POLYGON.setStyle({ color: livro.POLYGON.options.fillColor });
        });
      }
    } else {
      // Resetar para a cor original se não for ANALITICA
      this.Nat_operacoes_mapa_edicoes.forEach((livro, l) => {
        livro.POLYGON.setStyle({ color: livro.POLYGON.options.fillColor });
      });
    }
  }

  /**
   * Função para converter uma string no formato HH:MM:SS para minutos.
   */
  converterDuracaoParaMinutos(duracaoStr: string): number {
    const [horas, minutos, segundos] = duracaoStr.split(':').map(Number);
    return (horas * 60) + minutos + (segundos / 60);
  }

  Nat_state_perfil:boolean = false

  Out_change_state_perfil(state:boolean){
    this.Nat_state_perfil=state
  }



  Nat_coordenada_local_buscado: L.Marker
  Out_move_local_buscado(coordenada: COORDENADA) {

    if (this.Nat_coordenada_local_buscado) {
      this.Nat_coordenada_local_buscado.remove()
      this.Nat_coordenada_local_buscado = undefined
    }

    const customIcon = L.icon({
      iconUrl: 'https://res.cloudinary.com/de74yvifw/image/upload/v1734221320/position-marker-svgrepo-com_uv8iqc.svg', // Caminho da imagem
      iconSize: [32, 32], // Tamanho do ícone [largura, altura]
      iconAnchor: [16, 32], // Ponto de ancoragem do ícone [largura, altura]
      popupAnchor: [0, -32] // Ponto de ancoragem do popup [x, y]
    });

    this.Nat_coordenada_local_buscado = L.marker([coordenada.lat, coordenada.lng], { icon: customIcon }).addTo(this.map);
    this.map.setView([coordenada.lat, coordenada.lng], 15);
    this.Nat_coordenada_local_buscado.on('click', (event) => {
      if (this.Nat_coordenada_local_buscado) {
        this.Nat_coordenada_local_buscado.remove()
        this.Nat_coordenada_local_buscado = undefined
      }
    })

  }

  Out_change_transferencia(state: boolean) {
    this.Nat_state_transferencia_itens = state
    if (state == true) {
      this.map.addControl(this.Nat_draw_control);
    } else {
      this.Nat_detalhes_poligono = undefined


      // Verifica se já existe algum polígono no array e o remove
      if (this.Nat_polygons_transferencia.length > 0) {
        // Remover o polígono antigo do mapa
        const oldLayer = this.Nat_polygons_transferencia.pop(); // Remove o último polígono
        this.map.removeLayer(oldLayer); // Remove do mapa
      }
      this.map.removeControl(this.Nat_draw_control)
    }


  }


  Nat_state_EDICOES: boolean = false
  Out_change_aba_EDICOES(state: boolean) {//altera o estado da aba edicoes
    this.Nat_state_EDICOES = state

    if (state = true) {//se estiver abrindo, tem que fechar as demais abas
      this.Nat_state_LIVROS = false
      this.Nat_state_FERRAMENTAS = false
      this.Nat_state_MODULOS = false
    }
  }

  Nat_state_LIVROS: boolean = false
  Nat_operacao_livros_atual: number //id da operacao que esta com os livros na tabela atual
  
  Out_change_aba_LIVROS(operacao_id: number) {//altera o estado da aba livros da operacao selecionada

    if (this.Nat_operacao_livros_atual) {
      this.Nat_state_LIVROS = false
      this.Nat_operacao_livros_atual = undefined
    } else {
      this.Nat_operacao_livros_atual = operacao_id
      this.Nat_state_LIVROS = true
      this.Nat_state_EDICOES = false
      this.Nat_state_FERRAMENTAS = false
      this.Nat_state_MODULOS = false
    }

  }
  Out_close_livros(variavel_inutil: boolean) {

    /**faz o mesmo que o acima mas quando o user clicar no btn fechar da lista de livros da operacao selecionada e nao quando selecionar 
     * outra operacao
     */
    this.Nat_operacao_livros_atual = undefined
    this.Nat_state_LIVROS = false

    this.Out_change_aba_EDICOES(true)
  }

  Nat_state_FERRAMENTAS: boolean = false
  Out_change_aba_FERRAMENTAS(state: boolean) {//altera o estado da aba edicoes
    this.Nat_state_FERRAMENTAS = state

    if (state = true) {//se estiver abrindo, tem que fechar as demais abas
      this.Nat_state_EDICOES = false
      this.Nat_state_LIVROS = false
      this.Nat_state_MODULOS = false
    }
  }

  Nat_state_MODULOS: boolean = false
  Out_change_aba_MODULOS(state: boolean) {//altera o estado da aba de modulos
    this.Nat_state_MODULOS = state

    if (state = true) {//se estiver abrindo, tem que fechar as demais abas
      this.Nat_state_EDICOES = false
      this.Nat_state_LIVROS = false
      this.Nat_state_FERRAMENTAS = false
    }
  }
  Nat_style_map_index: number = 0
  Out_change_map_type(variavel_inutil: any) { //altera a visualizacao do mapa entre normal, satelite e simplificado

    // Remover a camada atual
    this.map.removeLayer(this.Nat_activeLayer);



    if (this.Nat_style_map_index == 0) {
      this.Nat_style_map_index = 1
      this.Nat_activeLayer = this.Nat_satelliteMap;
      this.Nat_activeLayer.addTo(this.map);
    } else if (this.Nat_style_map_index == 1) {
      this.Nat_style_map_index = 2
      this.Nat_activeLayer = this.Nat_simplifiedMap;
      this.Nat_activeLayer.addTo(this.map);
    } else if (this.Nat_style_map_index == 2) {
      this.Nat_style_map_index = 0
      this.Nat_activeLayer = this.Nat_normalMap;
      this.Nat_activeLayer.addTo(this.map);
    }



  }


  Nat_state_coleta_coordenada: boolean = false //se ativo esta coletando coordenadas manualmente

  Nat_state_transferencia_itens: boolean = false

  Out_change_state_coleta_coordenada(state: boolean) {
    //recebe o estado do botao de coleta manual de coordenada do component gpx-ferramentas
    this.Nat_state_coleta_coordenada = state
  }

  Nat_state_detalhes_poligono: boolean = false

  Nat_detalhes_poligono: DETALHES_POLIGONO
  Out_change_state_detalhes_poligono(state: boolean) {
    this.Nat_state_detalhes_poligono = state
  }






  async Out_repoe_mapa_pos_edicao(variavel_inutil: boolean) {


    this.Nat_state_loading = true



    this.Nat_operacoes_mapa_edicoes.forEach((operacao, o) => {
      // Remover todos os clusters associados a esta operação
      this.Nat_operacoes_mapa_edicoes[o].CLUSTERS.forEach((cluster, c) => {
        this.map.removeLayer(cluster);
      });

      // Remover todos os marcadores individuais associados a esta operação
      this.Nat_operacoes_mapa_edicoes[o].MARKERS.forEach((marker, m) => {

        this.map.removeLayer(marker);
      });

      this.Nat_operacoes_mapa_edicoes[o].POLYGON.remove()
    })




    for (let od = 0; od < this.Nat_operacoes_mapa_edicoes.length; od++) {


      let operacao_atual = this.Nat_operacoes_mapa_edicoes[od]

      const requisicao_server: any = await this.service.POST_ITENS_LIVRO_EXEC(parseInt(operacao_atual.ID.split('-')[0]), operacao_atual.ID.split('-')[1].replace(".", ""));
      const resposta: RESPOSTA_PADRAO_SERVIDOR = requisicao_server;
      this.Nat_state_detalhes_poligono = false
      if (resposta.MSG === 'SUCESSO') {



        // Encontrar o índice da operação específica no array de operações
        const index = this.Nat_operacoes_mapa_edicoes.findIndex(
          (operacao_local) => operacao_local.ID == operacao_atual.ID
        );


        if (resposta.RESPOSTA.length > 0) {
          this.Out_change_transferencia(false) //desabilita o poligono de transferencia

          let instalacoes: INSTALACAO_BD[] = resposta.RESPOSTA;
          let new_operacao_map: OPERACAO_ou_LIVRO = {
            ID: operacao_atual.ID,
            ID_STR: operacao_atual.ID_STR,
            CENTRO_LEITURA_STR: operacao_atual.CENTRO_LEITURA_STR,
            GRUPO_STR: operacao_atual.GRUPO_STR,
            LIVRO_STR: operacao_atual.LIVRO_STR,
            QUANT_ITENS: instalacoes.length,
            DURACAO_STR: operacao_atual.DURACAO_STR,
            WAY_LINES: operacao_atual.WAY_LINES,
            POLYGON: undefined,
            MARKERS: [],
            ITENS: [],
            CLUSTERS: [],
            VISIVEL: true,
            POSSUI_EDICAO: true,
            QUANT_LIVROS: operacao_atual.QUANT_LIVROS,
            DISTANCIA_STR: operacao_atual.DISTANCIA_STR
          };

          // Criando o grupo de marcadores com o plugin MarkerCluster
          const cluster = this.leaflet.markerClusterGroup();
          const markers: L.CircleMarker[] = []; // Para armazenar os marcadores individuais
          const cor: string = operacao_atual.POLYGON.options.fillColor

          let coordenadas_poligono: COORDENADA[] = []
          let coordenadas_hull: number[][] = [];

          let distancia_ja_percorrida:number = 0 //metros
          let tempo_decorrido:number = 0 //segundos

          instalacoes = instalacoes.sort((a,b)=>a.SEQUENCIA_LIVRO-b.SEQUENCIA_LIVRO)
          instalacoes.forEach((instalacao:any) => {

      
            distancia_ja_percorrida = distancia_ja_percorrida+instalacao.DISTANCIA_ATE_PROXIMO
            tempo_decorrido = tempo_decorrido+instalacao.EXEC_TIME

            const marcador = L.circleMarker([instalacao.LAT, instalacao.LNG], {
              radius: 3,
              color: cor,
              weight: 2,
              opacity: 0.8,
              fillColor: '#484e55',
              fillOpacity: 0.8
            });

            coordenadas_hull.push([instalacao.LNG, instalacao.LAT])


            // Adiciona uma tooltip ao passar o mouse sobre o marcador, com cada informação em uma linha separada
            marcador.bindTooltip(
              `
            <div>INSTALAÇÃO: ${instalacao.INSTALACAO}</div>
             <div> ${instalacao.LOGRADOURO}</div>
            <div>SEQ: ${instalacao.SEQUENCIA_LIVRO}</div>
            <div>TEMPO PASSADO: ${formatarDuracao(tempo_decorrido)}</div>
            <div>${(distancia_ja_percorrida / 1000).toFixed(2)} Km Percorridos</div>
        `,
              {
                permanent: false,
                direction: 'top',
                offset: [0, -10],
                className: 'custom-tooltip' // Classe CSS personalizada
              }
            );


            marcador.on('click', async (event) => {

              const popupContent = `
              <div class="p-2 bg-white rounded-md">
                <p class="text-xl font-bold mb-2">Detalhes da Instalação</p>
                <p class="mb-1"><strong>Instalação:</strong> ${instalacao.INSTALACAO}</p>
                <p class="mb-1"><strong>Operação:</strong> ${instalacao.OPERACAO_ID}</p>
                <p class="mb-1"><strong>Sequência do Livro:</strong> ${instalacao.SEQUENCIA_LIVRO}</p>
                <p class="mb-1"><strong>Distância até o Próximo:</strong> ${instalacao.DISTANCIA_ATE_PROXIMO} metros</p>
                <p class="mb-4"><strong>Tempo de Execução:</strong> ${instalacao.EXEC_TIME} seg</p>
                
                <!-- Select para o modal -->
                <div>
                  <label for="select-modal" class="block text-sm/6 font-medium text-gray-900">Modal</label>
                  <select id="select-modal" name="location" class="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm/6">
                    <option value="" selected>Selecione...</option>
                    <option value="pe">A Pé</option>
                    <option value="carro">Carro</option>
                    <option value="moto">Motocicleta</option>
                  </select>
                </div>
                
                <!-- Botão para executar ação -->
                <button id="detalhes-btn" class="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded focus:outline-none w-full mt-4">
                  Atualizar Sequência
                </button>
              </div>
              `;
              
              // Exibir o popup no mapa
              const popup = L.popup({ closeButton: true, autoClose: false })
                .setLatLng(event.latlng)
                .setContent(popupContent)
                .openOn(this.map);
              
              // Função para capturar o valor do select ao clicar no botão
              setTimeout(() => {
                const button = document.getElementById('detalhes-btn');
                const selectElement = document.getElementById('select-modal') as HTMLSelectElement;
              
                if (button && selectElement) {
                  button.addEventListener('click', () => {
                    const selectedValue = selectElement.value;
                    if (selectedValue) {
                      console.log('Valor selecionado:', selectedValue);
                      // Chama a função para atualizar a sequência
                      this.Nat_mudar_sequencia(instalacao, selectedValue);
                    } else {
                      alert('Por favor, selecione uma opção!');
                    }
                  });
                }
              }, 0);

            });

            new_operacao_map.ITENS.push(instalacao)
            new_operacao_map.MARKERS = markers;
            cluster.addLayer(marcador);
            markers.push(marcador);
          });


          if (resposta.RESPOSTA.length > 0) {
            const resultadoHull = hull(coordenadas_hull, 100);


            resultadoHull.forEach((arr_coord) => {
              coordenadas_poligono.push({
                lat: arr_coord[1],
                lng: arr_coord[0]
              })
            });


            const poligono_operacao = L.polygon(coordenadas_poligono, {
              fillColor: cor,
              color: cor,
              weight: 3,
              opacity: 1,
              fillOpacity: 0.1
            }).addTo(this.map);
            //poligono_operacao.enableEdit();

            let duracao_poligono: number = 0


            instalacoes.forEach((instalacao, i) => {
              duracao_poligono = duracao_poligono + instalacao.EXEC_TIME
            });

            new_operacao_map.DURACAO_STR = formatarDuracao(duracao_poligono)

            // Adiciona uma tooltip ao passar o mouse sobre o marcador, com cada informação em uma linha separada
            poligono_operacao.bindTooltip(
              `
                  <div>OPERAÇÃO: ${new_operacao_map.ID.split('-')[0]}</div>
                  <div>LIVRO: ${new_operacao_map.LIVRO_STR}</div>
                 
                 `,
              {
                permanent: false,
                direction: 'top',
                offset: [0, -10],
                className: 'custom-tooltip' // Classe CSS personalizada
              }
            );

          
            this.Nat_detalhes_poligono = undefined
            poligono_operacao.on('click', async (event) => {
              let detalhes_poligono: DETALHES_POLIGONO = {
                OPERACAO_ID: parseInt(new_operacao_map.ID.split('-')[0]),
                CENTRO_LEITURA: new_operacao_map.CENTRO_LEITURA_STR,
                GRUPO: new_operacao_map.GRUPO_STR,
                LIVRO: new_operacao_map.LIVRO_STR,
                DURACAO: new_operacao_map.DURACAO_STR,
                TOTAL_ITENS: resposta.RESPOSTA.length,
                OPERACAO_ID_DEST: undefined,
                LIVRO_DEST: undefined,
                TIPO: 'POLIGONO',
                MODULO: 'DIVIX',
                ITENS_ID: resposta.RESPOSTA,
                ID_INICIO: 0,
                DISTANCIA: new_operacao_map.DISTANCIA_STR
              };
    

              this.Nat_detalhes_poligono = detalhes_poligono;
    
              // Conteúdo do popup com informações e um botão
              const popupContent = `
        <div class="p-2 bg-white rounded-md">
          <p class="text-xl font-bold mb-2">Detalhes do Polígono</p>
          <p class="mb-1"><strong>Operação:</strong> ${detalhes_poligono.OPERACAO_ID}</p>
          <p class="mb-1"><strong>Centro de Leitura:</strong> ${detalhes_poligono.CENTRO_LEITURA}</p>
          <p class="mb-1"><strong>Grupo:</strong> ${detalhes_poligono.GRUPO}</p>
          <p class="mb-1"><strong>Livro:</strong> ${detalhes_poligono.LIVRO}</p>
          <p class="mb-1"><strong>Duração:</strong> ${detalhes_poligono.DURACAO}</p>
          <p class="mb-1"><strong>Distância:</strong> ${detalhes_poligono.DISTANCIA}</p>
          <p class="mb-4"><strong>Total de Itens:</strong> ${detalhes_poligono.TOTAL_ITENS}</p>
          
          <!-- Botão para executar ação -->
         <button id="novo_roteiro-btn" class="bg-indigo-500 hover:bg-indigo-600 text-white font-bold py-2 px-4 rounded focus:outline-none w-full">
        Inicio Externo
      </button>
      <button id="detalhes-poligono-btn" class="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded focus:outline-none w-full mt-2">
        Ver Roteiro
      </button>
        </div>
      `;
              // Exibir o popup no local onde o usuário clicou
              const popup = L.popup({ closeButton: true, autoClose: false })
                .setLatLng(event.latlng)
                .setContent(popupContent)
                .openOn(this.map);
    
              // Função para capturar o valor do input ao clicar no botão
              setTimeout(() => {
                const button = document.getElementById('detalhes-poligono-btn');
                const button_2 = document.getElementById('novo_roteiro-btn');
    
                if (button) {
                  button.addEventListener('click', () => {
    
                    this.Nat_ver_roteiro(detalhes_poligono);
    
                  });
                }

                if(button_2){
                  button_2.addEventListener('click', () => {
    
                    this.Nat_change_state_novo_inicio(true);
    
                  });
                }
              }, 0);
    
            });

            new_operacao_map.POLYGON = poligono_operacao
          }






          new_operacao_map.CLUSTERS.push(cluster);
          this.map.addLayer(cluster);

          const updateMarkerDisplay = () => {
            if (this.Nat_ids_operacoes_mapa_edicoes.includes(operacao_atual.ID)) {
              const zoomLevel = this.map.getZoom();
              const mapBounds = this.map.getBounds();

              if (zoomLevel >= 16) {
                // Remove o cluster e exibe apenas os marcadores dentro dos limites visíveis
                this.map.removeLayer(cluster);
                markers.forEach(marker => {
                  if (mapBounds.contains(marker.getLatLng())) {
                    this.map.addLayer(marker);
                  } else {
                    this.map.removeLayer(marker);
                  }
                });
              } else {
                // Remove os marcadores individuais e exibe o cluster
                markers.forEach(marker => {
                  this.map.removeLayer(marker);
                });
                this.map.addLayer(cluster);
              }
            } else {
              // Remover todos os marcadores e clusters da visualização
              markers.forEach(marker => {
                this.map.removeLayer(marker);
              });
              this.map.removeLayer(cluster);


            }
          };

          this.map.on('zoomend moveend', updateMarkerDisplay);
          updateMarkerDisplay();




          this.Nat_operacoes_mapa_edicoes[index] = new_operacao_map
          this.Nat_ids_operacoes_mapa_edicoes[index] = new_operacao_map.ID





          this.Nat_state_loading = false


        } else {
          this.Nat_ids_operacoes_mapa_edicoes.splice(index, 1)
          this.Nat_operacoes_mapa_edicoes.splice(index, 1)
        }


      } else {
        this.Nat_state_loading = false
        let new_alert: DADOS_ALERT = {
          TITULO: 'ERRO',
          CORPO: resposta.ADICIONAL
        };
        this.Nat_dados_alert = new_alert;
        this.Nat_status_alert_erro = true;
      }


    }





  }

   formatarDuracao(segundos: number): string {
    const horas = Math.floor(segundos / 3600);
    const minutos = Math.floor((segundos % 3600) / 60);
    const segundosRestantes = segundos % 60;
  
    const horasStr = horas.toString().padStart(2, '0');
    const minutosStr = minutos.toString().padStart(2, '0');
    const segundosStr = segundosRestantes.toString().padStart(2, '0');
  
    return `${horasStr}:${minutosStr}:${segundosStr}`;
  }

  Nat_operacoes_mapa_edicoes: OPERACAO_ou_LIVRO[] = []
  Nat_ids_operacoes_mapa_edicoes: any[] = []

  

  /**
   * recebe a operacao que deve ser lançada no mapa, busca suas instalacoes, e as lança no mapa
   * elas são agrupadas em clusters para melhorar o desempenho e usabilidade, e são exibidos apennas os
   * pontos que estao dentro da vizualização atual
   */
  async Out_open_operacao_edicoes(operacao: OPERACAO_ou_LIVRO) {
    if (operacao.QUANT_ITENS == 0) {
      this.Nat_state_loading = false
      let new_alert: DADOS_ALERT = {
        TITULO: 'ERRO',
        CORPO: 'Não é Possível abrir Operações sem Itens!'
      };
      this.Nat_dados_alert = new_alert;
      this.Nat_status_alert_erro = true;

    } else {
      this.Nat_state_loading = true

      const requisicao_server: any = await this.service.POST_ITENS_LIVRO_EXEC(parseInt(operacao.ID.split('-')[0]), operacao.LIVRO_STR);
      const resposta: RESPOSTA_PADRAO_SERVIDOR = requisicao_server;



      if (resposta.MSG === 'SUCESSO') {
        // let new_alert: DADOS_ALERT = {
        //   TITULO: resposta.MSG,
        //   CORPO: resposta.ADICIONAL
        // };
        // this.Nat_dados_alert = new_alert;
        // this.Nat_status_alert_sucesso = true;

        let instalacoes: INSTALACAO_BD[] = resposta.RESPOSTA;
        let new_operacao_map: OPERACAO_ou_LIVRO = operacao;

        // Criando o grupo de marcadores com o plugin MarkerCluster
        const cluster = this.leaflet.markerClusterGroup();
        const markers: L.CircleMarker[] = []; // Para armazenar os marcadores individuais
        const cor: string = this.Nat_gerarCorUnica()

        let coordenadas_poligono: COORDENADA[] = []
        let coordenadas_hull: number[][] = [];

        let distancia_ja_percorrida:number = 0 //metros
        let tempo_decorrido:number = 0 //segundos

        instalacoes = instalacoes.sort((a,b)=>a.SEQUENCIA_LIVRO-b.SEQUENCIA_LIVRO)

        instalacoes.forEach((instalacao:any) => {

    
          distancia_ja_percorrida = distancia_ja_percorrida+instalacao.DISTANCIA_ATE_PROXIMO
          tempo_decorrido = tempo_decorrido+instalacao.EXEC_TIME

          const marcador = L.circleMarker([instalacao.LAT, instalacao.LNG], {
            radius: 3,
            color: cor,
            weight: 2,
            opacity: 0.8,
            fillColor: '#484e55',
            fillOpacity: 0.8
          });

          coordenadas_hull.push([instalacao.LNG, instalacao.LAT])


          // Adiciona uma tooltip ao passar o mouse sobre o marcador, com cada informação em uma linha separada
          marcador.bindTooltip(
            `
             <div>INSTALAÇÃO: ${instalacao.INSTALACAO}</div>
             <div> ${instalacao.LOGRADOURO}</div>
            <div>SEQ: ${instalacao.SEQUENCIA_LIVRO}</div>
             <div>TEMPO PASSADO: ${formatarDuracao(tempo_decorrido)}</div>
            <div>${(distancia_ja_percorrida / 1000).toFixed(2)} Km Percorridos</div>
        `,
            {
              permanent: false,
              direction: 'top',
              offset: [0, -10],
              className: 'custom-tooltip' // Classe CSS personalizada
            }
          );


          marcador.on('click', async (event) => {

            // Conteúdo do popup com informações, um input e um botão
            const popupContent = `
            <div class="p-2 bg-white rounded-md">
              <p class="text-xl font-bold mb-2">Detalhes da Instalação</p>
              <p class="mb-1"><strong>Instalação:</strong> ${instalacao.INSTALACAO}</p>
              <p class="mb-1"><strong>Operação:</strong> ${instalacao.OPERACAO_ID}</p>
              <p class="mb-1"><strong>Sequência do Livro:</strong> ${instalacao.SEQUENCIA_LIVRO}</p>
              <p class="mb-1"><strong>Distância até o Próximo:</strong> ${instalacao.DISTANCIA_ATE_PROXIMO} metros</p>
              <p class="mb-4"><strong>Tempo de Execução:</strong> ${instalacao.EXEC_TIME} seg</p>
              
              <!-- Select para o modal -->
              <div>
                <label for="select-modal" class="block text-sm/6 font-medium text-gray-900">Modal</label>
                <select id="select-modal" name="location" class="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm/6">
                  <option value="" selected>Selecione...</option>
                  <option value="pe">A Pé</option>
                  <option value="carro">Carro</option>
                  <option value="moto">Motocicleta</option>
                </select>
              </div>
              
              <!-- Botão para executar ação -->
              <button id="detalhes-btn" class="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded focus:outline-none w-full mt-4">
                Atualizar Sequência
              </button>
            </div>
            `;
            
            // Exibir o popup no mapa
            const popup = L.popup({ closeButton: true, autoClose: false })
              .setLatLng(event.latlng)
              .setContent(popupContent)
              .openOn(this.map);
            
            // Função para capturar o valor do select ao clicar no botão
            setTimeout(() => {
              const button = document.getElementById('detalhes-btn');
              const selectElement = document.getElementById('select-modal') as HTMLSelectElement;
            
              if (button && selectElement) {
                button.addEventListener('click', () => {
                  const selectedValue = selectElement.value;
                  if (selectedValue) {
                    console.log('Valor selecionado:', selectedValue);
                    // Chama a função para atualizar a sequência
                    this.Nat_mudar_sequencia(instalacao, selectedValue);
                  } else {
                    alert('Por favor, selecione uma opção!');
                  }
                });
              }
            }, 0);

          });
          new_operacao_map.ITENS.push(instalacao)
          new_operacao_map.MARKERS = markers;
          cluster.addLayer(marcador);
          markers.push(marcador);
        });


        const resultadoHull = hull(coordenadas_hull, 100);


        resultadoHull.forEach((arr_coord) => {
          coordenadas_poligono.push({
            lat: arr_coord[1],
            lng: arr_coord[0]
          })
        });


        const poligono_operacao = L.polygon(coordenadas_poligono, {
          fillColor: cor,
          color: cor,
          weight: 3,
          opacity: 1,
          fillOpacity: 0.1
        }).addTo(this.map);
        //poligono_operacao.enableEdit();

        let duracao_poligono: number = 0


        instalacoes.forEach((instalacao, i) => {
          duracao_poligono = duracao_poligono + instalacao.EXEC_TIME
        });

        operacao.DURACAO_STR = formatarDuracao(duracao_poligono)

        // Adiciona uma tooltip ao passar o mouse sobre o marcador, com cada informação em uma linha separada
        poligono_operacao.bindTooltip(
          `
              <div>OPERAÇÃO: ${operacao.ID.split('-')[0]}</div>
              <div>LIVRO: ${operacao.LIVRO_STR}</div>
             
             `,
          {
            permanent: false,
            direction: 'top',
            offset: [0, -10],
            className: 'custom-tooltip' // Classe CSS personalizada
          }
        );

        // Evento de início da edição do polígono
        poligono_operacao.on('editable:vertex:dragend', async (event) => {

          alert('mudou o poligono')
          const newCoordinates = event.target.getLatLngs();
          // Atualize o array com as novas coordenadas se necessário

          // newCoordinates[0].forEach((latlng: any) => {
          //   coordenadas_polygon.push({ lat: latlng.lat, lng: latlng.lng });


          // });

        })

        poligono_operacao.on('click', async (event) => {



          
          this.Nat_detalhes_poligono = undefined

          // Criação do objeto de detalhes
          let detalhes_poligono: DETALHES_POLIGONO = {
            OPERACAO_ID: parseInt(operacao.ID.split('-')[0]),
            CENTRO_LEITURA: operacao.CENTRO_LEITURA_STR,
            GRUPO: operacao.GRUPO_STR,
            LIVRO: operacao.LIVRO_STR,
            DURACAO: operacao.DURACAO_STR,
            TOTAL_ITENS: resposta.RESPOSTA.length,
            OPERACAO_ID_DEST: undefined,
            LIVRO_DEST: undefined,
            TIPO: 'POLIGONO',
            MODULO: 'DIVIX',
            ITENS_ID: resposta.RESPOSTA,
            ID_INICIO: 0,
            DISTANCIA: operacao.DISTANCIA_STR
          };

          // Armazena os detalhes para uso em outro lugar, se necessário
          this.Nat_detalhes_poligono = detalhes_poligono;
 

          const popupContent = `
          <div class="p-2 bg-white rounded-md">
            <p class="text-xl font-bold mb-2">Detalhes do Polígono</p>
            <p class="mb-1"><strong>Operação:</strong> ${detalhes_poligono.OPERACAO_ID}</p>
            <p class="mb-1"><strong>Centro de Leitura:</strong> ${detalhes_poligono.CENTRO_LEITURA}</p>
            <p class="mb-1"><strong>Grupo:</strong> ${detalhes_poligono.GRUPO}</p>
            <p class="mb-1"><strong>Livro:</strong> ${detalhes_poligono.LIVRO}</p>
            <p class="mb-1"><strong>Duração:</strong> ${detalhes_poligono.DURACAO}</p>
            <p class="mb-1"><strong>Distância:</strong> ${detalhes_poligono.DISTANCIA}</p>
            <p class="mb-4"><strong>Total de Itens:</strong> ${detalhes_poligono.TOTAL_ITENS}</p>
            
            <!-- Botão para executar ação -->
           <button id="novo_roteiro-btn" class="bg-indigo-500 hover:bg-indigo-600 text-white font-bold py-2 px-4 rounded focus:outline-none w-full">
          Inicio Externo
        </button>
        <button id="detalhes-poligono-btn" class="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded focus:outline-none w-full mt-2">
          Ver Roteiro
        </button>
          </div>
        `;
                // Exibir o popup no local onde o usuário clicou
                const popup = L.popup({ closeButton: true, autoClose: false })
                  .setLatLng(event.latlng)
                  .setContent(popupContent)
                  .openOn(this.map);
      
                // Função para capturar o valor do input ao clicar no botão
                setTimeout(() => {
                  const button = document.getElementById('detalhes-poligono-btn');
                  const button_2 = document.getElementById('novo_roteiro-btn');
      
                  if (button) {
                    button.addEventListener('click', () => {
      
                      this.Nat_ver_roteiro(detalhes_poligono);
      
                    });
                  }
  
                  if(button_2){
                    button_2.addEventListener('click', () => {
      
                      this.Nat_change_state_novo_inicio(true);
      
                    });
                  }
                }, 0);

        });



        new_operacao_map.POLYGON = poligono_operacao




        new_operacao_map.CLUSTERS.push(cluster);
        this.map.addLayer(cluster);

        const updateMarkerDisplay = () => {
          if (this.Nat_ids_operacoes_mapa_edicoes.includes(operacao.ID)) {
            const zoomLevel = this.map.getZoom();
            const mapBounds = this.map.getBounds();

            if (zoomLevel >= 16) {
              // Remove o cluster e exibe apenas os marcadores dentro dos limites visíveis
              this.map.removeLayer(cluster);
              markers.forEach(marker => {
                if (mapBounds.contains(marker.getLatLng())) {
                  this.map.addLayer(marker);
                } else {
                  this.map.removeLayer(marker);
                }
              });
            } else {
              // Remove os marcadores individuais e exibe o cluster
              markers.forEach(marker => {
                this.map.removeLayer(marker);
              });
              this.map.addLayer(cluster);
            }
          } else {
            // Remover todos os marcadores e clusters da visualização
            markers.forEach(marker => {
              this.map.removeLayer(marker);
            });
            this.map.removeLayer(cluster);


          }
        };

        this.map.on('zoomend moveend', updateMarkerDisplay);
        updateMarkerDisplay();

        //this.map.setView([instalacoes[0].LAT, instalacoes[0].LNG], 15);
        this.Nat_operacoes_mapa_edicoes.push(new_operacao_map);
        this.Nat_ids_operacoes_mapa_edicoes.push(operacao.ID);


        this.Nat_state_loading = false
      } else {

        this.Nat_state_loading = false
        let new_alert: DADOS_ALERT = {
          TITULO: 'ERRO',
          CORPO: resposta.ADICIONAL
        };
        this.Nat_dados_alert = new_alert;
        this.Nat_status_alert_erro = true;
      }
    }
  }



  /**
   muda a sequencia de um item dentro de um roteiro existente, se o roteiro não existir retorna
   com o aviso de que o roteiro nao foi calculado ainda, a menos que o numero digitado seja 1 pois 
   ele entendera que deve ser calculado a partir desse ponto
   */
  async Nat_mudar_sequencia(instalacao: INSTALACAO_BD, valor: string) {

    this.Out_open_loading(true)

    if (valor == "pe" || valor == "carro" || valor == "moto") {
      const requisicao_server: any = await this.service.POST_OSRM_TRIP(
        instalacao.OPERACAO_ID,
        instalacao.LIVRO,
        instalacao.ID,
        valor
      );
      const resposta: RESPOSTA_PADRAO_SERVIDOR = requisicao_server;
      this.Nat_state_detalhes_poligono = false;

      if (resposta.MSG === 'SUCESSO') {

        this.Out_repoe_mapa_pos_edicao(true)

        this.Out_close_loading(true);
        let new_alert: DADOS_ALERT = {
          TITULO: resposta.MSG,
          CORPO: "Sequencia Alterada com Sucesso!",
        };
        this.Nat_dados_alert = new_alert;
        this.Nat_status_alert_sucesso = true;

      } else {
        this.Out_close_loading(true);
        let new_alert: DADOS_ALERT = {
          TITULO: resposta.MSG,
          CORPO: resposta.ADICIONAL,
        };
        this.Nat_dados_alert = new_alert;
        this.Nat_status_alert_erro = true;
      }
    } else {
      this.Out_close_loading(true);
      let new_alert: DADOS_ALERT = {
        TITULO: 'ERRO',
        CORPO: 'Defina o tipo de Modal como: "pe", "carro" ou "moto"!',
      };
      this.Nat_dados_alert = new_alert;
      this.Nat_status_alert_erro = true;
    }


  }

  Nat_roteiro_em_foco: CAMINHO_ROTEIRO
  /**
   * a variavel acima armazena a linha de background do livro em foco bem como a linha principal 
   * que mostram o roteiro que deve ser seguido para esse livro em foco
   */
  async Nat_ver_roteiro(dados_poligono: DETALHES_POLIGONO) {


    if (this.Nat_roteiro_em_foco) {
      // Remove o intervalo antes de limpar as linhas
      clearInterval(this.Nat_roteiro_em_foco.CONTADOR); // Corrigido aqui

      // Remove as linhas e outros objetos do mapa
      this.Nat_roteiro_em_foco.LINHA_BG.remove();
      this.Nat_roteiro_em_foco.LINHA_MOVEL.remove();
      this.Nat_roteiro_em_foco.LINHA.remove();

      // Limpa o objeto de roteiro em foco
      this.Nat_roteiro_em_foco = undefined;
    } else {
      let operacao_selecionada = this.Nat_operacoes_mapa_edicoes.filter(
        (operacao) => operacao.LIVRO_STR == dados_poligono.LIVRO
      );

      if (dados_poligono.DISTANCIA != "0.00 Km" && dados_poligono.DURACAO != "00:00:00") {
        const requisicao_server: any = await this.service.POST_OSRM_TABLE(
          dados_poligono.OPERACAO_ID,
          dados_poligono.LIVRO,
          undefined
        );
        const resposta: RESPOSTA_PADRAO_SERVIDOR = requisicao_server;
        this.Nat_state_detalhes_poligono = false;

        if (resposta.MSG === 'SUCESSO') {
          operacao_selecionada[0].MARKERS.forEach((marker, m) => {
            marker.openTooltip();
          });

          const coordinates: [number, number][] = [];

          resposta.RESPOSTA.forEach((coordenada: COORDENADA, c) => {
            coordinates.push([coordenada.lat, coordenada.lng]);
          });

          this.Nat_roteiro_em_foco = new CAMINHO_ROTEIRO();
          this.Nat_roteiro_em_foco.OPERACAO = operacao_selecionada[0].ID_STR;

          // Desenha a linha no mapa usando L.polyline
          this.Nat_roteiro_em_foco.LINHA_BG = L.polyline(coordinates, {
            color: 'blue',
            weight: 8,
            opacity: 0.8,
            smoothFactor: 1,
          }).addTo(this.map);

          this.Nat_roteiro_em_foco.LINHA = L.polyline(coordinates, {
            color: 'white',
            weight: 4,
            opacity: 0.5,
            smoothFactor: 1,
          }).addTo(this.map);

          // Inicializa o índice do ponto e a linha móvel
          let currentIndex = 0;

          // Adiciona um ponto inicial para a linha móvel
          this.Nat_roteiro_em_foco.LINHA_MOVEL = L.polyline([coordinates[currentIndex]], {
            color: 'blue',
            weight: 4,
            opacity: 1,
            smoothFactor: 1,
          }).addTo(this.map);

          // Função para mover a linha
          function moveLine() {
            // Verifica se ainda há coordenadas para percorrer
            if (currentIndex < coordinates.length - 1) {
              currentIndex++;
              // Atualiza a posição da linha móvel para o próximo ponto
              this.Nat_roteiro_em_foco.LINHA_MOVEL.setLatLngs([coordinates.slice(0, currentIndex + 1)]);
            } else {
              // Reinicia a linha quando ela chega ao final
              currentIndex = 0;
              this.Nat_roteiro_em_foco.LINHA_MOVEL.setLatLngs([coordinates.slice(0, currentIndex + 1)]);
            }
          }

          // Define o intervalo para mover a linha a cada 50ms
          this.Nat_roteiro_em_foco.CONTADOR = setInterval(moveLine.bind(this), 50);

          // Adiciona o evento de clique na linha
          this.Nat_roteiro_em_foco.LINHA.on('click', () => {
            // Ao clicar na linha, abre os tooltips dos marcadores
            operacao_selecionada[0].MARKERS.forEach((marker) => {
              marker.openTooltip();
            });
          });
        } else {
          this.Out_close_loading(true);
          let new_alert: DADOS_ALERT = {
            TITULO: resposta.MSG,
            CORPO: resposta.ADICIONAL,
          };
          this.Nat_dados_alert = new_alert;
          this.Nat_status_alert_erro = true;
        }
      } else {
        this.Out_close_loading(true);
        let new_alert: DADOS_ALERT = {
          TITULO: 'ERRO',
          CORPO: 'Nenhum Roteiro Criado para Esse Livro!',
        };
        this.Nat_dados_alert = new_alert;
        this.Nat_status_alert_erro = true;
      }
    }
  }



  Out_desenha_trajeto_osrm(dados_osrm_trip: any) {
    /**
     * recebe o retorno do trip osrm e desenha tanto o percurso como os pontos mostrando a sequencia usada
     */

    const coordinates: [number, number][] = [];
    // Prepara as coordenadas para desenhar a linha do trajeto
    dados_osrm_trip.osrmData.trips[0].geometry.coordinates.forEach((coordinate) => {
      const lat = coordinate[1];
      const lng = coordinate[0];
      coordinates.push([lat, lng]);
    });

    // Desenha a linha no mapa usando L.polyline
    const polyline = L.polyline(coordinates, {
      color: 'blue',
      weight: 4,
      opacity: 0.8,
      smoothFactor: 1
    }).addTo(this.map);

    dados_osrm_trip.pontos_sem_a_origem.forEach((ponto, p) => {
      const marcador = L.circleMarker([ponto.LAT, ponto.LNG], {
        radius: 3,
        color: 'red',
        weight: 2,
        opacity: 0.8,
        fillColor: '#484e55',
        fillOpacity: 0.8
      }).addTo(this.map);



      // Adiciona uma tooltip ao passar o mouse sobre o marcador, com cada informação em uma linha separada
      if (ponto.SEQUENCIA_LIVRO == 0 || ponto.SEQUENCIA_LIVRO == dados_osrm_trip.pontos_sem_a_origem.length - 1) {
        marcador.bindTooltip(
          `
          <div>${ponto.SEQUENCIA_LIVRO}</div>
          <div>${ponto.EXEC_TIME}</div>
          `,
          {
            permanent: true,
            direction: 'top',
            offset: [0, -10],
            className: 'custom-tooltip' // Classe CSS personalizada
          }
        );
      } else {
        marcador.bindTooltip(
          `
          <div>${ponto.SEQUENCIA_LIVRO}</div>
           <div>${ponto.EXEC_TIME}</div>
          `,
          {
            permanent: false,
            direction: 'top',
            offset: [0, -10],
            className: 'custom-tooltip' // Classe CSS personalizada
          }
        );
      }


    })


    // Ajusta o mapa para que todos os pontos da linha sejam visíveis
    this.map.fitBounds(polyline.getBounds());
  }
  /**
   *retira do mapa tanto markers como clusters de uma operacao selecionada
   */
  Out_close_operacao_edicoes(operacao: OPERACAO_ou_LIVRO) {



    this.Nat_state_loading = true
    // Encontrar o índice da operação específica no array de operações
    const index = this.Nat_operacoes_mapa_edicoes.findIndex(
      (operacao_local) => operacao_local.ID == operacao.ID
    );

    if (index !== -1) {
      // Remover todos os clusters associados a esta operação
      this.Nat_operacoes_mapa_edicoes[index].CLUSTERS.forEach(cluster => {
        this.map.removeLayer(cluster);
      });

      // Remover todos os marcadores individuais associados a esta operação
      this.Nat_operacoes_mapa_edicoes[index].MARKERS.forEach(marker => {
        this.map.removeLayer(marker);
      });

      this.Nat_operacoes_mapa_edicoes[index].POLYGON.remove()
    }

    // Remover a operação da lista de operações disponíveis
    this.Nat_operacoes_mapa_edicoes = this.Nat_operacoes_mapa_edicoes.filter(
      (operacao_local) => operacao_local.ID != operacao.ID
    );
    this.Nat_ids_operacoes_mapa_edicoes = this.Nat_ids_operacoes_mapa_edicoes.filter(
      (id) => id != operacao.ID
    );

    this.Nat_state_loading = false
  }


  // /**evento que abre a aba de loading a partir de outros components */
  Out_open_loading(variavel_inutil: boolean) {
    this.Nat_state_loading = true
  }

  // /**evento que fecha a aba de loading a partir de outros components */
  Out_close_loading(variavel_inutil: boolean) {
    this.Nat_state_loading = false
  }

  /**abre o alerta da mensagem de sucesso */
  Out_open_alert_sucess(obg_alert: DADOS_ALERT) {
    this.Nat_state_loading = false
    this.Nat_dados_alert = obg_alert;
    this.Nat_status_alert_sucesso = true;
  }

  /**abre o alerta da mensagem de erro */
  Out_open_alert_erro(obg_alert: DADOS_ALERT) {
    this.Nat_state_loading = false
    this.Nat_dados_alert = obg_alert;
    this.Nat_status_alert_erro = true;
  }



  Nat_copy_value(value: any) {
    const valueCopy: string = `${value}`;

    // Cria um elemento de input temporário
    const tempInput = document.createElement('input');
    tempInput.value = valueCopy;
    document.body.appendChild(tempInput); // Adiciona o elemento ao DOM

    // Seleciona o texto do input temporário
    tempInput.select();
    tempInput.setSelectionRange(0, 99999); // Para dispositivos móveis

    try {
      // Copia o texto selecionado para a área de transferência
      document.execCommand('copy');



      let new_alert: DADOS_ALERT = {
        TITULO: 'Copiado!',
        CORPO: 'Valor Copiado com Sucesso para a Área de Transferência'
      }
      this.Nat_dados_alert = new_alert
      this.Nat_status_alert_sucesso = true




    } catch (err) {


      let new_alert: DADOS_ALERT = {
        TITULO: 'ERRO',
        CORPO: 'Valor não pôde se Copiado para a Área de Transferência'
      }
      this.Nat_dados_alert = new_alert
      this.Nat_status_alert_erro = true




    } finally {
      // Remove o elemento temporário do DOM
      document.body.removeChild(tempInput);
    }
  }


  /**
   * geracao e administração de cores para markers do mapa
   */
  Nat_coresGeradas: string[] = [];
  Nat_gerarCorHex(): string {
    const randomColor = Math.floor(Math.random() * 0xffffff);
    return `#${randomColor.toString(16).padStart(6, '0')}`;
  }

  Nat_gerarCorUnica(): string {
    let cor = this.Nat_gerarCorHex();

    // Tenta gerar uma nova cor se a cor atual já existe no array
    while (this.Nat_coresGeradas.includes(cor)) {
      cor = this.Nat_gerarCorHex();
    }

    // Armazena a cor única
    this.Nat_coresGeradas.push(cor);
    return cor;
  }



}
function formatarDuracao(segundos: number): string {
  const horas = Math.floor(segundos / 3600);
  const minutos = Math.floor((segundos % 3600) / 60);
  const segundosRestantes = segundos % 60;

  const horasStr = horas.toString().padStart(2, '0');
  const minutosStr = minutos.toString().padStart(2, '0');
  const segundosStr = segundosRestantes.toString().padStart(2, '0');

  return `${horasStr}:${minutosStr}:${segundosStr}`;
}