import { AfterViewInit, Component, ViewChild, OnInit, ElementRef } from '@angular/core';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

import { Router } from '@angular/router';
import { StoxService } from '../../SERVICES/stox.service';
import { CREATE_ITEM, ITEM, RETORNO_SERVIDOR } from '../../../../MODELS/MODELS_STOX';
import { environment } from '../../../../../environments/environment.development';





@Component({
  selector: 'app-meuestoque',
  standalone: true,
  imports: [MatFormFieldModule,
    MatInputModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
  ],
  templateUrl: './meuestoque.component.html',
  styleUrl: './meuestoque.component.css'
})
export class MeuestoqueComponent implements AfterViewInit {




  displayedColumns_itens_estoque: string[] = ['INFORMACOES', 'TIPO', 'ATIVO', 'QUANTIDADE'];
  dataSource_itens_estoque: MatTableDataSource<ITEM>;

  @ViewChild('sort_itens_estoque') sort_itens_estoque = new MatSort();
  @ViewChild('paginator_itens_estoque') paginator_itens_estoque: MatPaginator;


  constructor(private allservices: StoxService, private router: Router) {
  }

  selectedStatus: string = 'ATIVO';

  onStatusChange(event: Event) {
    const selectElement = event.target as HTMLSelectElement;
    this.selectedStatus = selectElement.value;
    //console.log('Status selecionado:', this.selectedStatus);
  }
  selectedCodBarras: string = 'SIM';

  onCodBarrasChange(event: Event) {
    const selectElement = event.target as HTMLSelectElement;
    this.selectedCodBarras = selectElement.value;
    //console.log('Cod barras selecionado:', this.selectedCodBarras);
  }

  selectedOsme: string = 'SIM';

  onOsmeChange(event: Event) {
    const selectElement = event.target as HTMLSelectElement;
    this.selectedOsme = selectElement.value;
    //console.log('osme selecionado:', this.selectedOsme);
  }

  selectedCce: string = 'SIM';

  onCceChange(event: Event) {
    const selectElement = event.target as HTMLSelectElement;
    this.selectedCce = selectElement.value;
    //console.log('cce selecionado:', this.selectedCce);
  }

  selectedDoc: string = 'SIM';

  onDocChange(event: Event) {
    const selectElement = event.target as HTMLSelectElement;
    this.selectedDoc = selectElement.value;
    //console.log('doc selecionado:', this.selectedDoc);
  }



  goto(page: string) {
    this.router.navigateByUrl(page)
  }

  @ViewChild('span_username') span_username!: ElementRef


  ngAfterViewInit() {

    if (!environment.user_atual) {
      this.router.navigateByUrl('')
    } else {

      
  this.span_username.nativeElement.innerText  = environment.user_atual.NOME
      this.atualiza_lista()

    }



  }

  applyFilter_itens_estoque(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource_itens_estoque.filter = filterValue.trim().toLowerCase();

    if (this.dataSource_itens_estoque.paginator) {
      this.dataSource_itens_estoque.paginator.firstPage();
    }
  }


  @ViewChild('input_id') input_id!: ElementRef
  @ViewChild('input_nome') input_nome!: ElementRef
  @ViewChild('input_atualizacao_id') input_atualizacao_id!: ElementRef
  @ViewChild('input_criador_id') input_criador_id!: ElementRef
  @ViewChild('input_tipo') input_tipo!: ElementRef
  @ViewChild('input_codigo_interno') input_codigo_interno!: ElementRef
  @ViewChild('input_data_criacao') input_data_criacao!: ElementRef
  @ViewChild('input_quantidade') input_quantidade!: ElementRef

  async criar_item() {

    const id: number = parseInt(this.input_id.nativeElement.value)
    const ultima_atualizacao_id: number = parseInt(this.input_atualizacao_id.nativeElement.value)
    const criador_id: number = environment.user_atual.ID
    const tipo: string = this.input_tipo.nativeElement.value
    const codigo_interno: string = this.input_codigo_interno.nativeElement.value
    const data_criacao: string = this.input_data_criacao.nativeElement.value
    let quantidade: number = 0

    if (!isNaN(parseInt(this.input_quantidade.nativeElement.value))) {
      quantidade = parseInt(this.input_quantidade.nativeElement.value)
    }

    const nome: string = this.input_nome.nativeElement.value

    if (this.input_id.nativeElement.value != '') {
      alert('Item já existente!')
    } else {
      let status: boolean = true
      let doc: boolean = true
      let cod_barras: boolean = true
      let cce: boolean = true
      let osme: boolean = true

      if (this.selectedStatus != 'ATIVO') {
        status = false
      }
      if (this.selectedCodBarras != 'SIM') {
        cod_barras = false
      }
      if (this.selectedOsme != 'SIM') {
        osme = false
      }
      if (this.selectedCce != 'SIM') {
        cce = false
      }
      if (this.selectedDoc != 'SIM') {
        doc = false
      }

      const body: CREATE_ITEM = {
        DATA_REGISTRO: '',
        ULTIMA_ATUALIZACAO_ID: 0,
        CRIADOR_ID: criador_id,
        QUANTIDADE: quantidade,
        EMPRESA_ID: environment.user_atual.EMPRESA_ID,
        ATIVO: status,
        COD_BARRAS: cod_barras,
        TIPO: tipo,
        NOME: nome,
        COD_INTERNO: codigo_interno,
        CCE: cce,
        OSME: osme,
        DOC: doc
      }

      if (body.NOME != '' && body.COD_INTERNO != '' && body.QUANTIDADE > 0 && body.TIPO != '') {

        const req_criar_item: any = await this.allservices.POST_criar_item(body)
        const retorno_1: RETORNO_SERVIDOR = req_criar_item

        if (retorno_1.MSG == 'SUCESSO') {
          alert(retorno_1.ADICIONAL)

          this.limpar()
          this.atualiza_lista()

        } else {
          alert(retorno_1.ADICIONAL)
        }

      } else {
        alert('Os campos: NOME, CODIGO INTERNO, QUANTIDADE E TIPO são obrigatórios!')
      }
    }



  }

  editar(ITEM: ITEM) {
    this.input_id.nativeElement.value = ITEM.ID
    this.input_atualizacao_id.nativeElement.value = ITEM.ULTIMA_ATUALIZACAO_ID
    this.input_tipo.nativeElement.value = ITEM.TIPO
    this.input_codigo_interno.nativeElement.value = ITEM.COD_INTERNO
    this.input_data_criacao.nativeElement.value = ITEM.DATA_REGISTRO
    this.input_quantidade.nativeElement.value = ITEM.QUANTIDADE
    this.input_nome.nativeElement.value = ITEM.NOME
    this.input_criador_id.nativeElement.value = ITEM.CRIADOR_ID

    if (ITEM.ATIVO == true) {
      this.selectedStatus = 'ATIVO'
    } else {
      this.selectedStatus = 'INATIVO'
    }

    if (ITEM.COD_BARRAS == true) {
      this.selectedCodBarras = 'SIM'
    } else {
      this.selectedCodBarras = 'NÃO'
    }

    if (ITEM.OSME == true) {
      this.selectedOsme = 'SIM'
    } else {
      this.selectedOsme = 'NÃO'
    }

    if (ITEM.CCE == true) {
      this.selectedCce = 'SIM'
    } else {
      this.selectedCce = 'NÃO'
    }

    if (ITEM.DOC == true) {
      this.selectedDoc = 'SIM'
    } else {
      this.selectedDoc = 'NÃO'
    }


  }

  async salvar_edicao() {

    const id: number = parseInt(this.input_id.nativeElement.value)
    const ultima_atualizacao_id: number = parseInt(this.input_atualizacao_id.nativeElement.value)
    const criador_id: number = environment.user_atual.ID
    const tipo: string = this.input_tipo.nativeElement.value
    const codigo_interno: string = this.input_codigo_interno.nativeElement.value
    const data_criacao: string = this.input_data_criacao.nativeElement.value
    let quantidade: number = 0

    if (!isNaN(parseInt(this.input_quantidade.nativeElement.value))) {
      quantidade = parseInt(this.input_quantidade.nativeElement.value)
    }

    const nome: string = this.input_nome.nativeElement.value

    if (this.input_id.nativeElement.value == '') {
      alert('Nenhum item selecionado!')
    } else {
      let status: boolean = true
      let doc: boolean = true
      let cod_barras: boolean = true
      let cce: boolean = true
      let osme: boolean = true

      if (this.selectedStatus != 'ATIVO') {
        status = false
      }
      if (this.selectedCodBarras != 'SIM') {
        cod_barras = false
      }
      if (this.selectedOsme != 'SIM') {
        osme = false
      }
      if (this.selectedCce != 'SIM') {
        cce = false
      }
      if (this.selectedDoc != 'SIM') {
        doc = false
      }

      const body: ITEM = {
        DATA_REGISTRO: data_criacao,
        ULTIMA_ATUALIZACAO_ID: 0,
        CRIADOR_ID: criador_id,
        QUANTIDADE: quantidade,
        EMPRESA_ID: environment.user_atual.EMPRESA_ID,
        ATIVO: status,
        COD_BARRAS: cod_barras,
        TIPO: tipo,
        NOME: nome,
        COD_INTERNO: codigo_interno,
        CCE: cce,
        OSME: osme,
        DOC: doc,
        ID: id
      }

      if (body.NOME != '' && body.COD_INTERNO != '' && body.QUANTIDADE > 0 && body.TIPO != '') {

        const req_editar_item: any = await this.allservices.POST_editar_item(body)
        const retorno_1: RETORNO_SERVIDOR = req_editar_item

        if (retorno_1.MSG == 'SUCESSO') {
          alert(retorno_1.ADICIONAL)

          this.limpar()
          this.atualiza_lista()

        } else {
          alert(retorno_1.ADICIONAL)
        }

      } else {
        alert('Os campos: NOME, CODIGO INTERNO, QUANTIDADE E TIPO são obrigatórios!')
      }
    }


  }

  async deletar(ITEM: ITEM) {
    const req_delete_item: any = await this.allservices.POST_delete_item(ITEM.ID)
    const retorno_4: RETORNO_SERVIDOR = req_delete_item

    if (retorno_4.MSG == 'SUCESSO') {
      alert(retorno_4.ADICIONAL)


      this.atualiza_lista()
      this.limpar()

    } else {
      alert(retorno_4.ADICIONAL)
    }
  }

  limpar() {
    this.input_id.nativeElement.value = ''
    this.input_atualizacao_id.nativeElement.value = ''
    this.input_tipo.nativeElement.value = ''
    this.input_codigo_interno.nativeElement.value = ''
    this.input_data_criacao.nativeElement.value = ''
    this.input_quantidade.nativeElement.value = ''
    this.input_nome.nativeElement.value = ''
    this.input_criador_id.nativeElement.value = ''

    this.selectedStatus = 'ATIVO'
    this.selectedCodBarras = 'SIM'
    this.selectedOsme = 'SIM'
    this.selectedCce = 'SIM'
    this.selectedDoc = 'SIM'


  }

  async atualiza_lista() {
    const req_buscar_users: any = await this.allservices.POST_listar_users({EMPRESA_ID:environment.user_atual.EMPRESA_ID})
    const retorno_3: RETORNO_SERVIDOR = req_buscar_users

    if (retorno_3.MSG == 'SUCESSO') {
      const req_buscar_item: any = await this.allservices.POST_listar_item(environment.user_atual.EMPRESA_ID)
      let retorno_2: RETORNO_SERVIDOR = req_buscar_item


      retorno_2.RESPOSTA.forEach((item, i) => {
        const foundItem = retorno_3.RESPOSTA.find(retornoItem => retornoItem.ID === item.CRIADOR_ID);
        if (foundItem) {
          retorno_2.RESPOSTA[i].CRIADOR_ID = foundItem.NOME
        }
      });


      if (retorno_2.MSG == 'SUCESSO') {
        this.dataSource_itens_estoque = new MatTableDataSource(retorno_2.RESPOSTA);



        this.dataSource_itens_estoque.paginator = this.paginator_itens_estoque;
        this.dataSource_itens_estoque.sort = this.sort_itens_estoque;
      } else {
        alert(retorno_2.ADICIONAL)
      }
    } else {
      alert(retorno_3.ADICIONAL)
    }



  }
}
