import { Component, EventEmitter, Output } from '@angular/core';
import { DADOS_PARAMETRO } from '../../../../MODELS/DADOS_PARAMETRO';

@Component({
  selector: 'app-divix-parametros-analise',
  standalone: true,
  templateUrl: './divix-parametros-analise.component.html',
  styleUrls: ['./divix-parametros-analise.component.css']
})
export class DivixParametrosAnaliseComponent {
  Nat_rangeValue: number = 20; // Valor inicial do range
  Nat_inputValue: number = 0; // Valor inicial do input de texto
  Nat_checkboxValue: boolean = false; // Estado inicial do checkbox

  Nat_tipo_parametro: "LIVRO" | "DURACAO" | "ITENS" = "ITENS"

  @Output() Evento_parametros = new EventEmitter<DADOS_PARAMETRO>()//quantidade de livros,itens ou minutos que esta sendo o parametro atual

  // Funções para lidar com mudanças nos campos
  Nat_onRangeChange(event: Event): void {
    const target = event.target as HTMLInputElement;
    this.Nat_rangeValue = Number(target.value);



   
  }

  Nat_onInputChange(event: Event): void {
    const target = event.target as HTMLInputElement;
    this.Nat_inputValue = parseInt(target.value);

  

  

  }

  Nat_onTipoChange() {
    if (this.Nat_tipo_parametro == "LIVRO") {
      this.Nat_tipo_parametro = "DURACAO"
    } else if (this.Nat_tipo_parametro == "DURACAO") {
      this.Nat_tipo_parametro = "ITENS"
    } else {
      this.Nat_tipo_parametro = "LIVRO"
    }
  }

  Nat_onCheckboxChange(event: Event): void {
    const target = event.target as HTMLInputElement;
    this.Nat_checkboxValue = target.checked;

  }


  Nat_send_dados(){
    this.Evento_parametros.emit({
      TIPO: this.Nat_tipo_parametro,
      QUANTIDADE: this.Nat_inputValue,
      VARIACAO: (this.Nat_rangeValue / 100),
      QUADRAS: this.Nat_checkboxValue
    })
  }



}
