
<div class="flex min-h-screen w-full overflow-x-hidden flex-col justify-center py-12 sm:px-6 lg:px-8 bg-gradient-to-r from-slate-50 to-blue-200">
    <div class="sm:mx-auto sm:w-full sm:max-w-md py-4">
      <img class="mx-auto h-32 w-auto" src="https://res.cloudinary.com/de74yvifw/image/upload/v1728393036/EXFERA/logo_yw67pc.png" alt="Your Company">
      
    </div>
  
    <div class="mt-2 sm:mx-auto sm:w-full sm:max-w-[480px]">
      <div class="bg-white px-6 py-6 shadow sm:rounded-lg sm:px-12">
        <form class="space-y-6" >
            <p class="py-4 text-center text-2xl/9 font-bold tracking-tight text-gray-700">Acesse sua Conta Xfera</p>
          <div>
            <label for="email" class="block text-sm/6 font-medium text-gray-900">Email</label>
            <div class="mt-2">
              <input id="email" #inp_email name="email" type="email" autocomplete="email" required class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm/6">
            </div>
          </div>
  
          <div>
            <label for="password" class="block text-sm/6 font-medium text-gray-900">Senha</label>
            <div class="mt-2">
              <input id="password" #inp_password name="password" type="password" autocomplete="current-password" required class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm/6">
            </div>
          </div>

          <div class="py-4">
            
<form class="max-w-sm mx-auto">
  <label for="countries" class="block mb-2 text-sm font-medium
   text-gray-900 dark:text-white">Selecione um Módulo</label>
  <select
  (change)="Nat_change_modulo_inicial($event)"
  id="modulos" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg
   focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5
    dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400
     dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">

    <option selected value="">Escolha um Módulo</option>
    <option value="gpx">Gpx</option>
    <option value="divix">Divix</option>
    <option value="routex">Routex</option>
    <option value="aordex">Aordex Admin</option>
    <option value="ceara_apps">Ceará Apps</option>
    <option value="stox_user">Stox User</option>
    <option value="stox_admin">Stox Admin</option>
    <option value="xsocial">XSocial</option>
  </select>
</form>

          </div>
  
          <div class="flex items-center justify-between nt-4">
         
  
            <div class="text-sm/6">
              <a 
              (click)="Nat_open_reset_senha()"
              blue class="font-semibold text-blue-600 hover:text-blue-600 hover:underline cursor-pointer">Resetar Senha?</a>
            </div>

            <div class="text-sm/6">
              <a 
              (click)="Nat_goto('')"
              blue class="font-semibold text-blue-600 hover:text-blue-600 hover:underline cursor-pointer">Página Inicial</a>
            </div>
          </div>
  
          <div>
            <button
            (click)="Nat_gerar_token()"
            type="button" class="flex w-full justify-center rounded-md
             bg-blue-600 px-3 py-1.5 text-sm/6 font-semibold text-white shadow-sm
              hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 
              focus-visible:outline-offset-2 focus-visible:outline-blue-600">Gerar Token</button>
          </div>
        </form>
  
        <div>
          <div class="relative mt-10">
            <div class="absolute inset-0 flex items-center" aria-hidden="true">
              <div class="w-full border-t border-gray-200"></div>
            </div>
            <div class="relative flex justify-center text-sm/6 font-medium">
              <span class="bg-white px-6 text-gray-900">Ou Siga para</span>
            </div>
          </div>
  
          <div class="mt-6 grid grid-cols-2 gap-4">
            <a blue class="flex w-full items-center justify-center gap-3 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:ring-transparent">
           
              <span class="text-sm/6 font-semibold">Empresa</span>
            </a>
  
            <a blue class="flex w-full items-center justify-center gap-3 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:ring-transparent">
              <svg class="h-5 w-5 fill-[#24292F]" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
                <path fill-rule="evenodd" d="M10 0C4.477 0 0 4.484 0 10.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0110 4.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.203 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.942.359.31.678.921.678 1.856 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0020 10.017C20 4.484 15.522 0 10 0z" clip-rule="evenodd" />
              </svg>
              <span class="text-sm/6 font-semibold">Desenvolvedores</span>
            </a>
          </div>
        </div>
      </div>
  
      <p class="mt-10 text-center text-sm/6 text-gray-500">
        Não Possui Cadastro?
        <a blue class="font-semibold text-blue-600 hover:text-blue-500 cursor-pointer hover:underline" 
        (click)="Nat_goto('cadastro')"
        >Solicite Acesso</a>
      </p>
    </div>



<!--modal de verificacao em duas etapas-->
@if(Nat_status_modal_token){
  <app-login-modal-token
  (Evento_send_token)="Out_recebe_token_digitado($event)"
  (Evento_close_modal_token)="Out_close_modal_token($event)"
  >
  </app-login-modal-token>
  
}

<!--modal de reset de senha-->
@if(Nat_status_modal_reset_senha){

<app-login-modal-reset-senha
(Evento_close_modal_reset_senha)="Out_close_modal_reset_senha($event)"
(Evento_close_modal_reset_senha_status)="Out_close_modal_reset_senha_status($event)"
></app-login-modal-reset-senha>

}


<!--alert de sucesso-->
@if(Nat_status_alert_sucesso){

  <app-alert-sucesso
  [Input_dados_alert]="Nat_dados_alert"
  (Evento_close_alert_sucesso)="Out_close_alert_sucesso($event)"
  >
  </app-alert-sucesso>
}
<!--alert de erro-->
@if(Nat_status_alert_erro){
  <app-alert-erro
  [Input_dados_alert]="Nat_dados_alert"
  (Evento_close_alert_erro)="Out_close_alert_erro($event)"
  >
  </app-alert-erro>
}

  </div>
  