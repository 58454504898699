import { Component, ElementRef, EventEmitter, Input, numberAttribute, OnInit, Output, ViewChild } from '@angular/core';
import { AordexService } from '../../SERVICES/aordex.service';
import { DADOS_ALERT } from '../../../../MODELS/DADOS_ALERT';
import { BASE, close_and_delete, EQUIPE, REQ_TRIP_AORDEX, ROTEIRO_MAPA } from '../../../../MODELS/MODELS_AORDEX';
import { MatDatepicker, MatDatepickerInputEvent, MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { RESPOSTA_PADRAO_SERVIDOR } from '../../../../MODELS/RESPOSTA_PADRAO_SERVIDOR';
import { environment } from '../../../../../environments/environment.development';



import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { AsyncPipe } from '@angular/common';
import { MatAutocompleteModule } from '@angular/material/autocomplete';


@Component({
  selector: 'app-detalhes-roteiro',
  standalone: true,
  imports: [MatDatepickerModule, MatNativeDateModule, MatInputModule, MatFormFieldModule, FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    AsyncPipe,],
    
  templateUrl: './detalhes-roteiro.component.html',
  styleUrl: './detalhes-roteiro.component.css'
})
export class DetalhesRoteiroComponent implements OnInit {

  constructor(private aordexService: AordexService) { }


  /**evento que abre a aba de loading no component main */
  @Output() Evento_open_loading = new EventEmitter<boolean>();

  /**evento que fecha a aba de loading no component main */
  @Output() Evento_close_loading = new EventEmitter<boolean>();

  /**evento que abre um alerta de sucesso no component main */
  @Output() Evento_open_alert_sucess = new EventEmitter<DADOS_ALERT>();

  /**evento que abre um alerta de erro no component main */
  @Output() Evento_open_alert_erro = new EventEmitter<DADOS_ALERT>();

  @Output() Evento_change_state_aba = new EventEmitter<close_and_delete>();


  @Input() input_roteiros_mapa: ROTEIRO_MAPA[]
  @Input() input_roteiro_selecionado_id: number
  @Input() input_ids_notas_new_roteiro: number[]



  @ViewChild('picker') datepicker!: MatDatepicker<Date>;

  @ViewChild('inp_data') inp_data: ElementRef

  @ViewChild('inp_hora_inicio') inp_hora_inicio: ElementRef

  @ViewChild('inp_hora_fim') inp_hora_fim: ElementRef

  @ViewChild('inp_duracao') inp_duracao: ElementRef

  @ViewChild('inp_percurso') inp_percurso: ElementRef
  openDatepicker() {
    this.datepicker.open();
  }
  onDateChange(event: MatDatepickerInputEvent<Date>): void {
    if (event.value) {
      const date = new Date(event.value);
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();
      this.inp_data.nativeElement.value = `${day}/${month}/${year}`
    }
  }

  Nat_bases: BASE[] = []
  Nat_equipes: EQUIPE[] = []


  myControl = new FormControl('');
  options: string[] = [];
  filteredOptions: Observable<string[]>;


 

  async ngOnInit() {

    console.log(this.input_ids_notas_new_roteiro)

    if (this.input_roteiro_selecionado_id) {//se estiver abrindo um roteiro ja criado

    } else {//se estiver planejando um novo roteiro
      const requisicao_server: any = await this.aordexService.POST_BUSCAR_BASES(environment.user_atual.EMPRESA_ID);
      const resposta: RESPOSTA_PADRAO_SERVIDOR = requisicao_server;

      if (resposta.MSG == 'SUCESSO') {

        const requisicao_server2: any = await this.aordexService.POST_BUSCAR_EQUIPES(environment.user_atual.EMPRESA_ID);
        const resposta2: RESPOSTA_PADRAO_SERVIDOR = requisicao_server2;

        if (resposta2.MSG == 'SUCESSO') {


          this.Nat_bases = resposta.RESPOSTA
          this.Nat_equipes = resposta2.RESPOSTA

          this.options = this.Nat_equipes.map((equipe) => `${equipe.ID}-${equipe.CCE}`)


          this.filteredOptions = this.myControl.valueChanges.pipe(
            startWith(''),
            map(value => this._filter(value || '')),
          );


        } else {
          this.Evento_close_loading.emit(true)
          let new_alert: DADOS_ALERT = {
            TITULO: resposta2.MSG,
            CORPO: resposta2.ADICIONAL
          }
          this.Evento_open_alert_erro.emit(new_alert)
        }

      } else {
        this.Evento_close_loading.emit(true)
        let new_alert: DADOS_ALERT = {
          TITULO: resposta.MSG,
          CORPO: resposta.ADICIONAL
        }
        this.Evento_open_alert_erro.emit(new_alert)
      }
    }

  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.options.filter(option => option.toLowerCase().includes(filterValue));
  }

  Nat_equipe_selecionada_id: number
  Nat_select_equipe(equipe: any) {
    const equipe_id_selecionada: number = parseInt(equipe.option.value.split('-')[0])
    this.Nat_equipe_selecionada_id = equipe_id_selecionada
  }
  Nat_base_selecionada_id: number
  Nat_select_base(base_id: string) {
    this.Nat_base_selecionada_id = parseInt(base_id)
  }
  Nat_modal_selecionado: string
  Nat_select_modal(modal: string) {
    if (modal != '') {
      this.Nat_modal_selecionado = modal
    }
  }


 
  @Output() Event_send_roteiro = new EventEmitter<any[]>()

  Nat_pontos_calculados:any[] = []
  async Nat_calcular_roteiro() {
    // console.log(
    //   this.Nat_equipe_selecionada_id,
    //   this.Nat_base_selecionada_id,
    //   this.inp_data.nativeElement.value,
    //   this.inp_hora_inicio.nativeElement.value,
    //   this.inp_hora_fim.nativeElement.value,
    //   this.input_ids_notas_new_roteiro,
    //   this.Nat_modal_selecionado)


      let new_calq_roteiro: REQ_TRIP_AORDEX = {
        BASE_ID: this.Nat_base_selecionada_id,
        EQUIPE_ID: this.Nat_equipe_selecionada_id,
        NOTAS_IDS: this.input_ids_notas_new_roteiro,
        DATA_REALIZACAO: this.inp_data.nativeElement.value, // Formato DD/MM/YYYY
        HORA_INICIO: this.inp_hora_inicio.nativeElement.value,
        MODAL: this.Nat_modal_selecionado,
      };
      
      // Obter a data atual
      const now = new Date();
      now.setHours(0, 0, 0, 0); // Zera horas, minutos, segundos e milissegundos para comparação
      
      // Transformar a data de entrada do usuário (DD/MM/YYYY) em um objeto Date
      let userDate = null;
      if (new_calq_roteiro.DATA_REALIZACAO) {
        const [day, month, year] = new_calq_roteiro.DATA_REALIZACAO.split('/').map(Number);
        userDate = new Date(year, month - 1, day); // `month - 1` porque os meses no JS começam em 0
      }
      
      if (!new_calq_roteiro.BASE_ID) {
        this.Evento_close_loading.emit(true)
        let new_alert: DADOS_ALERT = {
          TITULO: 'ERRO',
          CORPO: 'Base é obrigatório.'
        }
        this.Evento_open_alert_erro.emit(new_alert)
        
      } else if (!new_calq_roteiro.EQUIPE_ID) {
        this.Evento_close_loading.emit(true)
        let new_alert: DADOS_ALERT = {
          TITULO: 'ERRO',
          CORPO: 'Equipe é obrigatório.'
        }
        this.Evento_open_alert_erro.emit(new_alert)
        
      }else if (new_calq_roteiro.HORA_INICIO == '00:00' ||parseInt(new_calq_roteiro.HORA_INICIO.split(':')[0])<6 ) {
        this.Evento_close_loading.emit(true)
        let new_alert: DADOS_ALERT = {
          TITULO: 'ERRO',
          CORPO: 'Insira um Horário de Inicio Válido.'
        }
        this.Evento_open_alert_erro.emit(new_alert)
        
      } else if (new_calq_roteiro.MODAL=="") {
        this.Evento_close_loading.emit(true)
        let new_alert: DADOS_ALERT = {
          TITULO: 'ERRO',
          CORPO: 'Modal é obrigatório.'
        }
        this.Evento_open_alert_erro.emit(new_alert)
        
      } else if (new_calq_roteiro.NOTAS_IDS.length === 0) {
        this.Evento_close_loading.emit(true)
        let new_alert: DADOS_ALERT = {
          TITULO: 'ERRO',
          CORPO: 'Nenhuma nota selecionada.'
        }
        this.Evento_open_alert_erro.emit(new_alert)
        
      } else if (
        !userDate || // Verifica se a data foi fornecida
        userDate < now // Compara se a data do usuário é anterior à data atual
      ) {
        this.Evento_close_loading.emit(true)
        let new_alert: DADOS_ALERT = {
          TITULO: 'ERRO',
          CORPO: 'A data selecionada deve ser igual ou superior à data atual.'
        }
        this.Evento_open_alert_erro.emit(new_alert)
      
      } else {

        if(new_calq_roteiro.MODAL == 'pe'){
          new_calq_roteiro.MODAL = 'walking'
        }else{
          new_calq_roteiro.MODAL = 'driving'
        }



        const requisicao_server: any = await this.aordexService.POST_OSRM_TRIP_AORDEX(new_calq_roteiro);
        let resposta: RESPOSTA_PADRAO_SERVIDOR = requisicao_server;
    
        if (resposta.MSG == 'SUCESSO') {

       

          resposta.RESPOSTA.pontos.sort((a,b)=>b.SEQUENCIA-a.SEQUENCIA)


          this.Nat_pontos_calculados = resposta.RESPOSTA.pontos

          this.inp_duracao.nativeElement.value = resposta.RESPOSTA.duracao_total.split('.')[0]
          this.inp_percurso.nativeElement.value = `${resposta.RESPOSTA.distancia_total} Km`
          this.inp_hora_fim.nativeElement.value = `${resposta.RESPOSTA.pontos[0].HORARIO_VISITA.split(' ')[1].split(':')[0]}:${resposta.RESPOSTA.pontos[0].HORARIO_VISITA.split(' ')[1].split(':')[1]}`
          this.Event_send_roteiro.emit(resposta.RESPOSTA)
    
        } else {
          this.Evento_close_loading.emit(true)
          let new_alert: DADOS_ALERT = {
            TITULO: resposta.MSG,
            CORPO: resposta.ADICIONAL
          }
          this.Evento_open_alert_erro.emit(new_alert)
        }
      }
      



  }


  async Nat_atribuir_roteiro() {
    const requisicao_server: any = await this.aordexService.POST_ATRIBUIR_NOTAS({
      pontos: this.Nat_pontos_calculados,
      equipe_id: this.Nat_equipe_selecionada_id,
      base_id: this.Nat_base_selecionada_id,
      user_dados: environment.user_atual
    });
    const resposta: RESPOSTA_PADRAO_SERVIDOR = requisicao_server;
  
    if (resposta.MSG === 'SUCESSO') {
      this.Evento_close_loading.emit(true);
      const new_alert: DADOS_ALERT = {
        TITULO: resposta.MSG,
        CORPO: resposta.ADICIONAL
      };
      this.Evento_open_alert_sucess.emit(new_alert);
  
      // Aguardar 1 segundo antes de fechar
      await new Promise(resolve => setTimeout(resolve, 1000));
      this.Nat_close(true);
    } else {
      this.Evento_close_loading.emit(true);
      const new_alert: DADOS_ALERT = {
        TITULO: resposta.MSG,
        CORPO: resposta.ADICIONAL
      };
      this.Evento_open_alert_erro.emit(new_alert);
    }
  }
  
  Nat_close(deletar:boolean) {
    this.Evento_change_state_aba.emit({
      state: false,
      delete: deletar
    })
  }

}
