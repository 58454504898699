<div class="h-screen w-screen">





    <!--navbar menu principal-->
    <app-navbar-aordex 
    
        [Nat_state_FERRAMENTAS]="Nat_state_FERRAMENTAS"
        [input_state_new_equipe]="Nat_state_new_equipe"
        [input_state_view_equipe]="Nat_state_view_equipe"
        [input_bases_mapa]="Nat_bases_on_map"
        (Evento_close_perfil)="Out_change_state_perfil($event)"
        (Evento_change_state_FERRAMENTAS)="Out_change_aba_FERRAMENTAS($event)"
        (Evento_change_state_MODULOS)="Out_change_aba_MODULOS($event)"
        (Evento_local_buscado)="Out_move_local_buscado($event)"
        (Evento_abrir_informacoes_importacao)="Out_change_informacao_importacao($event)"
        (Evento_open_loading)="Out_open_loading($event)" (Evento_close_loading)="Out_close_loading($event)"
        (Evento_open_alert_sucess)="Out_open_alert_sucess($event)"
        (Evento_open_alert_erro)="Out_open_alert_erro($event)" 
        (Evento_close_perfil)="Out_change_state_perfil($event)"
        (Evento_change_state_new_equipe)="Nat_change_state_new_equipe($event)"
        (Evento_change_state_view_equipe)="Nat_change_state_view_equipe($event)"
        (Evento_change_state_new_base)="Nat_change_state_new_base($event)"
        (Evento_change_state_view_base)="Nat_change_state_view_base($event)"
        (Evento_change_state_open_bases)="Nat_change_state_open_bases($event)"


        (Evento_change_state_pendentes)="Out_change_pendentes()"
        (Evento_change_state_atribuidas)="Out_change_atribuidas()"
        (Evento_change_state_concluidas)="Out_change_concluidas()"
        (Evento_change_state_rejeitadas)="Out_change_rejeitadas()"
        (Evento_change_state_roteiros)="Out_change_roteiros()"
        (Evento_change_state_importacoes)="Out_change_importacoes()"
        ></app-navbar-aordex>




@if(Nat_state_informacao_importacao){
    <app-info-importacao

    (Evento_close_informacao)="Out_change_informacao_importacao($event)"
    >
   
    </app-info-importacao>
   
}

@if(Nat_state_change_importacoes){
    <app-importacoes-main
    (Evento_open_loading)="Out_open_loading($event)" 
    (Evento_close_loading)="Out_close_loading($event)"
    (Evento_open_alert_sucess)="Out_open_alert_sucess($event)"
    (Evento_open_alert_erro)="Out_open_alert_erro($event)" 
    >

    </app-importacoes-main>
}


@if(Nat_state_change_pendentes){
    <app-pendentes-main
    [input_pendentes_mapa]="Notas_pendentes_mapa"

    (Evento_open_loading)="Out_open_loading($event)" 
    (Evento_close_loading)="Out_close_loading($event)"
    (Evento_open_alert_sucess)="Out_open_alert_sucess($event)"
    (Evento_open_alert_erro)="Out_open_alert_erro($event)" 
    (Evento_change_coordenada)="Out_change_state_nova_coordenada($event)" 

    (Evento_view_notas)="Out_view_notas_pendentes($event)"
    (Evento_remove_notas)="Out_remove_notas_pendentes($event)"
    >

    </app-pendentes-main>
    
}

@if(Nat_state_change_atribuidas){
    <app-atribuidas-main
    [input_atribuidas_mapa]="Notas_atribuidas_mapa"

    (Evento_open_loading)="Out_open_loading($event)" 
    (Evento_close_loading)="Out_close_loading($event)"
    (Evento_open_alert_sucess)="Out_open_alert_sucess($event)"
    (Evento_open_alert_erro)="Out_open_alert_erro($event)" 
    (Evento_change_coordenada)="Out_change_state_nova_coordenada($event)" 

    (Evento_view_notas)="Out_view_notas_atribuidas($event)"
    (Evento_remove_notas)="Out_remove_notas_atribuidas($event)"
    >

    </app-atribuidas-main>
    
}


@if(Nat_state_change_concluidas){
    <app-concluidas-main
    [input_concluidas_mapa]="Notas_concluidas_mapa"

    (Evento_open_loading)="Out_open_loading($event)" 
    (Evento_close_loading)="Out_close_loading($event)"
    (Evento_open_alert_sucess)="Out_open_alert_sucess($event)"
    (Evento_open_alert_erro)="Out_open_alert_erro($event)" 
    (Evento_change_coordenada)="Out_change_state_nova_coordenada($event)" 

    (Evento_view_notas)="Out_view_notas_concluidas($event)"
    (Evento_remove_notas)="Out_remove_notas_concluidas($event)"
    >

    </app-concluidas-main>
    
}

@if(Nat_state_change_rejeitadas){
    <app-rejeitadas-main
    [input_rejeitadas_mapa]="Notas_rejeitadas_mapa"

    (Evento_open_loading)="Out_open_loading($event)" 
    (Evento_close_loading)="Out_close_loading($event)"
    (Evento_open_alert_sucess)="Out_open_alert_sucess($event)"
    (Evento_open_alert_erro)="Out_open_alert_erro($event)" 
    (Evento_change_coordenada)="Out_change_state_nova_coordenada($event)" 

    (Evento_view_notas)="Out_view_notas_rejeitadas($event)"
    (Evento_remove_notas)="Out_remove_notas_rejeitadas($event)"
    >

    </app-rejeitadas-main>
    
}

@if(Nat_state_change_roteiros){
   <app-roteiros
    [input_roteiros_mapa]="Nat_roteiros_em_mapa"

    (Evento_open_loading)="Out_open_loading($event)" 
    (Evento_close_loading)="Out_close_loading($event)"
    (Evento_open_alert_sucess)="Out_open_alert_sucess($event)"
    (Evento_open_alert_erro)="Out_open_alert_erro($event)" 
    (Evento_change_coordenada)="Out_change_state_nova_coordenada($event)" 


    (Evento_view_roteiro)="Out_view_roteiro($event)"
    (Evento_remove_roteiro)="Out_remove_roteiro($event)"
    >

</app-roteiros>
    
}

@if(Nat_state_nova_coordenada){
    <app-mudar-coordenada
    (Evento_open_loading)="Out_open_loading($event)" 
    (Evento_close_loading)="Out_close_loading($event)"
    (Evento_open_alert_sucess)="Out_open_alert_sucess($event)"
    (Evento_open_alert_erro)="Out_open_alert_erro($event)" 
    (Evento_change_coordenada)="Out_change_state_nova_coordenada($event)" 
    [input_id_nota]="Nat_id_muda_coordenada"
    >
    </app-mudar-coordenada>
}


@if(Nat_state_new_equipe){
    <app-new-equipe
    (Evento_open_loading)="Out_open_loading($event)" 
    (Evento_close_loading)="Out_close_loading($event)"
    (Evento_open_alert_sucess)="Out_open_alert_sucess($event)"
    (Evento_open_alert_erro)="Out_open_alert_erro($event)" 
    (Evento_change_state_new_equipe)="Nat_change_state_new_equipe($event)"
    >
    </app-new-equipe>
}


@if(Nat_state_new_base){
    <app-new-base
    (Evento_open_loading)="Out_open_loading($event)" 
    (Evento_close_loading)="Out_close_loading($event)"
    (Evento_open_alert_sucess)="Out_open_alert_sucess($event)"
    (Evento_open_alert_erro)="Out_open_alert_erro($event)" 
    (Evento_change_state_new_base)="Nat_change_state_new_base($event)"
    >
    </app-new-base>
}

@if(Nat_state_view_equipe){


    <app-view-equipe
    (Evento_change_state_view_equipe)="Nat_change_state_view_equipe($event)"
    >
    </app-view-equipe>
}
@if(Nat_state_view_base){


    <app-view-base
    (Evento_change_state_view_base)="Nat_change_state_view_base($event)"
    >
    </app-view-base>
}


    <!--tabela de ferramentas-->
    @if(Nat_state_FERRAMENTAS){
    <app-gpx-ferramentas [Input_estado_coleta_coordenada]="Nat_state_coleta_coordenada"
        [Input_estado_transferencia_itens]="Nat_state_transferencia_itens"
        (Evento_change_type_MAPA)="Out_change_map_type($event)"
        (Evento_change_state_COLETA_COORDENADA)="Out_change_state_coleta_coordenada($event)"
        (Evento_change_state_TRANSFERENCIA)="Out_change_transferencia($event)"></app-gpx-ferramentas>
    }

    @if(Nat_state_MODULOS){
    <app-modulos>

    </app-modulos>
    }


    @if(Nat_state_perfil){
    <app-perfil 
        (Evento_open_loading)="Out_open_loading($event)" 
        (Evento_close_loading)="Out_close_loading($event)"
        (Evento_open_alert_sucess)="Out_open_alert_sucess($event)"
        (Evento_open_alert_erro)="Out_open_alert_erro($event)" 
        (Evento_close_perfil)="Out_change_state_perfil($event)">
    </app-perfil>
    }

    @if(Nat_state_detalhes_roteiro){
        <app-detalhes-roteiro
        [input_roteiros_mapa]="Nat_roteiros_mapa"
        [input_roteiro_selecionado_id]="Nat_id_roteiro_selecionado"
        [input_ids_notas_new_roteiro]="Nat_ids_notas_new_roteiro"
        (Evento_open_loading)="Out_open_loading($event)" 
        (Evento_close_loading)="Out_close_loading($event)"
        (Evento_open_alert_sucess)="Out_open_alert_sucess($event)"
        (Evento_open_alert_erro)="Out_open_alert_erro($event)" 
        (Evento_change_state_aba)="Out_change_state_detalhes_roteiro($event)"
        (Event_send_roteiro)="Out_recebe_calculo_new_roteiro($event)"
        ></app-detalhes-roteiro>
    }
 


    <div id="map" class="h-full w-full z-10">
    </div>


    <!--loading-->
    @if(Nat_state_loading){
    <app-alert-loading></app-alert-loading>
    }



    <!--alert de sucesso-->
    @if(Nat_status_alert_sucesso){

    <app-alert-sucesso [Input_dados_alert]="Nat_dados_alert"
        (Evento_close_alert_sucesso)="Out_close_alert_sucesso($event)">
    </app-alert-sucesso>
    }
    <!--alert de erro-->
    @if(Nat_status_alert_erro){

    <app-alert-erro [Input_dados_alert]="Nat_dados_alert" (Evento_close_alert_erro)="Out_close_alert_erro($event)">
    </app-alert-erro>
    }
</div>