

<div class="w-full h-full">

    <ngb-carousel
	#carousel
	[interval]="2000"
	[pauseOnHover]="pauseOnHover"
	[pauseOnFocus]="pauseOnFocus"
	(slide)="onSlide($event)"
>
	@for (img of images; track img; let i = $index) {
		<ng-template ngbSlide>
			<div class="carousel-caption">
				
			</div>
			<a>
				<div class="picsum-img-wrapper">
					<img [src]="img" class="object-cover"/>
				</div>
			</a>
		</ng-template>
	}
</ngb-carousel>

</div>





