import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';

import * as L from 'leaflet';
import 'leaflet.markercluster';
import 'leaflet-draw';
import 'leaflet-editable';
import { AlertLoadingComponent } from '../../../../COMPONENTS_UI/alert-loading/alert-loading.component';
import { NavbarMainComponent } from "../../../../COMPONENTS_UI/navbar/navbar-main/navbar-main.component";

import { DADOS_ALERT } from '../../../../MODELS/DADOS_ALERT';
import { AlertSucessoComponent } from "../../../../COMPONENTS_UI/alert-sucesso/alert-sucesso.component";
import { AlertErroComponent } from "../../../../COMPONENTS_UI/alert-erro/alert-erro.component";
import { OPERACAO_ou_LIVRO } from '../../../../MODELS/OPERACAO_ou_LIVRO';
import { RESPOSTA_PADRAO_SERVIDOR } from '../../../../MODELS/RESPOSTA_PADRAO_SERVIDOR';

import { INSTALACAO_BD } from '../../../../MODELS/INSTALACAO_BD';
import { COORDENADA } from '../../../../MODELS/COORDENADA';

import hull from 'hull.js';
import { MainService } from '../../../../SERVICES/main.service';

import { DETALHES_POLIGONO } from '../../../../MODELS/DETALHES_POLIGONO';
import { environment } from '../../../../../environments/environment.development';


import { GpxFerramentasComponent } from '../../../GPX/COMPONENTS/gpx-ferramentas/gpx-ferramentas.component';
import { GpxService } from '../../../GPX/SERVICES/gpx.service';
import { NavbarDivixComponent } from "../../../../COMPONENTS_UI/navbar/navbar-divix/navbar-divix.component";
import { DivixEdicoesComponent } from '../divix-edicoes/divix-edicoes.component';
import { DivixLivrosComponent } from "../divix-livros/divix-livros.component";
import { DivixService } from '../../SERVICES/divix.service';
import { DivixDetalhesPoligonoComponent } from '../../../../COMPONENTS_UI/divix-detalhes-poligono/divix-detalhes-poligono.component';
import { DivixParametrosAnaliseComponent } from "../divix-parametros-analise/divix-parametros-analise.component";
import { DADOS_PARAMETRO } from '../../../../MODELS/DADOS_PARAMETRO';
import { OSRM_TRIP_RETURN } from '../../../../MODELS/OSRM_TRIP_RETORNO';
import { ModulosComponent } from '../../../../COMPONENTS_UI/modulos/modulos.component';
import { PerfilComponent } from '../../../../COMPONENTS_UI/perfil/perfil.component';




@Component({
  selector: 'app-divix-main',
  standalone: true,
  imports: [
    PerfilComponent,
    ModulosComponent,
    AlertLoadingComponent,
    NavbarMainComponent,
    DivixEdicoesComponent,
    GpxFerramentasComponent,
    AlertSucessoComponent,
    AlertErroComponent,
    DivixDetalhesPoligonoComponent,
    NavbarDivixComponent,
    DivixLivrosComponent,
    DivixParametrosAnaliseComponent
  ],
  templateUrl: './divix-main.component.html',
  styleUrl: './divix-main.component.css'
})
export class DivixMainComponent implements OnInit {
  private leaflet = (window as any).L; // Referência ao Leaflet com MarkerCluster

  map!: L.Map;

  Nat_dados_alert?: DADOS_ALERT

  Nat_state_loading: boolean = false

  Nat_status_alert_sucesso: boolean = false
  Out_close_alert_sucesso(status: boolean) {//fecha o alerta de sucesso
    this.Nat_status_alert_sucesso = status
    this.Nat_dados_alert = undefined
  }
  Nat_status_alert_erro: boolean = false
  Out_close_alert_erro(status: boolean) {//fecha o alerta de erro
    this.Nat_status_alert_erro = status
    this.Nat_dados_alert = undefined
  }


  constructor(private router: Router, private service: DivixService, private service_main: MainService) { }

  async ngOnInit() {



    if (environment.user_atual) {
      this.initMap();

      const response:any = await this.service.POST_LISTAR_ROTEIROS_MANUAIS(environment.user_atual.ID)

      if(response.MSG == 'SUCESSO'){
        this.rotas_manuais = response.RESPOSTA
      }



    } else {
      this.router.navigateByUrl('')
    }

  }



  Nat_normalMap: L.TileLayer
  Nat_satelliteMap: L.TileLayer
  Nat_simplifiedMap: L.TileLayer
  Nat_activeLayer: L.TileLayer


  Nat_draw_control: L.Control.Draw //recebe o controle de criacao de poligonos temporarios para seleção e transferencia de itens


  Nat_polygons_transferencia: L.Polygon[] = []
  Nat_polylines_transferencia: L.Polyline[] = [];
  modalAtual: 'a_pe' | 'moto' | 'carro' = 'a_pe';


  ids_itens_selecionados:number[] = []

  initMap() {
    // Inicializando o mapa com coordenadas e nível de zoom
    if(!this.map){
      // Inicializando o mapa com coordenadas e nível de zoom
      this.map = this.leaflet.map('map_divix', {
        center: [-14.2350, -51.9253],  // Coordenadas do centro inicial do mapa
        zoom: 5,  // Nível de zoom inicial
        //maxZoom: 18,  // Definindo o zoom 
        minZoom: 3,  // Definindo o zoom mínimo
        editable: true, // Habilita a edição no mapa
  
      });
      }

    navigator.geolocation.getCurrentPosition(
      (position) => {
        const userLat = position.coords.latitude;
        const userLng = position.coords.longitude;


        this.map.setView([userLat, userLng])

      })

    //clicar no mapa
    this.map.on('click', (event) => {
      if (this.Nat_state_coleta_coordenada) {
        /**
         * se a funcao de copia de coordenada manual estiver ativa chama a funcao que copia para a 
         * area de transferencia a coordenada
         */

        const latlng = event.latlng;
        const coordenada_coletada = latlng


        this.Nat_copy_value(`${coordenada_coletada.lat},${coordenada_coletada.lng}`)
      }
    })



    // Remover o controle de zoom
    this.map.zoomControl.remove()


    // Inicializando o controle de desenho com edição habilitada
    this.Nat_draw_control = new L.Control.Draw({
      position: 'bottomright',

      draw: {
        polyline: {
          shapeOptions: {
            color: 'red',
            weight: 4,
            opacity: 0.7
          }
        },
        polygon: {
          shapeOptions: {
            color: 'blue', // Cor do polígono
            weight: 5, // Espessura da borda
            opacity: 0.5, // Opacidade da borda
            fillOpacity: 0.2, // Opacidade de preenchimento
            fillColor: 'blue', // Cor de preenchimento
          },
        },
        rectangle: false, // Desativando a criação de retângulos
        circle: false, // Desativando a criação de círculos
        marker: false, // Desativando a criação de marcadores
        circlemarker: false
      },
    });





    this.map.on(L.Draw.Event.CREATED, (e: any) => {
      //limpando os dados de poligono selecionado
      this.Nat_detalhes_poligono = undefined

      const type = e.layerType,
        layer = e.layer;

      if (type === 'polygon') {
        // Verifica se já existe algum polígono no array e o remove
        if (this.Nat_polygons_transferencia.length > 0) {
          // Remover o polígono antigo do mapa
          const oldLayer = this.Nat_polygons_transferencia.pop(); // Remove o último polígono
          this.map.removeLayer(oldLayer); // Remove do mapa
        }

        // Habilitando a edição do polígono
        this.map.addLayer(layer);
        layer.enableEdit();
        this.Nat_polygons_transferencia.push(layer);



        // Evento de clique no polígono
        layer.on('click', async () => {


          // Encontrar o índice do polígono no array
          const index = this.Nat_polygons_transferencia.indexOf(layer);

          let ids_instalacoes_mapa: number[] = this.Nat_operacoes_mapa_edicoes
            .map(operacao => operacao.ITENS.map(item => item.ID)) // Mapeia os IDs de cada item
            .flat(); // Achata o array de arrays em um único array de números



          // Obtendo as coordenadas do polígono
          const latLngs = layer.getLatLngs(); // Isso retorna as coordenadas dos vértices do polígono

          // Transformando as coordenadas em um formato desejado (ex: [lat, lng])
          const coordenadas_poligono: COORDENADA[] = latLngs[0].map((latLng: L.LatLng) => ({
            lat: latLng.lat,
            lng: latLng.lng
          }));

          this.ids_itens_selecionados = []

          const requisicao_server: any = await this.service_main.POST_MARKER_IN_POLIGONO(coordenadas_poligono, ids_instalacoes_mapa, 'ZONE');
          const resposta: RESPOSTA_PADRAO_SERVIDOR = requisicao_server;

          if (resposta.MSG == 'SUCESSO') {

            this.ids_itens_selecionados = resposta.RESPOSTA
   

            let detalhes_poligono: DETALHES_POLIGONO = {
              OPERACAO_ID: undefined,
              CENTRO_LEITURA: undefined,
              GRUPO: undefined,
              LIVRO: undefined,
              DURACAO: undefined,
              TOTAL_ITENS: resposta.RESPOSTA.length,
              OPERACAO_ID_DEST: undefined,
              LIVRO_DEST: undefined,
              TIPO: 'SELECAO',
              MODULO: 'DIVIX',
              ITENS_ID: resposta.RESPOSTA,
              ID_INICIO: 0,
              DISTANCIA: undefined
            }

            this.Nat_detalhes_poligono = detalhes_poligono

            this.Nat_state_detalhes_poligono = true


          } else {
            this.Nat_state_loading = false
            let new_alert: DADOS_ALERT = {
              TITULO: 'ERRO',
              CORPO: resposta.ADICIONAL
            };
            this.Nat_dados_alert = new_alert;
            this.Nat_status_alert_erro = true;
          }
        });

        // Evento de edição do polígono (quando o vértice for movido)
        layer.on('editable:vertex:dragend', async () => {

          // Encontrar o índice do polígono no array
          const index = this.Nat_polygons_transferencia.indexOf(layer);

          let ids_instalacoes_mapa: number[] = this.Nat_operacoes_mapa_edicoes
            .map(operacao => operacao.ITENS.map(item => item.ID)) // Mapeia os IDs de cada item
            .flat(); // Achata o array de arrays em um único array de números



          // Obtendo as coordenadas do polígono
          const latLngs = layer.getLatLngs(); // Isso retorna as coordenadas dos vértices do polígono

          // Transformando as coordenadas em um formato desejado (ex: [lat, lng])
          const coordenadas_poligono: COORDENADA[] = latLngs[0].map((latLng: L.LatLng) => ({
            lat: latLng.lat,
            lng: latLng.lng
          }));

          const requisicao_server: any = await this.service_main.POST_MARKER_IN_POLIGONO(coordenadas_poligono, ids_instalacoes_mapa, 'ZONE');
          const resposta: RESPOSTA_PADRAO_SERVIDOR = requisicao_server;

          if (resposta.MSG == 'SUCESSO') {


            let detalhes_poligono: DETALHES_POLIGONO = {
              OPERACAO_ID: undefined,
              CENTRO_LEITURA: undefined,
              GRUPO: undefined,
              LIVRO: undefined,
              DURACAO: undefined,
              TOTAL_ITENS: resposta.RESPOSTA.length,
              OPERACAO_ID_DEST: undefined,
              LIVRO_DEST: undefined,
              TIPO: 'SELECAO',
              MODULO: 'DIVIX',
              ITENS_ID: resposta.RESPOSTA,
              ID_INICIO: 0,
              DISTANCIA: undefined
            }

            this.Nat_detalhes_poligono = detalhes_poligono

            this.Nat_state_detalhes_poligono = true


          } else {
            this.Nat_state_loading = false
            let new_alert: DADOS_ALERT = {
              TITULO: 'ERRO',
              CORPO: resposta.ADICIONAL
            };
            this.Nat_dados_alert = new_alert;
            this.Nat_status_alert_erro = true;
          }
        });
      }


      
    if (type === 'polyline') {
      if (this.Nat_polylines_transferencia.length > 0) {
        const oldLayer = this.Nat_polylines_transferencia.pop();
        this.map.removeLayer(oldLayer);
      }

      this.map.addLayer(layer);
      this.Nat_polylines_transferencia.push(layer);

      let distanciaTotal = 0;
      const latlngs = layer.getLatLngs();

      for (let i = 0; i < latlngs.length - 1; i++) {
        distanciaTotal += latlngs[i].distanceTo(latlngs[i + 1]);
      }

      const tempos = this.calcularTempos(distanciaTotal);

      const popup = L.popup()
        .setLatLng(latlngs[latlngs.length - 1])
        .setContent(`Distância: ${distanciaTotal.toFixed(2)}m <br> 
                     A pé: ${tempos.a_pe} min <br> 
                     Moto: ${tempos.moto} min <br> 
                     Carro: ${tempos.carro} min`)
        .openOn(this.map);

        layer.on('click', () => {
          this.exibirModal(layer, distanciaTotal);
        });
  
        this.exibirModal(layer, distanciaTotal);
    }
  });


    this.map.on(L.Draw.Event.DRAWSTART, (e: any) => {

      //limpando os dados de poligono selecionado
      this.Nat_detalhes_poligono = undefined

      if (this.Nat_polygons_transferencia.length > 0) {
        // Remover o polígono antigo do mapa
        const oldLayer = this.Nat_polygons_transferencia.pop(); // Remove o último polígono
        this.map.removeLayer(oldLayer); // Remove do mapa
      }

    })


    // Definindo as camadas de mapa
    this.Nat_normalMap = this.leaflet.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    });

    this.Nat_satelliteMap = this.leaflet.tileLayer('https://services.arcgisonline.com/arcgis/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', {
      attribution: 'Tiles © Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
    });

    this.Nat_simplifiedMap = this.leaflet.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}', {
      attribution: 'Tiles &copy; Esri &mdash; Esri, DeLorme, NAVTEQ',
    });

    // Adicionando o mapa normal inicialmente
    this.Nat_normalMap.addTo(this.map);

    // Inicializando a camada ativa
    this.Nat_activeLayer = this.Nat_normalMap;



  }

  itens_rota_manual:INSTALACAO_BD[] = []
  markers_rota_manual = []
  linhaRoteiroManualEscolhido
  rotas_manuais:{
    str_way_name: string,
    coordenadas:COORDENADA[],
    str_distancia_meters_pe:string,
    str_duration_secounds_pe: string,
    str_distancia_meters_moto: string,
    str_duration_secounds_moto: string,
    str_distancia_meters_carro: string,
    str_duration_secounds_carro: string,
    int_id_criador: number,
    str_data_hora_criacao: string,
    id:number
  }[] = []

  async select_rota_manual(id: string) {
    this.itens_rota_manual = [];
    
    // Remover os marcadores existentes
    this.markers_rota_manual.forEach((marker) => {
      marker.remove();
    });
    
    // Remover a linha existente, caso haja
    if (this.linhaRoteiroManualEscolhido) {
      this.linhaRoteiroManualEscolhido.remove();
    }
    
    if (id != '') {
      const response: any = await this.service.POST_ITENS_ROTEIRO_MANUAL(parseInt(id));
    
      if (response.MSG == 'SUCESSO') {
        const rotaSelecionada = this.rotas_manuais.find((rota) => rota.id == parseInt(id));
    
        // Garantir que as coordenadas estejam no formato [lat, lng]
        const coordenadas: L.LatLngExpression[] = rotaSelecionada.coordenadas.map((coord) => [coord.lat, coord.lng]);
    
        // Adicionar linha no mapa
        this.linhaRoteiroManualEscolhido = L.polyline(coordenadas, { color: 'blue' }).addTo(this.map);
    
        // Adicionar um evento de clique na linha para mostrar informações de percurso
        this.linhaRoteiroManualEscolhido.on('click', () => {
          const distancia = rotaSelecionada.str_distancia_meters_pe; // Exemplo de distância, altere conforme necessário
          const duracao = rotaSelecionada.str_duration_secounds_pe; // Exemplo de duração, altere conforme necessário
          const duracao_moto = rotaSelecionada.str_duration_secounds_moto;
          const duracao_carro = rotaSelecionada.str_duration_secounds_carro;
          
          // Exibir informações no popup da linha
          const popupContent = `
            <div>
              <p><strong>Distância a pé:</strong> ${distancia}</p>
              <p><strong>Duração a pé:</strong> ${this.converterParaMinutos(duracao)}</p>
              <p><strong>Duração a moto:</strong> ${this.converterParaMinutos(duracao_moto)}</p>
              <p><strong>Duração a carro:</strong> ${this.converterParaMinutos(duracao_carro)}</p>
              <button id="deleteRotaButton">Deletar Rota</button>
            </div>
          `;
          
          this.linhaRoteiroManualEscolhido.bindPopup(popupContent).openPopup();


          setTimeout(() => {
    
            const deleteButton = document.getElementById('deleteRotaButton');
            if (deleteButton) {

            

              deleteButton.addEventListener('click', () => {
                 // Remover a linha e os marcadores do mapa
      if (this.linhaRoteiroManualEscolhido) {
        this.linhaRoteiroManualEscolhido.remove();
      }
      this.markers_rota_manual.forEach(marker => {
        marker.remove();
      });
      
      // Limpar os arrays
      this.markers_rota_manual = [];
      this.itens_rota_manual = [];
              });
            }
          }, 100);
        });


    
        // Adicionar os marcadores para cada ponto de coordenada
        this.itens_rota_manual = response.RESPOSTA;
    
        this.itens_rota_manual.forEach((item) => {
          const latLng = L.latLng(item.GOOGLE_LAT, item.GOOGLE_LNG);
    
          // Criar o marcador
          const marker = L.marker(latLng).addTo(this.map);
    
          // Adicionar popup com os dados do trajeto (exemplo com a sequência na rua)
          marker.bindPopup(`Sequência: ${item.SEQUENCIA_NA_RUA}`).openPopup();
    
          // Guardar o marcador no array markers_rota_manual
          this.markers_rota_manual.push(marker);
        });
    
        // Opcional: Centralizar o mapa na linha desenhada
        this.map.fitBounds(this.linhaRoteiroManualEscolhido.getBounds());
      }
    }
  }
  
  // Função para converter segundos em minutos
  converterParaMinutos(segundos: string): string {
    const minutos = Math.floor(parseInt(segundos) / 60);
    const segundosRestantes = parseInt(segundos) % 60;
    return `${minutos} min ${segundosRestantes} s`;
  }
  

  
  exibirModal(layer: L.Polyline, distanciaTotal: number) {
    const tempos = this.calcularTempos(distanciaTotal);
  
    const latlngs = layer.getLatLngs() as L.LatLng[];
    const lastPoint = latlngs[latlngs.length - 1]; // Pegamos o último ponto
  
    if (!lastPoint) return; // Evita erro caso a linha esteja vazia
  
    const popupContent = document.createElement('div');
    popupContent.innerHTML = `
      <b>Distância:</b> ${distanciaTotal.toFixed(2)}m <br> 
      <b>A pé:</b> ${tempos.a_pe} min <br> 
      <b>Moto:</b> ${tempos.moto} min <br> 
      <b>Carro:</b> ${tempos.carro} min <br><br>
      <button id="inputButton">Salvar Roteiro</button>
      <button id="colarNaLinhaButton">Trazer para a Linha</button>
      <button id="deleteButton" style="background: red; color: white;">Deletar</button>
    `;
  
    const popup = L.popup()
      .setLatLng(lastPoint)
      .setContent(popupContent)
      .openOn(this.map);
  
    setTimeout(() => {
      const inputButton = document.getElementById('inputButton');
      if (inputButton) {
        inputButton.addEventListener('click', async () => {
          const userInput = prompt("Digite um nome para a Sequência:");
          if (userInput !== null && userInput.trim() !== "") {
            let instalacoes = [];
  
            this.Nat_operacoes_mapa_edicoes.forEach((operacao, o) => {
              operacao.ITENS.forEach((item, i) => {
                if (this.ids_itens_selecionados.includes(item.ID)) {
                  instalacoes.push(item);
                }
              });
            });
  
            try {
              instalacoes.sort((a, b) => a.SEQUENCIA_NA_RUA - b.SEQUENCIA_NA_RUA)

              const response = await this.service.POST_SALVAR_ROTEIRO_MANUAL(instalacoes, {
                str_way_name: userInput,
                str_distancia_meters_pe: distanciaTotal.toFixed(2),
                str_duration_secounds_pe: tempos.a_pe,
                str_distancia_meters_moto: distanciaTotal.toFixed(2),
                str_duration_secounds_moto: tempos.moto,
                str_distancia_meters_carro: distanciaTotal.toFixed(2),
                str_duration_secounds_carro: tempos.carro,
                int_id_criador: environment.user_atual.ID,
                coordenadas:instalacoes.map((instalacao)=>`${instalacao.GOOGLE_LAT},${instalacao.GOOGLE_LNG}`)
                
              });
       
              alert(`Novo percurso criado com o nome: ${userInput}`);
            } catch (error) {
              console.error("Erro ao salvar o roteiro:", error);
              alert("Erro ao salvar o roteiro. Tente novamente.");
            }
          }
        });
      }
  
      const colarNaLinhaButton = document.getElementById('colarNaLinhaButton');
      if (colarNaLinhaButton) {
        colarNaLinhaButton.addEventListener('click', () => {
          this.colarItensNaLinha(layer);
        });
      }
  
      const deleteButton = document.getElementById('deleteButton');
      if (deleteButton) {
        deleteButton.addEventListener('click', () => {
          if (confirm("Deseja realmente excluir esta linha?")) {
            this.ids_itens_selecionados = [];
            this.map.removeLayer(layer);
  
            const index = this.Nat_polylines_transferencia.indexOf(layer);
            if (index > -1) {
              this.Nat_polylines_transferencia.splice(index, 1);
            }
          }
        });
      }
    }, 100);
  }
  
  colarItensNaLinha(layer: L.Polyline) {
    const latlngs = layer.getLatLngs() as L.LatLng[];
  
    if (latlngs.length < 2) return; // Garante que há uma linha válida
  
    const pontosFracionados = this.fragmentarLinha(latlngs, 1); // Fragmenta metro a metro
    let sequenciaAtual = 0; // Sequência inicial
  
    // Criar um array para armazenar os itens ordenados pela sequência da linha
    const itensOrdenados: { item: any; pontoMaisProximo: L.LatLng; distancia: number }[] = [];
  
    this.Nat_operacoes_mapa_edicoes.forEach((operacao, o) => {
      operacao.ITENS.forEach((item, i) => {
        if (this.ids_itens_selecionados.includes(item.ID)) {
          const marcador = this.Nat_operacoes_mapa_edicoes[o].MARKERS[i];
          const posicaoAtual = marcador.getLatLng();
  
          // Encontrar o ponto da linha mais próximo
          let menorDistancia = Infinity;
          let pontoMaisProximo: L.LatLng | null = null;
          let indicePontoMaisProximo = -1;
  
          pontosFracionados.forEach((ponto, index) => {
            const distancia = posicaoAtual.distanceTo(ponto);
            if (distancia < menorDistancia) {
              menorDistancia = distancia;
              pontoMaisProximo = ponto;
              indicePontoMaisProximo = index;
            }
          });
  
          if (pontoMaisProximo) {
            itensOrdenados.push({
              item,
              pontoMaisProximo,
              distancia: menorDistancia,
            });
  
            marcador.setLatLng(pontoMaisProximo); // Atualiza a posição do marcador
          }
        }
      });
    });
  
    // Ordenar os itens pela sequência dos pontos na linha
    itensOrdenados.sort((a, b) => {
      const indexA = pontosFracionados.indexOf(a.pontoMaisProximo);
      const indexB = pontosFracionados.indexOf(b.pontoMaisProximo);
      return indexA - indexB;
    });
  
    // Atribuir a sequência correta para cada item
    itensOrdenados.forEach(({ item, pontoMaisProximo }, index) => {
      item.GOOGLE_LAT = pontoMaisProximo.lat;
      item.GOOGLE_LNG = pontoMaisProximo.lng;
      item.SEQUENCIA_NA_RUA = index + 1; // Sequência baseada na posição na linha
    });


    console.log(itensOrdenados)
  }
  
  
  // Função para fragmentar a linha metro a metro
  fragmentarLinha(latlngs: L.LatLng[], intervalo: number): L.LatLng[] {
    const pontos: L.LatLng[] = [];
    for (let i = 0; i < latlngs.length - 1; i++) {
      const p1 = latlngs[i];
      const p2 = latlngs[i + 1];
  
      const distancia = p1.distanceTo(p2);
      const passos = Math.floor(distancia / intervalo);
  
      for (let j = 0; j <= passos; j++) {
        const lat = p1.lat + (j / passos) * (p2.lat - p1.lat);
        const lng = p1.lng + (j / passos) * (p2.lng - p1.lng);
        pontos.push(new L.LatLng(lat, lng));
      }
    }
    return pontos;
  }
  
  
calcularTempos(distancia: number) {
  const velocidadePe = 5; // km/h
  const velocidadeMoto = 60; // km/h
  const velocidadeCarro = 50; // km/h

  const tempoPe = (distancia / 1000) / velocidadePe * 60;
  const tempoMoto = (distancia / 1000) / velocidadeMoto * 60;
  const tempoCarro = (distancia / 1000) / velocidadeCarro * 60;

  return {
    a_pe: tempoPe.toFixed(1),
    moto: tempoMoto.toFixed(1),
    carro: tempoCarro.toFixed(1)
  };
}



  Nat_parametros: DADOS_PARAMETRO

  Out_change_parametros(parametros: DADOS_PARAMETRO) {
    /**Cconforme o usuario altera os parametros que considera ideais para seus livros altera aqui via output */
    this.Nat_parametros = parametros


    this.Nat_state_loading = false
    let new_alert: DADOS_ALERT = {
      TITULO: 'SUCESSO',
      CORPO: 'Parãmetros Alterados com Sucesso!'
    };
    this.Nat_dados_alert = new_alert;
    this.Nat_status_alert_sucesso = true;
  }

  Out_muda_visao_livros(tipo: string) {
    /** SIMPLES ou ANALITICA */
  
    if (this.Nat_operacoes_mapa_edicoes.length > 0 && this.Nat_parametros) {
  
      if (tipo === "ANALITICA") {
        const variacao = this.Nat_parametros.VARIACAO;
        const quantidade = this.Nat_parametros.QUANTIDADE;
  
        console.log(this.Nat_parametros);
  
        this.Nat_operacoes_mapa_edicoes.forEach((livro, l) => {
          if (this.Nat_parametros.TIPO === "ITENS") {
            // Critério baseado na quantidade de itens
            if (livro.QUANT_ITENS > ((variacao * quantidade) + quantidade)) {
              livro.POLYGON.setStyle({ color: 'blue' });
            } else if (livro.QUANT_ITENS < (quantidade - (variacao * quantidade))) {
              livro.POLYGON.setStyle({ color: 'red' });
            } else {
              livro.POLYGON.setStyle({ color: 'green' });
            }
  
          } else if (this.Nat_parametros.TIPO === "DURACAO") {
            // Critério baseado na duração (DURACAO_STR)
            const duracaoMinutos = this.converterDuracaoParaMinutos(livro.DURACAO_STR);
  
            if (duracaoMinutos > ((variacao * quantidade) + quantidade)) {
              livro.POLYGON.setStyle({ color: 'blue' });
            } else if (duracaoMinutos < (quantidade - (variacao * quantidade))) {
              livro.POLYGON.setStyle({ color: 'red' });
            } else {
              livro.POLYGON.setStyle({ color: 'green' });
            }
          }
        });
      } else {
        // Resetar para a cor original se não for ANALITICA
        this.Nat_operacoes_mapa_edicoes.forEach((livro, l) => {
          livro.POLYGON.setStyle({ color: livro.POLYGON.options.fillColor });
        });
      }
    }else{
            // Resetar para a cor original se não for ANALITICA
            this.Nat_operacoes_mapa_edicoes.forEach((livro, l) => {
              livro.POLYGON.setStyle({ color: livro.POLYGON.options.fillColor });
            });
    }
  }
  
  /**
   * Função para converter uma string no formato HH:MM:SS para minutos.
   */
  converterDuracaoParaMinutos(duracaoStr: string): number {
    const [horas, minutos, segundos] = duracaoStr.split(':').map(Number);
    return (horas * 60) + minutos + (segundos / 60);
  }
  
  Nat_state_perfil:boolean = false

  Out_change_state_perfil(state:boolean){
    this.Nat_state_perfil=state
  }




  Nat_coordenada_local_buscado: L.Marker
  Out_move_local_buscado(coordenada: COORDENADA) {

    if (this.Nat_coordenada_local_buscado) {
      this.Nat_coordenada_local_buscado.remove()
      this.Nat_coordenada_local_buscado = undefined
    }

    const customIcon = L.icon({
      iconUrl: 'https://res.cloudinary.com/de74yvifw/image/upload/v1734221320/position-marker-svgrepo-com_uv8iqc.svg', // Caminho da imagem
      iconSize: [32, 32], // Tamanho do ícone [largura, altura]
      iconAnchor: [16, 32], // Ponto de ancoragem do ícone [largura, altura]
      popupAnchor: [0, -32] // Ponto de ancoragem do popup [x, y]
    });

    this.Nat_coordenada_local_buscado = L.marker([coordenada.lat, coordenada.lng], { icon: customIcon }).addTo(this.map);
    this.map.setView([coordenada.lat, coordenada.lng], 15);
    this.Nat_coordenada_local_buscado.on('click', (event) => {
      if (this.Nat_coordenada_local_buscado) {
        this.Nat_coordenada_local_buscado.remove()
        this.Nat_coordenada_local_buscado = undefined
      }
    })

  }

  Out_change_transferencia(state: boolean) {
    this.Nat_state_transferencia_itens = state
    if (state == true) {
      this.map.addControl(this.Nat_draw_control);
    } else {
      this.Nat_detalhes_poligono = undefined


      // Verifica se já existe algum polígono no array e o remove
      if (this.Nat_polygons_transferencia.length > 0) {
        // Remover o polígono antigo do mapa
        const oldLayer = this.Nat_polygons_transferencia.pop(); // Remove o último polígono
        this.map.removeLayer(oldLayer); // Remove do mapa
      }
      this.map.removeControl(this.Nat_draw_control)
    }


  }


  Nat_state_EDICOES: boolean = false
  Out_change_aba_EDICOES(state: boolean) {//altera o estado da aba edicoes
    this.Nat_state_EDICOES = state

    if (state = true) {//se estiver abrindo, tem que fechar as demais abas
      this.Nat_state_LIVROS = false
      this.Nat_state_FERRAMENTAS = false
      this.Nat_state_MODULOS = false
    }
  }

  Nat_state_LIVROS: boolean = false
  Nat_operacao_livros_atual: number //id da operacao que esta com os livros na tabela atual
  Out_change_aba_LIVROS(operacao_id: number) {//altera o estado da aba livros da operacao selecionada

    if (this.Nat_operacao_livros_atual) {
      this.Nat_state_LIVROS = false
      this.Nat_operacao_livros_atual = undefined
    } else {
      this.Nat_operacao_livros_atual = operacao_id
      this.Nat_state_LIVROS = true
      this.Nat_state_EDICOES = false
      this.Nat_state_FERRAMENTAS = false
      this.Nat_state_MODULOS = false
    }

  }
  Out_close_livros(variavel_inutil: boolean) {

    /**faz o mesmo que o acima mas quando o user clicar no btn fechar da lista de livros da operacao selecionada e nao quando selecionar 
     * outra operacao
     */
    this.Nat_operacao_livros_atual = undefined
    this.Nat_state_LIVROS = false

    this.Out_change_aba_EDICOES(true)
  }

  Nat_state_FERRAMENTAS: boolean = false
  Out_change_aba_FERRAMENTAS(state: boolean) {//altera o estado da aba edicoes
    this.Nat_state_FERRAMENTAS = state

    if (state = true) {//se estiver abrindo, tem que fechar as demais abas
      this.Nat_state_EDICOES = false
      this.Nat_state_LIVROS = false
      this.Nat_state_MODULOS = false
    }
  }

  Nat_state_MODULOS: boolean = false
  Out_change_aba_MODULOS(state: boolean) {//altera o estado da aba de modulos
    this.Nat_state_MODULOS = state

    if (state = true) {//se estiver abrindo, tem que fechar as demais abas
      this.Nat_state_EDICOES = false
      this.Nat_state_LIVROS = false
      this.Nat_state_FERRAMENTAS = false
    }
  }
  Nat_style_map_index: number = 0
  Out_change_map_type(variavel_inutil: any) { //altera a visualizacao do mapa entre normal, satelite e simplificado

    // Remover a camada atual
    this.map.removeLayer(this.Nat_activeLayer);



    if (this.Nat_style_map_index == 0) {
      this.Nat_style_map_index = 1
      this.Nat_activeLayer = this.Nat_satelliteMap;
      this.Nat_activeLayer.addTo(this.map);
    } else if (this.Nat_style_map_index == 1) {
      this.Nat_style_map_index = 2
      this.Nat_activeLayer = this.Nat_simplifiedMap;
      this.Nat_activeLayer.addTo(this.map);
    } else if (this.Nat_style_map_index == 2) {
      this.Nat_style_map_index = 0
      this.Nat_activeLayer = this.Nat_normalMap;
      this.Nat_activeLayer.addTo(this.map);
    }



  }


  Nat_state_coleta_coordenada: boolean = false //se ativo esta coletando coordenadas manualmente

  Nat_state_transferencia_itens: boolean = false

  Out_change_state_coleta_coordenada(state: boolean) {
    //recebe o estado do botao de coleta manual de coordenada do component gpx-ferramentas
    this.Nat_state_coleta_coordenada = state
  }

  Nat_state_detalhes_poligono: boolean = false

  Nat_detalhes_poligono: DETALHES_POLIGONO
  Out_change_state_detalhes_poligono(state: boolean) {
    this.Nat_state_detalhes_poligono = state
  }






  async Out_repoe_mapa_pos_edicao(variavel_inutil: boolean) {


    this.Nat_state_loading = true



    this.Nat_operacoes_mapa_edicoes.forEach((operacao, o) => {
      // Remover todos os clusters associados a esta operação
      this.Nat_operacoes_mapa_edicoes[o].CLUSTERS.forEach((cluster, c) => {
        this.map.removeLayer(cluster);
      });

      // Remover todos os marcadores individuais associados a esta operação
      this.Nat_operacoes_mapa_edicoes[o].MARKERS.forEach((marker, m) => {

        this.map.removeLayer(marker);
      });

      this.Nat_operacoes_mapa_edicoes[o].POLYGON.remove()
    })




    // for (let od = 0; od < this.Nat_operacoes_mapa_edicoes.length; od++) {


    //   let operacao_atual = this.Nat_operacoes_mapa_edicoes[od]

    //   const requisicao_server: any = await this.service.POST_ITENS_LIVRO_DIVIX(parseInt(operacao_atual.ID.split('-')[0]), operacao_atual.ID.split('-')[1].replace(".", ""));
    //   const resposta: RESPOSTA_PADRAO_SERVIDOR = requisicao_server;
    //   this.Nat_state_detalhes_poligono = false
    //   if (resposta.MSG === 'SUCESSO') {



    //     // Encontrar o índice da operação específica no array de operações
    //     const index = this.Nat_operacoes_mapa_edicoes.findIndex(
    //       (operacao_local) => operacao_local.ID == operacao_atual.ID
    //     );


    //     if (resposta.RESPOSTA.length > 0) {
    //       this.Out_change_transferencia(false) //desabilita o poligono de transferencia

    //       const instalacoes: INSTALACAO_BD[] = resposta.RESPOSTA;
    //       let new_operacao_map: OPERACAO_ou_LIVRO = {
    //         ID: operacao_atual.ID,
    //         ID_STR: operacao_atual.ID_STR,
    //         CENTRO_LEITURA_STR: operacao_atual.CENTRO_LEITURA_STR,
    //         GRUPO_STR: operacao_atual.GRUPO_STR,
    //         LIVRO_STR: operacao_atual.LIVRO_STR,
    //         QUANT_ITENS: instalacoes.length,
    //         DURACAO_STR: operacao_atual.DURACAO_STR,
    //         WAY_LINES: operacao_atual.WAY_LINES,
    //         POLYGON: undefined,
    //         MARKERS: [],
    //         ITENS: [],
    //         CLUSTERS: [],
    //         VISIVEL: true,
    //         POSSUI_EDICAO: true,
    //         QUANT_LIVROS: operacao_atual.QUANT_LIVROS,
    //         DISTANCIA_STR: operacao_atual.DISTANCIA_STR
    //       };

    //       // Criando o grupo de marcadores com o plugin MarkerCluster
    //       const cluster = this.leaflet.markerClusterGroup();
    //       const markers: L.CircleMarker[] = []; // Para armazenar os marcadores individuais
    //       const cor: string = operacao_atual.POLYGON.options.fillColor

    //       let coordenadas_poligono: COORDENADA[] = []
    //       let coordenadas_hull: number[][] = [];


    //       instalacoes.forEach((instalacao) => {
    //         const marcador = L.circleMarker([instalacao.LAT, instalacao.LNG], {
    //           radius: 3,
    //           color: cor,
    //           weight: 2,
    //           opacity: 0.8,
    //           fillColor: '#484e55',
    //           fillOpacity: 0.8
    //         });

    //         coordenadas_hull.push([instalacao.LNG, instalacao.LAT])


    //         // Adiciona uma tooltip ao passar o mouse sobre o marcador, com cada informação em uma linha separada
    //         marcador.bindTooltip(
    //           `
    //   <div>Instalação: ${instalacao.INSTALACAO}</div>
    //   <div>Endereço: ${instalacao.LOGRADOURO} ${instalacao.NUMERO} ${instalacao.BAIRRO}</div>
    //   <div>OPERAÇÃO: ${instalacao.OPERACAO_ID}</div>
    //   <div>CENTRO DE LEITURA: ${instalacao.CENTRO_LEITURA}</div>
    //   <div>GRUPO: ${instalacao.GRUPO}</div>
    //   <div>SEQUENCIA: ${instalacao.SEQUENCIA_LIVRO}</div>
    //   <div>DURAÇÃO: ${instalacao.EXEC_TIME}</div>`,
    //           {
    //             permanent: false,
    //             direction: 'top',
    //             offset: [0, -10],
    //             className: 'custom-tooltip' // Classe CSS personalizada
    //           }
    //         );
    //         new_operacao_map.ITENS.push(instalacao)
    //         new_operacao_map.MARKERS = markers;
    //         cluster.addLayer(marcador);
    //         markers.push(marcador);
    //       });


    //       if (resposta.RESPOSTA.length > 0) {
    //         const resultadoHull = hull(coordenadas_hull, 100);


    //         resultadoHull.forEach((arr_coord) => {
    //           coordenadas_poligono.push({
    //             lat: arr_coord[1],
    //             lng: arr_coord[0]
    //           })
    //         });


    //         const poligono_operacao = L.polygon(coordenadas_poligono, {
    //           fillColor: cor,
    //           color: cor,
    //           weight: 3,
    //           opacity: 1,
    //           fillOpacity: 0.1
    //         }).addTo(this.map);
    //         //poligono_operacao.enableEdit();

    //         poligono_operacao.bindTooltip(
    //           `
    //           <div>OPERAÇÃO: ${new_operacao_map.ID.split('-')[0]}</div>
    //           <div>LIVRO: ${new_operacao_map.LIVRO_STR}</div>
    //           <div>CENTRO DE LEITURA: ${new_operacao_map.CENTRO_LEITURA_STR}</div>
    //           <div>GRUPO: ${new_operacao_map.GRUPO_STR}</div>
    //           <div>ITENS: ${new_operacao_map.ITENS.length}</div>
    //           <div>DURAÇÃO: ${new_operacao_map.DURACAO_STR}</div>
    //          `,
    //           {
    //             permanent: false,
    //             direction: 'top',
    //             offset: [0, -10],
    //             className: 'custom-tooltip' // Classe CSS personalizada
    //           }
    //         );




    //         // Evento de início da edição do polígono
    //         poligono_operacao.on('click', async (event) => {


    //           let detalhes_poligono: DETALHES_POLIGONO = {
    //             OPERACAO_ID: new_operacao_map.ID.split('-')[0],
    //             CENTRO_LEITURA: new_operacao_map.CENTRO_LEITURA_STR,
    //             GRUPO: new_operacao_map.GRUPO_STR,
    //             LIVRO: new_operacao_map.LIVRO_STR,
    //             DURACAO: new_operacao_map.DURACAO_STR,
    //             TOTAL_ITENS: resposta.RESPOSTA.length,
    //             OPERACAO_ID_DEST: undefined,
    //             LIVRO_DEST: undefined,
    //             TIPO: 'POLIGONO',
    //             MODULO: 'DIVIX',
    //             ITENS_ID: resposta.RESPOSTA,
    //             ID_INICIO: 0,
    //             DISTANCIA: new_operacao_map.DISTANCIA_STR
    //           }

    //           this.Nat_detalhes_poligono = detalhes_poligono

    //           this.Nat_state_detalhes_poligono = true


    //         })


    //         new_operacao_map.POLYGON = poligono_operacao
    //       }






    //       new_operacao_map.CLUSTERS.push(cluster);
    //       this.map.addLayer(cluster);

    //       const updateMarkerDisplay = () => {
    //         if (this.Nat_ids_operacoes_mapa_edicoes.includes(operacao_atual.ID)) {
    //           const zoomLevel = this.map.getZoom();
    //           const mapBounds = this.map.getBounds();

    //           if (zoomLevel >= 16) {
    //             // Remove o cluster e exibe apenas os marcadores dentro dos limites visíveis
    //             this.map.removeLayer(cluster);
    //             markers.forEach(marker => {
    //               if (mapBounds.contains(marker.getLatLng())) {
    //                 this.map.addLayer(marker);
    //               } else {
    //                 this.map.removeLayer(marker);
    //               }
    //             });
    //           } else {
    //             // Remove os marcadores individuais e exibe o cluster
    //             markers.forEach(marker => {
    //               this.map.removeLayer(marker);
    //             });
    //             this.map.addLayer(cluster);
    //           }
    //         } else {
    //           // Remover todos os marcadores e clusters da visualização
    //           markers.forEach(marker => {
    //             this.map.removeLayer(marker);
    //           });
    //           this.map.removeLayer(cluster);


    //         }
    //       };

    //       this.map.on('zoomend moveend', updateMarkerDisplay);
    //       updateMarkerDisplay();



       
    //       this.Nat_operacoes_mapa_edicoes[index] = new_operacao_map
    //       this.Nat_ids_operacoes_mapa_edicoes[index] = new_operacao_map.ID





    //       this.Nat_state_loading = false


    //     }else{
    //       this.Nat_ids_operacoes_mapa_edicoes.splice(index,1)
    //       this.Nat_operacoes_mapa_edicoes.splice(index,1)
    //     }
    
  
    //   } else {
    //     this.Nat_state_loading = false
    //     let new_alert: DADOS_ALERT = {
    //       TITULO: 'ERRO',
    //       CORPO: resposta.ADICIONAL
    //     };
    //     this.Nat_dados_alert = new_alert;
    //     this.Nat_status_alert_erro = true;
    //   }


    // }





  }



  Nat_operacoes_mapa_edicoes: OPERACAO_ou_LIVRO[] = []
  Nat_ids_operacoes_mapa_edicoes: any[] = []

  /**
   * recebe a operacao que deve ser lançada no mapa, busca suas instalacoes, e as lança no mapa
   * elas são agrupadas em clusters para melhorar o desempenho e usabilidade, e são exibidos apennas os
   * pontos que estao dentro da vizualização atual
   */
  async Out_open_operacao_edicoes(operacao: OPERACAO_ou_LIVRO) {
    if (operacao.QUANT_ITENS == 0) {
      this.Nat_state_loading = false
      let new_alert: DADOS_ALERT = {
        TITULO: 'ERRO',
        CORPO: 'Não é Possível abrir Operações sem Itens!'
      };
      this.Nat_dados_alert = new_alert;
      this.Nat_status_alert_erro = true;

    } else {
      this.Nat_state_loading = true

      const requisicao_server: any = await this.service.POST_ITENS_LIVRO_DIVIX(parseInt(operacao.ID.split('-')[0]), operacao.LIVRO_STR);
      const resposta: RESPOSTA_PADRAO_SERVIDOR = requisicao_server;

      

      if (resposta.MSG === 'SUCESSO') {
        // let new_alert: DADOS_ALERT = {
        //   TITULO: resposta.MSG,
        //   CORPO: resposta.ADICIONAL
        // };
        // this.Nat_dados_alert = new_alert;
        // this.Nat_status_alert_sucesso = true;

        const instalacoes: INSTALACAO_BD[] = resposta.RESPOSTA;
        let new_operacao_map: OPERACAO_ou_LIVRO = operacao;

        // Criando o grupo de marcadores com o plugin MarkerCluster
        const cluster = this.leaflet.markerClusterGroup();
        const markers: L.CircleMarker[] = []; // Para armazenar os marcadores individuais
        const cor: string = this.Nat_gerarCorUnica()

        let coordenadas_poligono: COORDENADA[] = []
        let coordenadas_hull: number[][] = [];


        instalacoes.forEach((instalacao) => {
          const marcador = L.circleMarker([instalacao.LAT, instalacao.LNG], {
            radius: 3,
            color: cor,
            weight: 2,
            opacity: 0.8,
            fillColor: '#484e55',
            fillOpacity: 0.8
          });

          coordenadas_hull.push([instalacao.LNG, instalacao.LAT])


          // Adiciona uma tooltip ao passar o mouse sobre o marcador, com cada informação em uma linha separada
          marcador.bindTooltip(
            `
            <div>Instalação: ${instalacao.INSTALACAO}</div>
            <div>Endereço: ${instalacao.LOGRADOURO} ${instalacao.NUMERO} ${instalacao.BAIRRO}</div>
            <div>OPERAÇÃO: ${instalacao.OPERACAO_ID}</div>
            <div>CENTRO DE LEITURA: ${instalacao.CENTRO_LEITURA}</div>
            <div>GRUPO: ${instalacao.GRUPO}</div>
            <div>SEQUENCIA: ${instalacao.SEQUENCIA_LIVRO}</div>
            <div>DURAÇÃO: ${instalacao.EXEC_TIME}</div>`,
            {
              permanent: false,
              direction: 'top',
              offset: [0, -10],
              className: 'custom-tooltip' // Classe CSS personalizada
            }
          );
          new_operacao_map.ITENS.push(instalacao)
          new_operacao_map.MARKERS = markers;
          cluster.addLayer(marcador);
          markers.push(marcador);
        });


        const resultadoHull = hull(coordenadas_hull, 100);


        resultadoHull.forEach((arr_coord) => {
          coordenadas_poligono.push({
            lat: arr_coord[1],
            lng: arr_coord[0]
          })
        });


        const poligono_operacao = L.polygon(coordenadas_poligono, {
          fillColor: cor,
          color: cor,
          weight: 3,
          opacity: 1,
          fillOpacity: 0.1
        }).addTo(this.map);
        //poligono_operacao.enableEdit();

        let duracao_poligono: number = 0


        instalacoes.forEach((instalacao, i) => {
          duracao_poligono = duracao_poligono + instalacao.EXEC_TIME
        });

        operacao.DURACAO_STR = formatarDuracao(duracao_poligono)

        // Adiciona uma tooltip ao passar o mouse sobre o marcador, com cada informação em uma linha separada
        poligono_operacao.bindTooltip(
          `
              <div>OPERAÇÃO: ${operacao.ID.split('-')[0]}</div>
              <div>LIVRO: ${operacao.LIVRO_STR}</div>
              <div>CENTRO DE LEITURA: ${operacao.CENTRO_LEITURA_STR}</div>
              <div>GRUPO: ${operacao.GRUPO_STR}</div>
              <div>ITENS: ${operacao.ITENS.length}</div>
              <div>DURAÇÃO: ${operacao.DURACAO_STR}</div>
             `,
          {
            permanent: false,
            direction: 'top',
            offset: [0, -10],
            className: 'custom-tooltip' // Classe CSS personalizada
          }
        );

        // Evento de início da edição do polígono
        poligono_operacao.on('editable:vertex:dragend', async (event) => {

          alert('mudou o poligono')
          const newCoordinates = event.target.getLatLngs();
          // Atualize o array com as novas coordenadas se necessário

          // newCoordinates[0].forEach((latlng: any) => {
          //   coordenadas_polygon.push({ lat: latlng.lat, lng: latlng.lng });


          // });

        })

        // Evento de início da edição do polígono
        poligono_operacao.on('click', async (event) => {


          let detalhes_poligono: DETALHES_POLIGONO = {
            OPERACAO_ID: operacao.ID.split('-')[0],
            CENTRO_LEITURA: operacao.CENTRO_LEITURA_STR,
            GRUPO: operacao.GRUPO_STR,
            LIVRO: operacao.LIVRO_STR,
            DURACAO: operacao.DURACAO_STR,
            TOTAL_ITENS: resposta.RESPOSTA.length,
            OPERACAO_ID_DEST: undefined,
            LIVRO_DEST: undefined,
            TIPO: 'POLIGONO',
            MODULO: 'DIVIX',
            ITENS_ID: resposta.RESPOSTA,
            ID_INICIO: 0,
            DISTANCIA: operacao.DISTANCIA_STR
          }

          this.Nat_detalhes_poligono = detalhes_poligono

          this.Nat_state_detalhes_poligono = true


        })



        new_operacao_map.POLYGON = poligono_operacao




        new_operacao_map.CLUSTERS.push(cluster);
        this.map.addLayer(cluster);

        const updateMarkerDisplay = () => {
          if (this.Nat_ids_operacoes_mapa_edicoes.includes(operacao.ID)) {
            const zoomLevel = this.map.getZoom();
            const mapBounds = this.map.getBounds();

            if (zoomLevel >= 16) {
              // Remove o cluster e exibe apenas os marcadores dentro dos limites visíveis
              this.map.removeLayer(cluster);
              markers.forEach(marker => {
                if (mapBounds.contains(marker.getLatLng())) {
                  this.map.addLayer(marker);
                } else {
                  this.map.removeLayer(marker);
                }
              });
            } else {
              // Remove os marcadores individuais e exibe o cluster
              markers.forEach(marker => {
                this.map.removeLayer(marker);
              });
              this.map.addLayer(cluster);
            }
          } else {
            // Remover todos os marcadores e clusters da visualização
            markers.forEach(marker => {
              this.map.removeLayer(marker);
            });
            this.map.removeLayer(cluster);


          }
        };

        this.map.on('zoomend moveend', updateMarkerDisplay);
        updateMarkerDisplay();

        //this.map.setView([instalacoes[0].LAT, instalacoes[0].LNG], 15);
        this.Nat_operacoes_mapa_edicoes.push(new_operacao_map);
        this.Nat_ids_operacoes_mapa_edicoes.push(operacao.ID);


        this.Nat_state_loading = false
      } else {

        this.Nat_state_loading = false
        let new_alert: DADOS_ALERT = {
          TITULO: 'ERRO',
          CORPO: resposta.ADICIONAL
        };
        this.Nat_dados_alert = new_alert;
        this.Nat_status_alert_erro = true;
      }
    }
  }

  Out_desenha_trajeto_osrm(dados_osrm_trip: any) {
    /**
     * recebe o retorno do trip osrm e desenha tanto o percurso como os pontos mostrando a sequencia usada
     */

    const coordinates: [number, number][] = [];
    // Prepara as coordenadas para desenhar a linha do trajeto
    dados_osrm_trip.osrmData.trips[0].geometry.coordinates.forEach((coordinate) => {
      const lat = coordinate[1];
      const lng = coordinate[0];
      coordinates.push([lat, lng]);
    });

    // Desenha a linha no mapa usando L.polyline
    const polyline = L.polyline(coordinates, {
      color: 'blue',
      weight: 4,
      opacity: 0.8,
      smoothFactor: 1
    }).addTo(this.map);

    dados_osrm_trip.pontos_sem_a_origem.forEach((ponto, p) => {
      const marcador = L.circleMarker([ponto.LAT, ponto.LNG], {
        radius: 3,
        color: 'red',
        weight: 2,
        opacity: 0.8,
        fillColor: '#484e55',
        fillOpacity: 0.8
      }).addTo(this.map);



      // Adiciona uma tooltip ao passar o mouse sobre o marcador, com cada informação em uma linha separada
      if (ponto.SEQUENCIA_LIVRO == 0 || ponto.SEQUENCIA_LIVRO == dados_osrm_trip.pontos_sem_a_origem.length - 1) {
        marcador.bindTooltip(
          `
          <div>${ponto.SEQUENCIA_LIVRO}</div>
          <div>${ponto.EXEC_TIME}</div>
          `,
          {
            permanent: true,
            direction: 'top',
            offset: [0, -10],
            className: 'custom-tooltip' // Classe CSS personalizada
          }
        );
      } else {
        marcador.bindTooltip(
          `
          <div>${ponto.SEQUENCIA_LIVRO}</div>
           <div>${ponto.EXEC_TIME}</div>
          `,
          {
            permanent: false,
            direction: 'top',
            offset: [0, -10],
            className: 'custom-tooltip' // Classe CSS personalizada
          }
        );
      }


    })


    // Ajusta o mapa para que todos os pontos da linha sejam visíveis
    this.map.fitBounds(polyline.getBounds());
  }
  /**
   *retira do mapa tanto markers como clusters de uma operacao selecionada
   */
  Out_close_operacao_edicoes(operacao: OPERACAO_ou_LIVRO) {



    this.Nat_state_loading = true
    // Encontrar o índice da operação específica no array de operações
    const index = this.Nat_operacoes_mapa_edicoes.findIndex(
      (operacao_local) => operacao_local.ID == operacao.ID
    );

    if (index !== -1) {
      // Remover todos os clusters associados a esta operação
      this.Nat_operacoes_mapa_edicoes[index].CLUSTERS.forEach(cluster => {
        this.map.removeLayer(cluster);
      });

      // Remover todos os marcadores individuais associados a esta operação
      this.Nat_operacoes_mapa_edicoes[index].MARKERS.forEach(marker => {
        this.map.removeLayer(marker);
      });

      this.Nat_operacoes_mapa_edicoes[index].POLYGON.remove()
    }

    // Remover a operação da lista de operações disponíveis
    this.Nat_operacoes_mapa_edicoes = this.Nat_operacoes_mapa_edicoes.filter(
      (operacao_local) => operacao_local.ID != operacao.ID
    );
    this.Nat_ids_operacoes_mapa_edicoes = this.Nat_ids_operacoes_mapa_edicoes.filter(
      (id) => id != operacao.ID
    );

    this.Nat_state_loading = false
  }


  // /**evento que abre a aba de loading a partir de outros components */
  Out_open_loading(variavel_inutil: boolean) {
    this.Nat_state_loading = true
  }

  // /**evento que fecha a aba de loading a partir de outros components */
  Out_close_loading(variavel_inutil: boolean) {
    this.Nat_state_loading = false
  }

  /**abre o alerta da mensagem de sucesso */
  Out_open_alert_sucess(obg_alert: DADOS_ALERT) {
    this.Nat_state_loading = false
    this.Nat_dados_alert = obg_alert;
    this.Nat_status_alert_sucesso = true;
  }

  /**abre o alerta da mensagem de erro */
  Out_open_alert_erro(obg_alert: DADOS_ALERT) {
    this.Nat_state_loading = false
    this.Nat_dados_alert = obg_alert;
    this.Nat_status_alert_erro = true;
  }



  Nat_copy_value(value: any) {
    const valueCopy: string = `${value}`;

    // Cria um elemento de input temporário
    const tempInput = document.createElement('input');
    tempInput.value = valueCopy;
    document.body.appendChild(tempInput); // Adiciona o elemento ao DOM

    // Seleciona o texto do input temporário
    tempInput.select();
    tempInput.setSelectionRange(0, 99999); // Para dispositivos móveis

    try {
      // Copia o texto selecionado para a área de transferência
      document.execCommand('copy');



      let new_alert: DADOS_ALERT = {
        TITULO: 'Copiado!',
        CORPO: 'Valor Copiado com Sucesso para a Área de Transferência'
      }
      this.Nat_dados_alert = new_alert
      this.Nat_status_alert_sucesso = true




    } catch (err) {


      let new_alert: DADOS_ALERT = {
        TITULO: 'ERRO',
        CORPO: 'Valor não pôde se Copiado para a Área de Transferência'
      }
      this.Nat_dados_alert = new_alert
      this.Nat_status_alert_erro = true




    } finally {
      // Remove o elemento temporário do DOM
      document.body.removeChild(tempInput);
    }
  }


  /**
   * geracao e administração de cores para markers do mapa
   */
  Nat_coresGeradas: string[] = [];
  Nat_gerarCorHex(): string {
    const randomColor = Math.floor(Math.random() * 0xffffff);
    return `#${randomColor.toString(16).padStart(6, '0')}`;
  }

  Nat_gerarCorUnica(): string {
    let cor = this.Nat_gerarCorHex();

    // Tenta gerar uma nova cor se a cor atual já existe no array
    while (this.Nat_coresGeradas.includes(cor)) {
      cor = this.Nat_gerarCorHex();
    }

    // Armazena a cor única
    this.Nat_coresGeradas.push(cor);
    return cor;
  }



}
function formatarDuracao(segundos: number): string {
  const horas = Math.floor(segundos / 3600);
  const minutos = Math.floor((segundos % 3600) / 60);
  const segundosRestantes = segundos % 60;

  const horasStr = horas.toString().padStart(2, '0');
  const minutosStr = minutos.toString().padStart(2, '0');
  const segundosStr = segundosRestantes.toString().padStart(2, '0');

  return `${horasStr}:${minutosStr}:${segundosStr}`;
}