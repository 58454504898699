<mat-select [formControl]="Fix_modulos" multiple (selectionChange)="Nat_add_modulo($event)">
  <mat-select-trigger>
    {{Fix_modulos.value?.[0] || ''}}
    @if ((Fix_modulos.value?.length || 0) > 1) {
      <span>
        (+{{(Fix_modulos.value?.length || 0) - 1}} {{Fix_modulos.value?.length === 2 ? 'Outro' : 'Outros'}})
      </span>
    }
  </mat-select-trigger>
  @for (modulo of List_modulos; track modulo) {
    <mat-option [value]="modulo">{{modulo}}</mat-option>
  }
</mat-select>
