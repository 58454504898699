import { Routes } from '@angular/router';
import { HeroMainComponent } from './MODULES/HERO/COMPONENTS/hero-main/hero-main.component';
import { LoginMainComponent } from './MODULES/LOGIN/COMPONENTS/login-main/login-main.component';
import { CadastroMainComponent } from './MODULES/CADASTRO/COMPONENTS/cadastro-main/cadastro-main.component';
import { InfoMainComponent } from './MODULES/INFO/COMPONENTS/info-main/info-main.component';
import { GpxMainComponent } from './MODULES/GPX/COMPONENTS/gpx-main/gpx-main.component';
import { DivixMainComponent } from './MODULES/DIVIX/COMPONENTS/divix-main/divix-main.component';
import { RoutexMainComponent } from './MODULES/ROUTEX/COMPONENTS/routex-main/routex-main.component';
import { HomeComponentStox } from './MODULES/STOX/COMPONENTS/home/home.component';
import { RetiradasComponent } from './MODULES/STOX/COMPONENTS/retiradas/retiradas.component';
import { AtualizacoesComponent } from './MODULES/STOX/COMPONENTS/atualizacoes/atualizacoes.component';
import { MeuestoqueComponent } from './MODULES/STOX/COMPONENTS/meuestoque/meuestoque.component';

import { MapaMainComponent } from './MODULES/AORDEX/COMPONENTS/mapa-main/mapa-main.component';



export const routes: Routes = [
     { path: '', component: HeroMainComponent },//pagina inicial

     { path: 'login', component: LoginMainComponent },//pagina login
     { path: 'cadastro', component: CadastroMainComponent },//pagina cadastro user
     { path: 'info', component: InfoMainComponent },//pagina informacoes sobre os modulos

     { path: 'gpx', component: GpxMainComponent },//pagina gpx main
     { path: 'divix', component: DivixMainComponent },//pagina divix main
     { path: 'routex', component: RoutexMainComponent },//pagina divix main

     { path: 'aordex', component: MapaMainComponent },//pagina aordex main


     { path: 'stox_admin', component: HomeComponentStox },//pagina stox main
     { path: 'stox_user', component: HomeComponentStox },//pagina stox main
     { path: 'retiradas', component: RetiradasComponent },
     { path: 'atualizacoes', component: AtualizacoesComponent },
     { path: 'homestox', component: HomeComponentStox },
     { path: 'estoque', component: MeuestoqueComponent },


];
