import { USER } from "../app/MODELS/USER";

export const environment : { user_atual?: USER,url_api_local:string,url_api_prod:string,foto:string} = {
    user_atual: undefined,
    url_api_local:"http://200.103.142.69:3000",
    url_api_prod: "http://200.103.142.69:3000",
    foto:undefined

  };
    
  