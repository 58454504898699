import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { LoginModalTokenComponent } from "../login-modal-token/login-modal-token.component";
import { DADOS_ALERT } from '../../../../MODELS/DADOS_ALERT';
import { RESPOSTA_PADRAO_SERVIDOR } from '../../../../MODELS/RESPOSTA_PADRAO_SERVIDOR';

import { Router } from '@angular/router';
import { LoginService } from '../../SERVICES/login.service';
import { AlertErroComponent } from "../../../../COMPONENTS_UI/alert-erro/alert-erro.component";
import { AlertSucessoComponent } from "../../../../COMPONENTS_UI/alert-sucesso/alert-sucesso.component";
import { LoginModalResetSenhaComponent } from "../login-modal-reset-senha/login-modal-reset-senha.component";
import { USER } from '../../../../MODELS/USER';
import { environment } from '../../../../../environments/environment.development';

@Component({
  selector: 'app-login-main',
  standalone: true,
  imports: [LoginModalTokenComponent, AlertErroComponent, AlertSucessoComponent,LoginModalResetSenhaComponent],
  templateUrl: './login-main.component.html',
  styleUrl: './login-main.component.css'
})
export class LoginMainComponent implements OnInit {
  constructor(

    private router: Router,
    private service: LoginService
  ) { }

  ngOnInit(): void {

   
  }


  Nat_goto(page: string) { // Navega até uma página específica
    this.router.navigateByUrl(page);
  }




  Nat_dados_alert?: DADOS_ALERT


  Nat_status_alert_sucesso: boolean = false
  Out_close_alert_sucesso(status: boolean) {//fecha o alerta de sucesso
    this.Nat_status_alert_sucesso = status
    this.Nat_dados_alert = undefined
  }
  Nat_status_alert_erro: boolean = false
  Out_close_alert_erro(status: boolean) {//fecha o alerta de erro
    this.Nat_status_alert_erro = status
    this.Nat_dados_alert = undefined
  }

  Nat_status_modal_token: boolean = false
  Out_close_modal_token(status: boolean) {//fecha o alerta de erro
    this.Nat_status_modal_token = status
    this.Nat_token_digitado = ''
  }
  Nat_status_modal_reset_senha: boolean = false
  Out_close_modal_reset_senha(status: boolean) {//fecha o modal de reset da senha do user
    this.Nat_status_modal_reset_senha = status
  }

  Out_close_modal_reset_senha_status(status: boolean) {//fecha o modal de reset da senha do user
    if(status == true){
      let new_alert: DADOS_ALERT = {
        TITULO: "SUCESSO",
        CORPO: "Senha Alterada com Sucesso!"
      }
      this.Nat_dados_alert = new_alert
      this.Nat_status_alert_sucesso = true

      this.Nat_status_modal_reset_senha = false
    }else{
      let new_alert: DADOS_ALERT = {
        TITULO: "ERRO",
        CORPO: "Não foi Possível Alterar a Senha!"
      }
      this.Nat_dados_alert = new_alert
      this.Nat_status_alert_erro = true
    }
  }
  Nat_token_digitado: string = ''
  Out_recebe_token_digitado(token_digitado: string) {//fecha o modal com o input do token gerado
    this.Nat_token_digitado = token_digitado



    this.Nat_logar()

  }


  @ViewChild('inp_email') inp_email!: ElementRef;
  @ViewChild('inp_password') inp_password!: ElementRef;
  async Nat_gerar_token() {//tenta logar com email e senha e token digitado pelo user

    if(this.Nat_selected_modulo !=''){

      const email: string = this.inp_email.nativeElement.value
      const senha: string = this.inp_password.nativeElement.value
  
      const requisicao_server: any = await this.service.POST_GERAR_TOKEN_LOGIN({
        email: email,
        senha: senha
      })
      const resposta: RESPOSTA_PADRAO_SERVIDOR = requisicao_server

  
      if (resposta.MSG == 'SUCESSO') {
        this.Nat_status_modal_token = true
      } else {
        let new_alert: DADOS_ALERT = {
          TITULO: resposta.MSG,
          CORPO: resposta.ADICIONAL
        }
        this.Nat_dados_alert = new_alert
        this.Nat_status_alert_erro = true
      }
    }else{
      let new_alert: DADOS_ALERT = {
        TITULO: 'ERRO',
        CORPO: 'Nenhum Módulo Selecionado!'
      }
      this.Nat_dados_alert = new_alert
      this.Nat_status_alert_erro = true
    }



  }

  async Nat_open_reset_senha(){//gera um token para o user poder realizar o reset de sua senha
    const email: string = this.inp_email.nativeElement.value


    const requisicao_server: any = await this.service.POST_GERAR_TOKEN_RESET_SENHA({
      email: email
    })
    const resposta: RESPOSTA_PADRAO_SERVIDOR = requisicao_server


    if (resposta.MSG == 'SUCESSO') {
      this.Nat_status_modal_reset_senha = true
    } else {
      let new_alert: DADOS_ALERT = {
        TITULO: resposta.MSG,
        CORPO: resposta.ADICIONAL
      }
      this.Nat_dados_alert = new_alert
      this.Nat_status_alert_erro = true
    }
  }

  Nat_selected_modulo:string = ''
  Nat_change_modulo_inicial(event: Event) {//seleciona o modulo que o usuario quer acessar inicialmente
    const selectedValue = (event.target as HTMLSelectElement).value;
    this.Nat_selected_modulo = selectedValue

  }
  async Nat_logar() {


      const email: string = this.inp_email.nativeElement.value
      const senha: string = this.inp_password.nativeElement.value
  
      const requisicao_server: any = await this.service.POST_LOGAR({
        email: email,
        senha: senha,
        token:this.Nat_token_digitado
      })
      const resposta: RESPOSTA_PADRAO_SERVIDOR = requisicao_server


      const user_atual = resposta.RESPOSTA

      let logou:boolean = false


      if(this.Nat_selected_modulo == 'gpx'){
    if(user_atual){
      if(user_atual.GPS == true){
        environment.user_atual = user_atual
        logou=true
      }
    }
      

      }else if(this.Nat_selected_modulo == 'divix'){
        if(user_atual){
          if(user_atual.ZONE == true){
            environment.user_atual = user_atual
            logou=true
          }
        }
      

      }else if(this.Nat_selected_modulo == 'routex'){
        if(user_atual){
          if(user_atual.EXEC == true){
            environment.user_atual = user_atual
            logou=true
          }
        }
      
      }else if(this.Nat_selected_modulo == 'aordex'){
        if(user_atual){
          if(user_atual.RAXTRO_USER == true){
            environment.user_atual = user_atual
            logou=true
          }
        }
       
      }else if(this.Nat_selected_modulo == 'ceara_apps'){
        if(user_atual){
          if(user_atual.CEARA_APS == true){
            environment.user_atual = user_atual
            logou=true
          }
        }
       
      }else if(this.Nat_selected_modulo == 'stox_user'){
        if(user_atual){
          if(user_atual.XTOQ_USER == true){
            environment.user_atual = user_atual
            logou=true
          }
        }
  
      }else if(this.Nat_selected_modulo == 'stox_admin'){
        if(user_atual){
          if(user_atual.XTOQ_MASTER == true){
            environment.user_atual = user_atual
            logou=true
          }
        }
    
      }else if(this.Nat_selected_modulo == 'xsocial'){
        if(user_atual){
          if(user_atual.SOCIAL == true){
            environment.user_atual = user_atual
            logou=true
          }
        }
      
      }
  
      if (resposta.MSG == 'SUCESSO') {

        if(logou == true){
          this.router.navigateByUrl(this.Nat_selected_modulo)
         
        }else{
          let new_alert: DADOS_ALERT = {
            TITULO: 'ERRO',
            CORPO: 'Módulo Selecionado não Disponível para o seu Acesso!'
          }
          this.Nat_dados_alert = new_alert
          this.Nat_status_alert_erro = true
        }


      } else {
        let new_alert: DADOS_ALERT = {
          TITULO: "ERRO",
          CORPO: "Dados de Login ou Token Inválidos"
        }
        this.Nat_dados_alert = new_alert
        this.Nat_status_alert_erro = true
      }
  

    }
}
