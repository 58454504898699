import { Component, OnInit } from '@angular/core';

import { HeroCarouselMainComponent } from "../hero-carousel-main/hero-carousel-main.component";
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { AlertSucessoComponent } from "../../../../COMPONENTS_UI/alert-sucesso/alert-sucesso.component";
import { AlertErroComponent } from "../../../../COMPONENTS_UI/alert-erro/alert-erro.component";
import { DADOS_ALERT } from '../../../../MODELS/DADOS_ALERT';

@Component({
  selector: 'app-hero-main',
  standalone: true,
  imports: [
    HeroCarouselMainComponent,
    MatTooltipModule,
    AlertSucessoComponent,
    AlertErroComponent
],
  templateUrl: './hero-main.component.html',
  styleUrls: ['./hero-main.component.css']  // Corrigido 'styleUrl' para 'styleUrls'
})
export class HeroMainComponent implements OnInit {



  constructor(

    private router: Router
  ) {}

  ngOnInit(): void {
 
  }

  Nat_goto(page: string) { // Navega até uma página específica
    this.router.navigateByUrl(page);
  }


  Nat_dados_alert?:DADOS_ALERT


  Nat_status_alert_sucesso:boolean = false
  Out_close_alert_sucesso(status:boolean){//fecha o alerta de sucesso
    this.Nat_status_alert_sucesso = status
  }
  Nat_status_alert_erro:boolean = false
  Out_close_alert_erro(status:boolean){//fecha o alerta de erro
    this.Nat_status_alert_erro = status
  }
}
