import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment.development';
import { RESPOSTA_PADRAO_SERVIDOR } from '../../../MODELS/RESPOSTA_PADRAO_SERVIDOR';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

    constructor(private httpClient: HttpClient) { }
  
    //GERA O LOGIN DE ACESSO NA VERIFICACAO DE 2 ETAPAS
    async POST_GERAR_TOKEN_LOGIN(body:{email:string,senha:string}) {
      try {
        const response = await this.httpClient
          .post(`${environment.url_api_local}/POST_GERAR_TOKEN_LOGIN`,body)
          .toPromise();
  
  
        return response;
      } catch (error) {
        console.log(error, 'erro');
        const retorno_padrao: RESPOSTA_PADRAO_SERVIDOR = {
          MSG: "ERRO",
          ADICIONAL: "",
          RESPOSTA: undefined
        }
        return retorno_padrao
  
      }
    }
    //GERA UM TOKEN PARA O USER MUDAR DE SENHA
    async POST_GERAR_TOKEN_RESET_SENHA(body:{email:string}) {
      try {
        const response = await this.httpClient
          .post(`${environment.url_api_local}/POST_GERAR_TOKEN_RESET_SENHA`,body)
          .toPromise();
  
  
        return response;
      } catch (error) {
        console.log(error, 'erro');
        const retorno_padrao: RESPOSTA_PADRAO_SERVIDOR = {
          MSG: "ERRO",
          ADICIONAL: "",
          RESPOSTA: undefined
        }
        return retorno_padrao
  
      }
    }

        //ALTERA A SENHA DO USUARIO
        async POST_RESET_SENHA(body:{email:string,senha:string,token:string}) {
          try {
            const response = await this.httpClient
              .post(`${environment.url_api_local}/POST_RESET_SENHA`,body)
              .toPromise();
      
      
            return response;
          } catch (error) {
            console.log(error, 'erro');
            const retorno_padrao: RESPOSTA_PADRAO_SERVIDOR = {
              MSG: "ERRO",
              ADICIONAL: "",
              RESPOSTA: undefined
            }
            return retorno_padrao
      
          }
        }
    
        //REALIZA O LOGIN APÓS A VERIFICACAO DE DUAS ETAPAS SER VALIDADA
        async POST_LOGAR(body:{email:string,senha:string,token:string}) {
          try {
            const response = await this.httpClient
              .post(`${environment.url_api_local}/POST_LOGAR`,body)
              .toPromise();
      
      
            return response;
          } catch (error) {
            console.log(error, 'erro');
            const retorno_padrao: RESPOSTA_PADRAO_SERVIDOR = {
              MSG: "ERRO",
              ADICIONAL: "",
              RESPOSTA: undefined
            }
            return retorno_padrao
      
          }
        }

  }

