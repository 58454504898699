<div class="isolate bg-gradient-to-r from-slate-50 to-blue-200  px-6 py-24 sm:py-32 lg:px-8">
    <div class="absolute inset-x-0 top-[-10rem] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[-20rem]"
        aria-hidden="true">
        <div class="relative left-1/2 -z-10  w-[36.125rem] max-w-none sm:w-[72.1875rem]"
            >
        </div>
    </div>
    <div class="mx-auto max-w-2xl text-center">
        <p class="text-balance text-4xl font-semibold tracking-tight text-gray-700 sm:text-5xl">Novo Usuário</p>
        <p class="mt-2 text-lg/8 text-gray-600">Informe os Dados Solicitados para concluir o requerimento de novo
            Cadastro ou 
            <a 
            (click)="Nat_goto('')"
            class="text-blue-600 hover:underline cursor-pointer">volte ao Inicio.</a></p> 
    </div>
    <form method="POST" class="mx-auto mt-4 max-w-xl sm:mt-6 bg-white p-6 shadow sm:rounded-lg" autocomplete="off">
        <div class="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
            <div>
                <label for="first-name" class="block text-sm/6 font-semibold text-gray-900">Nome Completo</label>
                <div class="mt-2.5">
                    <input type="text" name="name" id="name" #inp_nome placeholder="Seu Nome Completo" autocomplete="off"
                        class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm/6">
                </div>
            </div>
            <div>
                <label for="last-name" class="block text-sm/6 font-semibold text-gray-900">Senha</label>
                <div class="mt-2.5">
                    <input type="password" name="password" id="password" #inp_password placeholder="Senha com 8 ou mais Digitos" autocomplete="off"
                        class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm/6">
                </div>
            </div>


            @if(Nat_isAdmin){
                <div>
                    <div class="mt-2.5">
                        <label for="token" class="block text-sm/6 font-medium text-gray-900">Token de Liberação</label>
                        <div class="mt-2 flex rounded-md shadow-sm">
                          <div class="relative flex flex-grow items-stretch focus-within:z-10">
                            <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                             
                            </div>
                            <input #inp_token type="text" name="token" id="token" class="block w-full rounded-none
                             rounded-l-md border-0 py-1.5  text-gray-900 ring-1 ring-inset
                              ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset
                               focus:ring-indigo-600 sm:text-sm/6" placeholder="Token de Administrador">
                          </div>
                          <button 
                          (click)="Nat_gerar_token_cadastro()"
                          type="button" class="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                           
                            Gerar
                          </button>
                        </div>
                      </div>
                </div>
            }

         
            <div class="sm:col-span-2">
                <label class="block text-sm/6 font-semibold text-gray-900">Email</label>
                <div class="mt-2.5">
                    <input type="text" #inp_email autocomplete="off" 
                        class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300
                         placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm/6">
                </div>
            </div>
            <div class="sm:col-span-2">
                <label class="block text-sm/6 font-semibold text-gray-900">Empresa</label>
                <div class="mt-2.5">
                    <app-cadastro-select-empresas
                    (Evento_empresa_id)="Out_recebe_empresa($event)"
                    ></app-cadastro-select-empresas>
                </div>
            </div>
            <div class="sm:col-span-2">
                <label class="block text-sm/6 font-semibold text-gray-900">Módulos</label>
                <div class="mt-2.5">
           <app-cadastro-select-modulos
           (Evento_modulos)="Out_recebe_modulos($event)"
           ></app-cadastro-select-modulos>
                </div>
            </div>
            
        
        </div>
        <div class="mt-10">
          
            <div class="mb-4">
                <label class="inline-flex items-center cursor-pointer">
                    <input 
                    [(ngModel)]="Nat_isAdmin"
                    [ngModelOptions]="{standalone: true}"
                    (change)="Nat_onCheckboxAdmChange($event)"
                    type="checkbox" value="" class="sr-only peer">
                    <div class="relative w-11 h-6 bg-gray-200 peer-focus:outline-none 
                    peer-focus:ring-4 peer-focus:ring-blue-300
                     dark:peer-focus:ring-blue-800 rounded-full peer
                      dark:bg-gray-700 peer-checked:after:translate-x-full 
                      rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white
                      after:content-[''] after:absolute after:top-[2px] after:start-[2px]
                       after:bg-white after:border-gray-300 after:border after:rounded-full 
                       after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600">
                    </div>
                    <span class="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">Administrador</span>
                  </label>
            </div>

  
            <button type="button"
            (click)="Nat_cadastrar()"
                class="block w-full rounded-md bg-blue-600 px-3.5 py-2.5 text-center text-sm
                 font-semibold text-white shadow-sm hover:bg-blue-500
                  focus-visible:outline focus-visible:outline-2 
                  focus-visible:outline-offset-2 focus-visible:outline-blue-600">
                  Enviar Solicitação
            </button>
        </div>
    </form>



    <!--alert de sucesso-->
@if(Nat_status_alert_sucesso){

    <app-alert-sucesso
    [Input_dados_alert]="Nat_dados_alert"
    (Evento_close_alert_sucesso)="Out_close_alert_sucesso($event)"
    >
    </app-alert-sucesso>
}
<!--alert de erro-->
@if(Nat_status_alert_erro){

    <app-alert-erro
    [Input_dados_alert]="Nat_dados_alert"
    (Evento_close_alert_erro)="Out_close_alert_erro($event)"
    >
    </app-alert-erro>
}
</div>
