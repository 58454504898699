import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DADOS_ALERT } from '../../MODELS/DADOS_ALERT';

@Component({
  selector: 'app-alert-erro',
  standalone: true,
  imports: [],
  templateUrl: './alert-erro.component.html',
  styleUrl: './alert-erro.component.css'
})
export class AlertErroComponent {
  @Input() Input_dados_alert?:DADOS_ALERT

  @Output() Evento_close_alert_erro = new EventEmitter<boolean>();

  Nat_close_alert(){
    this.Evento_close_alert_erro.emit(false)
  }
}
