import { HttpClient } from '@angular/common/http';
import { Component, ViewChild, AfterViewInit, inject, OnInit, viewChild, ElementRef, SimpleChanges, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule, SortDirection } from '@angular/material/sort';
import { merge, Observable, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { MatTableModule, MatTableDataSource } from '@angular/material/table';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DatePipe } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { environment } from '../../../../../environments/environment.development';

import { RESPOSTA_PADRAO_SERVIDOR } from '../../../../MODELS/RESPOSTA_PADRAO_SERVIDOR';
import { OPERACAO_ou_LIVRO } from '../../../../MODELS/OPERACAO_ou_LIVRO';
import { DADOS_ALERT } from '../../../../MODELS/DADOS_ALERT';
import { MainService } from '../../../../SERVICES/main.service';
import { GpxService } from '../../../GPX/SERVICES/gpx.service';
import { DivixService } from '../../SERVICES/divix.service';
import { OSRM_TRIP_RETURN } from '../../../../MODELS/OSRM_TRIP_RETORNO';

@Component({
  selector: 'app-divix-livros',
  standalone: true,
  imports: [
    MatProgressSpinnerModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    DatePipe,
    MatFormFieldModule,
    MatInputModule
  ],
  templateUrl: './divix-livros.component.html',
  styleUrl: './divix-livros.component.css'
})
export class DivixLivrosComponent implements AfterViewInit, OnInit, OnChanges {
  displayedColumns: string[] = ['acoes', 'ID_STR', 'CENTRO_LEITURA_STR', 'GRUPO_STR', 'QUANT_ITENS', 'DURACAO_STR','DISTANCIA_STR'];

  dataSource: MatTableDataSource<OPERACAO_ou_LIVRO>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private service: DivixService, private main_service: MainService) {


  }
  Nat_operacoes: OPERACAO_ou_LIVRO[] = []

  /**evento que abre a aba de loading no component main */
  @Output() Evento_open_loading = new EventEmitter<boolean>();

  /**evento que fecha a aba de loading no component main */
  @Output() Evento_close_loading = new EventEmitter<boolean>();

  /**evento que abre um alerta de sucesso no component main */
  @Output() Evento_open_alert_sucess = new EventEmitter<DADOS_ALERT>();

  /**evento que abre um alerta de erro no component main */
  @Output() Evento_open_alert_erro = new EventEmitter<DADOS_ALERT>();



  @Input() input_operacao_selecionada: number

  Nat_visao_poligonos: "ANALITICA" | "SIMPLES" 

  async ngOnInit() {

    this.Evento_open_loading.emit(true)

    const requisicao_server: any = await this.service.POST_RETORNA_LIVROS_OPERACAO_DIVIX(this.input_operacao_selecionada)
    const resposta: RESPOSTA_PADRAO_SERVIDOR = requisicao_server


    if (resposta.MSG == 'SUCESSO') {

      this.Nat_operacoes = resposta.RESPOSTA

      this.dataSource = new MatTableDataSource(this.Nat_operacoes);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;


      this.dataSource.data.forEach((operacao, o) => {


        if (this.input_livros_mapa.filter((livro => livro.ID_STR == operacao.ID_STR && livro.LIVRO_STR == operacao.LIVRO_STR)).length > 0) {

          this.dataSource.data[o].VISIVEL = true
        } else {
          this.dataSource.data[o].VISIVEL = false
        }
      })


      if (this.dataSource.data.filter((operacao) => operacao.VISIVEL == true).length > 0) {
        this.Nat_all_itens_mapa = true
      }



      this.Evento_close_loading.emit(true)
    } else {
      this.Evento_close_loading.emit(true)
      let new_alert: DADOS_ALERT = {
        TITULO: resposta.MSG,
        CORPO: resposta.ADICIONAL
      }
      this.Evento_open_alert_erro.emit(new_alert)
    }
  }

  @Output() Evento_tipo_visao_livros = new EventEmitter<string>()
  Nat_change_visao_poligonos() {
    if (this.Nat_visao_poligonos == 'ANALITICA') {
      this.Nat_visao_poligonos = 'SIMPLES'

      this.Evento_tipo_visao_livros.emit('SIMPLES')
    } else {
      this.Nat_visao_poligonos = 'ANALITICA'

      this.Evento_tipo_visao_livros.emit('ANALITICA')
    }
  }

  ngAfterViewInit() {

  }

  /**
 * ids das operacoes que estao em mapa para manter a visualizacao do mapa atualizada com as indicacoes dos itens 
 * que estao ou nao no mapa e estao ou nao disponiveis
 */
  @Input() input_ids_operacoes_mapa: number[] = []

  @Input() input_ids_operacoes_mapa_disponiveis: number[] = []

  @Input() input_livros_mapa: OPERACAO_ou_LIVRO[] = []


  /**evento que envia um id de operacao para ser aberto no mapa */
  @Output() Evento_open_operacao = new EventEmitter<OPERACAO_ou_LIVRO>();
  /**evento que envia um id de operacao para ser fechado no mapa */
  @Output() Evento_close_operacao = new EventEmitter<OPERACAO_ou_LIVRO>();


  Nat_open_operacao(operacao: OPERACAO_ou_LIVRO) {

      this.Evento_open_operacao.emit(operacao)


      const index_operacao: number = this.dataSource.data.findIndex((livro => livro.ID_STR == operacao.ID_STR && livro.LIVRO_STR == operacao.LIVRO_STR))
      this.dataSource.data[index_operacao].VISIVEL = true


    



  }


  Nat_all_itens_mapa: boolean = false
  Nat_open_todos() {

      for (let d = 0; d < this.dataSource.data.length; d++) {
        if (this.dataSource.data[d].VISIVEL == false) {
          this.Nat_open_operacao(this.dataSource.data[d])
        }

      }

      this.Nat_all_itens_mapa = true
    

  }
  Nat_close_todos() {
    for (let d = 0; d < this.dataSource.data.length; d++) {
      if (this.dataSource.data[d].VISIVEL == true) {
        this.Nat_close_operacao(this.dataSource.data[d])
      }

    }

    this.Nat_all_itens_mapa = false
  }


  Nat_close_operacao(operacao: OPERACAO_ou_LIVRO) {
    this.Evento_close_operacao.emit(operacao)

    const index_operacao: number = this.dataSource.data.findIndex((livro => livro.ID_STR == operacao.ID_STR && livro.LIVRO_STR == operacao.LIVRO_STR))
    this.dataSource.data[index_operacao].VISIVEL = false

  }

  async Nat_delete_operacao(operacao_id: number) {

    this.Evento_open_loading.emit(true)

    const requisicao_server: any = await this.main_service.POST_DELETE_OPERACAO(operacao_id)
    const resposta: RESPOSTA_PADRAO_SERVIDOR = requisicao_server


    if (resposta.MSG == 'SUCESSO') {


      if (this.input_ids_operacoes_mapa.includes(operacao_id)) {

        const index = this.dataSource.data.findIndex((operacao => operacao.ID == operacao_id))
        this.Nat_close_operacao(this.dataSource.data[index])
      }


      this.dataSource.data = this.dataSource.data.filter((operacao => operacao.ID != operacao_id))
      this.Evento_close_loading.emit(true)
      let new_alert: DADOS_ALERT = {
        TITULO: resposta.MSG,
        CORPO: resposta.ADICIONAL
      }
      this.Evento_open_alert_sucess.emit(new_alert)

    } else {
      this.Evento_close_loading.emit(true)
      let new_alert: DADOS_ALERT = {
        TITULO: resposta.MSG,
        CORPO: resposta.ADICIONAL
      }
      this.Evento_open_alert_erro.emit(new_alert)
    }
  }

  applyFilter(event: Event) {//filtragem de itens da tabela
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  @ViewChild('input') input!: ElementRef;

  Nat_clear_filter() {//limpa o campo de busca 
    this.input.nativeElement.value = ''

    this.dataSource.filter = ''
  }


  @Output() Evento_close_livros = new EventEmitter<boolean>()

  Nat_close_aba() {
    /**
     * fecha a lista de livros atuais
     */
    this.Evento_close_livros.emit(false)

  }

  async Nat_calcular_duracoes_gerais(tipo: "pe" | "carro" | "moto"){
    for(let d =0;d<this.dataSource.data.length;d++){
      let livro_calculado = await this.Nat_calcular_duracao_livro(this.dataSource.data[d],tipo)
    }
  }
  @Output() Evento_desenha_trajeto_osrm = new EventEmitter<OSRM_TRIP_RETURN>()
  async Nat_calcular_duracao_livro(livro_operacao: OPERACAO_ou_LIVRO, tipo: "pe" | "carro" | "moto") {

    let operacao_id: number = parseInt(livro_operacao.ID.split("-")[0])
    let livro: string = livro_operacao.LIVRO_STR.replace(".", "")
    let origem_id: number = null


    this.Evento_open_loading.emit(true)

    const requisicao_server: any = await this.service.POST_OSRM_TRIP(
      operacao_id,
      livro,
      origem_id,
      tipo
    )
    const resposta: RESPOSTA_PADRAO_SERVIDOR = requisicao_server



    if (resposta.MSG == 'SUCESSO') {
      this.Evento_close_loading.emit(true)
      //this.Evento_desenha_trajeto_osrm.emit(resposta.RESPOSTA)

     let atualiza = await this.ngOnInit()
      console.log(resposta.RESPOSTA)
    } else {
      this.Evento_close_loading.emit(true)
      let new_alert: DADOS_ALERT = {
        TITULO: resposta.MSG,
        CORPO: resposta.ADICIONAL
      }
      this.Evento_open_alert_erro.emit(new_alert)
    }
  }

  Nat_rangeValue: number = 0; // Valor inicial do range

    // Funções para lidar com mudanças nos campos
    Nat_onRangeChange(event: Event): void {
      const target = event.target as HTMLInputElement;
      this.Nat_rangeValue = Number(target.value);
  
  
  
     
    }


  // async Nat_envia_divix(operacao_id: number) {
  //   this.Evento_open_loading.emit(true)

  //   const requisicao_server: any = await this.service.POST_ENVIAR_DIVIX(operacao_id)
  //   const resposta: RESPOSTA_PADRAO_SERVIDOR = requisicao_server


  //   if (resposta.MSG == 'SUCESSO') {


  //     if(this.input_ids_operacoes_mapa.includes(operacao_id)){

  //       const index = this.dataSource.data.findIndex((operacao => operacao.ID == operacao_id))

  //       this.Nat_close_operacao(this.dataSource.data[index])
  //     }

  //     this.dataSource.data = this.dataSource.data.filter((operacao => operacao.ID != operacao_id))


  //     this.Evento_close_loading.emit(true)
  //     let new_alert: DADOS_ALERT = {
  //       TITULO: resposta.MSG,
  //       CORPO: resposta.ADICIONAL
  //     }
  //     this.Evento_open_alert_sucess.emit(new_alert)
  //   } else {
  //     this.Evento_close_loading.emit(true)
  //     let new_alert: DADOS_ALERT = {
  //       TITULO: resposta.MSG,
  //       CORPO: resposta.ADICIONAL
  //     }
  //     this.Evento_open_alert_erro.emit(new_alert)
  //   }
  // }
  ngOnChanges(changes: SimpleChanges) {
    // Verifica se houve mudanças em 'input_ids_operacoes_mapa'
    if (changes['input_ids_operacoes_mapa']) {
      const change = changes['input_ids_operacoes_mapa'];

      // Verifica se houve realmente uma mudança no valor da variavel input_ids_operacoes_mapa
      if (change.previousValue !== change.currentValue) {

        if (change.currentValue.length > 0 && this.dataSource) {

          this.dataSource.data.forEach((operacao, o) => {
            if (change.currentValue.includes(operacao.ID)) {
              this.dataSource.data[o].VISIVEL = true
            } else {
              this.dataSource.data[o].VISIVEL = false
            }
          })


        }

      }
    }
  }
}
