import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';

import * as L from 'leaflet';
import 'leaflet.markercluster';
import 'leaflet-draw';
import 'leaflet-editable';

import { AlertLoadingComponent } from '../../../../COMPONENTS_UI/alert-loading/alert-loading.component';
import { NavbarMainComponent } from "../../../../COMPONENTS_UI/navbar/navbar-main/navbar-main.component";
import { GpxDisponiveisComponent } from "../gpx-disponiveis/gpx-disponiveis.component";
import { GpxEdicoesComponent } from "../gpx-edicoes/gpx-edicoes.component";
import { GpxFerramentasComponent } from '../gpx-ferramentas/gpx-ferramentas.component';
import { DADOS_ALERT } from '../../../../MODELS/DADOS_ALERT';
import { AlertSucessoComponent } from "../../../../COMPONENTS_UI/alert-sucesso/alert-sucesso.component";
import { AlertErroComponent } from "../../../../COMPONENTS_UI/alert-erro/alert-erro.component";
import { OPERACAO_ou_LIVRO } from '../../../../MODELS/OPERACAO_ou_LIVRO';
import { RESPOSTA_PADRAO_SERVIDOR } from '../../../../MODELS/RESPOSTA_PADRAO_SERVIDOR';
import { GpxService } from '../../SERVICES/gpx.service';
import { INSTALACAO_BD } from '../../../../MODELS/INSTALACAO_BD';
import { COORDENADA } from '../../../../MODELS/COORDENADA';

import hull from 'hull.js';
import { MainService } from '../../../../SERVICES/main.service';
import { GpxDetalhesPoligonoComponent } from '../../../../COMPONENTS_UI/gpx-detalhes-poligono/gpx-detalhes-poligono.component';
import { DETALHES_POLIGONO } from '../../../../MODELS/DETALHES_POLIGONO';
import { environment } from '../../../../../environments/environment.development';
import { ModulosComponent } from '../../../../COMPONENTS_UI/modulos/modulos.component';
import { PerfilComponent } from '../../../../COMPONENTS_UI/perfil/perfil.component';
import { initFlowbite } from 'flowbite';

export class VALIDA_LOGRADOURO {
  ID:number
  LOGRADOURO:string
  BAIRRO
  CIDADE:string
  ESTADO:string
  EMPRESA_ID:number
  OPERACAO_ID:number
  VALIDADO:boolean
  USER_VALIDADOR:string
  DATA_VALIDACAO:string
}


@Component({
  selector: 'app-gpx-main',
  standalone: true,
  imports: [
    PerfilComponent,
    ModulosComponent,
    AlertLoadingComponent,
    NavbarMainComponent,
    GpxDisponiveisComponent,
    GpxEdicoesComponent,
    GpxFerramentasComponent,
    AlertSucessoComponent,
    AlertErroComponent,
    GpxDetalhesPoligonoComponent
  ],

  templateUrl: './gpx-main.component.html',
  styleUrls: ['./gpx-main.component.css']
})
export class GpxMainComponent implements OnInit {
  private leaflet = (window as any).L; // Referência ao Leaflet com MarkerCluster

  map!: L.Map;

  Nat_dados_alert?: DADOS_ALERT

  Nat_state_loading: boolean = false

  logradouros_localizados:VALIDA_LOGRADOURO[] = []

  Nat_status_alert_sucesso: boolean = false
  Out_close_alert_sucesso(status: boolean) {//fecha o alerta de sucesso
    this.Nat_status_alert_sucesso = status
    this.Nat_dados_alert = undefined
  }
  Nat_status_alert_erro: boolean = false
  Out_close_alert_erro(status: boolean) {//fecha o alerta de erro
    this.Nat_status_alert_erro = status
    this.Nat_dados_alert = undefined
  }


  constructor(private router: Router, private service: GpxService, private service_main: MainService) { }

  ngOnInit() {

    initFlowbite();

    if (environment.user_atual) {
      this.initMap();
    } else {
      this.router.navigateByUrl('')
    }

  }


  Nat_normalMap: L.TileLayer
  Nat_satelliteMap: L.TileLayer
  Nat_simplifiedMap: L.TileLayer
  Nat_activeLayer: L.TileLayer


  Nat_draw_control: L.Control.Draw //recebe o controle de criacao de poligonos temporarios para seleção e transferencia de itens


  Nat_polygons_transferencia: L.Polygon[] = []

  initMap() {
    // Inicializando o mapa com coordenadas e nível de zoom
    if(!this.map){
      // Inicializando o mapa com coordenadas e nível de zoom
      this.map = this.leaflet.map('map_gpx', {
        center: [-14.2350, -51.9253],  // Coordenadas do centro inicial do mapa
        zoom: 5,  // Nível de zoom inicial
        maxZoom: 18,  // Definindo o zoom máximo
        minZoom: 3,  // Definindo o zoom mínimo
        editable: true, // Habilita a edição no mapa
  
      });
      }

    navigator.geolocation.getCurrentPosition(
      (position) => {
        const userLat = position.coords.latitude;
        const userLng = position.coords.longitude;


        this.map.setView([userLat, userLng])

      })

      this.map.on('contextmenu', (event) => {
        if (this.Nat_state_coleta_coordenada) {
          // Obtém a latitude e longitude do evento
          const latlng = event.latlng;
          const coordenada_coletada = `${latlng.lat},${latlng.lng}`;
      
          // Cria a URL do Google Maps com as coordenadas
          const googleMapsUrl = `https://www.google.com/maps?q=${latlng.lat},${latlng.lng}`;
      
          // Envia para a função de cópia
          this.Nat_copy_value(googleMapsUrl);
      
          // Exibe no console para verificar
          console.log('URL do Google Maps:', googleMapsUrl);
        }
      });
      
    //clicar no mapa
    this.map.on('click', (event) => {
      if (this.Nat_state_coleta_coordenada) {
        /**
         * se a funcao de copia de coordenada manual estiver ativa chama a funcao que copia para a 
         * area de transferencia a coordenada
         */

        const latlng = event.latlng;
        const coordenada_coletada = latlng
        console.log('copia de click:', `${coordenada_coletada.lat},${coordenada_coletada.lng}`);

        this.Nat_copy_value(`${coordenada_coletada.lat},${coordenada_coletada.lng}`)
      }
    })
 


    // Remover o controle de zoom
    //this.map.zoomControl.setPosition('bottomright');


    // Inicializando o controle de desenho com edição habilitada
    this.Nat_draw_control = new L.Control.Draw({
      position: 'bottomleft',

      draw: {
        polyline: false, // Desativando linha poligonal
        polygon: {
          shapeOptions: {
            color: 'blue', // Cor do polígono
            weight: 5, // Espessura da borda
            opacity: 0.5, // Opacidade da borda
            fillOpacity: 0.2, // Opacidade de preenchimento
            fillColor: 'blue', // Cor de preenchimento
          },
        },
        rectangle: false, // Desativando a criação de retângulos
        circle: false, // Desativando a criação de círculos
        marker: false, // Desativando a criação de marcadores
        circlemarker: false
      },
    });





    this.map.on(L.Draw.Event.CREATED, (e: any) => {
      //limpando os dados de poligono selecionado
      this.Nat_detalhes_poligono = undefined

      const type = e.layerType,
        layer = e.layer;

      if (type === 'polygon') {
        // Verifica se já existe algum polígono no array e o remove
        if (this.Nat_polygons_transferencia.length > 0) {
          // Remover o polígono antigo do mapa
          const oldLayer = this.Nat_polygons_transferencia.pop(); // Remove o último polígono
          this.map.removeLayer(oldLayer); // Remove do mapa

          this.Nat_polygons_transferencia = []
        }

        // Habilitando a edição do polígono
        this.map.addLayer(layer);
        layer.enableEdit();
        this.Nat_polygons_transferencia.push(layer);



        // Evento de clique no polígono
        layer.on('click', async () => {


          // Encontrar o índice do polígono no array
          const index = this.Nat_polygons_transferencia.indexOf(layer);

          let ids_instalacoes_mapa: number[] = this.Nat_operacoes_mapa_edicoes
            .map(operacao => operacao.ITENS.map(item => item.ID)) // Mapeia os IDs de cada item
            .flat(); // Achata o array de arrays em um único array de números



          // Obtendo as coordenadas do polígono
          const latLngs = layer.getLatLngs(); // Isso retorna as coordenadas dos vértices do polígono

          // Transformando as coordenadas em um formato desejado (ex: [lat, lng])
          const coordenadas_poligono: COORDENADA[] = latLngs[0].map((latLng: L.LatLng) => ({
            lat: latLng.lat,
            lng: latLng.lng
          }));

          const requisicao_server: any = await this.service_main.POST_MARKER_IN_POLIGONO(coordenadas_poligono, ids_instalacoes_mapa,'GPS');
          const resposta: RESPOSTA_PADRAO_SERVIDOR = requisicao_server;

          if (resposta.MSG == 'SUCESSO') {


            let detalhes_poligono: DETALHES_POLIGONO = {
              OPERACAO_ID: undefined,
              CENTRO_LEITURA: undefined,
              GRUPO: undefined,
              LIVRO: undefined,
              DURACAO: undefined,
              TOTAL_ITENS: resposta.RESPOSTA.length,
              OPERACAO_ID_DEST: undefined,
              LIVRO_DEST: undefined,
              TIPO: 'SELECAO',
              MODULO: 'GPX',
              ITENS_ID: resposta.RESPOSTA,
              ID_INICIO: 0,
              DISTANCIA: undefined
            }

            this.Nat_detalhes_poligono = detalhes_poligono

            this.Nat_state_detalhes_poligono = true


          } else {
            this.Nat_state_loading = false
            let new_alert: DADOS_ALERT = {
              TITULO: 'ERRO',
              CORPO: resposta.ADICIONAL
            };
            this.Nat_dados_alert = new_alert;
            this.Nat_status_alert_erro = true;
          }
        });

        // Evento de edição do polígono (quando o vértice for movido)
        layer.on('editable:vertex:dragend', async () => {

          // Encontrar o índice do polígono no array
          const index = this.Nat_polygons_transferencia.indexOf(layer);

          let ids_instalacoes_mapa: number[] = this.Nat_operacoes_mapa_edicoes
            .map(operacao => operacao.ITENS.map(item => item.ID)) // Mapeia os IDs de cada item
            .flat(); // Achata o array de arrays em um único array de números



          // Obtendo as coordenadas do polígono
          const latLngs = layer.getLatLngs(); // Isso retorna as coordenadas dos vértices do polígono

          // Transformando as coordenadas em um formato desejado (ex: [lat, lng])
          const coordenadas_poligono: COORDENADA[] = latLngs[0].map((latLng: L.LatLng) => ({
            lat: latLng.lat,
            lng: latLng.lng
          }));

          const requisicao_server: any = await this.service_main.POST_MARKER_IN_POLIGONO(coordenadas_poligono, ids_instalacoes_mapa,'GPS');
          const resposta: RESPOSTA_PADRAO_SERVIDOR = requisicao_server;

          if (resposta.MSG == 'SUCESSO') {


            let detalhes_poligono: DETALHES_POLIGONO = {
              OPERACAO_ID: undefined,
              CENTRO_LEITURA: undefined,
              GRUPO: undefined,
              LIVRO: undefined,
              DURACAO: undefined,
              TOTAL_ITENS: resposta.RESPOSTA.length,
              OPERACAO_ID_DEST: undefined,
              LIVRO_DEST: undefined,
              TIPO: 'SELECAO',
              MODULO: 'GPX',
              ITENS_ID: resposta.RESPOSTA,
              ID_INICIO: 0,
              DISTANCIA: undefined
            }

            this.Nat_detalhes_poligono = detalhes_poligono

            this.Nat_state_detalhes_poligono = true


          } else {
            this.Nat_state_loading = false
            let new_alert: DADOS_ALERT = {
              TITULO: 'ERRO',
              CORPO: resposta.ADICIONAL
            };
            this.Nat_dados_alert = new_alert;
            this.Nat_status_alert_erro = true;
          }
        });
      }
    });
    this.map.on(L.Draw.Event.DRAWSTART, (e: any) => {

      //limpando os dados de poligono selecionado
      this.Nat_detalhes_poligono = undefined

      if (this.Nat_polygons_transferencia.length > 0) {
        // Remover o polígono antigo do mapa
        const oldLayer = this.Nat_polygons_transferencia.pop(); // Remove o último polígono
        this.map.removeLayer(oldLayer); // Remove do mapa
      }

    })


    // Definindo as camadas de mapa
    this.Nat_normalMap = this.leaflet.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    });

    this.Nat_satelliteMap = this.leaflet.tileLayer('https://services.arcgisonline.com/arcgis/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', {
      attribution: 'Tiles © Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
    });

    this.Nat_simplifiedMap = this.leaflet.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}', {
      attribution: 'Tiles &copy; Esri &mdash; Esri, DeLorme, NAVTEQ',
    });

    // Adicionando o mapa normal inicialmente
    this.Nat_normalMap.addTo(this.map);

    // Inicializando a camada ativa
    this.Nat_activeLayer = this.Nat_normalMap;



  }


  Nat_coordenada_local_buscado:L.Marker
  Out_move_local_buscado(coordenada:COORDENADA){

    if(this.Nat_coordenada_local_buscado){
      this.Nat_coordenada_local_buscado.remove()
      this.Nat_coordenada_local_buscado = undefined
    }

    const customIcon = L.icon({
      iconUrl: 'https://res.cloudinary.com/de74yvifw/image/upload/v1734221320/position-marker-svgrepo-com_uv8iqc.svg', // Caminho da imagem
      iconSize: [32, 32], // Tamanho do ícone [largura, altura]
      iconAnchor: [16, 32], // Ponto de ancoragem do ícone [largura, altura]
      popupAnchor: [0, -32] // Ponto de ancoragem do popup [x, y]
    });

    this.Nat_coordenada_local_buscado = L.marker([coordenada.lat,coordenada.lng], { icon: customIcon }).addTo(this.map);
    this.map.setView([coordenada.lat, coordenada.lng], 15);
    this.Nat_coordenada_local_buscado.on('click', (event) => {
      if (this.Nat_coordenada_local_buscado) {
        this.Nat_coordenada_local_buscado.remove()
        this.Nat_coordenada_local_buscado = undefined
      }
    })

  }

  Nat_markers_temporarios:L.Marker[] = []
  Out_cria_markers_temporarios(coordenadas: COORDENADA[]) {


    if(this.Nat_markers_temporarios.length>0){
      this.Nat_markers_temporarios.forEach((marker, m) => {
        this.Nat_markers_temporarios[m].remove();
      });
      this.Nat_markers_temporarios = []
    }else{
      this.Nat_markers_temporarios.forEach((marker, m) => {
        this.Nat_markers_temporarios[m].remove();
      });
      this.Nat_markers_temporarios = [];
    
       // Ícone personalizado (com sua imagem)
  const customIcon = L.icon({
    iconUrl: 'https://res.cloudinary.com/de74yvifw/image/upload/v1734221320/position-marker-svgrepo-com_uv8iqc.svg', // Caminho da imagem personalizada
    iconSize: [64, 64], // Tamanho do ícone [largura, altura]
    iconAnchor: [32, 64], // Ponto de ancoragem do ícone [largura, altura]
    popupAnchor: [0, -64], // Ponto de ancoragem do popup [x, y]
    className: 'black-marker',  // Aplica a classe CSS com a animação de salto
  });
    
      coordenadas.forEach(coordenada => {
        const marker = L.marker([coordenada.lat, coordenada.lng], { icon: customIcon }).addTo(this.map);
        this.Nat_markers_temporarios.push(marker);
    
        marker.on('click', (event) => {
          marker.remove();
        });
      });
    
      if (this.Nat_markers_temporarios.length > 0) {
        this.map.setView([coordenadas[0].lat, coordenadas[0].lng], 15);
      }
    }

 
  }
  

  Out_change_transferencia(state: boolean) {
    this.Nat_state_transferencia_itens = state
    if (state == true) {
      this.map.addControl(this.Nat_draw_control);
    } else {
      this.Nat_detalhes_poligono = undefined


      // Verifica se já existe algum polígono no array e o remove
      if (this.Nat_polygons_transferencia.length > 0) {
        // Remover o polígono antigo do mapa
        const oldLayer = this.Nat_polygons_transferencia.pop(); // Remove o último polígono
        this.map.removeLayer(oldLayer); // Remove do mapa
      }
      this.map.removeControl(this.Nat_draw_control)
    }


  }

  Nat_state_DISPONIVEIS: boolean = false
  Out_change_aba_DISPONIVEIS(state: boolean) {//altera o estado da aba disponiveis
    this.Nat_state_DISPONIVEIS = state

    if (state = true) {//se estiver abrindo, tem que fechar as demais abas
      this.Nat_state_EDICOES = false
      this.Nat_state_FERRAMENTAS = false
      this.Nat_state_MODULOS = false
    }

  }
  Nat_state_EDICOES: boolean = false
  Out_change_aba_EDICOES(state: boolean) {//altera o estado da aba edicoes
    this.Nat_state_EDICOES = state

    if (state = true) {//se estiver abrindo, tem que fechar as demais abas
      this.Nat_state_DISPONIVEIS = false
      this.Nat_state_FERRAMENTAS = false
      this.Nat_state_MODULOS = false
    }
  }
  Nat_state_FERRAMENTAS: boolean = false
  Out_change_aba_FERRAMENTAS(state: boolean) {//altera o estado da aba edicoes
    this.Nat_state_FERRAMENTAS = state

    if (state = true) {//se estiver abrindo, tem que fechar as demais abas
      this.Nat_state_EDICOES = false
      this.Nat_state_DISPONIVEIS = false
      this.Nat_state_MODULOS = false
    }
  }
  Nat_state_MODULOS: boolean = false
  Out_change_aba_MODULOS(state: boolean) {//altera o estado da aba de modulos
    this.Nat_state_MODULOS = state

    if (state = true) {//se estiver abrindo, tem que fechar as demais abas
      this.Nat_state_EDICOES = false
      this.Nat_state_DISPONIVEIS = false
      this.Nat_state_FERRAMENTAS = false
    }
  }
  Nat_style_map_index: number = 0
  Out_change_map_type(variavel_inutil: any) { //altera a visualizacao do mapa entre normal, satelite e simplificado

    // Remover a camada atual
    this.map.removeLayer(this.Nat_activeLayer);



    if (this.Nat_style_map_index == 0) {
      this.Nat_style_map_index = 1
      this.Nat_activeLayer = this.Nat_satelliteMap;
      this.Nat_activeLayer.addTo(this.map);
    } else if (this.Nat_style_map_index == 1) {
      this.Nat_style_map_index = 2
      this.Nat_activeLayer = this.Nat_simplifiedMap;
      this.Nat_activeLayer.addTo(this.map);
    } else if (this.Nat_style_map_index == 2) {
      this.Nat_style_map_index = 0
      this.Nat_activeLayer = this.Nat_normalMap;
      this.Nat_activeLayer.addTo(this.map);
    }



  }

  Nat_state_perfil:boolean = false

  Out_change_state_perfil(state:boolean){
    this.Nat_state_perfil=state
  }


  Nat_state_coleta_coordenada: boolean = false //se ativo esta coletando coordenadas manualmente

  Nat_state_transferencia_itens: boolean = false

  Out_change_state_coleta_coordenada(state: boolean) {
    //recebe o estado do botao de coleta manual de coordenada do component gpx-ferramentas
    this.Nat_state_coleta_coordenada = state
  }

  Nat_state_detalhes_poligono: boolean = false

  Nat_detalhes_poligono: DETALHES_POLIGONO
  Out_change_state_detalhes_poligono(state: boolean) {
    this.Nat_state_detalhes_poligono = state
  }

  Nat_operacoes_mapa_disponiveis: OPERACAO_ou_LIVRO[] = []
  Nat_ids_operacoes_mapa_disponiveis: number[] = []
  /**
   * recebe a operacao que deve ser lançada no mapa, busca suas instalacoes, e as lança no mapa
   * elas são agrupadas em clusters para melhorar o desempenho e usabilidade, e são exibidos apennas os
   * pontos que estao dentro da vizualização atual
   */
  async Out_open_operacao_disponivel(operacao: OPERACAO_ou_LIVRO) {

    if (this.Nat_ids_operacoes_mapa_edicoes.length > 0) {
      this.Nat_state_loading = false
      let new_alert: DADOS_ALERT = {
        TITULO: 'ERRO',
        CORPO: 'Não é Possível Analizar Itens Disponíveis e Edições ao mesmo Tempo!'
      };
      this.Nat_dados_alert = new_alert;
      this.Nat_status_alert_erro = true;
    } else {
      this.Nat_state_loading = true

      const requisicao_server: any = await this.service.POST_ITENS_OPERACAO_GPX(operacao.ID);
      const resposta: RESPOSTA_PADRAO_SERVIDOR = requisicao_server;

      if (resposta.MSG === 'SUCESSO') {
        // let new_alert: DADOS_ALERT = {
        //   TITULO: resposta.MSG,
        //   CORPO: resposta.ADICIONAL
        // };
        // this.Nat_dados_alert = new_alert;
        // this.Nat_status_alert_sucesso = true;

        const instalacoes: INSTALACAO_BD[] = resposta.RESPOSTA;
        let new_operacao_map: OPERACAO_ou_LIVRO = operacao;

        // Criando o grupo de marcadores com o plugin MarkerCluster
        const cluster = this.leaflet.markerClusterGroup();
        const markers: L.CircleMarker[] = []; // Para armazenar os marcadores individuais
        const cor: string = this.Nat_gerarCorUnica()

        let coordenadas_poligono: COORDENADA[] = []
        let coordenadas_hull: number[][] = [];


        instalacoes.forEach((instalacao:any) => {
          const marcador = L.circleMarker([instalacao.LAT, instalacao.LNG], {
            radius: 3,
            color: cor,
            weight: 2,
            opacity: 0.8,
            fillColor: '#484e55',
            fillOpacity: 0.8
          });

          coordenadas_hull.push([instalacao.LNG, instalacao.LAT])


          // Adiciona uma tooltip ao passar o mouse sobre o marcador, com cada informação em uma linha separada
          marcador.bindTooltip(
            `
            <div>Instalação: ${instalacao.INSTALACAO}</div>
            <div>Endereço: ${instalacao.TIPO_LOGRADOURO} ${instalacao.LOGRADOURO} ${instalacao.NUMERO} ${instalacao.BAIRRO} ${instalacao.CIDADE}</div>
            <div>OPERAÇÃO: ${instalacao.OPERACAO_ID}</div>
            <div>CENTRO DE LEITURA: ${instalacao.CENTRO_LEITURA}</div>
            <div>GRUPO: ${instalacao.GRUPO}</div>
            <div>HISTÓRICO: ${instalacao.COORDS_HISTORY}</div>`,
            {
              permanent: false,
              direction: 'top',
              offset: [0, -10],
              className: 'custom-tooltip' // Classe CSS personalizada
            }
          );
          new_operacao_map.ITENS.push(instalacao)
          new_operacao_map.MARKERS = markers;
          cluster.addLayer(marcador);
          markers.push(marcador);
        });


        const resultadoHull = hull(coordenadas_hull, 100);


        resultadoHull.forEach((arr_coord) => {
          coordenadas_poligono.push({
            lat: arr_coord[1],
            lng: arr_coord[0]
          })
        });


        const poligono_operacao = L.polygon(coordenadas_poligono, {
          fillColor: cor,
          color: cor,
          weight: 3,
          opacity: 1,
          fillOpacity: 0.1
        }).addTo(this.map);
        //poligono_operacao.enableEdit();

        // Adiciona uma tooltip ao passar o mouse sobre o marcador, com cada informação em uma linha separada
        poligono_operacao.bindTooltip(
          `
                  <div>OPERACAO: ${operacao.ID}</div>
                  <div>CENTRO DE LEITURA: ${operacao.CENTRO_LEITURA_STR}</div>
                  <div>GRUPO: ${operacao.GRUPO_STR}</div>
                  <div>ITENS: ${operacao.ITENS.length}</div>
                 `,
          {
            permanent: false,
            direction: 'top',
            offset: [0, -10],
            className: 'custom-tooltip' // Classe CSS personalizada
          }
        );

        // Evento de início da edição do polígono
        poligono_operacao.on('editable:vertex:dragend', async (event) => {

          alert('mudou o poligono')
          const newCoordinates = event.target.getLatLngs();
          // Atualize o array com as novas coordenadas se necessário

          // newCoordinates[0].forEach((latlng: any) => {
          //   coordenadas_polygon.push({ lat: latlng.lat, lng: latlng.lng });


          // });

        })

        new_operacao_map.POLYGON = poligono_operacao




        new_operacao_map.CLUSTERS.push(cluster);
        this.map.addLayer(cluster);

        const updateMarkerDisplay = () => {
          if (this.Nat_ids_operacoes_mapa_disponiveis.includes(operacao.ID)) {
            const zoomLevel = this.map.getZoom();
            const mapBounds = this.map.getBounds();

            if (zoomLevel >= 16) {
              // Remove o cluster e exibe apenas os marcadores dentro dos limites visíveis
              this.map.removeLayer(cluster);
              markers.forEach(marker => {
                if (mapBounds.contains(marker.getLatLng())) {
                  this.map.addLayer(marker);
                } else {
                  this.map.removeLayer(marker);
                }
              });
            } else {
              // Remove os marcadores individuais e exibe o cluster
              markers.forEach(marker => {
                this.map.removeLayer(marker);
              });
              this.map.addLayer(cluster);
            }
          } else {
            // Remover todos os marcadores e clusters da visualização
            markers.forEach(marker => {
              this.map.removeLayer(marker);
            });
            this.map.removeLayer(cluster);


          }
        };

        this.map.on('zoomend moveend', updateMarkerDisplay);
        updateMarkerDisplay();

        this.map.setView([instalacoes[0].LAT, instalacoes[0].LNG], 15);
        this.Nat_operacoes_mapa_disponiveis.push(new_operacao_map);
        this.Nat_ids_operacoes_mapa_disponiveis.push(operacao.ID);





        this.Nat_state_loading = false
      } else {
        this.Nat_state_loading = false
        let new_alert: DADOS_ALERT = {
          TITULO: 'ERRO',
          CORPO: resposta.ADICIONAL
        };
        this.Nat_dados_alert = new_alert;
        this.Nat_status_alert_erro = true;
      }
    }

  }


  /**
   *retira do mapa tanto markers como clusters de uma operacao selecionada
   */
  Out_close_operacao_disponivel(operacao: OPERACAO_ou_LIVRO) {

    this.Nat_state_loading = true

    // Encontrar o índice da operação específica no array de operações
    const index = this.Nat_operacoes_mapa_disponiveis.findIndex(
      (operacao_local) => operacao_local.ID == operacao.ID
    );

    if (index !== -1) {
      // Remover todos os clusters associados a esta operação
      this.Nat_operacoes_mapa_disponiveis[index].CLUSTERS.forEach(cluster => {
        this.map.removeLayer(cluster);
      });

      // Remover todos os marcadores individuais associados a esta operação
      this.Nat_operacoes_mapa_disponiveis[index].MARKERS.forEach(marker => {
        this.map.removeLayer(marker);
      });

      this.Nat_operacoes_mapa_disponiveis[index].POLYGON.remove()
    }

    // Remover a operação da lista de operações disponíveis
    this.Nat_operacoes_mapa_disponiveis = this.Nat_operacoes_mapa_disponiveis.filter(
      (operacao_local) => operacao_local.ID != operacao.ID
    );
    this.Nat_ids_operacoes_mapa_disponiveis = this.Nat_ids_operacoes_mapa_disponiveis.filter(
      (id) => id != operacao.ID
    );

    this.Nat_state_loading = false


  }




  async Out_repoe_mapa_pos_edicao(variavel_inutil: boolean) {
    this.Nat_state_loading = true;
    this.Nat_state_transferencia_itens = false
  
    // Capturar o centro e o nível de zoom atuais antes de remover o mapa
    let currentCenter = this.map?.getCenter();
    let currentZoom = this.map?.getZoom();
  
    if (this.map) {
      // Remover todas as layers e o próprio mapa
      this.map.off();
      this.map.remove();
    }
  
    // Recriar o mapa usando o centro e o nível de zoom anteriores
    if (currentCenter && currentZoom) {
      this.map = this.leaflet.map('map_gpx', {
        center: [currentCenter.lat, currentCenter.lng],  // Coordenadas do centro inicial do mapa
        zoom: currentZoom,  // Nível de zoom inicial
        maxZoom: 18,  // Definindo o zoom máximo
        minZoom: 3,  // Definindo o zoom mínimo
        editable: true, // Habilita a edição no mapa
  
      });
          //clicar no mapa
    this.map.on('click', (event) => {
      if (this.Nat_state_coleta_coordenada) {
        /**
         * se a funcao de copia de coordenada manual estiver ativa chama a funcao que copia para a 
         * area de transferencia a coordenada
         */

        const latlng = event.latlng;
        const coordenada_coletada = latlng


        this.Nat_copy_value(`${coordenada_coletada.lat},${coordenada_coletada.lng}`)
      }
    })



    // Remover o controle de zoom
    //this.map.zoomControl.setPosition('bottomright');


    // Inicializando o controle de desenho com edição habilitada
    this.Nat_draw_control = new L.Control.Draw({
      position: 'bottomleft',

      draw: {
        polyline: false, // Desativando linha poligonal
        polygon: {
          shapeOptions: {
            color: 'blue', // Cor do polígono
            weight: 5, // Espessura da borda
            opacity: 0.5, // Opacidade da borda
            fillOpacity: 0.2, // Opacidade de preenchimento
            fillColor: 'blue', // Cor de preenchimento
          },
        },
        rectangle: false, // Desativando a criação de retângulos
        circle: false, // Desativando a criação de círculos
        marker: false, // Desativando a criação de marcadores
        circlemarker: false
      },
    });





    this.map.on(L.Draw.Event.CREATED, (e: any) => {
      //limpando os dados de poligono selecionado
      this.Nat_detalhes_poligono = undefined

      const type = e.layerType,
        layer = e.layer;

      if (type === 'polygon') {
        // Verifica se já existe algum polígono no array e o remove
        if (this.Nat_polygons_transferencia.length > 0) {
          // Remover o polígono antigo do mapa
          const oldLayer = this.Nat_polygons_transferencia.pop(); // Remove o último polígono
          this.map.removeLayer(oldLayer); // Remove do mapa

          this.Nat_polygons_transferencia = []
        }

        // Habilitando a edição do polígono
        this.map.addLayer(layer);
        layer.enableEdit();
        this.Nat_polygons_transferencia.push(layer);



        // Evento de clique no polígono
        layer.on('click', async () => {


          // Encontrar o índice do polígono no array
          const index = this.Nat_polygons_transferencia.indexOf(layer);

          let ids_instalacoes_mapa: number[] = this.Nat_operacoes_mapa_edicoes
            .map(operacao => operacao.ITENS.map(item => item.ID)) // Mapeia os IDs de cada item
            .flat(); // Achata o array de arrays em um único array de números



          // Obtendo as coordenadas do polígono
          const latLngs = layer.getLatLngs(); // Isso retorna as coordenadas dos vértices do polígono

          // Transformando as coordenadas em um formato desejado (ex: [lat, lng])
          const coordenadas_poligono: COORDENADA[] = latLngs[0].map((latLng: L.LatLng) => ({
            lat: latLng.lat,
            lng: latLng.lng
          }));

          const requisicao_server: any = await this.service_main.POST_MARKER_IN_POLIGONO(coordenadas_poligono, ids_instalacoes_mapa,'GPS');
          const resposta: RESPOSTA_PADRAO_SERVIDOR = requisicao_server;

          if (resposta.MSG == 'SUCESSO') {


            let detalhes_poligono: DETALHES_POLIGONO = {
              OPERACAO_ID: undefined,
              CENTRO_LEITURA: undefined,
              GRUPO: undefined,
              LIVRO: undefined,
              DURACAO: undefined,
              TOTAL_ITENS: resposta.RESPOSTA.length,
              OPERACAO_ID_DEST: undefined,
              LIVRO_DEST: undefined,
              TIPO: 'SELECAO',
              MODULO: 'GPX',
              ITENS_ID: resposta.RESPOSTA,
              ID_INICIO: 0,
              DISTANCIA: undefined
            }

            this.Nat_detalhes_poligono = detalhes_poligono

            this.Nat_state_detalhes_poligono = true


          } else {
            this.Nat_state_loading = false
            let new_alert: DADOS_ALERT = {
              TITULO: 'ERRO',
              CORPO: resposta.ADICIONAL
            };
            this.Nat_dados_alert = new_alert;
            this.Nat_status_alert_erro = true;
          }
        });

        // Evento de edição do polígono (quando o vértice for movido)
        layer.on('editable:vertex:dragend', async () => {

          // Encontrar o índice do polígono no array
          const index = this.Nat_polygons_transferencia.indexOf(layer);

          let ids_instalacoes_mapa: number[] = this.Nat_operacoes_mapa_edicoes
            .map(operacao => operacao.ITENS.map(item => item.ID)) // Mapeia os IDs de cada item
            .flat(); // Achata o array de arrays em um único array de números



          // Obtendo as coordenadas do polígono
          const latLngs = layer.getLatLngs(); // Isso retorna as coordenadas dos vértices do polígono

          // Transformando as coordenadas em um formato desejado (ex: [lat, lng])
          const coordenadas_poligono: COORDENADA[] = latLngs[0].map((latLng: L.LatLng) => ({
            lat: latLng.lat,
            lng: latLng.lng
          }));

          const requisicao_server: any = await this.service_main.POST_MARKER_IN_POLIGONO(coordenadas_poligono, ids_instalacoes_mapa,'GPS');
          const resposta: RESPOSTA_PADRAO_SERVIDOR = requisicao_server;

          if (resposta.MSG == 'SUCESSO') {


            let detalhes_poligono: DETALHES_POLIGONO = {
              OPERACAO_ID: undefined,
              CENTRO_LEITURA: undefined,
              GRUPO: undefined,
              LIVRO: undefined,
              DURACAO: undefined,
              TOTAL_ITENS: resposta.RESPOSTA.length,
              OPERACAO_ID_DEST: undefined,
              LIVRO_DEST: undefined,
              TIPO: 'SELECAO',
              MODULO: 'GPX',
              ITENS_ID: resposta.RESPOSTA,
              ID_INICIO: 0,
              DISTANCIA: undefined
            }

            this.Nat_detalhes_poligono = detalhes_poligono

            this.Nat_state_detalhes_poligono = true


          } else {
            this.Nat_state_loading = false
            let new_alert: DADOS_ALERT = {
              TITULO: 'ERRO',
              CORPO: resposta.ADICIONAL
            };
            this.Nat_dados_alert = new_alert;
            this.Nat_status_alert_erro = true;
          }
        });
      }
    });
    this.map.on(L.Draw.Event.DRAWSTART, (e: any) => {

      //limpando os dados de poligono selecionado
      this.Nat_detalhes_poligono = undefined

      if (this.Nat_polygons_transferencia.length > 0) {
        // Remover o polígono antigo do mapa
        const oldLayer = this.Nat_polygons_transferencia.pop(); // Remove o último polígono
        this.map.removeLayer(oldLayer); // Remove do mapa
      }

    })


    // Definindo as camadas de mapa
    this.Nat_normalMap = this.leaflet.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    });

    this.Nat_satelliteMap = this.leaflet.tileLayer('https://services.arcgisonline.com/arcgis/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', {
      attribution: 'Tiles © Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
    });

    this.Nat_simplifiedMap = this.leaflet.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}', {
      attribution: 'Tiles &copy; Esri &mdash; Esri, DeLorme, NAVTEQ',
    });

    // Adicionando o mapa normal inicialmente
    this.Nat_normalMap.addTo(this.map);

    // Inicializando a camada ativa
    this.Nat_activeLayer = this.Nat_normalMap;
     
    } else {
      this.map = this.leaflet.map('map_gpx', {
        center: [-23.5505, -46.6333],  // Coordenadas do centro inicial do mapa
        zoom: 5,  // Nível de zoom inicial
        maxZoom: 18,  // Definindo o zoom máximo
        minZoom: 3,  // Definindo o zoom mínimo
        editable: true, // Habilita a edição no mapa
  
      });

          //clicar no mapa
    this.map.on('click', (event) => {
      if (this.Nat_state_coleta_coordenada) {
        /**
         * se a funcao de copia de coordenada manual estiver ativa chama a funcao que copia para a 
         * area de transferencia a coordenada
         */

        const latlng = event.latlng;
        const coordenada_coletada = latlng


        this.Nat_copy_value(`${coordenada_coletada.lat},${coordenada_coletada.lng}`)
      }
    })



    // Remover o controle de zoom
    //this.map.zoomControl.setPosition('bottomright');


    // Inicializando o controle de desenho com edição habilitada
    this.Nat_draw_control = new L.Control.Draw({
      position: 'bottomleft',

      draw: {
        polyline: false, // Desativando linha poligonal
        polygon: {
          shapeOptions: {
            color: 'blue', // Cor do polígono
            weight: 5, // Espessura da borda
            opacity: 0.5, // Opacidade da borda
            fillOpacity: 0.2, // Opacidade de preenchimento
            fillColor: 'blue', // Cor de preenchimento
          },
        },
        rectangle: false, // Desativando a criação de retângulos
        circle: false, // Desativando a criação de círculos
        marker: false, // Desativando a criação de marcadores
        circlemarker: false
      },
    });





    this.map.on(L.Draw.Event.CREATED, (e: any) => {
      //limpando os dados de poligono selecionado
      this.Nat_detalhes_poligono = undefined

      const type = e.layerType,
        layer = e.layer;

      if (type === 'polygon') {
        // Verifica se já existe algum polígono no array e o remove
        if (this.Nat_polygons_transferencia.length > 0) {
          // Remover o polígono antigo do mapa
          const oldLayer = this.Nat_polygons_transferencia.pop(); // Remove o último polígono
          this.map.removeLayer(oldLayer); // Remove do mapa

          this.Nat_polygons_transferencia = []
        }

        // Habilitando a edição do polígono
        this.map.addLayer(layer);
        layer.enableEdit();
        this.Nat_polygons_transferencia.push(layer);



        // Evento de clique no polígono
        layer.on('click', async () => {


          // Encontrar o índice do polígono no array
          const index = this.Nat_polygons_transferencia.indexOf(layer);

          let ids_instalacoes_mapa: number[] = this.Nat_operacoes_mapa_edicoes
            .map(operacao => operacao.ITENS.map(item => item.ID)) // Mapeia os IDs de cada item
            .flat(); // Achata o array de arrays em um único array de números



          // Obtendo as coordenadas do polígono
          const latLngs = layer.getLatLngs(); // Isso retorna as coordenadas dos vértices do polígono

          // Transformando as coordenadas em um formato desejado (ex: [lat, lng])
          const coordenadas_poligono: COORDENADA[] = latLngs[0].map((latLng: L.LatLng) => ({
            lat: latLng.lat,
            lng: latLng.lng
          }));

          const requisicao_server: any = await this.service_main.POST_MARKER_IN_POLIGONO(coordenadas_poligono, ids_instalacoes_mapa,'GPS');
          const resposta: RESPOSTA_PADRAO_SERVIDOR = requisicao_server;

          if (resposta.MSG == 'SUCESSO') {


            let detalhes_poligono: DETALHES_POLIGONO = {
              OPERACAO_ID: undefined,
              CENTRO_LEITURA: undefined,
              GRUPO: undefined,
              LIVRO: undefined,
              DURACAO: undefined,
              TOTAL_ITENS: resposta.RESPOSTA.length,
              OPERACAO_ID_DEST: undefined,
              LIVRO_DEST: undefined,
              TIPO: 'SELECAO',
              MODULO: 'GPX',
              ITENS_ID: resposta.RESPOSTA,
              ID_INICIO: 0,
              DISTANCIA: undefined
            }

            this.Nat_detalhes_poligono = detalhes_poligono

            this.Nat_state_detalhes_poligono = true


          } else {
            this.Nat_state_loading = false
            let new_alert: DADOS_ALERT = {
              TITULO: 'ERRO',
              CORPO: resposta.ADICIONAL
            };
            this.Nat_dados_alert = new_alert;
            this.Nat_status_alert_erro = true;
          }
        });

        // Evento de edição do polígono (quando o vértice for movido)
        layer.on('editable:vertex:dragend', async () => {

          // Encontrar o índice do polígono no array
          const index = this.Nat_polygons_transferencia.indexOf(layer);

          let ids_instalacoes_mapa: number[] = this.Nat_operacoes_mapa_edicoes
            .map(operacao => operacao.ITENS.map(item => item.ID)) // Mapeia os IDs de cada item
            .flat(); // Achata o array de arrays em um único array de números



          // Obtendo as coordenadas do polígono
          const latLngs = layer.getLatLngs(); // Isso retorna as coordenadas dos vértices do polígono

          // Transformando as coordenadas em um formato desejado (ex: [lat, lng])
          const coordenadas_poligono: COORDENADA[] = latLngs[0].map((latLng: L.LatLng) => ({
            lat: latLng.lat,
            lng: latLng.lng
          }));

          const requisicao_server: any = await this.service_main.POST_MARKER_IN_POLIGONO(coordenadas_poligono, ids_instalacoes_mapa,'GPS');
          const resposta: RESPOSTA_PADRAO_SERVIDOR = requisicao_server;

          if (resposta.MSG == 'SUCESSO') {


            let detalhes_poligono: DETALHES_POLIGONO = {
              OPERACAO_ID: undefined,
              CENTRO_LEITURA: undefined,
              GRUPO: undefined,
              LIVRO: undefined,
              DURACAO: undefined,
              TOTAL_ITENS: resposta.RESPOSTA.length,
              OPERACAO_ID_DEST: undefined,
              LIVRO_DEST: undefined,
              TIPO: 'SELECAO',
              MODULO: 'GPX',
              ITENS_ID: resposta.RESPOSTA,
              ID_INICIO: 0,
              DISTANCIA: undefined
            }

            this.Nat_detalhes_poligono = detalhes_poligono

            this.Nat_state_detalhes_poligono = true


          } else {
            this.Nat_state_loading = false
            let new_alert: DADOS_ALERT = {
              TITULO: 'ERRO',
              CORPO: resposta.ADICIONAL
            };
            this.Nat_dados_alert = new_alert;
            this.Nat_status_alert_erro = true;
          }
        });
      }
    });
    this.map.on(L.Draw.Event.DRAWSTART, (e: any) => {

      //limpando os dados de poligono selecionado
      this.Nat_detalhes_poligono = undefined

      if (this.Nat_polygons_transferencia.length > 0) {
        // Remover o polígono antigo do mapa
        const oldLayer = this.Nat_polygons_transferencia.pop(); // Remove o último polígono
        this.map.removeLayer(oldLayer); // Remove do mapa
      }

    })


    // Definindo as camadas de mapa
    this.Nat_normalMap = this.leaflet.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    });

    this.Nat_satelliteMap = this.leaflet.tileLayer('https://services.arcgisonline.com/arcgis/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', {
      attribution: 'Tiles © Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
    });

    this.Nat_simplifiedMap = this.leaflet.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}', {
      attribution: 'Tiles &copy; Esri &mdash; Esri, DeLorme, NAVTEQ',
    });

    // Adicionando o mapa normal inicialmente
    this.Nat_normalMap.addTo(this.map);

    // Inicializando a camada ativa
    this.Nat_activeLayer = this.Nat_normalMap;
     
    }


    this.Out_change_map_type('')
    this.Out_change_map_type('')
    this.Out_change_map_type('')
  
    let indexes = [];
    for (let od = 0; od < this.Nat_operacoes_mapa_edicoes.length; od++) {
      // Abrir cada operação novamente
      //this.Out_open_operacao_edicoes(this.Nat_operacoes_mapa_edicoes[od]);
      indexes.push(od);
    }
  
    for (let od = 0; od < this.Nat_operacoes_mapa_edicoes.length; od++) {
      // Fechar operações, se necessário
      if (indexes.includes(od)) {
        this.Out_close_operacao_edicoes(this.Nat_operacoes_mapa_edicoes[od]);
      }
    }
  
    this.Nat_state_loading = false;
  }
  
  
  





  



  Nat_operacoes_mapa_edicoes: OPERACAO_ou_LIVRO[] = []
  Nat_ids_operacoes_mapa_edicoes: number[] = []

  Nat_markers_prioridade:L.Marker[] = []
  Nat_state_markers_prioridades:boolean = true

  Nat_change_state_prioridades(){
   

    if(this.Nat_markers_prioridade.length>0){
      this.Nat_state_markers_prioridades = !this.Nat_state_markers_prioridades

      this.Nat_markers_prioridade.forEach((marker,m)=>{

        if(this.Nat_state_markers_prioridades == true){//se for pra mostrar
          this.Nat_markers_prioridade[m].addTo(this.map)
        }else{
          this.Nat_markers_prioridade[m].remove()
        }
        
      })
    }else{
      this.Nat_state_loading = false
      let new_alert: DADOS_ALERT = {
        TITULO: 'ERRO',
        CORPO: 'Nenhum Item Prioritário no Momento!'
      };
      this.Nat_dados_alert = new_alert;
      this.Nat_status_alert_erro = true;
    }

  }
  /**
   * recebe a operacao que deve ser lançada no mapa, busca suas instalacoes, e as lança no mapa
   * elas são agrupadas em clusters para melhorar o desempenho e usabilidade, e são exibidos apennas os
   * pontos que estao dentro da vizualização atual
   */


  view_vizinhos(logradouro: VALIDA_LOGRADOURO) {

    let movedMap: boolean = false;
    this.Nat_operacoes_mapa_edicoes.forEach((operacao, o) => {
      operacao.ITENS.forEach((item, m) => {
        if (item.LOGRADOURO.trim().toLowerCase() == logradouro.LOGRADOURO.trim().toLowerCase()) {
          // Verificar se o marcador já está no mapa
          const marker = this.Nat_operacoes_mapa_edicoes[o].MARKERS[m];
          
          if (!this.map.hasLayer(marker)) {
            // Se o marcador não estiver no mapa, adiciona-o
            marker.addTo(this.map);
          }
  
          if (!movedMap) {
            // Apenas mover o mapa na primeira vez
            this.map.setView([item.LAT, item.LNG], 20);
            movedMap = true;  // Marca que o mapa foi movido
          }
        } else {
          // Remover o marcador que não corresponde
          const marker = this.Nat_operacoes_mapa_edicoes[o].MARKERS[m];
          if (this.map.hasLayer(marker)) {
            marker.remove();
          }
        }
      });
    });
  }
  
  async validate_logradouro(logradouro:VALIDA_LOGRADOURO){
    const requisicao_server: any = await this.service.POST_VALIDAR_LOGRADOURO(logradouro.ID,environment.user_atual);
    const resposta: RESPOSTA_PADRAO_SERVIDOR = requisicao_server;

   

    if (resposta.MSG === 'SUCESSO') {
      this.logradouros_localizados.forEach((logradouro_,l)=>{
        if(logradouro.ID == logradouro_.ID){
          this.logradouros_localizados[l].VALIDADO = true
        }
        
      })


      this.Nat_state_loading = false
      let new_alert: DADOS_ALERT = {
        TITULO: 'SUCESSO',
        CORPO: resposta.ADICIONAL
      };
      this.Nat_dados_alert = new_alert;
      this.Nat_status_alert_sucesso = true;

      }else{
        this.Nat_state_loading = false
      let new_alert: DADOS_ALERT = {
        TITULO: 'ERRO',
        CORPO: resposta.ADICIONAL
      };
      this.Nat_dados_alert = new_alert;
      this.Nat_status_alert_erro = true;
      }

    
  }
  async Out_open_operacao_edicoes(operacao: OPERACAO_ou_LIVRO) {
    if (this.Nat_ids_operacoes_mapa_disponiveis.length > 0) {
      this.Nat_state_loading = false
      let new_alert: DADOS_ALERT = {
        TITULO: 'ERRO',
        CORPO: 'Não é Possível Analizar Itens Disponíveis e Edições ao mesmo Tempo!'
      };
      this.Nat_dados_alert = new_alert;
      this.Nat_status_alert_erro = true;
    }else if(operacao.QUANT_ITENS==0){
      this.Nat_state_loading = false
      let new_alert: DADOS_ALERT = {
        TITULO: 'ERRO',
        CORPO: 'Não é Possível abrir Operações sem Itens!'
      };
      this.Nat_dados_alert = new_alert;
      this.Nat_status_alert_erro = true;
    } else {
      this.Nat_state_loading = true

      const requisicao_server: any = await this.service.POST_ITENS_OPERACAO_GPX(operacao.ID);
      const resposta: RESPOSTA_PADRAO_SERVIDOR = requisicao_server;

     

      if (resposta.MSG === 'SUCESSO') {


        const requisicao_server_1: any = await this.service.POST_RETORNA_LISTA_LOGRADOUROS_OP(operacao.ID);
        const resposta_1: RESPOSTA_PADRAO_SERVIDOR = requisicao_server_1;
  
       
  
        if (resposta_1.MSG === 'SUCESSO') {



          this.logradouros_localizados = resposta_1.RESPOSTA;

          }else{
            this.Nat_state_loading = false
          let new_alert: DADOS_ALERT = {
            TITULO: 'ERRO',
            CORPO: resposta.ADICIONAL
          };
          this.Nat_dados_alert = new_alert;
          this.Nat_status_alert_erro = true;
          }
  
    




        const instalacoes: INSTALACAO_BD[] = resposta.RESPOSTA;
        let new_operacao_map: OPERACAO_ou_LIVRO = operacao;

        // Criando o grupo de marcadores com o plugin MarkerCluster
        const cluster = this.leaflet.markerClusterGroup();
        const markers: L.Marker[] = []; // Para armazenar os marcadores individuais
        const cor: string = this.Nat_gerarCorUnica()

        let coordenadas_poligono: COORDENADA[] = []
        let coordenadas_hull: number[][] = [];


        instalacoes.forEach((instalacao: any) => {
          const customIcon = L.icon({
            iconUrl: 'https://unpkg.com/leaflet@1.9.4/dist/images/marker-icon.png', // Ícone padrão do Leaflet
            iconSize: [25, 41], 
            iconAnchor: [12, 41],
            popupAnchor: [1, -34],
            shadowUrl: 'https://unpkg.com/leaflet@1.9.4/dist/images/marker-shadow.png', // Sombra do ícone
            shadowSize: [41, 41]
          });
          
          const marcador = L.marker([instalacao.LAT, instalacao.LNG], {
            draggable: true,
            icon: customIcon
          }).addTo(this.map);


 
          
          marcador.on('dragend', async (event) => {
            const { lat, lng } = event.target.getLatLng();
          
            try {
              const requisicao_server: any = await this.service.POST_MUDAR_COORDENADA(lat, lng, [instalacao.ID]);
              const resposta: RESPOSTA_PADRAO_SERVIDOR = requisicao_server;
          
              if (resposta.MSG === 'SUCESSO') {
                // let new_alert: DADOS_ALERT = {
                //   TITULO: resposta.MSG,
                //   CORPO: resposta.ADICIONAL
                // };
                // this.Nat_dados_alert = new_alert;
                // this.Nat_status_alert_sucesso = true;
              } else {
                this.Nat_state_loading = false;
                let new_alert: DADOS_ALERT = {
                  TITULO: 'ERRO',
                  CORPO: resposta.ADICIONAL
                };
                this.Nat_dados_alert = new_alert;
                this.Nat_status_alert_erro = true;
              }
            } catch (error) {
              console.error('Erro ao mudar coordenada:', error);
            }
          });
          
        
          coordenadas_hull.push([instalacao.LNG, instalacao.LAT]);

          const popupContent = `
          <div>Instalação: ${instalacao.INSTALACAO}</div>
          <div>Endereço: ${instalacao.TIPO_LOGRADOURO} ${instalacao.LOGRADOURO} ${instalacao.NUMERO} ${instalacao.BAIRRO} ${instalacao.CIDADE}</div>
          <div>OPERAÇÃO: ${instalacao.OPERACAO_ID}</div>
          <div>CENTRO DE LEITURA: ${instalacao.CENTRO_LEITURA}</div>
          <div>GRUPO: ${instalacao.GRUPO}</div>
          <div>HISTÓRICO: ${instalacao.COORDS_HISTORY}</div>
        `;
      
        // Associa o popup ao marcador
        marcador.bindPopup(popupContent);
      
        // Evento de clique para abrir o popup
        marcador.on('click', () => {
          marcador.openPopup();
        });
        
          // marcador.bindTooltip(
          //   `
          //   <div>Instalação: ${instalacao.INSTALACAO}</div>
          //   <div>Endereço: ${instalacao.TIPO_LOGRADOURO} ${instalacao.LOGRADOURO} ${instalacao.NUMERO} ${instalacao.BAIRRO} ${instalacao.CIDADE}</div>
          //   <div>OPERAÇÃO: ${instalacao.OPERACAO_ID}</div>
          //   <div>CENTRO DE LEITURA: ${instalacao.CENTRO_LEITURA}</div>
          //   <div>GRUPO: ${instalacao.GRUPO}</div>
          //   <div>HISTÓRICO: ${instalacao.COORDS_HISTORY}</div>`,
          //   {
          //     permanent: false,
          //     direction: 'top',
          //     offset: [0, -10],
          //     className: 'custom-tooltip' // Classe CSS personalizada
          //   }
          // );
        
          new_operacao_map.ITENS.push(instalacao);
          new_operacao_map.MARKERS = markers;
        
          //cluster.addLayer(marcador);
          markers.push(marcador);
        });
        


      


        const resultadoHull = hull(coordenadas_hull, 100);


        resultadoHull.forEach((arr_coord) => {
          coordenadas_poligono.push({
            lat: arr_coord[1],
            lng: arr_coord[0]
          })
        });


        const poligono_operacao = L.polygon(coordenadas_poligono, {
          fillColor: cor,
          color: cor,
          weight: 3,
          opacity: 1,
          fillOpacity: 0.1
        }).addTo(this.map);
        //poligono_operacao.enableEdit();

        // Adiciona uma tooltip ao passar o mouse sobre o marcador, com cada informação em uma linha separada
        poligono_operacao.bindTooltip(
          `
              <div>OPERACAO: ${operacao.ID}</div>
              <div>CENTRO DE LEITURA: ${operacao.CENTRO_LEITURA_STR}</div>
              <div>GRUPO: ${operacao.GRUPO_STR}</div>
              <div>ITENS: ${operacao.ITENS.length}</div>
             `,
          {
            permanent: false,
            direction: 'top',
            offset: [0, -10],
            className: 'custom-tooltip' // Classe CSS personalizada
          }
        );

        // // Evento de início da edição do polígono
        // poligono_operacao.on('editable:vertex:dragend', async (event) => {

        //   alert('mudou o poligono')
        //   const newCoordinates = event.target.getLatLngs();
        //   // Atualize o array com as novas coordenadas se necessário

        //   // newCoordinates[0].forEach((latlng: any) => {
        //   //   coordenadas_polygon.push({ lat: latlng.lat, lng: latlng.lng });


        //   // });

        // })

        new_operacao_map.POLYGON = poligono_operacao




        new_operacao_map.CLUSTERS.push(cluster);
        // this.map.addLayer(cluster);

        // const updateMarkerDisplay = () => {
        //   if (this.Nat_ids_operacoes_mapa_edicoes.includes(operacao.ID)) {
        //     const zoomLevel = this.map.getZoom();
        //     const mapBounds = this.map.getBounds();

        //     if (zoomLevel >= 16) {
        //       // Remove o cluster e exibe apenas os marcadores dentro dos limites visíveis
        //       this.map.removeLayer(cluster);
        //       markers.forEach(marker => {
        //         if (mapBounds.contains(marker.getLatLng())) {







        //           this.map.addLayer(marker);
        //         } else {
        //         this.map.removeLayer(marker);
        //         }
        //       });
        //     } else {
        //       // Remove os marcadores individuais e exibe o cluster
        //       markers.forEach(marker => {
        //        this.map.removeLayer(marker);
        //       });
        //      this.map.addLayer(cluster);
        //     }
        //   } else {
        //     // Remover todos os marcadores e clusters da visualização
        //     markers.forEach(marker => {
        //       this.map.removeLayer(marker);
        //     });
        //     this.map.removeLayer(cluster);


        //   }
        // };

        // this.map.on('zoomend moveend', updateMarkerDisplay);
        // updateMarkerDisplay();

        this.map.setView([instalacoes[0].LAT, instalacoes[0].LNG], 15);
        this.Nat_operacoes_mapa_edicoes.push(new_operacao_map);
        this.Nat_ids_operacoes_mapa_edicoes.push(operacao.ID);


        this.Nat_state_loading = false
      } else {

        this.Nat_state_loading = false
        let new_alert: DADOS_ALERT = {
          TITULO: 'ERRO',
          CORPO: resposta.ADICIONAL
        };
        this.Nat_dados_alert = new_alert;
        this.Nat_status_alert_erro = true;
      }
    }
  }


  /**
   *retira do mapa tanto markers como clusters de uma operacao selecionada
   */
  Out_close_operacao_edicoes(operacao: OPERACAO_ou_LIVRO) {
    this.Nat_state_loading = true
    // Encontrar o índice da operação específica no array de operações
    const index = this.Nat_operacoes_mapa_edicoes.findIndex(
      (operacao_local) => operacao_local.ID == operacao.ID
    );

    if (index !== -1) {
      // Remover todos os clusters associados a esta operação
      this.Nat_operacoes_mapa_edicoes[index].CLUSTERS.forEach(cluster => {
        this.map.removeLayer(cluster);
      });

      // Remover todos os marcadores individuais associados a esta operação
      this.Nat_operacoes_mapa_edicoes[index].MARKERS.forEach(marker => {
        this.map.removeLayer(marker);
      });

      this.Nat_operacoes_mapa_edicoes[index].POLYGON.remove()
    }

    // Remover a operação da lista de operações disponíveis
    this.Nat_operacoes_mapa_edicoes = this.Nat_operacoes_mapa_edicoes.filter(
      (operacao_local) => operacao_local.ID != operacao.ID
    );
    this.Nat_ids_operacoes_mapa_edicoes = this.Nat_ids_operacoes_mapa_edicoes.filter(
      (id) => id != operacao.ID
    );


    this.Nat_markers_prioridade.forEach((marker,m) => {
      this.Nat_markers_prioridade[m].remove()
    });
    this.Nat_markers_prioridade = []
    this.Nat_state_loading = false
  }


  // /**evento que abre a aba de loading a partir de outros components */
  Out_open_loading(variavel_inutil: boolean) {
    this.Nat_state_loading = true
  }

  // /**evento que fecha a aba de loading a partir de outros components */
  Out_close_loading(variavel_inutil: boolean) {
    this.Nat_state_loading = false
  }

  /**abre o alerta da mensagem de sucesso */
  Out_open_alert_sucess(obg_alert: DADOS_ALERT) {
    this.Nat_state_loading = false
    this.Nat_dados_alert = obg_alert;
    this.Nat_status_alert_sucesso = true;
  }

  /**abre o alerta da mensagem de erro */
  Out_open_alert_erro(obg_alert: DADOS_ALERT) {
    this.Nat_state_loading = false
    this.Nat_dados_alert = obg_alert;
    this.Nat_status_alert_erro = true;
  }



  Nat_copy_value(value: any) {
    const valueCopy: string = `${value}`;

    // Cria um elemento de input temporário
    const tempInput = document.createElement('input');
    tempInput.value = valueCopy;
    document.body.appendChild(tempInput); // Adiciona o elemento ao DOM

    // Seleciona o texto do input temporário
    tempInput.select();
    tempInput.setSelectionRange(0, 99999); // Para dispositivos móveis

    try {
      // Copia o texto selecionado para a área de transferência
      document.execCommand('copy');



      let new_alert: DADOS_ALERT = {
        TITULO: 'Copiado!',
        CORPO: 'Valor Copiado com Sucesso para a Área de Transferência'
      }
      this.Nat_dados_alert = new_alert
      this.Nat_status_alert_sucesso = true




    } catch (err) {


      let new_alert: DADOS_ALERT = {
        TITULO: 'ERRO',
        CORPO: 'Valor não pôde se Copiado para a Área de Transferência'
      }
      this.Nat_dados_alert = new_alert
      this.Nat_status_alert_erro = true




    } finally {
      // Remove o elemento temporário do DOM
      document.body.removeChild(tempInput);
    }
  }


  /**
   * geracao e administração de cores para markers do mapa
   */
  Nat_coresGeradas: string[] = [];
  Nat_gerarCorHex(): string {
    const randomColor = Math.floor(Math.random() * 0xffffff);
    return `#${randomColor.toString(16).padStart(6, '0')}`;
  }

  Nat_gerarCorUnica(): string {
    let cor = this.Nat_gerarCorHex();

    // Tenta gerar uma nova cor se a cor atual já existe no array
    while (this.Nat_coresGeradas.includes(cor)) {
      cor = this.Nat_gerarCorHex();
    }

    // Armazena a cor única
    this.Nat_coresGeradas.push(cor);
    return cor;
  }



}
