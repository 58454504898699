<div class="px-4 z-30 absolute w-full bg-white top-16 py-4">
    <div class="sm:flex sm:items-center mt-2">
  
  
    </div>
    <!-- Search input -->
    <div class="mt-2">
      <input matInput (keyup)="applyFilter($event)" #input type="text" placeholder="Buscar Notas..."
        class="w-96 rounded-md border-gray-300 px-4 py-2 text-sm focus:border-blue-600 focus:ring-blue-600"
        id="search-input">
    </div>
    <div class="mt-2 flow-root">
      <div class=" -my-2 overflow-x-auto ">
        <div class="inline-block min-w-full py-2 align-middle">
          <div class="relative pb-4">
  
  
            <table mat-table [dataSource]="dataSource" matSort class="min-w-full table-fixed divide-y divide-gray-300">
  
         
  <!-- ID Column -->
  <ng-container matColumnDef="VISIVEL">
  
  
    <th mat-header-cell *matHeaderCellDef class="px-7 sm:w-12 sm:px-6">
      <div class="flex justify-center items-center h-full">
        @if(input_concluidas_mapa.length>0){
          <input #checkbox_all checked (change)="Nat_change_view()" type="checkbox"
          class="size-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600">
        }@else{
          <input #checkbox_all (change)="Nat_change_view()" type="checkbox"
          class="size-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600">
        }
        
      </div>
    </th>
  
  
    <td mat-cell *matCellDef="let row" class="px-7 sm:w-12 sm:px-6">
      <div class="flex justify-center items-center h-full">
        @if(row.VISIVEL){
          <input type="checkbox" checked (change)="Nat_remove_nota(row.ID)"
          class="size-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600">
        }@else{
          <input (change)="Nat_view_nota(row.ID)" type="checkbox"
          class="size-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600">
        }
       
      </div>
    </td>
  </ng-container>
  
  
              <!-- Progress Column -->
              <ng-container matColumnDef="ID_STR">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
                <td mat-cell *matCellDef="let row"> {{row.ID_STR}} </td>
              </ng-container>
  
              <!-- Name Column -->
              <ng-container matColumnDef="INSTALACAO">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> INSTALACAO </th>
                <td mat-cell *matCellDef="let row"> {{row.INSTALACAO}} </td>
              </ng-container>
  
              <!-- Name Column -->
              <ng-container matColumnDef="ENDERECO">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> ENDEREÇO </th>
                <td mat-cell *matCellDef="let row"> {{row.ENDERECO}} </td>
              </ng-container>
              <!-- Name Column -->
              <ng-container matColumnDef="NUM_MEDIDA">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> MEDIDA </th>
                <td mat-cell *matCellDef="let row"> {{row.NUM_MEDIDA}} </td>
              </ng-container>
              <!-- Name Column -->
              <ng-container matColumnDef="COD_NOTA">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> COD. NOTA </th>
                <td mat-cell *matCellDef="let row"> {{row.COD_NOTA}} </td>
              </ng-container>
              <!-- Name Column -->
              <ng-container matColumnDef="LIVRO">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> LIVRO </th>
                <td mat-cell *matCellDef="let row"> {{row.LIVRO}} </td>
              </ng-container>
              <!-- Name Column -->
              <ng-container matColumnDef="DATA_GERACAO_STR">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> GERADO EM </th>
                <td mat-cell *matCellDef="let row"> {{row.DATA_GERACAO_STR}} </td>
              </ng-container>
              <!-- Name Column -->
              <ng-container matColumnDef="DATA_CONCLUSAO">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> CONCLUIDO EM </th>
                <td mat-cell *matCellDef="let row"> {{row.DATA_CONCLUSAO}} </td>
              </ng-container>
              <!-- Name Column -->
              <ng-container matColumnDef="DATA_MANUAL_CONC_STR">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> PREVISTO PARA </th>
                <td mat-cell *matCellDef="let row"> {{row.DATA_MANUAL_CONC_STR}} </td>
              </ng-container>
              <!-- Name Column -->
              <ng-container matColumnDef="EQUIPE_NAME_STR">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> EQUIPE </th>
                <td mat-cell *matCellDef="let row"> {{row.EQUIPE_NAME_STR}} </td>
              </ng-container>
              <!-- Name Column -->
              <ng-container matColumnDef="SEQUENCIA">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> SEQUÊNCIA </th>
                <td mat-cell *matCellDef="let row"> {{row.SEQUENCIA}} </td>
              </ng-container>
              <!-- Name Column -->
              <ng-container matColumnDef="EQUIPE_ID_STR">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> EQUIPE </th>
                <td mat-cell *matCellDef="let row"> {{row.EQUIPE_ID_STR}} </td>
              </ng-container>
              <!-- Name Column -->
              <ng-container matColumnDef="IMP_ID_STR">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> IMPORTAÇÃO </th>
                <td mat-cell *matCellDef="let row"> {{row.IMP_ID_STR}} </td>
              </ng-container>
              <!-- Name Column -->
              <ng-container matColumnDef="TIPO">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> TIPO </th>
                <td mat-cell *matCellDef="let row"> {{row.TIPO}} </td>
              </ng-container>
              <!-- Name Column -->
              <ng-container matColumnDef="DESC_SERVICO">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> SERVIÇO </th>
                <td mat-cell *matCellDef="let row"> {{row.DESC_SERVICO}} </td>
              </ng-container>
              <!-- Name Column -->
              <ng-container matColumnDef="CIDADE">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> CIDADE </th>
                <td mat-cell *matCellDef="let row"> {{row.CIDADE}} </td>
              </ng-container>
              <!-- Name Column -->
              <ng-container matColumnDef="COORDENADA_MINERADA">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> MINERADA </th>
                <td mat-cell *matCellDef="let row">
                  @if(row.COORDENADA_MINERADA){
                    SIM
                  }@else {
                    NÃO
                  }
                   
                  </td>
              </ng-container>
              <!-- Fruit Column -->
              <ng-container matColumnDef="ACOES">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
  
                </th>
                <td mat-cell *matCellDef="let row">
                  <div class="w-full space-x-2">
                    <a (click)="Nat_change_view_concluir_nota(row)" class="rounded-md
                          bg-blue-50 px-2 py-1 
                          text-xs font-medium text-blue-700 ring-1 
                          ring-inset ring-blue-600/20 cursor-pointer
                          hover:bg-blue-100
                          ">Visualizar</a>
  
                    <a (click)="Nat_delete_nota(row.ID)" class="rounded-md
                          bg-red-50 px-2 py-1 
                          text-xs font-medium text-red-700 ring-1 
                          ring-inset ring-red-600/20 cursor-pointer
                          hover:bg-red-100
                          ">Deletar</a>
                  </div>
  
                </td>
              </ng-container>
  
              <tr mat-header-row *matHeaderRowDef="displayedColumns"
                class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 hover:bg-slate-100"></tr>
  
              <!-- Row shown when there is no matching data. -->
              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
              </tr>
            </table>
  
            <mat-paginator [pageSizeOptions]="[5]" aria-label="Select page of users"></mat-paginator>
  
  
          </div>
        </div>
      </div>
    </div>
  </div>




@if(Nat_view_concluir_nota == true){
    <div class="relative z-50" aria-labelledby="modal-title" role="dialog" aria-modal="true">

        <div class="fixed inset-0 bg-gray-500/75 transition-opacity" aria-hidden="true"></div>
      
        <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
         
            <div class="relative transform w-[50rem] max-h-[40rem] overflow-auto rounded-lg bg-white text-left shadow-xl transition-all 
            ">
            <section class="bg-white dark:bg-gray-800">
                <div class="py-8 px-6 mx-auto max-w-full">
                    <p class="mb-4 text-xl font-bold text-gray-700 dark:text-white">Conclusão Manual de Nota de Serviço</p>
                    <form >
                        <div class="grid gap-4 sm:grid-cols-2 sm:gap-6">
                            <div class="sm:col-span-1">
                                <label for="name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">TOKEN DA NOTA</label>
                                <input #inp_token type="text" name="name" id="name" disabled [value]="Nat_nota_selecionada.TOKEN"
                                class="bg-gray-50 border border-gray-300
                                 text-gray-900 text-sm rounded-lg focus:ring-primary-600 
                                 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700
                                  dark:border-gray-600 dark:placeholder-gray-400 dark:text-white
                                   dark:focus:ring-primary-500 dark:focus:border-primary-500" >
                            </div>
                            <div class="sm:col-span-1">
                                <label for="name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">CÓDIGO DA NOTA</label>
                                <input type="text" name="name" id="name" [value]="Nat_nota_selecionada.COD_NOTA" disabled
                                class="bg-gray-50 border 
                                 border-gray-300 text-gray-900 text-sm rounded-lg
                                  focus:ring-primary-600 focus:border-primary-600 block 
                                  w-full p-2.5 dark:bg-gray-700 dark:border-gray-600
                                   dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500
                                    dark:focus:border-primary-500" >
                            </div>
                            <div class="sm:col-span-1">
                                <label for="name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">EQUIPE</label>
                                <input type="text" name="name" id="name" [value]="Nat_nota_selecionada.EQUIPE_ID_STR" disabled
                                class="bg-gray-50 border
                                 border-gray-300 text-gray-900 text-sm rounded-lg
                                  focus:ring-primary-600 focus:border-primary-600 block 
                                  w-full p-2.5 dark:bg-gray-700 dark:border-gray-600
                                   dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500
                                    dark:focus:border-primary-500" >
                            </div>
                            <div class="sm:col-span-1">
                                <label for="price" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">DATA DE CONCLUSÃO DETERMINADA</label>
                                <input type="text" name="price" id="price" [value]="Nat_nota_selecionada.DATA_MANUAL_CONC_STR" disabled
                                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm
                                 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5
                                  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white 
                                  dark:focus:ring-primary-500 dark:focus:border-primary-500" >
                            </div>
                            <div class="sm:col-span-1">
                                <label for="price" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">SEQUÊNCIA</label>
                                <input type="text" name="price" id="price" [value]="Nat_nota_selecionada.SEQUENCIA" disabled
                                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm
                                 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5
                                  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white 
                                  dark:focus:ring-primary-500 dark:focus:border-primary-500" >
                            </div>
                            <div class="sm:col-span-2">
                                <label for="brand" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">ENDEREÇO</label>
                                <input type="text" name="brand" id="brand" [value]="Nat_nota_selecionada.ENDERECO" disabled
                                class="bg-gray-50 border border-gray-300
                                 text-gray-900 text-sm rounded-lg focus:ring-primary-600 
                                 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700
                                  dark:border-gray-600 dark:placeholder-gray-400
                                   dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" >
                            </div>
                            <div class="sm:col-span-2">
                                <label for="brand" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">SERVIÇO</label>
                                <input type="text" name="brand" id="brand" [value]="Nat_nota_selecionada.DESC_SERVICO" disabled
                                class="bg-gray-50 border border-gray-300
                                 text-gray-900 text-sm rounded-lg focus:ring-primary-600
                                  focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700
                                   dark:border-gray-600 dark:placeholder-gray-400 dark:text-white 
                                   dark:focus:ring-primary-500 dark:focus:border-primary-500" >
                            </div>

                            <label for="brand" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">IMAGENS</label>
                            <div class="sm:col-span-2 flex space-x-2">
                                <div class="flex space-x-2">
                                    <img 
                                        class="h-20 w-20 cursor-pointer hover:h-24 hover:w-24" 
                                        [src]="Nat_nota_selecionada.IMG_1" 
                                        alt="Imagem 1"
                                        (click)="abrirImagem(Nat_nota_selecionada.IMG_1)">
                                    <img 
                                        class="h-20 w-20 cursor-pointer hover:h-24 hover:w-24" 
                                        [src]="Nat_nota_selecionada.IMG_2" 
                                        alt="Imagem 2"
                                        (click)="abrirImagem(Nat_nota_selecionada.IMG_2)">
                                    <img 
                                        class="h-20 w-20 cursor-pointer hover:h-24 hover:w-24" 
                                        [src]="Nat_nota_selecionada.IMG_3" 
                                        alt="Imagem 3"
                                        (click)="abrirImagem(Nat_nota_selecionada.IMG_3)">
                                </div>
                            </div>
                            
                            
                            <div class="sm:col-span-1">
                                <label for="brand" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">STATUS</label>
                                <input type="text" name="brand" id="brand" [value]="Nat_nota_selecionada.STATUS" disabled
                                class="bg-gray-50 border border-gray-300
                                 text-gray-900 text-sm rounded-lg focus:ring-primary-600
                                  focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700
                                   dark:border-gray-600 dark:placeholder-gray-400 dark:text-white 
                                   dark:focus:ring-primary-500 dark:focus:border-primary-500" >
                            </div>
                            <div class="sm:col-span-1">
                                <label for="brand" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">STATUS TEMPO</label>
                                <input type="text" name="brand" id="brand" [value]="Nat_nota_selecionada.DIF_CONC" disabled
                                class="bg-gray-50 border border-gray-300
                                 text-gray-900 text-sm rounded-lg focus:ring-primary-600
                                  focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700
                                   dark:border-gray-600 dark:placeholder-gray-400 dark:text-white 
                                   dark:focus:ring-primary-500 dark:focus:border-primary-500" >
                            </div>
                            <div class="sm:col-span-1">
                                <label for="brand" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">STATUS CONCLUSÃO</label>
                                <input type="text" name="brand" id="brand" [value]="Nat_nota_selecionada.STATUS_CONC" disabled
                                class="bg-gray-50 border border-gray-300
                                 text-gray-900 text-sm rounded-lg focus:ring-primary-600
                                  focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700
                                   dark:border-gray-600 dark:placeholder-gray-400 dark:text-white 
                                   dark:focus:ring-primary-500 dark:focus:border-primary-500" >
                            </div>
                            <div class="sm:col-span-1">
                                <label for="brand" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">DISTANCIA DO PONTO</label>
                                <input type="text" name="brand" id="brand" value="{{Nat_nota_selecionada.DIST_LOCAL_CONC/1000}} Km" disabled
                                class="bg-gray-50 border border-gray-300
                                 text-gray-900 text-sm rounded-lg focus:ring-primary-600
                                  focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700
                                   dark:border-gray-600 dark:placeholder-gray-400 dark:text-white 
                                   dark:focus:ring-primary-500 dark:focus:border-primary-500" >
                            </div>
                            <div class="sm:col-span-2">
                                <label for="description" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Observações e Motivo da Conclusão</label>
                                <textarea disabled #inp_obs id="description" [value]="Nat_nota_selecionada.OBSERVACAO"
                                 rows="8" class="block p-2.5 w-full
                                  text-sm text-gray-900 bg-gray-50 
                                  rounded-lg border border-gray-300 focus:ring-primary-500 
                                  focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600
                                   dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500
                                    dark:focus:border-primary-500" ></textarea>
                            </div>
                        </div>
                        <div class="w-full inline-block space-x-4">
                            <!-- <button (click)="concluir_nota()" type="button" class="inline-flex items-center px-5 py-2.5 mt-4 sm:mt-6 text-sm font-medium text-center
                            text-white bg-blue-600 rounded-lg focus:ring-4 focus:ring-blue-500 dark:focus:ring-blue-500 hover:bg-blue-500">
                               Concluir
                           </button> -->
       
                           <button (click)="Nat_change_view_concluir_nota(undefined)" type="button" class="inline-flex items-center px-5 py-2.5 mt-4 sm:mt-6 text-sm font-medium text-center
                            text-white bg-red-600 rounded-lg focus:ring-4 focus:ring-red-500 dark:focus:ring-red-500 hover:bg-red-500">
                               Fechar
                           </button>
                        </div>
                        
                    </form>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
}

  