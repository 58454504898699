<!-- Main modal -->
<div id="authentication-modal" tabindex="-1" aria-hidden="true" class="fixed inset-0 z-50 flex justify-center items-center w-full h-full bg-gray-500/75">
    <div class="relative p-4 w-full max-w-md">
      <!-- Modal content -->
      <div class="relative bg-white rounded-lg shadow">
        <!-- Modal header -->
        <div class="flex items-center justify-between p-4 border-b rounded-t">
          <a class="text-xl font-normal text-gray-900" >
           Alteração de Coordenadas
          </a>
          <button (click)="Nat_change_state_mudar_coordenada()" type="button" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center" data-modal-hide="authentication-modal">
            <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
            </svg>
            <span class="sr-only">Close modal</span>
          </button>
        </div>
        <!-- Modal body -->
        <div class="p-4">
          <form class="space-y-4" >
            <div>
              <label for="text" class="block mb-2 text-sm font-medium text-gray-900">Nova Coordenada</label>
              <input #inp_nova_coordenada type="text" name="text"
               id="text" class="bg-gray-50 border
                border-gray-300 text-gray-900 text-sm 
                rounded-lg focus:ring-blue-500 focus:border-blue-500 
                block w-full p-2.5" placeholder="Ex: -25.362955, -49.192667" required />
            </div>
          
            <button (click)="Nat_change_coordenada()" type="button" class="w-full text-white bg-blue-700
             hover:bg-blue-800 focus:ring-4 focus:outline-none
              focus:ring-blue-300 font-medium rounded-lg
               text-sm px-5 py-2.5 text-center">Enviar Alteração</button>
           
          </form>
        </div>
      </div>
    </div>
  </div>
  