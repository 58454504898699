import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment.development';
import { CREATE_ITEM, ITEM, REQ_NOVA_RETIRADA } from '../../../MODELS/MODELS_STOX';
import { RESPOSTA_PADRAO_SERVIDOR } from '../../../MODELS/RESPOSTA_PADRAO_SERVIDOR';

@Injectable({
  providedIn: 'root'
})
export class StoxService {

  constructor(private httpClient: HttpClient) { }

    /**--------------------------------------------------RETIRAR ITEM */
    async POST_criar_retirada(body: REQ_NOVA_RETIRADA) {
      try {
        const response = await this.httpClient
          .post(`${environment.url_api_local}/POST_criar_retirada`, body)
          .toPromise();
  
  
        return response;
      } catch (error) {
        console.log(error, 'erro');
        throw error;
      }
    }

      /**--------------------------------------------------LISTA DE ESTOQUE ATUAL */
  async POST_listar_item(EMPRESA_ID: number) {
    try {
      const response = await this.httpClient
        .post(`${environment.url_api_local}/POST_listar_item`, { EMPRESA_ID: EMPRESA_ID })
        .toPromise();


      return response;
    } catch (error) {
      console.log(error, 'erro');
      throw error;
    }
  }

    /**--------------------------------------------------CRIA NOVO ITEM */
    async POST_criar_item(body: CREATE_ITEM) {
      try {
        const response = await this.httpClient
          .post(`${environment.url_api_local}/POST_criar_item`, body)
          .toPromise();
  
  
        return response;
      } catch (error) {
        console.log(error, 'erro');
        throw error;
      }
    }

      /**--------------------------------------------------SALVAR MUDANÇAS NO ITEM */
  async POST_editar_item(body: ITEM) {
    try {
      const response = await this.httpClient
        .post(`${environment.url_api_local}/POST_editar_item`, body)
        .toPromise();


      return response;
    } catch (error) {
      console.log(error, 'erro');
      throw error;
    }
  }

    /**--------------------------------------------------DELETE ITEM DO ESTOQUE */
    async POST_delete_item(ITEM_ID: number) {
      try {
        const response = await this.httpClient
          .post(`${environment.url_api_local}/POST_delete_item`, { ID: ITEM_ID })
          .toPromise();
  
  
        return response;
      } catch (error) {
        console.log(error, 'erro');
        throw error;
      }
    }

      //BUSCANDO USERS CADASTRADAS
  async POST_listar_users(body) {
    try {
      const response = await this.httpClient
        .post(`${environment.url_api_local}/POST_listar_users`, body)
        .toPromise();


      return response;
    } catch (error) {
      console.log(error, 'erro');
      const retorno_padrao: RESPOSTA_PADRAO_SERVIDOR = {
        MSG: "ERRO",
        ADICIONAL: "",
        RESPOSTA: undefined
      }
      return retorno_padrao
      throw error;
    }
  }

    /**--------------------------------------------------LISTA DE updates ATUAL */
    async POST_listar_updates(EMPRESA_ID: number) {
      try {
        const response = await this.httpClient
          .post(`${environment.url_api_local}/POST_listar_updates`, { EMPRESA_ID: EMPRESA_ID })
          .toPromise();
  
  
        return response;
      } catch (error) {
        console.log(error, 'erro');
        throw error;
      }
    }
      /**--------------------------------------------------LISTA DE retiradas ATUAL */
  async POST_listar_retiradas(EMPRESA_ID: number) {
    try {
      const response = await this.httpClient
        .post(`${environment.url_api_local}/POST_listar_retiradas`, { EMPRESA_ID: EMPRESA_ID })
        .toPromise();


      return response;
    } catch (error) {
      console.log(error, 'erro');
      throw error;
    }
  }

    /**--------------------------------------------------DELETE DE RETIRADA*/
    async POST_delete_retirada(ITEM_ID: number) {
      try {
        const response = await this.httpClient
          .post(`${environment.url_api_local}/POST_delete_retirada`, { ID: ITEM_ID })
          .toPromise();
  
  
        return response;
      } catch (error) {
        console.log(error, 'erro');
        throw error;
      }
    }
}
