import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment.development';
import { RESPOSTA_PADRAO_SERVIDOR } from '../../../MODELS/RESPOSTA_PADRAO_SERVIDOR';
import { USER } from '../../../MODELS/USER';
import { COORDENADA } from '../../../MODELS/COORDENADA';
import { BASE, CONCLUSAO_NOTA, EQUIPE, NOVA_BASE, NOVA_EQUIPE, REQ_TRIP_AORDEX } from '../../../MODELS/MODELS_AORDEX';

@Injectable({
  providedIn: 'root'
})
export class AordexService {

  constructor(private httpClient: HttpClient) { }

  //GERA O LOGIN DE ACESSO NA VERIFICACAO DE 2 ETAPAS
  async POST_RECEBE_PLANILHA(formdata: FormData) {
    try {
      const response = await this.httpClient
        .post(`${environment.url_api_local}/POST_RECEBE_PLANILHA`, formdata)
        .toPromise();


      return response;
    } catch (error) {
      console.log(error, 'erro');
      const retorno_padrao: RESPOSTA_PADRAO_SERVIDOR = {
        MSG: "ERRO",
        ADICIONAL: "",
        RESPOSTA: undefined
      }
      return retorno_padrao

    }
  }

  //BUSCAR TODAS AS IMPORTACOES DE UMA EMPRESA
  async POST_BUSCAR_IMPORTACOES(user: USER) {
    try {
      const response = await this.httpClient
        .post(`${environment.url_api_local}/POST_BUSCAR_IMPORTACOES`, user)
        .toPromise();


      return response;
    } catch (error) {
      console.log(error, 'erro');
      const retorno_padrao: RESPOSTA_PADRAO_SERVIDOR = {
        MSG: "ERRO",
        ADICIONAL: "",
        RESPOSTA: undefined
      }
      return retorno_padrao

    }
  }



  //BUSCAR TODAS AS NOTAS PENDENTES DE UMA EMPRESA
  async POST_BUSCAR_PENDENTES(user: USER) {
    try {
      const response = await this.httpClient
        .post(`${environment.url_api_local}/POST_BUSCAR_PENDENTES`, user)
        .toPromise();


      return response;
    } catch (error) {
      console.log(error, 'erro');
      const retorno_padrao: RESPOSTA_PADRAO_SERVIDOR = {
        MSG: "ERRO",
        ADICIONAL: "",
        RESPOSTA: undefined
      }
      return retorno_padrao

    }
  }
  //BUSCAR TODAS AS NOTAS ATRIBUIDAS DE UMA EMPRESA
  async POST_BUSCAR_ATRIBUIDAS(user: USER) {
    try {
      const response = await this.httpClient
        .post(`${environment.url_api_local}/POST_BUSCAR_ATRIBUIDAS`, user)
        .toPromise();


      return response;
    } catch (error) {
      console.log(error, 'erro');
      const retorno_padrao: RESPOSTA_PADRAO_SERVIDOR = {
        MSG: "ERRO",
        ADICIONAL: "",
        RESPOSTA: undefined
      }
      return retorno_padrao

    }
  }

  //BUSCAR TODAS AS NOTAS CONCLUIDAS DE UMA EMPRESA
  async POST_BUSCAR_CONCLUIDAS(user: USER) {
    try {
      const response = await this.httpClient
        .post(`${environment.url_api_local}/POST_BUSCAR_CONCLUIDAS`, user)
        .toPromise();


      return response;
    } catch (error) {
      console.log(error, 'erro');
      const retorno_padrao: RESPOSTA_PADRAO_SERVIDOR = {
        MSG: "ERRO",
        ADICIONAL: "",
        RESPOSTA: undefined
      }
      return retorno_padrao

    }
  }

  //BUSCAR TODOS OS ROTEIROS DE UMA EMPRESA
  async POST_BUSCAR_ROTEIROS(user: USER) {
    try {
      const response = await this.httpClient
        .post(`${environment.url_api_local}/POST_BUSCAR_ROTEIROS`, user)
        .toPromise();


      return response;
    } catch (error) {
      console.log(error, 'erro');
      const retorno_padrao: RESPOSTA_PADRAO_SERVIDOR = {
        MSG: "ERRO",
        ADICIONAL: "",
        RESPOSTA: undefined
      }
      return retorno_padrao

    }
  }

   //BUSCAR TODOS OS DADOS DE UM ROTEIRO
   async POST_BUSCAR_NOTAS_ROTEIRO(roteiro_id: number) {
    try {
      const response = await this.httpClient
        .post(`${environment.url_api_local}/POST_BUSCAR_NOTAS_ROTEIRO`, {roteiro_id:roteiro_id})
        .toPromise();


      return response;
    } catch (error) {
      console.log(error, 'erro');
      const retorno_padrao: RESPOSTA_PADRAO_SERVIDOR = {
        MSG: "ERRO",
        ADICIONAL: "",
        RESPOSTA: undefined
      }
      return retorno_padrao

    }
  }
    //BUSCAR TODAS AS NOTAS REJEITADAS DE UMA EMPRESA
    async POST_BUSCAR_REJEITADAS(user: USER) {
      try {
        const response = await this.httpClient
          .post(`${environment.url_api_local}/POST_BUSCAR_REJEITADAS`, user)
          .toPromise();
  
  
        return response;
      } catch (error) {
        console.log(error, 'erro');
        const retorno_padrao: RESPOSTA_PADRAO_SERVIDOR = {
          MSG: "ERRO",
          ADICIONAL: "",
          RESPOSTA: undefined
        }
        return retorno_padrao
  
      }
    }
  


  //MUDA A COORDENADA DE UMA NOTA
  async POST_ALTERA_COORDENADA(id_nota: number, lat: number, lng: number) {
    try {
      const response = await this.httpClient
        .post(`${environment.url_api_local}/POST_ALTERA_COORDENADA`, {
          id_nota: id_nota,
          lat: lat,
          lng: lng
        })
        .toPromise();


      return response;
    } catch (error) {
      console.log(error, 'erro');
      const retorno_padrao: RESPOSTA_PADRAO_SERVIDOR = {
        MSG: "ERRO",
        ADICIONAL: "",
        RESPOSTA: undefined
      }
      return retorno_padrao

    }
  }

  async POST_CONCLUIR_NOTA(body: CONCLUSAO_NOTA) {
    try {
      const formData = new FormData();
      formData.append('NOTA_ID', body.NOTA_ID.toString());
      formData.append('OBSERVACOES', body.OBSERVACOES.toString());
      formData.append('STATUS', body.STATUS.toString());
      formData.append('LAT_CONC', body.LAT_CONC.toString());
      formData.append('LNG_CONC', body.LNG_CONC.toString());
      formData.append('TOKEN', body.TOKEN.toString());



      formData.append('medidor_esquerda', body.IMG_MD_ESQUERDA);

      formData.append('medidor_direita', body.IMG_MD_DIREITA);

      formData.append('fachada', body.IMG_FACHADA);


      const response = await this.httpClient
        .post(`${environment.url_api_local}/POST_CONCLUIR_NOTA`, formData)
        .toPromise();

      return response as RESPOSTA_PADRAO_SERVIDOR;
    } catch (error) {
      console.error('Erro ao enviar imagens:', error);
      return {
        MSG: 'ERRO',
        ADICIONAL: 'Falha no envio das imagens.',
        RESPOSTA: undefined,
      };
    }
  }

  //DELETA UMA NOTA
  async POST_DELETE_NOTA(id_nota: number) {
    try {
      const response = await this.httpClient
        .post(`${environment.url_api_local}/POST_DELETE_NOTA`, { id_nota: id_nota })
        .toPromise();


      return response;
    } catch (error) {
      console.log(error, 'erro');
      const retorno_padrao: RESPOSTA_PADRAO_SERVIDOR = {
        MSG: "ERRO",
        ADICIONAL: "",
        RESPOSTA: undefined
      }
      return retorno_padrao

    }
  }


  //DELETA TODAS AS NOTAS DE UMA IMPORTAÇÃO
  async POST_DELETE_IMPORTACAO(id_importacao: number) {
    try {
      const response = await this.httpClient
        .post(`${environment.url_api_local}/POST_DELETE_IMPORTACAO`, { id_importacao: id_importacao })
        .toPromise();


      return response;
    } catch (error) {
      console.log(error, 'erro');
      const retorno_padrao: RESPOSTA_PADRAO_SERVIDOR = {
        MSG: "ERRO",
        ADICIONAL: "",
        RESPOSTA: undefined
      }
      return retorno_padrao

    }
  }
  //DELETA UM ROTEIRO E RESETA SUAS NOTAS
  async POST_DELETE_ROTEIRO(id_roteiro: number) {
    try {
      const response = await this.httpClient
        .post(`${environment.url_api_local}/POST_DELETE_ROTEIRO`, { id_roteiro: id_roteiro })
        .toPromise();


      return response;
    } catch (error) {
      console.log(error, 'erro');
      const retorno_padrao: RESPOSTA_PADRAO_SERVIDOR = {
        MSG: "ERRO",
        ADICIONAL: "",
        RESPOSTA: undefined
      }
      return retorno_padrao

    }
  }


  //COLETA AS POSIÇOES DOS USERS PARA ATUALIZAR NO MAP
  async POST_ATUALIZAR_POSICOES_USERS(empresa_id: number) {
    try {
      const response = await this.httpClient
        .post(`${environment.url_api_local}/POST_ATUALIZAR_POSICOES_USERS`, { empresa_id: empresa_id })
        .toPromise();


      return response;
    } catch (error) {
      console.log(error, 'erro');
      const retorno_padrao: RESPOSTA_PADRAO_SERVIDOR = {
        MSG: "ERRO",
        ADICIONAL: "",
        RESPOSTA: undefined
      }
      return retorno_padrao

    }
  }  
  async POST_ATUALIZAR_POSICOES_USERS_CNG_CEARA() {
    try {
      const response = await this.httpClient
        .post(`${environment.url_api_local}/POST_localizacao_mdm`, { deviceId: "" })
        .toPromise();


      return response;
    } catch (error) {
      console.log(error, 'erro');
      const retorno_padrao: RESPOSTA_PADRAO_SERVIDOR = {
        MSG: "ERRO",
        ADICIONAL: "",
        RESPOSTA: undefined
      }
      return retorno_padrao

    }
  }  


  //VERIFICA QUAIS INSTALACOES ESTÃO DENTRO DO POLIGONO
  async POST_NOTA_IN_POLIGONO(coordenadas_polygon: COORDENADA[], instalacoes_ids: number[], modulo: string) {
    try {
      const response = await this.httpClient
        .post(`${environment.url_api_local}/POST_NOTA_IN_POLIGONO`, { coordenadas_polygon: coordenadas_polygon, instalacoes_ids: instalacoes_ids, modulo: modulo })
        .toPromise();


      return response;
    } catch (error) {
      console.log(error, 'erro');
      const retorno_padrao: RESPOSTA_PADRAO_SERVIDOR = {
        MSG: "ERRO",
        ADICIONAL: "",
        RESPOSTA: undefined
      }
      return retorno_padrao

    }
  }




  //BUSCAR TODOS OS USERS DE UMA EMPRESA QUE AINDA NÃO ESTÃO EM ALGUMA EQUIPE
  async POST_BUSCAR_USERS_EMPRESA_LIVRES(user: USER) {
    try {
      const response = await this.httpClient
        .post(`${environment.url_api_local}/POST_BUSCAR_USERS_EMPRESA_LIVRES`, user)
        .toPromise();


      return response;
    } catch (error) {
      console.log(error, 'erro');
      const retorno_padrao: RESPOSTA_PADRAO_SERVIDOR = {
        MSG: "ERRO",
        ADICIONAL: "",
        RESPOSTA: undefined
      }
      return retorno_padrao

    }
  }


  //POST BUSCAR EQUIPES
  async POST_BUSCAR_EQUIPES(empresa_id: number) {
    try {
      const response = await this.httpClient
        .post(`${environment.url_api_local}/POST_BUSCAR_EQUIPES`, { empresa_id: empresa_id })
        .toPromise();


      return response;
    } catch (error) {
      console.log(error, 'erro');
      const retorno_padrao: RESPOSTA_PADRAO_SERVIDOR = {
        MSG: "ERRO",
        ADICIONAL: "",
        RESPOSTA: undefined
      }
      return retorno_padrao

    }
  }

  //POST BUSCAR EQUIPES
  async POST_BUSCAR_BASES(empresa_id: number) {
    try {
      const response = await this.httpClient
        .post(`${environment.url_api_local}/POST_BUSCAR_BASES`, { empresa_id: empresa_id })
        .toPromise();


      return response;
    } catch (error) {
      console.log(error, 'erro');
      const retorno_padrao: RESPOSTA_PADRAO_SERVIDOR = {
        MSG: "ERRO",
        ADICIONAL: "",
        RESPOSTA: undefined
      }
      return retorno_padrao

    }
  }


  //CRIA UMA NOVA EQUIPE
  async POST_CRIAR_EQUIPE(equipe: NOVA_EQUIPE) {
    try {
      const response = await this.httpClient
        .post(`${environment.url_api_local}/POST_CRIAR_EQUIPE`, equipe)
        .toPromise();


      return response;
    } catch (error) {
      console.log(error, 'erro');
      const retorno_padrao: RESPOSTA_PADRAO_SERVIDOR = {
        MSG: "ERRO",
        ADICIONAL: "",
        RESPOSTA: undefined
      }
      return retorno_padrao

    }
  }

  //CRIA UMA NOVA BASE
  async POST_CRIAR_BASE(base: NOVA_BASE) {
    try {
      const response = await this.httpClient
        .post(`${environment.url_api_local}/POST_CRIAR_BASE`, base)
        .toPromise();


      return response;
    } catch (error) {
      console.log(error, 'erro');
      const retorno_padrao: RESPOSTA_PADRAO_SERVIDOR = {
        MSG: "ERRO",
        ADICIONAL: "",
        RESPOSTA: undefined
      }
      return retorno_padrao

    }
  }


  //CRIA UMA NOVA EQUIPE
  async POST_ATUALIZAR_EQUIPE(equipe_id: number, new_name: string) {
    try {
      const response = await this.httpClient
        .post(`${environment.url_api_local}/POST_ATUALIZAR_EQUIPE`, { equipe_id: equipe_id, new_name: new_name })
        .toPromise();


      return response;
    } catch (error) {
      console.log(error, 'erro');
      const retorno_padrao: RESPOSTA_PADRAO_SERVIDOR = {
        MSG: "ERRO",
        ADICIONAL: "",
        RESPOSTA: undefined
      }
      return retorno_padrao

    }
  }

  //ATUALIZAR BASE
  async POST_ATUALIZAR_BASE(base: BASE) {
    try {
      const response = await this.httpClient
        .post(`${environment.url_api_local}/POST_ATUALIZAR_BASE`, base)
        .toPromise();


      return response;
    } catch (error) {
      console.log(error, 'erro');
      const retorno_padrao: RESPOSTA_PADRAO_SERVIDOR = {
        MSG: "ERRO",
        ADICIONAL: "",
        RESPOSTA: undefined
      }
      return retorno_padrao

    }
  }
  //DELETA UMA EQUIPE
  async POST_DELETAR_EQUIPE(equipe_id: number) {
    try {
      const response = await this.httpClient
        .post(`${environment.url_api_local}/POST_DELETAR_EQUIPE`, { equipe_id: equipe_id })
        .toPromise();


      return response;
    } catch (error) {
      console.log(error, 'erro');
      const retorno_padrao: RESPOSTA_PADRAO_SERVIDOR = {
        MSG: "ERRO",
        ADICIONAL: "",
        RESPOSTA: undefined
      }
      return retorno_padrao

    }
  }


  //DELETA UMA BASE
  async POST_DELETAR_BASE(base_id: number) {
    try {
      const response = await this.httpClient
        .post(`${environment.url_api_local}/POST_DELETAR_BASE`, { base_id: base_id })
        .toPromise();


      return response;
    } catch (error) {
      console.log(error, 'erro');
      const retorno_padrao: RESPOSTA_PADRAO_SERVIDOR = {
        MSG: "ERRO",
        ADICIONAL: "",
        RESPOSTA: undefined
      }
      return retorno_padrao

    }
  }



  //POST TRIP AORDEX RETORNA DADOS DO ROTEIRO PROPOSTO
  async POST_OSRM_TRIP_AORDEX(body: REQ_TRIP_AORDEX) {
    try {
      const response = await this.httpClient
        .post(`${environment.url_api_local}/POST_OSRM_TRIP_AORDEX`, body)
        .toPromise();


      return response;
    } catch (error) {
      console.log(error, 'erro');
      const retorno_padrao: RESPOSTA_PADRAO_SERVIDOR = {
        MSG: "ERRO",
        ADICIONAL: "",
        RESPOSTA: undefined
      }
      return retorno_padrao

    }
  }



  //POST TRIP AORDEX RETORNA DADOS DO ROTEIRO PROPOSTO
  async POST_ATRIBUIR_NOTAS(body: { pontos: any[], equipe_id: number, base_id: number, user_dados: USER }) {
    try {
      const response = await this.httpClient
        .post(`${environment.url_api_local}/POST_ATRIBUIR_NOTAS`, body)
        .toPromise();


      return response;
    } catch (error) {
      console.log(error, 'erro');
      const retorno_padrao: RESPOSTA_PADRAO_SERVIDOR = {
        MSG: "ERRO",
        ADICIONAL: "",
        RESPOSTA: undefined
      }
      return retorno_padrao

    }
  }

}
