import { HttpClient } from '@angular/common/http';
import { Component, ViewChild, AfterViewInit, inject, OnInit, viewChild, ElementRef, SimpleChanges, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule, SortDirection } from '@angular/material/sort';
import { merge, Observable, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { MatTableModule, MatTableDataSource } from '@angular/material/table';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DatePipe } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { environment } from '../../../../../environments/environment.development';
import { GpxService } from '../../SERVICES/gpx.service';
import { RESPOSTA_PADRAO_SERVIDOR } from '../../../../MODELS/RESPOSTA_PADRAO_SERVIDOR';
import { OPERACAO_ou_LIVRO } from '../../../../MODELS/OPERACAO_ou_LIVRO';
import { DADOS_ALERT } from '../../../../MODELS/DADOS_ALERT';
import { MainService } from '../../../../SERVICES/main.service';


@Component({
  selector: 'app-gpx-edicoes',
  standalone: true,
  imports: [
    MatProgressSpinnerModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    DatePipe,
    MatFormFieldModule,
    MatInputModule
  ],
  templateUrl: './gpx-edicoes.component.html',
  styleUrl: './gpx-edicoes.component.css'
})
export class GpxEdicoesComponent implements AfterViewInit, OnInit, OnChanges {
  displayedColumns: string[] = ['acoes', 'ID_STR', 'CENTRO_LEITURA_STR', 'GRUPO_STR', 'QUANT_ITENS'];

  dataSource: MatTableDataSource<OPERACAO_ou_LIVRO>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private service: GpxService,private main_service:MainService) {


  }
  Nat_operacoes: OPERACAO_ou_LIVRO[] = []

  /**evento que abre a aba de loading no component main */
  @Output() Evento_open_loading = new EventEmitter<boolean>();

  /**evento que fecha a aba de loading no component main */
  @Output() Evento_close_loading = new EventEmitter<boolean>();

  /**evento que abre um alerta de sucesso no component main */
  @Output() Evento_open_alert_sucess = new EventEmitter<DADOS_ALERT>();

  /**evento que abre um alerta de erro no component main */
  @Output() Evento_open_alert_erro = new EventEmitter<DADOS_ALERT>();


  async ngOnInit() {

    this.Evento_open_loading.emit(true)

    const requisicao_server: any = await this.service.POST_OPERACOES_GPX_EDICOES(environment.user_atual.ID)
    const resposta: RESPOSTA_PADRAO_SERVIDOR = requisicao_server


    if (resposta.MSG == 'SUCESSO') {

      this.Nat_operacoes = resposta.RESPOSTA

      this.dataSource = new MatTableDataSource(this.Nat_operacoes);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;


      this.dataSource.data.forEach((operacao, o) => {
        if (this.input_ids_operacoes_mapa.includes(operacao.ID)) {
          this.dataSource.data[o].VISIVEL = true
        } else {
          this.dataSource.data[o].VISIVEL = false
        }
      })

   

      this.Evento_close_loading.emit(true)
    } else {
      this.Evento_close_loading.emit(true)
      let new_alert: DADOS_ALERT = {
        TITULO: resposta.MSG,
        CORPO: resposta.ADICIONAL
      }
      this.Evento_open_alert_erro.emit(new_alert)
    }
  }

  ngAfterViewInit() {

  }

  /**
 * ids das operacoes que estao em mapa para manter a visualizacao do mapa atualizada com as indicacoes dos itens 
 * que estao ou nao no mapa e estao ou nao disponiveis
 */
  @Input() input_ids_operacoes_mapa: number[] = []

  @Input() input_ids_operacoes_mapa_disponiveis: number[] = []


  /**evento que envia um id de operacao para ser aberto no mapa */
  @Output() Evento_open_operacao = new EventEmitter<OPERACAO_ou_LIVRO>();
  /**evento que envia um id de operacao para ser fechado no mapa */
  @Output() Evento_close_operacao = new EventEmitter<OPERACAO_ou_LIVRO>();

  Nat_open_operacao(operacao: OPERACAO_ou_LIVRO) {
    this.Evento_open_operacao.emit(operacao)

      if(this.input_ids_operacoes_mapa_disponiveis.length==0){
        const index_operacao: number = this.dataSource.data.findIndex((operacao_local => operacao_local.ID == operacao.ID))
        this.dataSource.data[index_operacao].VISIVEL = true
      }


    



  }

  Nat_close_operacao(operacao: OPERACAO_ou_LIVRO) {
    this.Evento_close_operacao.emit(operacao)

    const index_operacao: number = this.dataSource.data.findIndex((operacao_local => operacao_local.ID == operacao.ID))
    this.dataSource.data[index_operacao].VISIVEL = false

  }

  async Nat_delete_operacao(operacao_id:number){

    this.Evento_open_loading.emit(true)

    const requisicao_server: any = await this.main_service.POST_DELETE_OPERACAO(operacao_id)
    const resposta: RESPOSTA_PADRAO_SERVIDOR = requisicao_server


    if (resposta.MSG == 'SUCESSO') {


      if(this.input_ids_operacoes_mapa.includes(operacao_id)){

        const index = this.dataSource.data.findIndex((operacao => operacao.ID == operacao_id))
        this.Nat_close_operacao(this.dataSource.data[index])
      }


      this.dataSource.data = this.dataSource.data.filter((operacao => operacao.ID != operacao_id))
      this.Evento_close_loading.emit(true)
      let new_alert: DADOS_ALERT = {
        TITULO: resposta.MSG,
        CORPO: resposta.ADICIONAL
      }
      this.Evento_open_alert_sucess.emit(new_alert)
      
    } else {
      this.Evento_close_loading.emit(true)
      let new_alert: DADOS_ALERT = {
        TITULO: resposta.MSG,
        CORPO: resposta.ADICIONAL
      }
      this.Evento_open_alert_erro.emit(new_alert)
    }
  }

  applyFilter(event: Event) {//filtragem de itens da tabela
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  @ViewChild('input') input!: ElementRef;

  Nat_clear_filter() {//limpa o campo de busca 
    this.input.nativeElement.value = ''

    this.dataSource.filter = ''
  }



  async Nat_envia_divix(operacao_id: number) {
    this.Evento_open_loading.emit(true)

    const requisicao_server: any = await this.service.POST_ENVIAR_DIVIX(operacao_id)
    const resposta: RESPOSTA_PADRAO_SERVIDOR = requisicao_server


    if (resposta.MSG == 'SUCESSO') {

      
      if(this.input_ids_operacoes_mapa.includes(operacao_id)){

        const index = this.dataSource.data.findIndex((operacao => operacao.ID == operacao_id))

        this.Nat_close_operacao(this.dataSource.data[index])
      }

      this.dataSource.data = this.dataSource.data.filter((operacao => operacao.ID != operacao_id))


      this.Evento_close_loading.emit(true)
      let new_alert: DADOS_ALERT = {
        TITULO: resposta.MSG,
        CORPO: resposta.ADICIONAL
      }
      this.Evento_open_alert_sucess.emit(new_alert)
    } else {
      this.Evento_close_loading.emit(true)
      let new_alert: DADOS_ALERT = {
        TITULO: resposta.MSG,
        CORPO: resposta.ADICIONAL
      }
      this.Evento_open_alert_erro.emit(new_alert)
    }
  }
  ngOnChanges(changes: SimpleChanges) {
    // Verifica se houve mudanças em 'input_ids_operacoes_mapa'
    if (changes['input_ids_operacoes_mapa']) {
      const change = changes['input_ids_operacoes_mapa'];

      // Verifica se houve realmente uma mudança no valor da variavel input_ids_operacoes_mapa
      if (change.previousValue !== change.currentValue) {

        if (change.currentValue.length > 0 && this.dataSource) {

          this.dataSource.data.forEach((operacao, o) => {
            if (change.currentValue.includes(operacao.ID)) {
              this.dataSource.data[o].VISIVEL = true
            } else {
              this.dataSource.data[o].VISIVEL = false
            }
          })


        }

      }
    }
  }
}
