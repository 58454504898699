<nav class="bg-white shadow absolute t-0 z-50 w-screen">
    <div class="mx-auto max-w-full px-2 sm:px-4 lg:px-8">
        <div class="flex h-16 justify-between">
            <div class="flex px-2 lg:px-0 items-center">
                <div class="flex shrink-0 items-center">
                    <img class="h-8 w-auto"
                        src="https://res.cloudinary.com/de74yvifw/image/upload/v1728393037/EXFERA/logo_simples_rs5par.png"
                        alt="Your Company">Aordex
                </div>
                <!-- Barra Vertical de Divisão -->
                <div class="h-6 border-l border-gray-300 mx-4"></div>

                <div class="hidden lg:ml-6 lg:flex lg:space-x-4">

                    <!-- Dropdown Importações -->
                    <div class="relative">
                        <button (click)="Nat_change_dropdown_importacoes()" id="dropdownImportacoesButton"
                            data-dropdown-toggle="dropdownImportacoes"
                            class="inline-flex items-center border-b-4 border-transparent px-1 pt-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700">
                            Importações
                            <svg class="ml-2 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                                viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                    d="M19 9l-7 7-7-7"></path>
                            </svg>
                        </button>
                        @if(Nat_state_importacoes){
                            <!--opções de lista de importacoes-->
                            <div id="dropdownImportacoes"
                                class="absolute z-50 block bg-white divide-y divide-gray-100 rounded-lg shadow w-60 top-14">
                                <ul class="py-2 text-sm text-gray-700 dark:text-gray-200"
                                    aria-labelledby="dropdownDefaultButton">
                                    <li>
                                        <a blue class="block px-4 py-2 hover:bg-gray-100 " (click)="uploadInput.click()">Nova
                                            Importação</a>
                                        <input #uploadInput type="file" accept=".xlsx, .xls" class="hidden"
                                            (change)="Nat_importar_modelo($event)" />
                                    </li>
                                    <li>
                                        <a blue class="block px-4 py-2 hover:bg-gray-100 "
                                            (click)="Nat_change_state_importacoes()">Importações</a>
                                    </li>
                                    <li>
                                        <a blue class="block px-4 py-2 hover:bg-gray-100 " (click)="Nat_baixar_modelo()">Baixar
                                            Modelo Excel</a>
                                    </li>
                                    <li>
                                        <a blue class="block px-4 py-2 hover:bg-gray-100 "
                                            (click)="Nat_informacoes_importacao()">Informações Sobre Importação</a>
                                    </li>
                                </ul>
                            </div>
                            }
                    </div>
             

                    <a (click)="Nat_change_state_roteiros()" class="cursor-pointer inline-flex items-center border-b-4 border-transparent px-1 pt-1 text-sm font-medium text-gray-500
                     hover:border-gray-300 hover:text-gray-700">Roteiros</a>

                    @if(Nat_state_FERRAMENTAS){
                    <a (click)="Nat_change_aba('FERRAMENTAS')"
                        class="cursor-pointer inline-flex items-center border-b-4 border-blue-500 px-1 pt-1 text-sm font-medium text-gray-900">Ferramentas</a>
                    }@else {
                    <a (click)="Nat_change_aba('FERRAMENTAS')" class="cursor-pointer inline-flex items-center border-b-4 border-transparent px-1 pt-1 text-sm font-medium text-gray-500
                         hover:border-gray-300 hover:text-gray-700">Ferramentas</a>
                    }
                    @if(Nat_state_MODULOS){
                    <a (click)="Nat_change_aba('MODULOS')"
                        class="cursor-pointer inline-flex items-center border-b-4 border-blue-500 px-1 pt-1 text-sm font-medium text-gray-900">Módulos</a>
                    }@else {
                    <a (click)="Nat_change_aba('MODULOS')" class="cursor-pointer inline-flex items-center border-b-4 border-transparent px-1 pt-1 text-sm font-medium text-gray-500
                         hover:border-gray-300 hover:text-gray-700">Módulos</a>
                    }

                    <!-- Dropdown Notas -->
                    <div class="relative">
                        <button (click)="Nat_change_dropdown_notas()" id="dropdownNotasButton" data-dropdown-toggle="dropdownNotas"
                            class="inline-flex items-center border-b-4 border-transparent px-1 pt-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700">
                            Notas
                            <svg class="ml-2 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                                viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                    d="M19 9l-7 7-7-7"></path>
                            </svg>
                        </button>


                        @if(Nat_state_notas){
                            <!--opções de lista de notas-->
                            <div id="dropdownNotas"
                            class="z-50 block bg-white divide-y divide-gray-100 rounded-lg shadow w-60 absolute top-14">
                            <ul class="py-2 text-sm text-gray-700 dark:text-gray-200"
                                aria-labelledby="dropdownDefaultButton">
                                <li>
                                    <a blue class="block px-4 py-2 hover:bg-gray-100 "
                                        (click)="Nat_change_state_pendentes()">Pendentes</a>
                                </li>
                                <li>
                                    <a blue class="block px-4 py-2 hover:bg-gray-100 "
                                        (click)="Nat_change_state_atribuidas()">Atribuídas</a>
                                </li>
                                <li>
                                    <a blue class="block px-4 py-2 hover:bg-gray-100 "
                                        (click)="Nat_change_state_concluidas()">Concluídas</a>
                                </li>
                                <li>
                                    <a blue class="block px-4 py-2 hover:bg-gray-100 "
                                        (click)="Nat_change_state_rejeitadas()">Rejeitadas</a>
                                </li>
                            </ul>
                           </div>
                                               }
                                              
                    </div>



                    <!-- Dropdown Equipes -->
                    <div class="relative">
                        <button (click)="Nat_change_dropdown_equipes()" id="dropdownEquipesButton" data-dropdown-toggle="dropdownEquipes"
                            class="inline-flex items-center border-b-4 border-transparent px-1 pt-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700">
                            Equipes
                            <svg class="ml-2 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                                viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                    d="M19 9l-7 7-7-7"></path>
                            </svg>
                        </button>


                        @if(Nat_state_equipes){
                                <!--opções de lista de Equipes-->
                    <div id="dropdownEquipes"
                    class="z-50 block bg-white divide-y divide-gray-100 rounded-lg shadow w-60 absolute top-14">
                    <ul class="py-2 text-sm text-gray-700 dark:text-gray-200"
                        aria-labelledby="dropdownDefaultButton">
                        <li>
                            <a (click)="Nat_change_state_new_equipe()" blue
                                class="block px-4 py-2 hover:bg-gray-100 ">Criar Equipe</a>
                        </li>
                        <li>
                            <a (click)="Nat_change_state_view_equipe()" blue
                                class="block px-4 py-2 hover:bg-gray-100 ">Ver Equipes</a>
                        </li>

                    </ul>
                </div>
                        }
                    </div>
                


                    <!-- Dropdown Bases -->
                    <div class="relative">
                        <button (click)="Nat_change_dropdown_bases()" id="dropdownBasesButton" data-dropdown-toggle="dropdownBases"
                            class="inline-flex items-center border-b-4 border-transparent px-1 pt-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700">
                            Bases
                            <svg class="ml-2 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                                viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                    d="M19 9l-7 7-7-7"></path>
                            </svg>
                        </button>

                        @if(Nat_state_bases){
                             <!--opções de lista de Equipes-->
                    <div id="dropdownBases"
                    class="z-50 block bg-white divide-y divide-gray-100 rounded-lg shadow w-60 absolute top-14">
                    <ul class="py-2 text-sm text-gray-700 dark:text-gray-200"
                        aria-labelledby="dropdownDefaultButton">
                        <li>
                            <a (click)="Nat_change_state_new_base()" blue
                                class="block px-4 py-2 hover:bg-gray-100 ">Criar Base</a>
                        </li>
                        <li>
                            <a (click)="Nat_change_state_view_base()" blue
                                class="block px-4 py-2 hover:bg-gray-100 ">Editar Bases</a>
                        </li>
                        <li>
                            <a (click)="Nat_change_state_open_bases()" blue
                                class="block px-4 py-2 hover:bg-gray-100 ">Ver Bases</a>
                        </li>
                    </ul>
                </div>
                        }
                    </div>
                   



                </div>
            </div>
            <div class="flex flex-1 items-center justify-center px-2 lg:ml-6 lg:justify-end ">

                <form class="max-w-lg w-[50rem]">
                    <div class="flex w-full">

                        <button (click)="Nat_change_parametro()" id="dropdown-button" data-dropdown-toggle="dropdown"
                            class="flex-shrink-0 z-10 inline-flex items-center py-2.5 px-4 text-sm font-medium text-center text-gray-900
                             bg-gray-100 border border-gray-300 rounded-s-lg hover:bg-gray-200 focus:ring-4 
                             focus:outline-none focus:ring-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600
                              dark:focus:ring-gray-700 dark:text-white dark:border-gray-600"
                            type="button">{{Nat_type_busca}}</button>

                        <div class="relative w-full">
                            <input type="text" #inp_buscar id="search-dropdown" class="block p-2.5 w-full z-20
             text-sm text-gray-900 bg-gray-50 rounded-e-lg
              border-s-gray-50 border-s-2 border border-gray-300
               focus:ring-blue-500 focus:border-blue-500" placeholder="Buscar Locais ou Coordenadas"
                                (input)="onSearch($event)" />
                            <button type="button" (click)="Nat_clear_input()"
                                class="absolute top-0 end-0 p-2.5 text-sm font-medium h-full text-white bg-red-700 rounded-e-lg border border-red-700
                                 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800">

                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                    stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
                                </svg>



                            </button>
                        </div>
                        <ul *ngIf="suggestions.length > 0"
                            class="absolute w-full max-w-lg bg-white border border-gray-300 mt-14 rounded-lg shadow-lg max-h-52 overflow-y-auto">
                            <li *ngFor="let suggestion of suggestions"
                                class="p-2 hover:bg-blue-100 cursor-pointer break-words"
                                (click)="selectSuggestion(suggestion)">
                                {{ suggestion.display_name }}
                            </li>
                        </ul>

                    </div>
                </form>


            </div>

            <div class="flex items-center lg:hidden">
                <!-- Mobile menu button -->
                <button type="button"
                    class="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500"
                    aria-controls="mobile-menu" aria-expanded="false">
                    <span class="absolute -inset-0.5"></span>
                    <span class="sr-only">Open main menu</span>
                    <!--
              Icon when menu is closed.
  
              Menu open: "hidden", Menu closed: "block"
            -->
                    <svg class="block h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                        aria-hidden="true" data-slot="icon">
                        <path stroke-linecap="round" stroke-linejoin="round"
                            d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                    </svg>
                    <!--
              Icon when menu is open.
  
              Menu open: "block", Menu closed: "hidden"
            -->
                    <svg class="hidden h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                        aria-hidden="true" data-slot="icon">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
                    </svg>
                </button>
            </div>
            <div class="hidden lg:ml-4 lg:flex lg:items-center">
                <button type="button"
                    class="relative shrink-0 rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
                    <span class="absolute -inset-1.5"></span>
                    <span class="sr-only">View notifications</span>
                    <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                        aria-hidden="true" data-slot="icon">
                        <path stroke-linecap="round" stroke-linejoin="round"
                            d="M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0" />
                    </svg>
                </button>

                <!-- Profile dropdown -->
                <div class="relative ml-4 shrink-0">
                    <div>
                        <button type="button" (click)="Nat_open_perfil()"
                            class="relative flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                            id="user-menu-button" aria-expanded="false" aria-haspopup="true">
                            <span class="absolute -inset-1.5"></span>
                            <span class="sr-only">Open user menu</span>
                            @if(fotoBase64){
                            <img class="h-8 w-8 rounded-full" [src]="fotoBase64" alt="">
                            }@else {
                            <img class="h-8 w-8 rounded-full"
                                src="https://res.cloudinary.com/de74yvifw/image/upload/v1728500827/EXFERA/chktst75j1gaqapjqczg.svg"
                                alt="">
                            }
                        </button>
                    </div>


                </div>
            </div>
        </div>
    </div>

</nav>