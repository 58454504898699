import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DADOS_ALERT } from '../../../../MODELS/DADOS_ALERT';
import { ALTERA_COORDENADA, NOTA_DE_SERVICO, NOTA_DE_SERVICO_MAPA } from '../../../../MODELS/MODELS_AORDEX';
import { RESPOSTA_PADRAO_SERVIDOR } from '../../../../MODELS/RESPOSTA_PADRAO_SERVIDOR';
import { AordexService } from '../../SERVICES/aordex.service';
import { environment } from '../../../../../environments/environment.development';




@Component({
  selector: 'app-pendentes-main',
  standalone: true,
  imports: [MatFormFieldModule, MatInputModule, MatTableModule, MatSortModule, MatPaginatorModule],
  templateUrl: './pendentes-main.component.html',
  styleUrl: './pendentes-main.component.css'
})
export class PendentesMainComponent implements OnInit {


  constructor(private aordexService: AordexService) { }

  /**evento que abre a aba de loading no component main */
  @Output() Evento_open_loading = new EventEmitter<boolean>();

  /**evento que fecha a aba de loading no component main */
  @Output() Evento_close_loading = new EventEmitter<boolean>();

  /**evento que abre um alerta de sucesso no component main */
  @Output() Evento_open_alert_sucess = new EventEmitter<DADOS_ALERT>();

  /**evento que abre um alerta de erro no component main */
  @Output() Evento_open_alert_erro = new EventEmitter<DADOS_ALERT>();




  displayedColumns: string[] = [
    'VISIVEL',
    'ID_STR', 'INSTALACAO', 'ENDERECO', 'NUM_MEDIDA', 'COD_NOTA', 'LIVRO', 'DATA_GERACAO_STR',
    'DATA_PREV_CONC_SIS_STR', 'IMP_ID_STR', 'TIPO', 'DESC_SERVICO', 'CIDADE', 'COORDENADA_MINERADA', 'ACOES'];
  dataSource: MatTableDataSource<NOTA_DE_SERVICO>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;



  async ngOnInit() {
    this.Evento_open_loading.emit(true)

    const requisicao_server: any = await this.aordexService.POST_BUSCAR_PENDENTES(environment.user_atual);
    const resposta: RESPOSTA_PADRAO_SERVIDOR = requisicao_server;

    if (resposta.MSG == 'SUCESSO') {




      resposta.RESPOSTA.forEach((nota,n) => {
        if(this.input_pendentes_mapa.find((nota_mapa)=>nota_mapa.ID == nota.ID)){
          resposta.RESPOSTA[n].VISIVEL = true
        }
      });


      this.dataSource = new MatTableDataSource(resposta.RESPOSTA);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;

      this.Evento_close_loading.emit(true)
    } else {
      this.Evento_close_loading.emit(true)
      let new_alert: DADOS_ALERT = {
        TITULO: resposta.MSG,
        CORPO: resposta.ADICIONAL
      }
      this.Evento_open_alert_erro.emit(new_alert)
    }
  }



  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }


  @Output() Evento_change_coordenada = new EventEmitter<ALTERA_COORDENADA>()
  Nat_change_state_mudar_coordenada(id_nota: number) {
    this.Evento_change_coordenada.emit({
      ATIVO: true,
      ID_NOTA: id_nota
    })
  }


  @Output() Evento_view_notas = new EventEmitter<NOTA_DE_SERVICO[]>()
  @Output() Evento_remove_notas = new EventEmitter<NOTA_DE_SERVICO_MAPA[]>()

  @Input() input_pendentes_mapa: NOTA_DE_SERVICO_MAPA[] = []

  Nat_change_view() {
    if (this.input_pendentes_mapa.length > 0) {
      this.Nat_remove_all_notas()
    } else {
      this.Nat_view_all_notas_filtered()
    }
  }

  async Nat_view_nota(id_nota: number) {
    this.Evento_open_loading.emit(true)
    const aguarda = await this.Evento_view_notas.emit(this.dataSource.data.filter((nota) => nota.ID == id_nota))



    const index = this.dataSource.data.findIndex((sub_nota) => sub_nota.ID == id_nota)

    if (index >= 0) {
      this.dataSource.data[index].VISIVEL = true
    }
    this.Evento_close_loading.emit(true);
  }

  @ViewChild('checkbox_all') checkbox_all: ElementRef
  async Nat_remove_nota(id_nota: number) {
    this.Evento_open_loading.emit(true)
    const aguarda = await this.Evento_remove_notas.emit(this.input_pendentes_mapa.filter((nota) => nota.ID == id_nota))


    const index = this.dataSource.data.findIndex((sub_nota) => sub_nota.ID == id_nota)

    if (index >= 0) {
      this.dataSource.data[index].VISIVEL = false
    }


    if (this.dataSource.data.filter((nota) => nota.VISIVEL == true).length == 0) {
      this.checkbox_all.nativeElement.checked = false
    }

    this.Evento_close_loading.emit(true);

  }
  async Nat_view_all_notas_filtered() {

    this.Evento_open_loading.emit(true)

    const aguarda = await this.Evento_view_notas.emit(this.dataSource.filteredData)


    this.dataSource.filteredData.map((nota) => nota.VISIVEL = true)

    this.Evento_close_loading.emit(true);
  }

  async Nat_remove_all_notas() {
    this.Evento_open_loading.emit(true)
    const aguarda = await this.Evento_remove_notas.emit(this.input_pendentes_mapa)

    this.input_pendentes_mapa.forEach((nota, n) => {
      const index = this.dataSource.data.findIndex((sub_nota) => sub_nota.ID == nota.ID)

      if (index >= 0) {
        this.dataSource.data[index].VISIVEL = false
      }
    });

    this.checkbox_all.nativeElement.checked = false

    this.Evento_close_loading.emit(true);
  }


  async Nat_delete_nota(id_nota: number) {

    this.Evento_open_loading.emit(true)

    const requisicao_server: any = await this.aordexService.POST_DELETE_NOTA(id_nota);
    const resposta: RESPOSTA_PADRAO_SERVIDOR = requisicao_server;

    if (resposta.MSG == 'SUCESSO') {

      const atualiza = await this.ngOnInit()

      this.Evento_close_loading.emit(true);
      let new_alert: DADOS_ALERT = {
        TITULO: resposta.MSG,
        CORPO: resposta.ADICIONAL
      };
      this.Evento_open_alert_sucess.emit(new_alert);
    } else {
      this.Evento_close_loading.emit(true);
      let new_alert: DADOS_ALERT = {
        TITULO: resposta.MSG,
        CORPO: resposta.ADICIONAL
      };
      this.Evento_open_alert_erro.emit(new_alert);
    }
  }
}



