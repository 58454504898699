<div class="h-screen w-screen flex flex-col max-h-screen overflow-hidden">
    <div class="flex-1 relative">
        <div class="mx-auto max-w-7xl lg:grid lg:grid-cols-12 lg:gap-x-8 lg:px-8 h-full">
            <div class="px-6 pb-24 pt-10 sm:pb-32 lg:col-span-7 lg:px-0 lg:pb-48 lg:pt-40 xl:col-span-6">
                <div class="mx-auto max-w-lg lg:mx-0">
                    <img class="h-11" src="https://res.cloudinary.com/de74yvifw/image/upload/v1728393037/EXFERA/logo_simples_rs5par.png"
                        alt="Your Company">
                    <div class="hidden sm:mt-32 sm:flex lg:mt-16">
                        <div
                            class="relative rounded-full px-3 py-1 text-sm/6 text-gray-500 ring-1 ring-gray-900/10 hover:ring-gray-900/20">
                            Produtos Personalizados para atender suas Demandas. 
                            <a 
                            (click)="Nat_goto('info')"
                            matTooltip="Conheça todas as Nossas Soluções"
                            matTooltipPosition="right"
                                class="whitespace-nowrap font-semibold text-blue-600 cursor-pointer hover:underline"><span class="absolute inset-0"
                                    aria-hidden="true"></span>Saber Mais<span aria-hidden="true">&rarr;</span></a>
                        </div>
                    </div>
                    <p
                        class="mt-24 text-pretty text-5xl font-semibold tracking-tight text-gray-900 sm:mt-10 sm:text-7xl">
                        XFera</p>
                    <p class="mt-2 text-pretty text-lg font-medium text-gray-500 sm:text-xl/8">Soluções feitas por quem entende para Potencializar seus Resultados e Maximizar seus Ganhos.</p>
                    <div class="mt-10 flex items-center gap-x-6">
                        <a
                        (click)="Nat_goto('login')"
                            class="rounded-md bg-blue-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm
                             hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2
                              focus-visible:outline-blue-600 cursor-pointer" 
                              matTooltip="Já tenho Cadastro e Quero Logar em um dos Módulos"
                              matTooltipPosition="above">Logar</a>
                        <a 
                        (click)="Nat_goto('cadastro')"
                        class="text-sm/6 font-semibold text-gray-900 cursor-pointer hover:underline hover:text-blue-700"
                        matTooltip="Não tenho Cadastro e Quero Solicitar Acesso a um dos Módulos"
                              matTooltipPosition="above"
                        >Cadastre-se <span
                                aria-hidden="true">→</span></a>
                    </div>
                </div>
            </div>
            <div class="relative lg:col-span-5 lg:-mr-8 xl:absolute xl:inset-0 xl:left-1/2 xl:mr-0 h-full">


                <app-hero-carousel-main></app-hero-carousel-main>


            </div>
        </div>
    </div>


<!--alert de sucesso-->
@if(Nat_status_alert_sucesso){
    <app-alert-sucesso
    [Input_dados_alert]="Nat_dados_alert"
    (Evento_close_alert_sucesso)="Out_close_alert_sucesso($event)"
    >
    </app-alert-sucesso>
}
<!--alert de erro-->
@if(Nat_status_alert_erro){
    <app-alert-erro
    [Input_dados_alert]="Nat_dados_alert"
    (Evento_close_alert_erro)="Out_close_alert_erro($event)"
    >
    </app-alert-erro>
}

</div>