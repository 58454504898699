<!-- Main modal -->
<div id="authentication-modal" tabindex="-1" aria-hidden="true" class="fixed inset-0 z-50 flex justify-center items-center w-full h-full bg-gray-500/75">
    <div class="relative p-4 w-full max-w-md">
      <!-- Modal content -->
      <div class="relative bg-white rounded-lg shadow">
        <!-- Modal header -->
        <div class="flex items-center justify-between p-4 border-b rounded-t">
          <a class="text-xl font-normal text-gray-900" >
           Alterar uma Equipe
          </a>
          <button (click)="Nat_close()" type="button" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center" data-modal-hide="authentication-modal">
            <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
            </svg>
            <span class="sr-only">Close modal</span>
          </button>
        </div>
        <!-- Modal body -->
        <div class="p-2">
            <form class="p-4 md:p-5">
                <div class="grid gap-4 mb-4 grid-cols-2">
                    <div class="col-span-2">
                        <label for="category" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Bases Cadastradas</label>
                      
                         
                        <mat-select [(ngModel)]="selectedValue" (selectionChange)="Nat_select_base()" name="food">
                            @for (base of Nat_bases; track base) {
                              <mat-option [value]="base.ID">{{base.NOME}}</mat-option>
                            }
                          </mat-select>
                     
                          
                    </div>


                
                    <div class="col-span-2">
                        <label for="name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Nome da Base</label>
                        <input #inp_nome_base type="text" name="name" id="name" 
                        class="bg-gray-50 border border-gray-300
                         text-gray-900 text-sm rounded-lg focus:ring-primary-600
                          focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600
                           dark:border-gray-500 dark:placeholder-gray-400 dark:text-white
                            dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Ex: Base São Paulo" required="">
                    </div>

                    <div class="col-span-2">
                        <label for="name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Coordenada da Base</label>
                        <input #inp_coordenada type="text" name="name" id="name" 
                        class="bg-gray-50 border border-gray-300
                         text-gray-900 text-sm rounded-lg focus:ring-primary-600
                          focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600
                           dark:border-gray-500 dark:placeholder-gray-400 dark:text-white
                            dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Ex: Base São Paulo" required="">
                    </div>

                    <div class="col-span-2">
                        <label for="name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Endereço e Observações</label>
                        <textarea #inp_endereco type="text" name="name" id="name" 
                        class="bg-gray-50 border border-gray-300
                         text-gray-900 text-sm rounded-lg focus:ring-primary-600
                          focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600
                           dark:border-gray-500 dark:placeholder-gray-400 dark:text-white
                            dark:focus:ring-primary-500 dark:focus:border-primary-500" 
                            placeholder="Ex: Av. das Nações Unidas, 14401 - Vila Gertrudes, São Paulo - SP, 04794-000" required=""></textarea>
                    </div>
                  
                    
                </div>
                <div class="flex space-x-2">
                  <button (click)="save_alteracao()"
                      type="button" 
                      class="text-white inline-flex items-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-1.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                     
                      Salvar Edição
                  </button>
              
                  <button (click)="delete_base()"
                      type="button" 
                      class="text-white inline-flex items-center bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-1.5 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800">
                     
                      Deletar Base
                  </button>
              </div>
              
                
            </form>
        </div>
      </div>
    </div>
  </div>
  