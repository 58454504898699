import { Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';

import { Router } from '@angular/router';
import { LoginService } from '../../SERVICES/login.service';
import { RESPOSTA_PADRAO_SERVIDOR } from '../../../../MODELS/RESPOSTA_PADRAO_SERVIDOR';

@Component({
  selector: 'app-login-modal-reset-senha',
  standalone: true,
  imports: [],
  templateUrl: './login-modal-reset-senha.component.html',
  styleUrl: './login-modal-reset-senha.component.css'
})
export class LoginModalResetSenhaComponent {

  constructor(

    private router: Router,
    private service: LoginService
  ) { }

  @Output() Evento_close_modal_reset_senha = new EventEmitter<boolean>();
  @Output() Evento_close_modal_reset_senha_status = new EventEmitter<boolean>();

  Nat_close_modal() {
    this.Evento_close_modal_reset_senha.emit(false)
  }

  @ViewChild('inp_token') inp_token!: ElementRef;
  @ViewChild('inp_email') inp_email!: ElementRef;
  @ViewChild('inp_password') inp_password!: ElementRef;
  async Nat_reset_senha() {

    const email: string = this.inp_email.nativeElement.value
    const senha: string = this.inp_password.nativeElement.value
    const token: string = this.inp_token.nativeElement.value

    const requisicao_server: any = await this.service.POST_RESET_SENHA({
      email: email,
      senha:senha,
      token:token
    })
    const resposta: RESPOSTA_PADRAO_SERVIDOR = requisicao_server

    if (resposta.MSG == 'SUCESSO') {
      this.Evento_close_modal_reset_senha_status.emit(true)
    } else {
      this.Evento_close_modal_reset_senha_status.emit(false)
    }


  }
}
