import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { AsyncPipe } from '@angular/common';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DADOS_ALERT } from '../../MODELS/DADOS_ALERT';
import { OPERACAO_ou_LIVRO } from '../../MODELS/OPERACAO_ou_LIVRO';
import { GpxService } from '../../MODULES/GPX/SERVICES/gpx.service';
import { MainService } from '../../SERVICES/main.service';
import { RESPOSTA_PADRAO_SERVIDOR } from '../../MODELS/RESPOSTA_PADRAO_SERVIDOR';
import { environment } from '../../../environments/environment.development';
import { DETALHES_POLIGONO } from '../../MODELS/DETALHES_POLIGONO';
@Component({
  selector: 'app-gpx-detalhes-poligono',
  standalone: true,
  imports: [
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    AsyncPipe,
  ],
  templateUrl: './gpx-detalhes-poligono.component.html',
  styleUrl: './gpx-detalhes-poligono.component.css'
})
export class GpxDetalhesPoligonoComponent implements OnInit {
  myControl = new FormControl('');
  options: string[] = [];
  filteredOptions: Observable<string[]>;


  @Input() Input_poligono_recebido: DETALHES_POLIGONO

  Nat_tipo_alteracao: string = ""
  Nat_altera_tipo(event: Event): void {
    const selectElement = event.target as HTMLSelectElement;
    this.Nat_tipo_alteracao = selectElement.value;

  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.options.filter(option => option.toLowerCase().includes(filterValue));
  }



  constructor(private service: GpxService, private main_service: MainService) {


  }
  Nat_operacoes: OPERACAO_ou_LIVRO[] = []

  /**evento que abre a aba de loading no component main */
  @Output() Evento_open_loading = new EventEmitter<boolean>();

  /**evento que fecha a aba de loading no component main */
  @Output() Evento_close_loading = new EventEmitter<boolean>();

  /**evento que abre um alerta de sucesso no component main */
  @Output() Evento_open_alert_sucess = new EventEmitter<DADOS_ALERT>();

  /**evento que abre um alerta de erro no component main */
  @Output() Evento_open_alert_erro = new EventEmitter<DADOS_ALERT>();

  /**fehca a janela atual */
  @Output() Evento_close_detalhes_poligono = new EventEmitter<boolean>();


  async ngOnInit() {

    this.Evento_open_loading.emit(true)

    const requisicao_server: any = await this.service.POST_OPERACOES_GPX_EDICOES(environment.user_atual.ID)
    const resposta: RESPOSTA_PADRAO_SERVIDOR = requisicao_server


    if (resposta.MSG == 'SUCESSO') {

      this.Nat_operacoes = resposta.RESPOSTA



      this.Nat_operacoes.forEach((operacao, o) => {
        this.options.push(`${operacao.ID_STR} - ${operacao.CENTRO_LEITURA_STR} - ${operacao.GRUPO_STR}`)
      });

      this.filteredOptions = this.myControl.valueChanges.pipe(
        startWith(''),
        map(value => this._filter(value || '')),
      );

      this.Evento_close_loading.emit(true)
    } else {
      this.Evento_close_loading.emit(true)
      let new_alert: DADOS_ALERT = {
        TITULO: resposta.MSG,
        CORPO: resposta.ADICIONAL
      }
      this.Evento_open_alert_erro.emit(new_alert)
    }
  }


  /**evento que retira os itens do mapa e os repoe atualizados */
  @Output() Evento_atualiza_mapa = new EventEmitter<boolean>();


  @ViewChild('inp_nova_coordenada') inp_nova_coordenada!: ElementRef
  async Nat_enviar_alteracao_coordenada() {
    this.Evento_open_loading.emit(true)
    const nova_coordenada_input: string = this.inp_nova_coordenada.nativeElement.value.trim();

    // Expressão regular para validar o formato "latitude, longitude"
    const coordenadaRegex = /^(-?\d{1,2}(?:\.\d+)?),\s*(-?\d{1,3}(?:\.\d+)?)$/;

    if (coordenadaRegex.test(nova_coordenada_input)) {




      const [latitude, longitude] = nova_coordenada_input.split(',').map(coord => parseFloat(coord.trim()));

      const requisicao_server: any = await this.service.POST_MUDAR_COORDENADA(latitude, longitude, this.Input_poligono_recebido.ITENS_ID)
      const resposta: RESPOSTA_PADRAO_SERVIDOR = requisicao_server


      if (resposta.MSG == 'SUCESSO') {

        this.inp_nova_coordenada.nativeElement.value = ''
        this.Nat_tipo_alteracao = ''

        this.Evento_atualiza_mapa.emit(true)


        this.Evento_close_loading.emit(true)
        
        let new_alert: DADOS_ALERT = {
          TITULO: resposta.MSG,
          CORPO: resposta.ADICIONAL
        }
        this.Evento_open_alert_sucess.emit(new_alert)
        this.Nat_close_detalhes_poligono()

      } else {
        this.Evento_close_loading.emit(true)
        let new_alert: DADOS_ALERT = {
          TITULO: resposta.MSG,
          CORPO: resposta.ADICIONAL
        }
        this.Evento_open_alert_erro.emit(new_alert)
      }



    } else {
      // Coordenada inválida
      this.Evento_close_loading.emit(true)
      let new_alert: DADOS_ALERT = {
        TITULO: "ERRO",
        CORPO: "Formato inválido. Use o formato: -25.436251, -49.337996"
      }
      this.Evento_open_alert_erro.emit(new_alert)

    }
  }


  async Nat_enviar_alteracao_operacao() {
    /**
     * envia os itens selecionados para a operacao indicada pelo usuario
     */

    const id_destino: number = parseInt(this.myControl.value.split('.')[0].replace('ID ', ''))


    if (id_destino) {




      const requisicao_server: any = await this.service.POST_MUDAR_OPERACAO_GPX(id_destino, this.Input_poligono_recebido.ITENS_ID)
      const resposta: RESPOSTA_PADRAO_SERVIDOR = requisicao_server


      if (resposta.MSG == 'SUCESSO') {


        this.Nat_tipo_alteracao = ''

        this.Evento_atualiza_mapa.emit(true)


        this.Evento_close_loading.emit(true)
        let new_alert: DADOS_ALERT = {
          TITULO: resposta.MSG,
          CORPO: resposta.ADICIONAL
        }
        this.Evento_open_alert_sucess.emit(new_alert)


        this.Nat_close_detalhes_poligono()


      } else {
        this.Evento_close_loading.emit(true)
        let new_alert: DADOS_ALERT = {
          TITULO: resposta.MSG,
          CORPO: resposta.ADICIONAL
        }
        this.Evento_open_alert_erro.emit(new_alert)
      }



    } else {
      // Coordenada inválida
      this.Evento_close_loading.emit(true)
      let new_alert: DADOS_ALERT = {
        TITULO: "ERRO",
        CORPO: "Selecione a Operação de Destino"
      }
      this.Evento_open_alert_erro.emit(new_alert)

    }
  }


  Nat_close_detalhes_poligono() {
    this.Evento_close_detalhes_poligono.emit(false)
  }



}
