import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { DADOS_ALERT } from '../../../../MODELS/DADOS_ALERT';
import { RESPOSTA_PADRAO_SERVIDOR } from '../../../../MODELS/RESPOSTA_PADRAO_SERVIDOR';
import { Router } from '@angular/router';
import { RoutexService } from '../../SERVICES/routex.service';
import { MainService } from '../../../../SERVICES/main.service';
import { NEW_INICIO_EXTERNO_EXEC } from '../../../../MODELS/NEW_INICIO_EXTERNO_EXEC';
import { DETALHES_POLIGONO } from '../../../../MODELS/DETALHES_POLIGONO';

@Component({
  selector: 'app-routex-novo-inicio',
  standalone: true,
  imports: [],
  templateUrl: './routex-novo-inicio.component.html',
  styleUrl: './routex-novo-inicio.component.css'
})
export class RoutexNovoInicioComponent {

  constructor(private router: Router, private service: RoutexService, private service_main: MainService) { }

@Output() Evento_close_novo_inicio = new EventEmitter<boolean>()
@Output() Evento_repoemapa = new EventEmitter<boolean>()


  /**evento que abre a aba de loading no component main */
  @Output() Evento_open_loading = new EventEmitter<boolean>();

  /**evento que fecha a aba de loading no component main */
  @Output() Evento_close_loading = new EventEmitter<boolean>();

  /**evento que abre um alerta de sucesso no component main */
  @Output() Evento_open_alert_sucess = new EventEmitter<DADOS_ALERT>();

  /**evento que abre um alerta de erro no component main */
  @Output() Evento_open_alert_erro = new EventEmitter<DADOS_ALERT>();


  @Input() input_detalhes_poligono:DETALHES_POLIGONO

  @ViewChild('inp_nova_coordenada') inp_nova_coordenada!: ElementRef
  @ViewChild('inp_nome') inp_nome!: ElementRef

  Nat_modal_escolhido:string = ''
  Nat_select_modal(modal:string){
    this.Nat_modal_escolhido = modal
  }

  async Nat_delete_origem_externa(){



    let novo_inicio:NEW_INICIO_EXTERNO_EXEC = {
      LIVRO: this.input_detalhes_poligono.LIVRO.replace('.', ''),
      OPERACAO_ID: this.input_detalhes_poligono.OPERACAO_ID,
      MODAL: this.Nat_modal_escolhido,
      LAT: 0,
      LNG: 0,
      NOME: ''
    }

    const requisicao_server: any = await this.service.POST_DELETAR_INICIO_EXTERNO(novo_inicio);
    const resposta: RESPOSTA_PADRAO_SERVIDOR = requisicao_server;
    
    if (resposta.MSG === 'SUCESSO') {
      this.Evento_close_loading.emit(true)
      let new_alert: DADOS_ALERT = {
        TITULO: resposta.MSG,
        CORPO: resposta.ADICIONAL
      };
     this.Evento_open_alert_sucess.emit(new_alert)
    }else{
        this.Evento_close_loading.emit(true)
        let new_alert: DADOS_ALERT = {
          TITULO: resposta.MSG,
          CORPO: resposta.ADICIONAL
        };
       this.Evento_open_alert_erro.emit(new_alert)
      }
    
  }
  async Nat_criar_ponto_partida(){
    this.Evento_open_loading.emit(true)
    const nova_coordenada_input: string = this.inp_nova_coordenada.nativeElement.value.trim();
    const nome:string = this.inp_nome.nativeElement.value
    // Expressão regular para validar o formato "latitude, longitude"
    const coordenadaRegex = /^(-?\d{1,2}(?:\.\d+)?),\s*(-?\d{1,3}(?:\.\d+)?)$/;

    if (coordenadaRegex.test(nova_coordenada_input) && nome != "" && this.Nat_modal_escolhido != "") {
      const [latitude, longitude] = nova_coordenada_input.split(',').map(coord => parseFloat(coord.trim()));

      let novo_inicio:NEW_INICIO_EXTERNO_EXEC = {
        LIVRO: this.input_detalhes_poligono.LIVRO.replace('.', ''),
        OPERACAO_ID: this.input_detalhes_poligono.OPERACAO_ID,
        LAT: latitude,
        LNG: longitude,
        NOME: nome,
        MODAL: this.Nat_modal_escolhido
      }


      const requisicao_server: any = await this.service.POST_NOVO_INICIO_EXTERNO(novo_inicio);
      const resposta: RESPOSTA_PADRAO_SERVIDOR = requisicao_server;
      
      if (resposta.MSG === 'SUCESSO') {
        this.Evento_repoemapa.emit(true)
      
       this.Nat_close()
      }else{
        this.Evento_close_loading.emit(true)
        let new_alert: DADOS_ALERT = {
          TITULO: resposta.MSG,
          CORPO: resposta.ADICIONAL
        };
       this.Evento_open_alert_erro.emit(new_alert)
      }

    }else{
      this.Evento_close_loading.emit(true)
      let new_alert: DADOS_ALERT = {
        TITULO: 'ERRO',
        CORPO: 'Parãmetros Fornecidos Incorretos!'
      };
     this.Evento_open_alert_erro.emit(new_alert)
    }




      
  }

  Nat_close(){
    this.Evento_close_novo_inicio.emit(false)
  }




}
