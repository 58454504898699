import { AfterViewInit, Component, ViewChild, OnInit, ElementRef } from '@angular/core';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

import { Router } from '@angular/router';



import * as XLSX from 'xlsx';
import moment from 'moment';
import { StoxService } from '../../SERVICES/stox.service';
import { environment } from '../../../../../environments/environment.development';
import { RETIRADA, RETORNO_SERVIDOR } from '../../../../MODELS/MODELS_STOX';
@Component({
  selector: 'app-retiradas',
  standalone: true,
  imports: [MatFormFieldModule,
    MatInputModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,],
  templateUrl: './retiradas.component.html',
  styleUrl: './retiradas.component.css'
})
export class RetiradasComponent implements AfterViewInit {




  displayedColumns_itens_retiradas: string[] = ['INFORMACOES', 'TIPO', 'QUANTIDADE', 'DATA', 'USER', 'CCE', 'DOC', 'OSME'];
  dataSource_itens_retiradas: MatTableDataSource<RETIRADA>;

  @ViewChild('sort_itens_retiradas') sort_itens_retiradas = new MatSort();
  @ViewChild('paginator_itens_retiradas') paginator_itens_retiradas: MatPaginator;

  constructor(private allservices: StoxService, private router: Router) {
  }
  goto(page: string) {
    this.router.navigateByUrl(page)
  }

  @ViewChild('span_username') span_username!: ElementRef



  async ngAfterViewInit() {
    if (!environment.user_atual) {
      this.router.navigateByUrl('')
    } else {
      this.span_username.nativeElement.innerText  = environment.user_atual.NOME
      const req_buscar_users: any = await this.allservices.POST_listar_users({EMPRESA_ID:environment.user_atual.EMPRESA_ID})
      const retorno_3: RETORNO_SERVIDOR = req_buscar_users

  

      if (retorno_3.MSG == 'SUCESSO') {
        const req_retiradas: any = await this.allservices.POST_listar_retiradas(environment.user_atual.EMPRESA_ID)
        let retorno_2: RETORNO_SERVIDOR = req_retiradas



        if (retorno_2.MSG == 'SUCESSO') {

          retorno_2.RESPOSTA.forEach((item, i) => {
            const foundItem = retorno_3.RESPOSTA.find(retornoItem => retornoItem.ID === item.USER_ID);
            if (foundItem) {
              retorno_2.RESPOSTA[i].USER_ID = foundItem.NOME
            }
          });


          this.dataSource_itens_retiradas = new MatTableDataSource(retorno_2.RESPOSTA);

          this.dataSource_itens_retiradas.paginator = this.paginator_itens_retiradas;
          this.dataSource_itens_retiradas.sort = this.sort_itens_retiradas;
        } else {
          alert('Não foi possivel listar as retiradas')
        }


      } else {
        alert('Não foi possivel identificar os usuários das retiradas')
      }

    }




  }

  applyFilter_itens_retiradas(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource_itens_retiradas.filter = filterValue.trim().toLowerCase();

    if (this.dataSource_itens_retiradas.paginator) {
      this.dataSource_itens_retiradas.paginator.firstPage();
    }
  }



  download_filtradas() {
    const now = moment().format('DD-MM-YYYY HH:mm:ss');


    this.downloadExcelFromJson(this.dataSource_itens_retiradas.filteredData,`RETIRADAS FILTRADAS EM ${now}`)

  }
  download_todas() {
    const now = moment().format('YYYY-MM-DD HH:mm:ss');

    this.downloadExcelFromJson(this.dataSource_itens_retiradas.data,`RETIRADAS EM ${now}`)

  }


  downloadExcelFromJson(jsonData: any[], fileName: string) {
    // Cria uma nova planilha a partir do array JSON
    const worksheet = XLSX.utils.json_to_sheet(jsonData);

    // Cria um novo livro (workbook) e adiciona a planilha
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Dados");

    // Gera um buffer binário a partir do workbook
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

    // Cria um blob a partir do buffer e dispara o download
    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });

    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${fileName}.xlsx`);

    document.body.appendChild(link);
    link.click();

    // Remove o link após o download
    document.body.removeChild(link);
  }


  async delete_retirada(item:RETIRADA){
    const req_delete_item: any = await this.allservices.POST_delete_retirada(item.ID)
    const retorno_4: RETORNO_SERVIDOR = req_delete_item

    if (retorno_4.MSG == 'SUCESSO') {
      alert(retorno_4.ADICIONAL)

this.ngAfterViewInit()

    } else {
      alert(retorno_4.ADICIONAL)
    }
  }

}
