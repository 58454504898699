<div class="px-4 z-30 absolute w-full bg-white top-16 py-4">
  <div class="sm:flex sm:items-center mt-2">


  </div>
  <!-- Search input -->
  <div class="mt-2">
    <input matInput (keyup)="applyFilter($event)" #input type="text" placeholder="Buscar Notas..."
      class="w-96 rounded-md border-gray-300 px-4 py-2 text-sm focus:border-blue-600 focus:ring-blue-600"
      id="search-input">
  </div>
  <div class="mt-2 flow-root">
    <div class=" -my-2 overflow-x-auto ">
      <div class="inline-block min-w-full py-2 align-middle">
        <div class="relative pb-4">


          <table mat-table [dataSource]="dataSource" matSort class="min-w-full table-fixed divide-y divide-gray-300">

       
<!-- ID Column -->
<ng-container matColumnDef="VISIVEL">


  <th mat-header-cell *matHeaderCellDef class="px-7 sm:w-12 sm:px-6">
    <div class="flex justify-center items-center h-full">
      @if(input_pendentes_mapa.length>0){
        <input #checkbox_all checked (change)="Nat_change_view()" type="checkbox"
        class="size-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600">
      }@else{
        <input #checkbox_all (change)="Nat_change_view()" type="checkbox"
        class="size-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600">
      }
      
    </div>
  </th>


  <td mat-cell *matCellDef="let row" class="px-7 sm:w-12 sm:px-6">
    <div class="flex justify-center items-center h-full">
      @if(row.VISIVEL){
        <input type="checkbox" checked (change)="Nat_remove_nota(row.ID)"
        class="size-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600">
      }@else{
        <input (change)="Nat_view_nota(row.ID)" type="checkbox"
        class="size-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600">
      }
     
    </div>
  </td>
</ng-container>


            <!-- Progress Column -->
            <ng-container matColumnDef="ID_STR">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
              <td mat-cell *matCellDef="let row"> {{row.ID_STR}} </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="INSTALACAO">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> INSTALACAO </th>
              <td mat-cell *matCellDef="let row"> {{row.INSTALACAO}} </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="ENDERECO">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> ENDEREÇO </th>
              <td mat-cell *matCellDef="let row"> {{row.ENDERECO}} </td>
            </ng-container>
            <!-- Name Column -->
            <ng-container matColumnDef="NUM_MEDIDA">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> MEDIDA </th>
              <td mat-cell *matCellDef="let row"> {{row.NUM_MEDIDA}} </td>
            </ng-container>
            <!-- Name Column -->
            <ng-container matColumnDef="COD_NOTA">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> COD. NOTA </th>
              <td mat-cell *matCellDef="let row"> {{row.COD_NOTA}} </td>
            </ng-container>
            <!-- Name Column -->
            <ng-container matColumnDef="LIVRO">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> LIVRO </th>
              <td mat-cell *matCellDef="let row"> {{row.LIVRO}} </td>
            </ng-container>
            <!-- Name Column -->
            <ng-container matColumnDef="DATA_GERACAO_STR">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> GERADO EM </th>
              <td mat-cell *matCellDef="let row"> {{row.DATA_GERACAO_STR}} </td>
            </ng-container>
            <!-- Name Column -->
            <ng-container matColumnDef="DATA_PREV_CONC_SIS_STR">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> PREVISTO PARA </th>
              <td mat-cell *matCellDef="let row"> {{row.DATA_PREV_CONC_SIS_STR}} </td>
            </ng-container>
            <!-- Name Column -->
            <ng-container matColumnDef="IMP_ID_STR">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> IMPORTAÇÃO </th>
              <td mat-cell *matCellDef="let row"> {{row.IMP_ID_STR}} </td>
            </ng-container>
            <!-- Name Column -->
            <ng-container matColumnDef="TIPO">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> TIPO </th>
              <td mat-cell *matCellDef="let row"> {{row.TIPO}} </td>
            </ng-container>
            <!-- Name Column -->
            <ng-container matColumnDef="DESC_SERVICO">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> SERVIÇO </th>
              <td mat-cell *matCellDef="let row"> {{row.DESC_SERVICO}} </td>
            </ng-container>
            <!-- Name Column -->
            <ng-container matColumnDef="CIDADE">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> CIDADE </th>
              <td mat-cell *matCellDef="let row"> {{row.CIDADE}} </td>
            </ng-container>
            <!-- Name Column -->
            <ng-container matColumnDef="COORDENADA_MINERADA">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> MINERADA </th>
              <td mat-cell *matCellDef="let row">
                @if(row.COORDENADA_MINERADA){
                  SIM
                }@else {
                  NÃO
                }
                 
                </td>
            </ng-container>
            <!-- Fruit Column -->
            <ng-container matColumnDef="ACOES">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>

              </th>
              <td mat-cell *matCellDef="let row">
                <div class="w-full space-x-2">
                  <a (click)="Nat_change_state_mudar_coordenada(row.ID)" class="rounded-md
                        bg-blue-50 px-2 py-1 
                        text-xs font-medium text-blue-700 ring-1 
                        ring-inset ring-blue-600/20 cursor-pointer
                        hover:bg-blue-100
                        ">Mudar Coordenada</a>

                  <a (click)="Nat_delete_nota(row.ID)" class="rounded-md
                        bg-red-50 px-2 py-1 
                        text-xs font-medium text-red-700 ring-1 
                        ring-inset ring-red-600/20 cursor-pointer
                        hover:bg-red-100
                        ">Deletar</a>
                </div>

              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"
              class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"
              class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 hover:bg-slate-100"></tr>

            <!-- Row shown when there is no matching data. -->
            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
            </tr>
          </table>

          <mat-paginator [pageSizeOptions]="[5]" aria-label="Select page of users"></mat-paginator>


        </div>
      </div>
    </div>
  </div>
</div>